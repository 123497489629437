import React from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { TransportOrder } from '../../../models/transport-order'
import { handle } from '../../../utils/error-handlers'

interface CifModalProps {
  data: TransportOrder
  refetch: () => void
}

interface CifForm {
  vlrFrd: string
}

const CifModal: React.FC<CifModalProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<CifForm>({
    defaultValues: {
      vlrFrd: data.vlrFrd.replace('.', ',')
    }
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: CifForm): Promise<void> => {
    await handle(async () => {
      const vlrFrd = formData.vlrFrd.replace(',', '.')
      const response = await httpPatch<string>(`/expedition/cif/${data.numPed}`, {
        codEmp: data.codEmp,
        codFil: data.codFil,
        vlrFrd
      })
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Atualizar valor do frete CIF</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Valor"
          name="vlrFrd"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default CifModal
