import styled from 'styled-components'
import { colors } from '../../../../styles/default-styles'

export const Container = styled.div`
  max-width: 500px;
`

export const PermissionsContainer = styled.div`
  table {
    width: 100%;
    text-align: left;
    border-spacing: 0;
  }

  thead tr th {
    padding: 10px 0 5px;
    font-size: 9px;
    color: ${colors.mediumGray};
    text-transform: uppercase;
    font-weight: 400;
  }
  
  th:not(:first-child) {
    width: 70px;
    text-align: center;
  }

  td {
    padding: 5px 0;

    .checkbox {
      margin: 0 0 13px 0;
    }
  }

  td:not(:first-child) {
    vertical-align: super;
    text-align: center;
  }

  td:first-child {
    vertical-align: middle;
  }

  tbody tr:hover {
    background-color: ${colors.secondary};
  }
`
