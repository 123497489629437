import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const Container = styled.div`
  margin: 15px;
  padding: 15px;
  background-color: white;
  border: 1px solid ${colors.borders};
  border-radius: 2px;

  & > h2 {
    border-bottom: 1px solid ${colors.lightGray};
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  .date {
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word; 
  }
`
