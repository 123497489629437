import React from 'react'
import {
  BrowserRouter,
  Switch,
  Route
} from 'react-router-dom'
import Layout from '../Layout'
import Login from '../Login'
import PrivateRoute from './private-route'
import { routes } from './routes'

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Login />} />
        <Layout>
          {routes.map(route => (
            <PrivateRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              render={() => route.component}
            />
          ))}
        </Layout>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
