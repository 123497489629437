import React, {
  useCallback, useEffect, useState
} from 'react'
import Loader from '../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../components/Table'
import {
  useApi,
  useFetch,
  useLoading,
  useDetailsContainer,
  useModal
} from '../../hooks'
import { Container } from './styles'
import { CostTableModel } from '../../models/cost-table'
import CostTableDetails from './CostTableDetails'
import AttMarginCostModal from './MarginCost'
import AttToleranceCostModal from './ToleranceCost'
import Filter from './Filter'
import AttBasePriceModal from './BasePrice'

const CostTable: React.FC = () => {
  const { httpGet } = useApi()
  const {
    data,
    loading,
    refetching,
    refetch
  } = useFetch<CostTableModel[]>(httpGet, '/cost-table/list-costs')
  const { open: openModal } = useModal()
  const {
    close,
    pushPage,
    open
  } = useDetailsContainer()
  const { loading: loadingHook } = useLoading()
  const [filter, setFilter] = useState(() => ({
    tabela: null,
    produto: null
  }))
  const [filtered, setFiltered] = useState<CostTableModel[]>([])

  useEffect(() => {
    if (data) {
      if (filter.tabela === '') {
        setFiltered(data?.filter((item) => { return item.descricaoProdutoDerivacao === filter.produto }) ?? [])
      } else if (filter.produto === '') {
        setFiltered(data?.filter((item) => { return item.destpr === filter.tabela }) ?? [])
      } else {
        setFiltered(data?.filter((item) => { return item.destpr === filter.tabela && item.descricaoProdutoDerivacao === filter.produto }) ?? [])
      }
    }
  },
  [filter, data])

  const openAttMarginCost = useCallback((data: CostTableModel): () => void => {
    return () => {
      openModal(
        <AttMarginCostModal
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [refetch, openModal])

  const openAttBasePrice = useCallback((data: CostTableModel): () => void => {
    return () => {
      openModal(
        <AttBasePriceModal
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [refetch, openModal])

  const openAttToleranceCost = useCallback((data: CostTableModel): () => void => {
    return () => {
      openModal(
        <AttToleranceCostModal
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [refetch, openModal])

  const openDetails = useCallback((costTable: CostTableModel): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <CostTableDetails
            openAttBasePrice={openAttBasePrice}
            openAttMarginCost={openAttMarginCost}
            openAttTolerancecCost={openAttToleranceCost}
            data={costTable}
            refetch={refetch}
          />
        ),
        title: 'Tabela De Custo'
      })
      open()
    }
  }, [openAttMarginCost, openAttBasePrice, openAttToleranceCost, close, pushPage, open, refetch])

  return (
    <>
      <Filter
        refetch={refetch}
        setFilter={setFilter}
        data={data ?? []}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!filtered?.length}
            overflow="auto"
          >
            <THead>
              <Tr>
                <Th color="#000" width="30px">Empresa</Th>
                <Th color="#000" width="50px">Tabela</Th>
                <Th color="#000" width="70px">Código Pro.</Th>
                <Th color="#000" width="140px">Descrição Produto</Th>
                <Th color="#000" width="50px">Preço Médio</Th>
                <Th color="#000" width="30px">%Comissão</Th>
                <Th color="#000" width="40px">%Menor</Th>
                <Th color="#000" width="40px">%Curva ABC</Th>
                <Th color="#000" width="50px">%Cus. Direto</Th>
                <Th width="40px" color="#000">%Cus. Indireto</Th>
                <Th width="40px" color="#000">%Despesa</Th>
                <Th width="60px" color="#000">%Mar. Lucro</Th>
                <Th width="50px" color="#000">%Pis</Th>
                <Th width="40px" color="#000">%Cofins</Th>
                <Th width="50px" color="#000">%Csll</Th>
                <Th width="50px" color="#000">%IRPJ</Th>
                <Th width="20px" color="#000">Preço Base</Th>
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item, index) => (
                <Tr key={index}>
                  <Td title={item.codemp}>
                    <button
                      className="first-item"
                      onClick={openDetails(item)}
                    >
                      {item.codemp}
                    </button>
                  </Td>
                  <Td title={item.codtpr} className="hide-md">{item.codtpr}</Td>
                  <Td title={item.codpro} className="hide-sm">{item.codpro}</Td>
                  <Td title={item.descricaoProdutoDerivacao}>{item.descricaoProdutoDerivacao}</Td>
                  <Td title={item.premed}>{parseFloat(item.premed).toFixed(4)}</Td>
                  <Td title={item.percom}>{parseFloat(item.percom).toFixed(4)}</Td>
                  <Td title={item.tolmen}>{parseFloat(item.tolmen).toFixed(4)}</Td>
                  <Td title={item.usu_curabc}>{parseFloat(item.usu_curabc).toFixed(4)}</Td>
                  <Td title={item.usu_cusdir}>{parseFloat(item.usu_cusdir).toFixed(4)}</Td>
                  <Td title={item.usu_cusind}>{parseFloat(item.usu_cusind).toFixed(4)}</Td>
                  <Td title={item.usu_vlrdes}>{parseFloat(item.usu_vlrdes).toFixed(4)}</Td>
                  <Td title={item.usu_marluc}>{parseFloat(item.usu_marluc).toFixed(4)}</Td>
                  <Td title={item.usu_vlrpis}>{parseFloat(item.usu_vlrpis).toFixed(4)}</Td>
                  <Td title={item.usu_vlrcof}>{parseFloat(item.usu_vlrcof).toFixed(4)}</Td>
                  <Td title={item.usu_vlrcsl}>{parseFloat(item.usu_vlrcsl).toFixed(4)}</Td>
                  <Td title={item.usu_vlripj}>{parseFloat(item.usu_vlripj).toFixed(4)}</Td>
                  <Td textAlign='left' title={item.prebas}>{parseFloat(item.prebas).toFixed(3)}</Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default CostTable
