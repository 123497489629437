import { Action } from '../types'
import { ModalActions } from './modal-actions'

export interface Content {
  onClose?: () => void
}

export interface InitialState {
  opened: boolean
  component: JSX.Element | null
}

const initialState: InitialState = {
  opened: false,
  component: null
}

export const modalReducer = (
  state = initialState,
  action: Action
): InitialState => {
  switch (action.type) {
    case ModalActions.OPEN:
      return { ...state, ...action }
    case ModalActions.CLOSE:
      return { ...initialState }
    default:
      return state
  }
}
