import { useCallback, useState } from 'react'

interface UseLoading {
  load: (cb: () => Promise<void>) => Promise<void>
  loading: boolean
}

export const useLoading = (): UseLoading => {
  const [loading, setLoading] = useState(false)

  const load = useCallback(async (cb: () => Promise<void>): Promise<void> => {
    try {
      setLoading(true)
      await cb()
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  return {
    load,
    loading
  }
}
