import React from 'react'
import { useFormContext } from 'react-hook-form'
import Field from '../Field'
import { InputStyle } from './styles'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  register: any
  label: string
}

const Input: React.FC<InputProps> = ({
  register,
  name,
  label,
  type = 'text',
  ...props
}) => {
  const { errors } = useFormContext()

  return (
    <Field
      id={name}
      error={errors[name]?.message}
      label={label}
    >
      <InputStyle
        name={name}
        ref={register}
        type={type}
        autoComplete="off"
        {...props}
      />
    </Field>
  )
}

export default Input
