import React from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import Select from '../../../components/Select'
import SelectApi from '../../../components/Select/api'

interface AddClientProps {
  data: any
  refetch: () => void
}

interface AddClientForm{
  apeCli: string
  baiCli: string
  cepCli: string
  cgcCpf: string
  cidCli: string
  cliCon: string
  codFpg: string
  codRam: string
  codVen: string
  codcpg: string
  confin: string
  cplEnd: string
  endCli: string
  fonCli: string
  insEst: string
  intNet: string
  nenCli: string
  nomCli: string
  sigUfs: string
  sitCli: string
  tipCli: string
  tipEmp: string
  tipMer: string
}

const AddClient: React.FC<AddClientProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AddClientForm>({
    mode: 'onTouched',
    defaultValues: {
      apeCli: data.razao_social,
      baiCli: data.bairro,
      cepCli: data.cep,
      cgcCpf: data.cnpj,
      cidCli: data.municipio,
      cliCon: 'S',
      confin: 'N',
      cplEnd: data.complemento,
      endCli: data.logradouro,
      fonCli: data.telefone_1,
      insEst: 'ISENTO',
      intNet: data.email,
      nenCli: data.numero,
      nomCli: data.razao_social,
      sigUfs: data.uf,
      sitCli: 'A',
      tipCli: 'J',
      tipEmp: 'J',
      tipMer: 'I'
    }
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()

  const handleSendData = async (formData: AddClientForm): Promise<void> => {
    await handle(async () => {
      const json = {
        apeCli: formData.apeCli,
        baiCli: formData.baiCli,
        cepCli: formData.cepCli,
        cgcCpf: formData.cgcCpf,
        cidCli: formData.cidCli,
        cliCon: formData.cliCon,
        codFpg: formData.codFpg,
        codRam: formData.codRam,
        codVen: formData.codVen,
        codcpg: formData.codcpg,
        confin: formData.confin,
        cplEnd: formData.cplEnd,
        endCli: formData.endCli,
        fonCli: formData.fonCli,
        insEst: formData.insEst,
        intNet: formData.intNet,
        nenCli: formData.nenCli,
        nomCli: formData.nomCli,
        sigUfs: formData.sigUfs,
        sitCli: formData.sitCli,
        tipCli: formData.tipCli,
        tipEmp: formData.tipEmp,
        tipMer: formData.tipMer
      }

      const response = await httpPost<string>('/register/insert-client', json)

      if (response.body !== 'OK') {
        toast.error(response.body ?? 'Ocorreu um erro ao inserir o cliente.')

        return
      }

      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }
  return (
    <div>
      <h3>Cadastrar Novo Cliente</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Nome Cliente"
          name="nomCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Nome Fantasia"
          name="apeCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Tipo Cliente"
          name="tipCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Select
          readonly
          label="Tipo Empresa"
          name="tipEmp"
          options={[{
            display: '1 - INDUSTRIA',
            value: '1'
          }, {
            display: '2 - DISTRIBUIDOR',
            value: '2'
          }, {
            display: '3 - COMÉRCIO',
            value: '3'
          }
          ]}
          register={register()}
        />
        <Input
          label="Tipo Mercado"
          name="tipMer"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="desRam"
          readonly={false}
          label="Ramo de Atividade"
          name="codRam"
          register={register({
          })}
          url="register/list-ramo"
          valueProperty="codRam"
        />
        <Input
          label="CPF/CNPJ"
          name="cgcCpf"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Inscrição Estadual"
          name="insEst"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Endereço Cliente"
          name="endCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Número do Endereço"
          name="nenCli"
          register={register({
          })}
        />
        <Input
          label="Bairro Cliente"
          name="baiCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Complemento Endereço"
          name="cplEnd"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Cidade Cliente"
          name="cidCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Estado Cliente"
          name="sigUfs"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="CEP Cliente"
          name="cepCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="E-mail Cliente"
          name="intNet"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Telefone Cliente"
          name="fonCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Consumidor Final (S/N)"
          name="confin"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Cliente Contribuinte (S/N)"
          name="cliCon"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="desfpg"
          readonly={false}
          label="Forma de Pagamento"
          name="codFpg"
          register={register({
          })}
          url={'/salesorder/list-payment-form/2'}
          valueProperty="codfpg"
        />
        <SelectApi
          displayProperty="descpg"
          readonly={false}
          label="Condição de Pagamento"
          name="codcpg"
          register={register({
          })}
          url={'/salesorder/list-payment-condition/2/1/1'}
          valueProperty="codcpg"
        />
        <SelectApi
          displayProperty="nomVen"
          readonly={false}
          label="Vendedor"
          name="codVen"
          register={register({
          })}
          url="register/list-seller"
          valueProperty="codVen"
        />
        <Input
          label="Situação Cliente"
          name="sitCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AddClient
