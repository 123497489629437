import styled, { css, keyframes } from 'styled-components'

export const Container = styled.div<{ opened: boolean }>`
  ${props => props.opened && css`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    overflow: hidden;
  `}
`

const swipeUp = keyframes`
  % {
    transform: translateY(1000px);
  }

  100% {
    transform: translateY(0);
  }
`

export const ModalStyle = styled.div`
  max-width: 1000px;
  width: 100%;
  border-radius: 2px;
  background-color: white;
  padding: 15px;
  animation: 0.2s ${swipeUp} ease-out;
  position: relative;

  @media(max-width: 350px) {
    max-width: 300px;
  }
`
