import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal
} from '../../../hooks'
import SelectApi from '../../../components/Select/api'
import Input from '../../../components/Input'

interface ServicesModalProps {
  servicesDefault: any
  codemp: string
  codfil: string
  setServicesDefault: any
  data: any
}

interface ServicesModalForm {
  codpro: string
  despro: string
  codser: string
  qtdped: string
  preuni: string
  total: string
  codccu: string
  coddep: string
  perins: string
  peripi: string
  pericm: string
  perirf: string
  percsl: string
  percrt: string
  perpit: string
  periss: string
  seqiso: string
  usu_codbem: string
}

const ServicesModal: React.FC<ServicesModalProps> = ({
  servicesDefault,
  setServicesDefault,
  codfil,
  codemp,
  data
}) => {
  const methods = useForm<ServicesModalForm>({
  })
  const { close } = useModal()
  const { register } = methods
  const [desserServices, setDesserServices] = useState<any>()

  const handleSubmit = (formData: ServicesModalForm): void => {
    setServicesDefault([
      ...servicesDefault,
      {
        codccu: formData.codccu,
        codemp: codemp,
        codfil: codfil,
        codser: formData.codser,
        numocp: '',
        pericm: formData.pericm,
        desser: desserServices?.desser,
        perins: formData.perins,
        peripi: formData.peripi,
        perirf: formData.perirf,
        periss: formData.periss,
        preuni: parseFloat(formData.preuni),
        qtdped: formData.qtdped,
        seqiso: ''
      }])
    close()
  }

  return (
    <div>
      <h3>Inserir Serviço</h3>
      <br />
      <Form methods={methods} onSubmit={handleSubmit}>
        <SelectApi
          displayProperty="desser"
          readonly={false}
          label="Serviço"
          name="codser"
          register={register({
          })}
          url={`/acquisition-order/list-services/${codemp}`}
          valueProperty="codser"
          onSelect={(item) => {
            if (item) {
              setDesserServices(item.data)
            }
          }}
        />
        <SelectApi
          displayProperty="desccu"
          readonly={false}
          label="Centro de Custo"
          name="codccu"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url={`/acquisition-order/list-cost-center/${codemp}`}
          valueProperty="codccu"
        />
        <Input
          label="Quantidade Pedida"
          name="qtdped"
          register={register({
          })}
        />
        <Input
          label="Preço Unitário"
          name="preuni"
          register={register({
          })}
        />
        <Input
          label="ICMS %"
          name="pericm"
          register={register({
          })}
        />
        <Input
          label="COFINS %"
          name="percrt"
          register={register({
          })}
        />
        <Input
          label="PIS %"
          name="perpit"
          register={register({
          })}
        />
        <Input
          label="IPI %"
          name="peripi"
          register={register({
          })}
        />
        <Input
          label="IRRF %"
          name="perirf"
          register={register({
          })}
        />
        <Input
          label="CSLL %"
          name="percsl"
          register={register({
          })}
        />
        <Input
          label="ISS %"
          name="periss"
          register={register({
          })}
        />
        <Input
          label="INSS %"
          name="perins"
          register={register({
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Adicionar
        </Button>
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Fechar
        </Button>
      </Form>
    </div>
  )
}

export default ServicesModal
