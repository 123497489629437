import { SelectOption } from '../models/select-option'

export const getUniqueOptions = <T>(data: T[], property: keyof T): SelectOption[] => {
  const unique: string[] = []
  const values: unknown[] = data.map(item => item[property])
  values.forEach(item => {
    const value = item as string
    if (!unique.includes(value)) {
      unique.push(value)
    }
  })
  return unique.map(value => ({
    display: value,
    value
  }))
}
