import styled from 'styled-components'

export const Container = styled.div`
  padding: 5px 10px;

  p {
    margin-bottom: 15px;
  }

  .actions {
    text-align: left;

    button {
      margin-right: 5px;
    }
  }
`
