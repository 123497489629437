import styled from 'styled-components'
import { sizes, colors } from '../../styles/default-styles'

interface SideMenuContainerProps {
  opened: boolean
}

export const SideMenuContainer = styled.aside<SideMenuContainerProps>`
  width: ${props => props.opened ? sizes.sideMenuOpened : sizes.sideMenuClosed};
  min-width: ${props => props.opened ? sizes.sideMenuOpened : sizes.sideMenuClosed};
  background-color: #FFF;
  height: 100%;
  border: 1px solid ${colors.borders};
  transition: 0.2s ease-out;
  overflow-y: auto;

  nav {
    width: 100%;

    .main-menu {
      list-style: none;

      li {
        position: relative;

        &:hover {
          background-color: ${colors.lightGray};
        }

        button, div:not(.dropdown-options) {
          width: 100%;
        }

      .dropdown-options {
          position: fixed;
          z-index:300 ;
        } 
        a, button {
          border: none;
          background: transparent;
          text-decoration: none;
          font-size: 15px;
          font-weight: 900;
          display: flex;
          align-items: center;
          color: ${colors.textColor};
          overflow: hidden;
          padding: ${props => props.opened ? '10px 0 10px 13px' : '20px 0 20px 13px'};

          &:hover {
            cursor: pointer;
          }

          svg {
            min-width: 30px;
            max-width: 30px;
            font-size: 30px;
            margin-right: 15px;
            color: ${colors.textColor};
          }
        }
      }
    }
  }

  @media(max-width: 1000px) {
    width: ${props => props.opened ? sizes.sideMenuOpened : 0};
    min-width: ${props => props.opened ? sizes.sideMenuOpened : 0};
    pointer-events: ${props => props.opened ? 'all' : 'none'};
    position: absolute;
    z-index: 10;
    max-height: calc(100% - ${sizes.headerHeight});
    overflow-y: auto;
  }
`

export const CollapsibleMenu = styled.ul<{ expanded: boolean }>`
  list-style: none;
  overflow: hidden;
  position: relative;
  height: ${props => props.expanded ? '100%' : 0};

  li {
    padding: 0;

    svg {
      margin: 0 !important;
    }

    a {
      padding: 10px 0 10px 30px !important;
      white-space: nowrap;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.15) !important;
    }
  }
`
