import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { Container } from './styles'
import { AcquisitionOrderModel } from '../../../models/acquisition-order'
import DynamicIcon from '../../../components/DynamicIcon'

interface FilterForm {
  codemp: string
  codfil: string
  sitocp: string
}

interface FilterProps {
  onClickInsertAcquisitionOrder?: () => any
  onClickInsertProvider?: () => void
  setFilter: any
  setFilterRequest: any
  filterRequest: any
  data: AcquisitionOrderModel[]
  setData: any
  setLoading: any
}

const Filter: React.FC<FilterProps> = ({
  onClickInsertProvider,
  setFilterRequest
}) => {
  const methods = useForm<FilterForm>({})

  const handleSubmit = async (formData: FilterForm): Promise<void> => {
    setFilterRequest({ codemp: formData.codemp, codfil: formData.codfil, sitocp: formData.sitocp })
  }

  return (
    <>
      <Container>
        <Form
          onSubmit={handleSubmit}
          methods={methods}
        >
          <div className="dentro2">
            <Button
              buttonType="secondary"
              type="button"
              onClick={onClickInsertProvider}
            >
              <DynamicIcon
                family="fa"
                name="FaUserPlus"
                size={25}
              />
            </Button>
            <Button
              buttonType="secondary"
              type="button"
              onClick={() => window.open('http://bi.grupofortaleza.com.br:8082/ReportServer/Pages/ReportViewer.aspx?%2fRelatorios%2fADM+-+Grupo+Fortaleza%2fCompras%2fConsulta+Fornecedor&rs:Command=Render')}
            >
              <DynamicIcon
                family="fa"
                name="FaUserEdit"
                size={25}
              />
            </Button>
            <Button
              buttonType="secondary"
              type="button"
              onClick={() => window.open('http://bi.grupofortaleza.com.br:8082/ReportServer/Pages/ReportViewer.aspx?%2fRelatorios%2fADM+-+Grupo+Fortaleza%2fOrdem+de+Compra&rs:Command=Render')}
            >
              <DynamicIcon
                family="md"
                name="MdDescription"
                size={30}
              />

            </Button>
          </div>
        </Form>
      </Container>
    </>
  )
}

export default Filter
