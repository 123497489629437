import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  background: white;
  border-bottom: 1px solid ${colors.borders};
  height: 70px;
  padding: 0 15px;

  .filter-input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: ${colors.lightGray};
    padding: 10px;
    max-width: 100%;
    width: 90%;
    height: 50px;
    border-radius: 2px;
    margin-right: 15px;

    svg {
      min-width: 25px;
      min-height: 25px;
      margin: 0 5px;
    }

    input {
      box-sizing: border-box;
      border: none;
      background-color: transparent;
      width: 100%;
      border-radius: 2px;
    }
  }

  .filter-add-button {
    button {
      height: 50px;
      width: 50px;
      background-color: ${colors.header};
      border: none;
      border-radius: 2px;
      transition: 0.15s all;

      &:hover {
        cursor: pointer;
        background-color: #555;
      }

      svg {
        height: 25px;
        width: 25px;
        color: white;
      }
    }
  }
`
