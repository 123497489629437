import React from 'react'
import { CheckboxContainer } from './styles'

interface CheckboxProps {
  name: string
  register: any
  label?: string
  defaultValue?: boolean | false
  className?: string
  disabled?: boolean | false
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  register,
  label = '',
  defaultValue = false,
  className = '',
  disabled = false
}) => {
  return (
    <CheckboxContainer className={className}>
      <label>
        {label}
        <input
          type="checkbox"
          name={name}
          ref={register}
          defaultChecked={defaultValue}
          disabled={disabled}
        />
        <span className="checkmark" />
      </label>
    </CheckboxContainer>
  )
}

export default Checkbox
