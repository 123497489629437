import React from 'react'
import Label from '../../../components/Label'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import { Container } from './styles'
import { H1 } from './styles'
import { SalesOrderModel } from '../../../models/salesorder'
import IconButton from '../../../components/IconButton'
import { MdDelete, MdModeEdit } from 'react-icons/md'
import Button from '../../../components/Button'
import { toast } from 'react-toastify'
import DetailsButtons from '../../../components/DetailsButtons'
import { handle } from '../../../utils/error-handlers'
import { useApi, useDetailsContainer, useLoading, useModal } from '../../../hooks'

interface SalesOrderDetailsProps {
  data: SalesOrderModel
  openDeleteBudgetItem: (data: SalesOrderModel, index: number) => () => void
  openAttBudget: (data: SalesOrderModel) => () => void
  openAddItemOrderModal: (data: SalesOrderModel) => () => void
  openAddItemModal: (data: SalesOrderModel) => () => void
  refetch: () => void
}

const SalesOrderDetails: React.FC<SalesOrderDetailsProps> = ({
  data,
  openAttBudget,
  openAddItemModal,
  openAddItemOrderModal,
  openDeleteBudgetItem,
  refetch
}) => {
  const { httpPatch } = useApi()
  const { load } = useLoading()
  const { confirm, close } = useModal()
  const {
    clearData
  } = useDetailsContainer()

  const handleClose = (): void => {
    confirm('Deseja realmente fechar ?', () => {
      handle(async () => {
        load(async () => {
          const response = await httpPatch<string>('/salesorder/update-close-budget', {
            codCli: data.codcli,
            codemp: data.codemp,
            codfil: data.codfil,
            numPed: data.numped
          })

          toast.info(response.body)
          if (response.body === 'OK') {
            clearData()
          }
          close()
          refetch()
        })
      })
    })
  }

  return (
    <Container>
      <div className="info">
        <section>
          <Label
            title="Empresa"
            value={data.codemp}
          />
          <Label
            title="Filial"
            value={data.codfil}
          />
          <Label
            title="Tipo"
            value={data.tipo}
          />
          <Label
            title="Nº Ped"
            value={data.numped}
          />
          <Label
            title="Geração Orçamento/Pedido"
            value={data.datemi}
          />
          <Label
            title="Entrega Cliente"
            value={data.dataentregacliente}
          />
          <Label
            title="Condição de Pagamento"
            value={`${data.codcpg} - ${data.descpg}`}
          />
          <Label
            title="Forma de Pagamento"
            value={`${data.codfpg} - ${data.desfpg}`}
          />
          <Label
            title="Código Vendedora"
            value={data.codven}
          />
          <Label
            title="Nome Vendedora"
            value={data.nomrep}
          />
          <Label
            title="Frete CIF ou FOB"
            value={data.ciffob}
          />
          <Label
            title="Soma Frete SIM ou NÃO"
            value={data.somfre}
          />
        </section>
        <section>
          <Label
            title="Frete CIF"
            value={data.vlrfrd}
          />
          <Label
            title="Frete FOB"
            value={data.vlrfre}
          />
          <Label
            title="Código Transportadora"
            value={data.codtra}
          />
          <Label
            title="Nome Transportadora "
            value={data.nomtra}
          />
          <Label
            title="Condição de Pagamento"
            value={`${data.codcpg} - ${data.descpg}`}
          />
          <Label
            title="Código Representante"
            value={data.codrep}
          />
          <Label
            title="Nome Representante"
            value={data.repped}
          />
          <Label
            title="Observação"
            value={data.obsped}
          />
          <Label
            title="Atualizar"
            value={
              <IconButton
                icon={<MdModeEdit size={20} />}
                padding={3}
                size={18}
                onClick={openAttBudget(data)}
              />
            }
          />
          {data.tipo === 'Orçamento'
            ? <Label
              title="Adicionar Item"
              value={
                <IconButton
                  icon={<MdModeEdit size={20} />}
                  padding={3}
                  size={18}
                  onClick={openAddItemModal(data)}
                />
              }
            />
            : <></>}
          {data.tipo === 'Amostra'
            ? <Label
              title="Adicionar Item"
              value={
                <IconButton
                  icon={<MdModeEdit size={20} />}
                  padding={3}
                  size={18}
                  onClick={openAddItemModal(data)}
                />
              }
            />
            : <></>}
          {data.tipo === 'Pedido de Venda'
            ? <Label
              title="Adicionar Item"
              value={
                <IconButton
                  icon={<MdModeEdit size={20} />}
                  padding={3}
                  size={18}
                  onClick={openAddItemOrderModal(data)}
                />
              }
            />
            : <></>}
        </section>
      </div>
      <H1>Itens</H1>
      <Table layout="fixed">
        <THead>
          <Tr>
            <Th color="black" width="60px">Código</Th>
            <Th color="black" width="70px">Descrição</Th>
            <Th color="black" width="40px">Dep.</Th>
            <Th color="black" width="30px">Qtde</Th>
            <Th color="black" width="30px">Uni</Th>
            <Th color="black" width="30px">Total</Th>
            <Th color="black" width="40px">CC</Th>
            <Th color="black" width="30px"></Th>

          </Tr>
        </THead>
        <TBody>
          {data?.itens.map((item, index) =>
            <Tr key={index}>
              <Td>{item.codpro}</Td>
              <Td>{item.despro}</Td>
              <Td>{item.coddep}</Td>
              <Td>{item.qtdped}</Td>
              <Td>{item.preuni}</Td>
              <Td>{item.total}</Td>
              <Td>{item.codccu}</Td>
              <Td>
                <IconButton
                  onClick={openDeleteBudgetItem(data, index)}
                  icon={<MdDelete size={20} />}
                  padding={10}
                  size={18}
                />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <br />
      <H1>Valor Liquído</H1>
      <H1>{data?.itens.reduce((sum, item) => {
        const soma = sum + parseFloat(item.total)
        return soma
      }, 0)}</H1>
      {data.tipo === 'Pedido de Venda'
        ? <DetailsButtons>
          <Button
            buttonType="primary"
            type="button"
            onClick={handleClose}
          >
            FECHAR PEDIDO
          </Button>
        </DetailsButtons>
        : <></>}
      {data.tipo === 'Amostra'
        ? <DetailsButtons>
          <Button
            buttonType="primary"
            type="button"
            onClick={handleClose}
          >
            FECHAR AMOSTRA
          </Button>
        </DetailsButtons>
        : <></>}
    </Container>
  )
}

export default SalesOrderDetails
