import React, { useState } from 'react'
import {
  useApi,
  useFetch,
  useFilter,
  useLoading,
  useModal,
  useSelectItems
} from '../../../hooks'
import { Container } from './styles'
import Loader from '../../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import Checkbox from '../../../components/Checkbox/table'
import { BudgetItensModel } from '../../../models/salesorder'
import Button from '../../../components/Button'

interface GridModalProps {
  setSelectedDefault: any
  codfil: string
  codcli: string
  codemp: string
}

const GridModal: React.FC<GridModalProps> = ({
  setSelectedDefault,
  codfil,
  codcli,
  codemp
}) => {
  const { httpGet } = useApi()

  const {
    data,
    loading,
    refetching
  } = useFetch<BudgetItensModel[]>(httpGet, `/salesorder/list-budget-itens/${codcli}/${codemp}/${codfil}`)
  const { loading: loadingHook } = useLoading()
  const [filter] = useState(() => (item: any, index: number, array: any[]) => true)
  const { close } = useModal()
  const { filtered } = useFilter<BudgetItensModel>(data, filter)
  const {
    handleSelect,
    handleSelectAll,
    isSelected,
    selectedItems
  } = useSelectItems<BudgetItensModel>(item => `${item.seqipd} ${item.numped}`)

  return (
    <div>
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            selected={selectedItems.length}
          >
            <THead>
              <Tr>
                <Th width="40px">
                  <Checkbox
                    onSelect={handleSelectAll(filtered, !(filtered.length === selectedItems.length))}
                    value={(filtered.length === selectedItems.length)}
                  />
                </Th>
                <Th width="40px" color="#000" >Pedido</Th>
                <Th width="40px" className="hide-md" color="#000" >Produto</Th>
                <Th color="#000" width="160px" > Descrição </Th>
                <Th className="hide-sm" color="#000" width="40px">Depósito</Th>
                <Th color="#000" width="20px" >Quantidade</Th>
                <Th color="#000" width="40px" >Preço Uni</Th>
                <Th color="#000" width="40px" >Total</Th>
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item, index) => (
                <Tr key={index}>
                  {}
                  <Td className="table-checkbox">
                    <Checkbox
                      value={isSelected(item)}
                      onSelect={handleSelect(item, !isSelected(item))}
                    />
                  </Td>
                  <Td title={item.numped}>    {item.numped} </Td>
                  <Td title={item.codpro}>    {item.codpro} </Td>
                  <Td title={item.despro}>    {item.despro} </Td>
                  <Td title={item.coddep}>    {item.coddep} </Td>
                  <Td title={item.qtdped}>    {item.qtdped} </Td>
                  <Td title={item.preuni}>    {item.preuni} </Td>
                  <Td title={item.total}>     {item.total} </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
          <Button
            buttonType="primary"
            type="button"
            onClick={() => setSelectedDefault(selectedItems)}
          >
          Adicionar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={close}
          >
          Fechar
          </Button>
        </Loader>
      </Container>
    </div>
  )
}

export default GridModal
