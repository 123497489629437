import React, {
  useCallback,
  useEffect
} from 'react'
import {
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td
} from '../../../components/Table'
import Dropdown from '../../../components/Dropdown'
import Filter from '../../../components/Filter'
import { FaTrashAlt } from 'react-icons/fa'
import Loader from '../../../components/Loader'
import ProfileDetails from './ProfileDetails'
import {
  Link,
  useLocation,
  useHistory
} from 'react-router-dom'
import {
  useApi,
  useFetch,
  useDetailsContainer,
  useModal
} from '../../../hooks'
import { Profile } from '../../../models/profile'
import { handle } from '../../../utils/error-handlers'
import { toast } from 'react-toastify'
import { Container } from './styles'

const Profiles: React.FC = () => {
  const { httpDelete, httpGet } = useApi()
  const { data, loading, refetch } = useFetch<Profile[]>(httpGet, '/profiles')
  const { pushPage, open, close } = useDetailsContainer()
  const { search } = useLocation()
  const { replace } = useHistory()
  const { confirm } = useModal()

  const openDetails = useCallback((id?: number): void => {
    close()
    pushPage({
      component: <ProfileDetails id={id} refetch={refetch} />,
      title: 'Perfil',
      onClose: () => replace('/configuracoes/perfis')
    })
    open()
  }, [close, pushPage, open, replace, refetch])

  useEffect(() => {
    httpGet('/profiles')
  }, [httpGet])

  useEffect(() => {
    if (search?.includes('?id=')) {
      const id = Number(search.substr(search.indexOf('=') + 1))
      openDetails(id)
    }
  }, [search, openDetails])

  const renderRow = (row: Profile, index: number): JSX.Element => (
    <Tr key={index}>
      <Td title={row.name}>
        <Link to={`/configuracoes/perfis?id=${row.id}`}>
          {row.name}
        </Link>
      </Td>
      <Td>
        <Dropdown
          id="opcao"
          options={[
            {
              text: 'Excluir',
              icon: <FaTrashAlt size={15} />,
              onClick: () => {
                confirm('Tem certeza que deseja excluir esse perfil?', () => {
                  handle(async () => {
                    await httpDelete(`/profiles/${row.id}`)
                    httpGet('/profiles')
                    toast.success('Perfil excluído com sucesso.')
                    refetch()
                  })
                })
              }
            }
          ]}
        />
      </Td>
    </Tr>
  )

  return (
    <>
      <Filter onClickAdd={() => {
        openDetails()
      }} />
      <Container>
        <Loader loading={loading}>
          <Table empty={!data}>
            <THead>
              <Tr>
                <Th>Nome</Th>
                <Th width="50px"></Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map((item: Profile, index: number) => renderRow(item, index))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default Profiles
