import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { CallRegistrationModel } from '../../../models/call-registration'
import Input from '../../../components/Input'

interface ParentCallModalProps {
  data: CallRegistrationModel
  refetch: () => void
  refetchDetails: () => void
}

interface ParentCallForm {
  usu_chapai: string
}

const ParentCallModal: React.FC<ParentCallModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<ParentCallForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()
  const { clearData } = useDetailsContainer()
  const [loading, setLoading] = useState(false)

  const handleSendData = async (formData: ParentCallForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        usu_chapai: formData.usu_chapai,
        usu_codcham: data.usu_codcham
      }
      const response = await httpPatch<string>('/call-registration/update-parent-call', json)

      if (response.body === 'Atualizado com sucesso !!!') {
        clearData()
      }
      toast.info(response.body)
      setLoading(false)
      refetch()
      close()
    })
  }

  return (
    <div>
      <h3>Atualizar Chamado Pai do Chamado #{data.usu_codcham}</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Chamado Pai"
          name='usu_champai'
          register={register({})}
        />
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default ParentCallModal
