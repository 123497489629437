import React, { useState } from 'react'
import Loader from '../../../components/Loader'
import Button from '../../../components/Button'
import Input from '../../../components/Input/no-form'
import {
  useApi,
  useFetch,
  useModal,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { TransportOrder } from '../../../models/transport-order'
import { ShippingCompany } from '../../../models/shipping-company'
import { handle } from '../../../utils/error-handlers'
import { Container } from './styles'

interface ShippingCompanyModalProps {
  data: TransportOrder
  refetch: () => void
}

const ShippingCompanyModal: React.FC<ShippingCompanyModalProps> = ({
  data,
  refetch
}) => {
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpGet, httpPatch } = useApi()
  const { data: shippingCompanies, loading } = useFetch<ShippingCompany[]>(httpGet, '/expedition/shipping-companies')
  const [selected, setSelected] = useState<ShippingCompany>({
    codTra: data.codTra,
    nomTra: data.nomTra
  })
  const [inputValue, setInputValue] = useState('')

  const sortItems = (items: ShippingCompany[]): ShippingCompany[] => {
    return items
      .sort((a, b) => {
        if (Number(a.codTra) < Number(b.codTra)) {
          return -1
        }
        if (Number(a.codTra) > Number(b.codTra)) {
          return 1
        }
        return 0
      })
      .filter(item => item.nomTra.toLowerCase().includes(inputValue.toLowerCase()))
  }

  const handleClick = (item: ShippingCompany) => () => {
    setSelected({
      codTra: item.codTra,
      nomTra: item.nomTra
    })
  }

  const handleSendData = async (): Promise<void> => {
    handle(async () => {
      const requestData = {
        codFil: data.codFil,
        codEmp: data.codEmp,
        codTra: selected.codTra
      }
      const response = await httpPatch<string>(`/expedition/shipping-companies/${data.numPed}`, requestData)
      toast.success(response.body)
      refetch()
      clearData()
      close()
    })
  }

  return (
    <Container>
      <Loader loading={loading}>
        <div className="company">
          <p>
            Transportadora selecionada:<br />
            <strong>{selected.codTra} - {selected.nomTra}</strong>
          </p>
        </div>
        <br />
        <Input label="Filtrar" onChange={e => setInputValue(e.target.value)} />
        <ul>
          {sortItems(shippingCompanies ?? [])
            .map(item => (
              <li
                key={item.codTra}
                className={item.codTra === selected.codTra ? 'selected' : undefined}
                onClick={handleClick(item)}
              >
                {item.codTra} - {item.nomTra}
              </li>
            ))}
        </ul>
        <Button
          type="button"
          buttonType="primary"
          onClick={handleSendData}
        >
          Enviar
        </Button>
        <Button
          type="button"
          buttonType="secondary"
          onClick={close}
        >
          Cancelar
        </Button>
      </Loader>
    </Container>
  )
}

export default ShippingCompanyModal
