import React from 'react'
import DynamicIcon from '../../../components/DynamicIcon'
import Button from '../../../components/Button'
import Label from '../../../components/Label'
import DetailsButtons from '../../../components/DetailsButtons'
import {
  useModal,
  useApi,
  useLoading
} from '../../../hooks'
import { handle } from '../../../utils/error-handlers'
import Loader from '../../../components/Loader'
import { toast } from 'react-toastify'
import { Container } from './styles'
import { LogisticDriverModel } from '../../../models/logistic-driver'

interface LogisticDriversDetailsProps {
  data: LogisticDriverModel
  refetch: () => void
}

const LogisticDriversDetails: React.FC<LogisticDriversDetailsProps> = ({
  data,
  refetch
}) => {
  const { httpPatch } = useApi()
  const { load, loading } = useLoading()
  const { confirm } = useModal()

  const handleAssume = (): void => {
    confirm('Deseja assumir esse serviço ?', () => {
      handle(async () => {
        load(async () => {
          await httpPatch(`/logistic/update-assume/${data.usu_numped}/${data.usu_codemp}/${data.usu_seqipd}`, {
            codemp: data.usu_codemp,
            codfil: data.usu_codfil,
            numped: data.usu_numped,
            seqipd: data.usu_seqipd,
            tipo: data.usu_tipo
          })
          toast.success('Serviço Assumido !')
          refetch()
        })
      })
    })
  }

  const handleTerminate = (): void => {
    confirm('Deseja encerrar esse serviço ? ', () => {
      handle(async () => {
        load(async () => {
          await httpPatch(`/logistic/update-terminate/${data.usu_numped}/${data.usu_codemp}/${data.usu_seqipd}`, {
            codemp: data.usu_codemp,
            codfil: data.usu_codfil,
            numped: data.usu_numped,
            seqipd: data.usu_seqipd,
            tipo: data.usu_tipo
          })
          toast.success('Serviço Encerrado !')
          refetch()
        })
      })
    })
  }

  return (
    <Container>
      <Loader
        loading={loading}
        overlay
        message="Processando..."
      />
      <div className="info">
        <section>
          <Label
            title="Empresa"
            value={data.usu_codemp}
          />
          <Label
            title="Pedido"
            value={data.usu_numped}
          />
          <Label
            title="Cliente"
            value={data.usu_nomcli}
          />
          <Label
            title="Código Produto"
            value={data.usu_codpro}
          />
          <Label
            title="Quantidade"
            value={data.usu_qtdped}
          />
          <Label
            title="Carga/Descarga"
            value={data.usu_datcar}
          />
          <Label
            title="Veículo"
            value={data.plavei}
          />
          <Label
            title="Serviço Frete"
            value={data.desser}
          />
          <Label
            title="Situação"
            value={data.usu_sitord}
          />
        </section>
        <section>
          <Label
            title="Filial"
            value={data.usu_codfil}
          />
          <Label
            title="Tipo"
            value={data.usu_tipo}
          />
          <Label
            title="Código Cliente"
            value={data.usu_codcli}
          />
          <Label
            title="Sequência"
            value={data.usu_seqipd}
          />
          <Label
            title="Produto"
            value={data.usu_despro}
          />
          <Label
            title="Geração"
            value={data.usu_datean}
          />
          <Label
            title="Transportadora"
            value={`${data.nomtra} - ${data.codtra}`}
          />
          <Label
            title="Motorista"
            value={data.nommot}
          />
          <Label
            title="Descrição Caixa"
            value={data.desbem}
          />
        </section>
      </div>
      <DetailsButtons>
        { data.usu_sitord === 'A'
          ? <>
            <Button
              buttonType="primary"
              type="button"
              onClick={handleAssume}
            >
              <DynamicIcon
                family="md"
                name="MdCheck"
                size={15}
              />
          &nbsp;
          Assumir
            </Button>
          </>
          : <>
            <Button
              buttonType="secondary"
              type="button"
              onClick={handleTerminate}
            >
              <DynamicIcon
                family="md"
                name="MdClear"
                size={15}
              />
          &nbsp;
          Encerrar
            </Button>
          </> }
      </DetailsButtons>
    </Container>
  )
}

export default LogisticDriversDetails
