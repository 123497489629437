import styled from 'styled-components'

export const Div = styled.div`
  padding-bottom: 20px;

  div{
    display: flex;
    margin-bottom:5px;

    input{
      margin-top: 3px;
      margin-right:5px;
    }
    label{
      font-weight: 900;
      font-size: 1em;
    }
}
  
`

export const H1 = styled.div`
    font-weight: 300;
    font-family: Lato, sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
`

export const H3 = styled.div`
  font-weight: 900;
  font-size: 1.17em;
  padding-top: 10px;

`

export const H4 = styled.div`
  font-weight: 900;
  font-size: 1.17em;
  padding-bottom: 10px;

`
