import React, { useState } from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'
import { Table, TBody, Td, Th, THead, Tr } from '../../../components/Table'
import IconButton from '../../../components/IconButton'
import { MdDelete } from 'react-icons/md'
import { H1 } from './styles'
import { AcquisitionOrderModel } from '../../../models/acquisition-order'

interface InsertAcquisitionOrderProps {
  data: any
  setInsertAcquisition: any
  openServicesModal: (data: AcquisitionOrderModel, setServicesDefault: any, servicesDefault: any, codemp: string, codfil: string) => () => void
  openProductsModal: (data: AcquisitionOrderModel, setProductsDefault: any, productsDefault: any, codemp: string, codfil: string) => () => void
}

interface InsertAcquisitionOrderForm {
  ciffob: string
  codfor: string
  codtra: string
  datemi: string
  obsmot: string
  obsocp: string
  tempar: string
  codtns: string
  tnsser: string
  codtnspro: string
  codtnsser: string
  vlrfre: string
  codcli: string
  codcpg: string
  codfpg: string
  codven: string
  nomven: string
  nomrep: string
  descpg: string
  desfpg: string
  codemp: string
  codfil: string
  codrep: string
  codtpr: string
  entcli: string
  numPed: string
  obsPed: string
  somfre: string
  tipfre: string
  tnsPro: string
}
const InsertAcquisitionOrder: React.FC<InsertAcquisitionOrderProps> = ({
  data,
  openServicesModal,
  setInsertAcquisition,
  openProductsModal
}) => {
  const methods = useForm<InsertAcquisitionOrderForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()
  const codemp = methods.watch('codemp')
  const [productsDefault, setProductsDefault] = useState([])
  const [servicesDefault, setServicesDefault] = useState([])
  const [loading, setLoading] = useState(false)

  const codfil = methods.watch('codfil')

  var now = new Date()
  var month = (now.getMonth() + 1)
  var day = now.getDate()
  var today = `${now.getFullYear()}-${month}-${day}`

  const handleDeleteProducts = (index: number): void => {
    setProductsDefault(productsDefault.filter((item, i) => index !== i))
  }

  const handleDeleteServices = (index: number): void => {
    setServicesDefault(servicesDefault.filter((item, i) => index !== i))
  }

  const handleSendData = async (formData: InsertAcquisitionOrderForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        ciffob: formData.ciffob,
        codcpg: formData.codcpg,
        codemp: formData.codemp,
        codfil: formData.codfil,
        codfor: formData.codfor,
        codfpg: formData.codfpg,
        codtra: formData.codtra,
        datemi: today,
        numocp: '',
        obsmot: '',
        obsocp: formData.obsocp,
        somfre: formData.somfre,
        tempar: 'N',
        tnspro: formData.codtnspro,
        tnsser: formData.codtnsser,
        vlrfre: formData.vlrfre,
        produtos: productsDefault,
        servicos: servicesDefault

      }
      const response = await httpPost<any>('/acquisition-order/insert-acquisition-order', json)

      if (response.body === 'Ordem de compra incluída com sucesso.') {
        clearData()
      }
      toast.info(response.body)
      setLoading(false)
      close()
      setInsertAcquisition(true)
    })
  }
  return (
    <div>
      <Form methods={methods} onSubmit={handleSendData} >
        <SelectApi
          displayProperty="codemp"
          readonly={false}
          label="Empresa"
          name="codemp"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url="register/list-companies-users"
          valueProperty="codemp"
        />
        {codemp
          ? <SelectApi
            displayProperty="codfil"
            readonly={false}
            label="Filial"
            name="codfil"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
            url={`/register/list-filial-users/${codemp}`}
            valueProperty="codfil"
          />
          : <></>
        }
        {codemp && codfil
          ? <SelectApi
            displayProperty="destns"
            readonly={false}
            label="Transação Produto"
            name="codtnspro"
            register={register({})}
            url={`/acquisition-order/list-transactions/${codemp}`}
            valueProperty="codtns"
          />
          : <></>
        }
        {codemp && codfil
          ? <SelectApi
            displayProperty="destns"
            readonly={false}
            label="Transação Serviço"
            name="codtnsser"
            register={register({})}
            url={`/acquisition-order/list-transactions/${codemp}`}
            valueProperty="codtns"
          />
          : <></>
        }
        {codemp && codfil
          ? <SelectApi
            displayProperty="nomfor"
            readonly={false}
            label="Fornecedor"
            name="codfor"
            register={register({})}
            url={`/acquisition-order/list-providers/${codemp}/${codfil}`}
            valueProperty="codfor"
          />
          : <></>
        }
        {codemp && codfil
          ? <Input
            label="Frete CIF/FOB"
            name="ciffob"
            register={register({})}
          />
          : <></>
        }
        {codemp && codfil
          ? <SelectApi
            displayProperty="descpg"
            readonly={false}
            label="Condição de Pagamento"
            name="codcpg"
            register={register({})}
            url={`/acquisition-order/list-payment-condition/${codemp}`}
            valueProperty="codcpg"
          />
          : <></>
        }
        {codemp && codfil
          ? <SelectApi
            displayProperty="desfpg"
            readonly={false}
            label="Forma de Pagamento"
            name="codfpg"
            register={register({})}
            url={`/acquisition-order/list-payment-form/${codemp}`}
            valueProperty="codfpg"
          />
          : <></>
        }
        {codemp && codfil
          ? <SelectApi
            displayProperty="nomtra"
            readonly={false}
            label="Transportadora"
            name="codtra"
            register={register({})}
            url={'/acquisition-order/list-carriers'}
            valueProperty="codtra"
          />
          : <></>
        }
        {codemp && codfil
          ? <Input
            label="Observação Ordem de Compra"
            name="obsocp"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
          />
          : <></>
        }
        {codemp && codfil
          ? <Input
            label="Soma Frete"
            name="somfre"
            register={register({})}
          />
          : <></>
        }
        {/* {codemp && codfil
          ? <Input
            label="Parcela Especial"
            name="tempar"
            register={register({})}
          />
          : <></>
        } */}
        {/* {codemp && codfil
          ? <Input
            label="Valor Liquído"
            name="vlrliq"
            register={register({})}
          />
          : <></>
        } */}
        {codemp && codfil
          ? <>
            <H1>Produtos</H1>
            <Table layout="fixed">
              <THead>
                <Tr>
                  <Th color="black" width="50px" >Produto</Th>
                  <Th color="black" width="40px" >Descrição</Th>
                  <Th color="black" width="50px" >Qtd Pedida</Th>
                  <Th color="black" width="40px" >Preço UN</Th>
                  <Th color="black" width="40px" >Total</Th>
                  <Th color="black" width="30px" ></Th>
                </Tr>
              </THead>
              <TBody>
                {productsDefault?.map(({
                  codpro,
                  despro,
                  qtdped,
                  preuni,
                  total
                }, index) =>
                  <Tr key={index}>
                    <Td> {codpro} </Td>
                    <Td> {despro} </Td>
                    <Td> {qtdped} </Td>
                    <Td> {preuni} </Td>
                    <Td> {preuni * qtdped} </Td>
                    <Td>
                      <IconButton
                        padding={2}
                        size={20}
                        icon={<MdDelete />}
                        onClick={() => {
                          handleDeleteProducts(index)
                        }}
                      />
                    </Td>
                  </Tr>
                )}
              </TBody>
            </Table>
          </>
          : <> </>}
        <br />
        {codemp && codfil
          ? <>
            <H1>Serviços</H1>
            <Table layout="fixed">
              <THead>
                <Tr>
                  <Th color="black" width="50px" >Serviço</Th>
                  <Th color="black" width="40px" >Descrição</Th>
                  <Th color="black" width="50px" >Qtd Pedida</Th>
                  <Th color="black" width="40px" >Preço UN</Th>
                  <Th color="black" width="40px" >Total</Th>
                  <Th color="black" width="30px" ></Th>
                </Tr>
              </THead>
              <TBody>
                {servicesDefault?.map(({
                  codser,
                  desser,
                  qtdped,
                  preuni,
                  total
                }, index) =>
                  <Tr key={index}>
                    <Td> {codser} </Td>
                    <Td> {desser} </Td>
                    <Td> {qtdped} </Td>
                    <Td> {preuni} </Td>
                    <Td> {preuni * qtdped} </Td>
                    <Td>
                      <IconButton
                        padding={2}
                        size={20}
                        icon={<MdDelete />}
                        onClick={() => {
                          handleDeleteServices(index)
                        }}
                      />
                    </Td>
                  </Tr>
                )}
              </TBody>
            </Table>
          </>
          : <> </>}
        <br />
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        {codemp && codfil
          ? <Button
            buttonType="secondary"
            type="button"
            onClick={openProductsModal(data, setProductsDefault, productsDefault, codemp, codfil)}
          >
            Adicionar Produto
          </Button>
          : <></>}
        <span style={{ display: 'inline-block', width: '5px' }} />
        {codemp && codfil
          ? <Button
            buttonType="secondary"
            type="button"
            onClick={openServicesModal(data, setServicesDefault, servicesDefault, codemp, codfil)}
          >
            Adicionar Serviço
          </Button>
          : <></>}
      </Form>
    </div>
  )
}

export default InsertAcquisitionOrder
