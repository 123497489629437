import React from 'react'
import { CheckboxContainer } from './styles'

interface CheckboxProps {
  value: boolean
  className?: string
  onSelect?: () => void
}

const Checkbox: React.FC<CheckboxProps> = ({
  value,
  className = '',
  onSelect
}) => {
  return (
    <CheckboxContainer className={className}>
      <label>
        <input
          onChange={onSelect}
          type="checkbox"
          checked={value}
        />
        <span className="checkmark" />
      </label>
    </CheckboxContainer>
  )
}

export default Checkbox
