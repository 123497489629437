import styled from 'styled-components'
import { sizes, colors } from '../../styles/default-styles'

export const HeaderContainer = styled.header`
  box-sizing: border-box;
  background-color: ${colors.header};
  height: ${sizes.headerHeight};
  padding: 5px 25px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    a, img {
      min-width: 90px;
      max-width: 90px;
      min-height: 65px;
      max-height: 65px;
    }

    button {
      background: none;
      border: none;
      border-radius: 50%;
      padding: 10px;
      transition: 0.2s;
      margin-right: 15px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      svg {
        min-height: 30px;
        min-width: 30px;
        color: white;
      }
    }
  }

  .right {
    display: flex;

    .notifications {
      background-color: transparent;
      border: none;
      outline: none;
      position: relative;

      span {
        background-color: red;
        height: 15px;
        width: 15px;
        color: white;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: -2px;
        font-weight: bold;
        font-size: 11px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .user {
      height: 50px;
      width: 50px;
      background-color: purple;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: white;
      font-size: 20px;
      border: none;
      margin-left: 30px;

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }

  @media(max-width: 300px) {
    img {
      display: none;
    }
  }
`
