import React from 'react'
import { ButtonStyle, ButtonType } from './styles'

interface ButtonProps {
  children: any
  buttonType: ButtonType
  type?: 'button' | 'submit'
  fullWidth?: boolean | false
  onClick?: () => void
  onSubmit?: () => void
  disabled?: boolean | false
}

const Button: React.FC<ButtonProps> = ({
  children,
  buttonType,
  fullWidth = false,
  type = 'button',
  onClick,
  onSubmit,
  disabled = false
}) => {
  return (
    <ButtonStyle
      fullWidth={fullWidth}
      type={type}
      buttonType={buttonType}
      onClick={onClick}
      onSubmit={onSubmit}
      disabled={disabled}
    >
      {children}
    </ButtonStyle>
  )
}

export default Button
