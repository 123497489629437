import { useEffect, useCallback } from 'react'
import { AxiosRequestConfig } from 'axios'
import { ApiResponse } from '../models/api'
import { api } from '../services/api'
import { useAuth } from './auth'

interface UseApi {
  httpPost: <T>(url: string, body?: any) => Promise<ApiResponse<T>>
  httpPut: <T>(url: string, body?: any) => Promise<ApiResponse<T>>
  httpPatch: <T>(url: string, body?: any) => Promise<ApiResponse<T>>
  httpDelete: (url: string) => Promise<ApiResponse>
  httpGet: <T = any>(url: string) => Promise<ApiResponse<T>>
}

export const useApi = (): UseApi => {
  const { token, logout } = useAuth()

  const makeRequestConfig = useCallback((): AxiosRequestConfig => ({
    headers: {
      authorization: `Bearer ${token}`
    }
  }), [token])

  const httpDelete = useCallback(async (url: string): Promise<ApiResponse> => {
    const response = await api.delete(url, makeRequestConfig())
    return {
      statusCode: response.status,
      body: response.data
    }
  }, [makeRequestConfig])

  const httpPost = useCallback(async <T>(url: string, body?: any): Promise<ApiResponse<T>> => {
    const response = await api.post<T>(url, body, makeRequestConfig())
    return {
      statusCode: response.status,
      body: response.data
    }
  }, [makeRequestConfig])

  const httpPut = useCallback(async <T>(url: string, body?: any): Promise<ApiResponse<T>> => {
    const response = await api.put<T>(url, body, makeRequestConfig())
    return {
      statusCode: response.status,
      body: response.data
    }
  }, [makeRequestConfig])

  const httpGet = useCallback(async <T = any>(url: string): Promise<ApiResponse<T>> => {
    const response = await api.get<T>(url, makeRequestConfig())
    return {
      statusCode: response.status,
      body: response.data
    }
  }, [makeRequestConfig])

  const httpPatch = useCallback(async <T>(url: string, body?: any): Promise<ApiResponse<T>> => {
    const response = await api.patch<T>(url, body, makeRequestConfig())
    return {
      statusCode: response.status,
      body: response.data
    }
  }, [makeRequestConfig])

  useEffect(() => {
    api.interceptors.response.use(value => {
      return value
    }, error => {
      if (error.response.status === 401) {
        logout()
      }
    })
  }, [logout])

  return {
    httpDelete,
    httpPut,
    httpPost,
    httpGet,
    httpPatch
  }
}
