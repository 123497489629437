import styled from 'styled-components'
import { colors } from '../../../../styles/default-styles'

export const Container = styled.div`
  max-width: 500px;
`

export const SelectContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  border-radius: 2px;

  &.profile:hover {
    background-color: ${colors.secondary};
  }

  & > div, & > p {
    width: calc(100% - 35px);
    margin-right: 5px;
    margin-top: 7px;
  }

  .add-button {
    margin-top: 7px;
  }

  p {
    padding-left: 5px;
  }

  .add-button:last-child {
    margin-top: 15px;
  }
`

export const ChangeProfileButton = styled.button`
  background-color: transparent;
  border: none;
  height: 30px;
  width: 30px;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
    background-color: ${colors.secondary};
  }

  svg {
    height: 20px;
    width: 20px;
  }
`

export const SubTitle = styled.h3`
  margin-bottom: 20px;
`
