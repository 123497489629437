import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

interface FieldContainerProps {
  error: boolean
}

export const FieldContainer = styled.div<FieldContainerProps>`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  .bar {
    background-color: ${props => props.error ? colors.error : colors.mediumGray};
    width: ${props => props.error ? '100%' : '0px'};
    height: 2px;
    position: absolute;
    z-index: 0;
    bottom: 11px;
    transition: all 0.3s ease-in-out;
  }

  label {
    color: ${props => props.error ? colors.error : colors.mediumGray};
    text-transform: uppercase;
    font-size: 9px;
    float: left;
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
  }

  p {
    color: ${colors.error};
    font-size: 11px;
    height: 11px;
  }

  &:focus-within {
    .bar {
      width: 100%;
      height: 2px;
      background-color: ${props => props.error ? colors.error : colors.primary}
    }

    label {
      color: ${props => props.error ? colors.error : colors.primary};
    }
  }
`
