import React from 'react'
import Button from '../../../components/Button'
import { Container } from './styles'

interface CallRegistrationHeaderProps {
  refetch: () => void
  onClickInsertCall?: () => any
}

const CallRegistrationHeader: React.FC<CallRegistrationHeaderProps> = ({
  refetch,
  onClickInsertCall
}) => {
  return (
    <Container>
      <Button
        buttonType="primary"
        type="button"
        onClick={onClickInsertCall}
      >
        Inserir Chamado
      </Button>
      <Button
        buttonType="primary"
        type="button"
        onClick={refetch}
      >
        Atualizar
      </Button>
    </Container>
  )
}

export default CallRegistrationHeader
