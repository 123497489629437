import React from 'react'
import { Button } from './styles'

interface IconButtonProps {
  icon: JSX.Element
  size: number
  padding: number
  onClick?: () => void
  disabled?: boolean | false
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  size,
  padding,
  onClick,
  disabled = false
}) => (
  <Button
    type="button"
    padding={padding}
    size={size}
    onClick={onClick}
    disabled={disabled}
  >
    {icon}
  </Button>
)

export default IconButton
