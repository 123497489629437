import React, { useState } from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal
} from '../../../hooks'
import SelectApi from '../../../components/Select/api'
import Label from '../../../components/Label'

interface ItemModalProps {
  itensDefault: any
  setItensDefault: any
  data: any
  codtpr: string
  codcli: string
  codemp: string
  ciffob: string
  somfre: string
  redpac: string
  refetch: () => void
}

interface ItemModalForm{
  codccu: string
  despro: string
  coddep: string
  codpro: string
  preuni: string
  qtdPed: string
  seqIpd: string
}

const ItemModal: React.FC<ItemModalProps> = ({
  itensDefault,
  setItensDefault,
  data,
  codcli,
  codtpr,
  codemp,
  ciffob,
  somfre,
  redpac,
  refetch
}) => {
  const methods = useForm<ItemModalForm>({
  })
  const { register } = methods
  const codpro = methods.watch('codpro')
  const { close } = useModal()
  const [productDefault, setProductDefault] = useState<any>()

  const handleSubmit = (formData: ItemModalForm): void => {
    setItensDefault([
      ...itensDefault,
      {
        codCcu: formData.codccu,
        codDep: formData.coddep,
        codPro: formData.codpro,
        preuni: formData.preuni ? String(formData.preuni).replace('.', ',') : '',
        despro: formData.despro,
        qtdPed: formData.qtdPed,
        seqIpd: formData.seqIpd
      }])
    close()
  }

  return (
    <div>
      <h3>Inserir Item em Orçamento</h3>
      <br />
      <Form methods={methods} onSubmit={handleSubmit}>
        {}
        { codtpr
          ? <SelectApi
            displayProperty="despro"
            readonly={false}
            label="Produto"
            name="codpro"
            register={register({
            })}
            url={`/salesorder/list-products/${codemp}/${codcli}/${codtpr}/${ciffob}/${somfre}/${redpac}`}
            valueProperty="codpro"
            onSelect={(item) => {
              if (item) {
                setProductDefault(item.data)
              }
            }}
          />
          : <></> }
        { codtpr && codpro
          ? <Label
            title="Código Produto"
            value={codpro}
          />
          : <></> }
        { codtpr && codpro
          ? <>
            <Input
              type="hidden"
              label=""
              name="despro"
              title="Descrição Produto"
              value={productDefault?.despro}
              register={register({
              })}
            />
          </>
          : <></> }
        { codtpr
          ? <Input
            label="Centro de Custo"
            name="codccu"
            defaultValue={productDefault?.codccu}
            register={register({
            })}
          />
          : <></> }
        { codtpr
          ? <SelectApi
            displayProperty="desdep"
            readonly={false}
            label="Depósito"
            name="coddep"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
            url={`/salesorder/list-deposits/${codemp}/${codpro}`}
            valueProperty="coddep"
          />
          : <></> }
        { codtpr && codpro
          ? <Label
            title= "Informação valores"
            value={` O valor mínimo é de R$ ${parseFloat(productDefault?.minimo).toFixed(4)}, o valor ideal é de R$ ${parseFloat(productDefault?.ideal).toFixed(4)} e o percentual de frete está definido em ${parseFloat(productDefault?.percentual).toFixed(2)}% para o estado ${productDefault?.estado as string}.`}
          />
          : <></> }
        { codtpr
          ? <Input
            label="Preço Unitário"
            name="preuni"
            register={register({
            })}
          />
          : <></> }
        { codtpr
          ? <Input
            label="Quantidade Pedida"
            name="qtdPed"
            register={register({
            })}
          />
          : <></> }
        { codtpr
          ? <Input
            label="Sequencial"
            name="seqIpd"
            register={register({
            })}
          />
          : <></> }
        { codtpr
          ? <Button
            buttonType="primary"
            type="submit"
          >
          Adicionar
          </Button>
          : <></> }
        <span style={{ display: 'inline-block', width: '5px' }} />
        { codtpr
          ? <Button
            buttonType="secondary"
            type="button"
            onClick= {close}
          >
          Fechar
          </Button>
          : <></> }
      </Form>
    </div>
  )
}

export default ItemModal
