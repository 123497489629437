import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
// import SelectApi from '../../../components/Select/api'
import { CallRegistrationModel } from '../../../models/call-registration'
import Select from '../../../components/Select'

interface CategoryModalProps {
  data: CallRegistrationModel
  refetch: () => void
  refetchDetails: () => void
}

interface CategoryForm {
  usu_catcham: string
}

const CategoryModal: React.FC<CategoryModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<CategoryForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()
  const { clearData } = useDetailsContainer()
  const [loading, setLoading] = useState(false)

  const handleSendData = async (formData: CategoryForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        usu_codchamabe: data.usu_codusuabe,
        usu_codcham: data.usu_codcham,
        usu_catcham: formData.usu_catcham
      }
      const response = await httpPatch<string>('/call-registration/update-call-category', json)
      console.log(json)
      console.log(response)

      if (response.body === 'Atualizado com sucesso !!!') {
        clearData()
      }
      toast.info(response.body)
      setLoading(false)
      refetch()
      close()
    })
  }

  return (
    <div>
      <h3>Atualizar Categoria do Chamado #{data.usu_codcham}</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Select
          readonly
          label="Categoria"
          name="usu_catcham"
          options={[{
            display: 'Suporte ao Usuário',
            value: '1'
          }, {
            display: 'Desenvolvimento/Projetos',
            value: '2'
          }
          ]}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default CategoryModal
