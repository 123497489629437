import styled from 'styled-components'

export const Container = styled.div`
  .info-container {    
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    section:first-child {
      width: 48%;
    }

    section:last-child {
      width: 50%;
    }

  }
`
