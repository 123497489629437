import React from 'react'
import Form from '../../../components/Form'
import Select from '../../../components/Select'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { Container } from './styles'
import { getUniqueOptions } from '../../../utils/select-options'
import { LogisticModel } from '../../../models/logistic'

interface FilterForm {
  empresa: string
  cliente: string
  tipo: string
  transportadora: string
  situacao: string
}

interface FilterProps {
  setFilter: any
  refetch: () => void
  data: LogisticModel[]
}

const Filter: React.FC<FilterProps> = ({
  setFilter,
  refetch,
  data
}) => {
  const methods = useForm<FilterForm>({})
  const { register } = methods

  const handleSubmit = (data: FilterForm): void => {
    setFilter(data)
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        methods={methods}
      >
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'usu_codemp')}
            readonly
            label="Empresa"
            name="empresa"
            register={register()}
          />
        </div>
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'usu_tipo')}
            readonly
            label="Tipo"
            register={register()}
            name="tipo"
          />
        </div>
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'nomtra')}
            readonly
            label="Transportadora"
            name="transportadora"
            register={register()}
          />
        </div>
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'nommot')}
            readonly
            label="Motorista"
            name="motorista"
            register={register()}
          />
        </div>
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'usu_nomcli')}
            readonly
            label="Cliente"
            name="cliente"
            register={register()}
          />
        </div>
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'usu_sitord')}
            label="Situação"
            register={register()}
            readonly
            name="situacao"
          />
        </div>
        <div className="dentro2">
          <Button
            buttonType="primary"
            type="submit"
          >
            Filtrar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={refetch}
          >
            Atualizar
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default Filter
