import React from 'react'
import {
  DeepMap,
  FieldError,
  FormProvider,
  UseFormMethods
} from 'react-hook-form'

interface FormProps {
  children: any
  methods: UseFormMethods<any>
  onSubmit: (data: any) => void
  onError?: (errors: DeepMap<any, FieldError>) => void
}

const Form: React.FC<FormProps> = ({
  children,
  methods,
  onError,
  onSubmit
}) => {
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
        {children}
      </form>
    </FormProvider>
  )
}

export default Form
