import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useApi,
  useDetailsContainer,
  useModal
} from '../../../hooks'
import SelectApi from '../../../components/Select/api'
import { handle } from '../../../utils/error-handlers'
import { AcquisitionOrderModel } from '../../../models/acquisition-order'
import { toast } from 'react-toastify'
import Input from '../../../components/Input'

interface AddServiceProps {
  data: AcquisitionOrderModel
  setAddService: any
}

interface AddServiceForm {
  codccu: string
  coddep: string
  codemp: string
  codfil: string
  codser: string
  numocp: string
  perins: string
  percrt: string
  perpit: string
  pericm: string
  peripi: string
  perirf: string
  percsl: string
  periss: string
  preuni: string
  qtdped: string
  seqiso: string
}

const AddServiceModal: React.FC<AddServiceProps> = ({
  data,
  setAddService
}) => {
  const methods = useForm<AddServiceForm>({
  })
  const { register } = methods
  const { clearData } = useDetailsContainer()
  const { close } = useModal()
  const codser = methods.watch('codser')
  const [loading, setLoading] = useState(false)
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AddServiceForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        ciffob: data.ciffob,
        codcpg: data.codcpg,
        codemp: data.codemp,
        codfil: data.codfil,
        codfor: data.codfor,
        codfpg: data.codfpg,
        codtra: data.codtra,
        datemi: data.datemi,
        numocp: data.numocp,
        obsmot: data.obsmot,
        obsocp: data.obsocp,
        somfre: data.somfre,
        tempar: data.tempar,
        tnspro: data.tnspro,
        tnsser: data.tnsser,
        vlrfre: data.vlrfre,
        produtos: data.produtos,
        servicos: [{
          codccu: formData.codccu,
          codemp: data.codemp,
          codfil: data.codfil,
          codser: formData.codser,
          numocp: data.numocp,
          percrt: formData.percrt,
          perpit: formData.perpit,
          pericm: formData.pericm,
          peripi: formData.peripi,
          perirf: formData.perirf,
          percsl: formData.percsl,
          perins: formData.perins,
          periss: formData.periss,
          preuni: parseFloat(formData.preuni),
          qtdped: formData.qtdped,
          seqiso: ''
        }]
      }

      const response = await httpPatch<string>('/acquisition-order/update-acquisition-order', json)

      if (response.statusCode === 200) {
        toast.info(response.body ? response.body : 'Serviço Adicionado')
        clearData()
      }
      setLoading(false)
      close()
      setAddService(true)
    })
  }

  return (
    <div>
      <h3>Adicionar Serviço</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="desser"
          readonly={false}
          label="Serviço"
          name="codser"
          register={register({
          })}
          url={`/acquisition-order/list-services/${data.codemp}`}
          valueProperty="codser"
        />
        {codser
          ? <>
            <SelectApi
              displayProperty="desccu"
              readonly={false}
              label="Centro de Custo"
              name="codccu"
              register={register({
                required: {
                  message: 'Preencha este campo',
                  value: true
                }
              })}
              url={`/acquisition-order/list-cost-center/${data.codemp}`}
              valueProperty="codccu"
            />
            <Input
              label="Quantidade Pedida"
              name="qtdped"
              register={register({
              })}
            />
            <Input
              label="Preço Unitário"
              name="preuni"
              register={register({
              })}
            />
            <Input
              label="ICMS %"
              name="pericm"
              register={register({
              })}
            />
            <Input
              label="PIS %"
              name="perpit"
              register={register({
              })}
            />
            <Input
              label="ICMS %"
              name="pericm"
              register={register({
              })}
            />
            <Input
              label="IPI %"
              name="peripi"
              register={register({
              })}
            />
            <Input
              label="IRRF %"
              name="perirf"
              register={register({
              })}
            />
            <Input
              label="CSLL %"
              name="percsl"
              register={register({
              })}
            />
            <Input
              label="PIS %"
              name="perpit"
              register={register({
              })}
            />
            <Input
              label="INSS %"
              name="perins"
              register={register({
              })}
            />
            <Input
              label="COFINS %"
              name="percrt"
              register={register({
              })}
            />
            <Input
              label="ISS %"
              name="periss"
              register={register({
              })}
            />
          </>
          : <></>
        }
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={clearData}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AddServiceModal
