import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 20px;

  button {
      margin: 0 5px;
  }

  button:first-child {
    margin-left: 0;
  }

  button:last-child {
    margin-right: 0;
  }
`
