import styled from 'styled-components'
import { ButtonStyle } from '../../../components/Button/styles'

export const Container = styled.div`
  .info {
    display: flex;
    section {
      width: 50%;
      display: block;

    section {
      display: block;
      width: 50%;
     }
  }
}

`
export const DivButton = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3.1rem;
    margin-top: 5rem;
`

export const CallButton = styled(ButtonStyle)`
  font-size: 1.1rem;
`
