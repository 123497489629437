import React from 'react'
import {
  useApi,
  useFetch,
  useLoading,
  useModal
} from '../../../hooks'
import { Container } from './styles'
import Loader from '../../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import Button from '../../../components/Button'
import { AprovationsModel } from '../../../models/acquisition-order'

interface AprovationsGridProps {
  codfil: string
  codemp: string
  numocp: string
}

const AprovationsGrid: React.FC<AprovationsGridProps> = ({
  codfil,
  codemp,
  numocp
}) => {
  const { httpGet } = useApi()

  const {
    data,
    loading,
    refetching
  } = useFetch<AprovationsModel[]>(httpGet, `/acquisition-order/list-aprovations/${codemp}/${codfil}/${numocp}`)
  const { loading: loadingHook } = useLoading()
  const { close } = useModal()

  return (
    <div>
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table>
            <THead>
              <Tr>
                <Th width="40px" color="#000" >Empresa</Th>
                <Th width="20px" className="hide-md" color="#000" >Cod.Usuário</Th>
                <Th color="#000" width="40px" > Data Aprovação </Th>
                <Th color="#000" width="40px" > Níveis Aprovação </Th>
                <Th className="hide-sm" color="#000" width="40px">Hora Aprovação</Th>
                <Th color="#000" width="100px" >Nome Usuário</Th>
                <Th color="#000" width="40px" >Número Aprovador</Th>
                <Th color="#000" width="40px" >Situação</Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map((item, index) => (
                <Tr key={index}>
                  <Td title={item.codemp}>    {item.codemp} </Td>
                  <Td title={item.codusu}>    {item.codusu} </Td>
                  <Td title={item.dataapr}>   {item.dataapr} </Td>
                  <Td title={item.nivApr}>   {item.nivApr} </Td>
                  <Td title={item.horaapr}>   {item.horaapr} </Td>
                  <Td title={item.nomusu}>    {item.nomusu} </Td>
                  <Td title={item.numapr}>    {item.numapr} </Td>
                  <Td title={item.situacaoapr}>     {item.situacaoapr} </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
          <Button
            buttonType="secondary"
            type="button"
            onClick={close}
          >
            Fechar
          </Button>
        </Loader>
      </Container>
    </div>
  )
}

export default AprovationsGrid
