import React, { useState } from 'react'
import Form from '../../../components/Form'
import Select from '../../../components/Select'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { Container } from './styles'
import { getUniqueOptions } from '../../../utils/select-options'
import { SalesOrderModel } from '../../../models/salesorder'
import DynamicIcon from '../../../components/DynamicIcon'
import Input from '../../../components/Input'
import { format, subDays, addDays } from 'date-fns'
import SelectMulti from '../../../components/Select/multi'

interface FilterForm {
  cliente: string
  tipo: string
  vendedora: string
  situacao: string
  dataInicio: string
  dataFim: string
  situation?: string[]
  type?: string[]
}

interface FilterProps {
  setFilter: any
  onClickInsertBudget?: () => any
  onClickListClient?: () => any
  onClickFindClient?: () => void
  onClickInsertOrder?: () => any
  refetch: () => void
  data: SalesOrderModel[]
}

const Filter: React.FC<FilterProps> = ({
  setFilter,
  onClickInsertBudget,
  onClickListClient,
  onClickFindClient,
  onClickInsertOrder,
  refetch,
  data
}) => {
  const methods = useForm<FilterForm>({})
  const { register } = methods
  const now = format(subDays(new Date(), 30), 'yyyy-MM-dd')
  const tomorrow = format(addDays(new Date(), 1), 'yyyy-MM-dd')
  const [situation, setSituation] = useState<string[]>([])
  const [type, setType] = useState<string[]>([])

  const handleSubmit = (data: FilterForm): void => {
    data.situation = situation
    data.type = type
    setFilter(data)
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        methods={methods}
      >
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'nomcli')}
            readonly
            label="Cliente"
            name="cliente"
            register={register()}
          />
        </div>
        <div className="dentro">
          <SelectMulti
            options={getUniqueOptions(data, 'tipo')}
            label="Tipo"
            name="tipo"
            values={type}
            setValues={setType}
          />
        </div>
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'nomrep')}
            readonly
            label="Vendedora"
            name="vendedora"
            register={register()}
          />
        </div>
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'des_emp')}
            readonly
            label="Empresa"
            name="empresa"
            register={register()}
          />
        </div>
        <div className="dentro">
          <SelectMulti
            options={getUniqueOptions(data, 'dessit')}
            label="Situação"
            values={situation}
            setValues={setSituation}
            name="situacao"
          />
        </div>
        <div className="dentro">
          <Input
            label="Data inicial"
            defaultValue={now}
            name="dataInicio"
            register={register()}
            type="date"
          />
        </div>
        <div className="dentro">
          <Input
            label="Data final"
            defaultValue={tomorrow}
            name="dataFim"
            register={register()}
            type="date"
          />
        </div>
        <div className="dentro2">
          <Button
            buttonType="primary"
            type="submit"
          >
            Filtrar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={refetch}
          >
            Atualizar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={onClickInsertBudget}
          >
            Orçamento
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={onClickInsertOrder}
          >
            Pedido
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={onClickFindClient}
          >
            <DynamicIcon
              family="fa"
              name="FaUserPlus"
              size={25}
            />
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={() => window.open('http://bi.grupofortaleza.com.br:8082/ReportServer/Pages/ReportViewer.aspx?%2fRelatorios%2fADM+-+Grupo+Fortaleza%2fVendas+-+Esfera%2fConsulta+Cliente&rs:Command=Render')}
          >
            <DynamicIcon
              family="fa"
              name="FaUserEdit"
              size={25}
            />
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={() => window.open('http://bi.grupofortaleza.com.br:8082/ReportServer/Pages/ReportViewer.aspx?%2fRelatorios%2fADM+-+Grupo+Fortaleza%2fVendas+-+Esfera%2fOrcamento+e+Pedido+de+Venda&rs:Command=Render')}
          >
            <DynamicIcon
              family="md"
              name="MdDescription"
              size={25}
            />
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default Filter
