/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../components/Table'
import {
  useApi,
  useDetailsContainer,
  useLoading,
  useModal
} from '../../hooks'
import { Container } from './styles'
import Filter from './Filter'
import { AcquisitionOrderModel } from '../../models/acquisition-order'
import { useCallback } from 'react'
import InsertAcquisitionOrder from './InsertAcquisitionOrder'
import AcquisitionOrderDetails from './AcquisitionOrderDetails'
import ProductsModal from './ProductsModal'
import ServicesModal from './ServicesModal'
import AttAcquisitionOrderModal from './AttAcquisitionOrder'
import AddProductModal from './AddProduct'
import AddServiceModal from './AddService'
import { handle } from '../../utils/error-handlers'
import { toast } from 'react-toastify'
import IconButton from '../../components/IconButton'
import { MdDelete } from 'react-icons/md'
import AprovationsGrid from './ListAprovations'
import AddProvider from './AddProvider'
import InsertProvider from './InsertProvider'
import Weighing from './Weighing'
import AttProduct from './AttProduct'
import AttService from './AttService'

const AcquisitionOrder: React.FC = () => {
  const { loading: loadingHook } = useLoading()
  const [data, setData] = useState<AcquisitionOrderModel[]>([])
  const { httpPut, httpGet } = useApi()
  const { open: openModal, confirm } = useModal()
  const [loading, setLoading] = useState<boolean>(false)
  const [filterRequest, setFilterRequest] = useState({ codemp: '0', codfil: '0', sitocp: '0' })

  const [disable, setDisable] = useState(false)
  const [insertAcquisition, setInsertAcquisition] = useState<boolean>(false)
  const [attAcquisitionOrder, setAttAcquisitionOrder] = useState<boolean>(false)
  const [attProduct, setAttProduct] = useState<boolean>(false)
  const [attService, setAttService] = useState<boolean>(false)
  const [rehabiliateAcquisition, setRehabiliateAcquisition] = useState<boolean>(false)
  const [deletePurchaseOrder, setDeletePurchaseOrder] = useState<boolean>(false)
  const [deleteProduct, setDeleteProduct] = useState<boolean>(false)
  const [deleteService, setDeleteService] = useState<boolean>(false)
  const [addProduct, setAddProduct] = useState<boolean>(false)
  const [addService, setAddService] = useState<boolean>(false)

  const [filtered, setFiltered] = useState<AcquisitionOrderModel[]>([])
  const {
    pushPage,
    clearData,
    close,
    open
  } = useDetailsContainer()

  const [filter, setFilter] = useState(() => ({
    fornecedor: '',
    dataInicio: '',
    dataFim: ''
  }))

  useEffect(() => {
    if (data) {
      setFiltered(data?.filter((item) => {
        return (filter.fornecedor === '' || item.nomfor === filter.fornecedor) &&
          (filter.dataInicio === '' || new Date((item.datemi).split('/').reverse().join('-') + 'T03:00:00.000Z') >= new Date((filter.dataInicio))) &&
          (filter.dataFim === '' || new Date((item.datemi).split('/').reverse().join('-') + 'T03:00:00.000Z') <= new Date((filter.dataFim)))
      }) ?? [{ data }])
    }
  },
  [filter, data, setFiltered])

  const handleInsertAcquisitionOrder = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <InsertAcquisitionOrder
            openProductsModal={openProductsModal}
            openServicesModal={openServicesModal}
            setInsertAcquisition={setInsertAcquisition}
            data={data}
          />
        ),
        title: 'Inserir Ordem de Compra'
      })
      open()
    }
  }, [loading, loadingHook, pushPage, data, open])

  useEffect(() => {
    setLoading(true)
    handle(async () => {
      const response = await httpGet<any>(`/acquisition-order/${filterRequest.codemp}/${filterRequest.codfil}/${filterRequest.sitocp}`)
      setData(response.body)

      toast.info(response)
    })
    setLoading(false)
  },
  [filterRequest, httpGet, attProduct, rehabiliateAcquisition, attService, insertAcquisition, attAcquisitionOrder, deletePurchaseOrder, addService, addProduct, deleteProduct, deleteService])

  const openAttAcquisitionOrder = useCallback((data: AcquisitionOrderModel): () => void => {
    return () => {
      pushPage({
        component: (
          <AttAcquisitionOrderModal
            data={data}
            setAttAcquisitionOrder={setAttAcquisitionOrder}
          />
        ),
        title: 'Atualizar Ordem de Compra'
      })
      open()
    }
  }, [openModal, loadingHook, loading])

  const handleDeletePurchaseOrder = useCallback((data: AcquisitionOrderModel): () => void => {
    return () => {
      confirm('Continuar com a exclusão da ordem de compra ?', () => {
        setDisable(true)
        handle(async () => {
          const response = await httpPut<string>('/acquisition-order/delete-acquisition-order', {
            ciffob: data.ciffob,
            codcpg: data.codcpg,
            codemp: data.codemp,
            codfil: data.codfil,
            codfor: data.codfor,
            codfpg: data.codfpg,
            codtra: data.codtra,
            datemi: data.datemi,
            numocp: data.numocp,
            obsmot: data.obsmot,
            obsocp: data.obsocp,
            somfre: data.somfre,
            tempar: data.tempar,
            tnspro: data.tnspro,
            tnsser: data.tnsser,
            vlrfre: data.vlrfre
          })
          toast.info(response.body)
          setDeletePurchaseOrder(true)
          setDisable(false)
        })
      })
    }
  }, [httpPut, confirm])

  const openAddProduct = useCallback((data: AcquisitionOrderModel): () => void => {
    return () => {
      pushPage({
        component: (
          <AddProductModal
            data={data}
            setAddProduct={setAddProduct}
          />
        ),
        title: 'Adicionar Produto'
      })
      open()
    }
  }, [openModal, loadingHook, loading])

  const openAprovationsGrid = useCallback((numocp: string, codemp: string, codfil: string): () => void => {
    return () => {
      openModal(
        <AprovationsGrid
          codemp={codemp}
          codfil={codfil}
          numocp={numocp}
        />
      )
    }
  }, [openModal])

  const openWeighing = useCallback((numocp: string, codemp: string, codfil: string, codpro: string): () => void => {
    return () => {
      openModal(
        <Weighing
          codemp={codemp}
          codfil={codfil}
          numocp={numocp}
          codpro={codpro}
        />
      )
    }
  }, [openModal])

  const openAttProduct = useCallback((data: AcquisitionOrderModel, index: number): () => void => {
    return () => {
      openModal(
        <AttProduct
          data={data}
          index={index}
          setAttProduct={setAttProduct}
        />
      )
    }
  }, [openModal])

  const openAttService = useCallback((data: AcquisitionOrderModel, index: number): () => void => {
    return () => {
      openModal(
        <AttService
          data={data}
          index={index}
          setAttService={setAttService}
        />
      )
    }
  }, [openModal])

  const openAddService = useCallback((data: AcquisitionOrderModel): () => void => {
    return () => {
      pushPage({
        component: (
          <AddServiceModal
            data={data}
            setAddService={setAddService}
          />
        ),
        title: 'Adicionar Serviço'
      })
      open()
    }
  }, [openModal, loadingHook, loading])

  const openProductsModal = useCallback((data: AcquisitionOrderModel, setProductsDefault: any, productsDefault: any, codemp: string, codfil: string): () => void => {
    return () => {
      openModal(
        <ProductsModal
          codemp={codemp}
          codfil={codfil}
          setProductsDefault={setProductsDefault}
          productsDefault={productsDefault}
          data={data}
        />
      )
    }
  }, [openModal])

  const openServicesModal = useCallback((data: AcquisitionOrderModel, setServicesDefault: any, servicesDefault: any, codemp: string, codfil: string): () => void => {
    return () => {
      openModal(
        <ServicesModal
          codemp={codemp}
          codfil={codfil}
          setServicesDefault={setServicesDefault}
          servicesDefault={servicesDefault}
          data={data}
        />
      )
    }
  }, [openModal])

  const openDeleteProduct = useCallback((data: AcquisitionOrderModel, index: number): () => void => {
    return () => {
      confirm('Continuar com a exclusão do produto ?', () => {
        setDisable(true)
        handle(async () => {
          const item = data.produtos[index]
          const json = {
            produtos: {
              codemp: item.codemp,
              codfil: item.codfil,
              codpro: item.codpro,
              numocp: item.numocp,
              seqipo: item.seqipo
            }
          }
          const response = await httpPut<string>('/acquisition-order/delete-acquisition-order', json)
          toast.info(response.body)
          close()
          clearData()
          setDisable(false)
          setDeleteProduct(true)
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm])

  const openAddProvider = useCallback((data: any) => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <AddProvider
            data={data}
          />
        ),
        title: 'Adicionar Fornecedor'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInsertProvider = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <InsertProvider
            openAddProvider={openAddProvider}
          />
        ),
        title: 'Buscar Fornececdor'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openDeleteService = useCallback((data: AcquisitionOrderModel, index: number): () => void => {
    return () => {
      confirm('Continuar com a exclusão do serviço ?', () => {
        setDisable(true)
        handle(async () => {
          const item = data.servicos[index]
          const json = {
            servicos: {
              codemp: item.codemp,
              codfil: item.codfil,
              codser: item.codser,
              numocp: item.numocp,
              seqiso: item.seqiso
            }
          }
          const response = await httpPut<string>('/acquisition-order/delete-acquisition-order', json)
          toast.info(response.body)
          close()
          setDisable(false)
          clearData()
          setDeleteService(true)
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm])

  const openDetails = useCallback((data: AcquisitionOrderModel): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <AcquisitionOrderDetails
            data={data}
            disable={disable}
            openWeighing={openWeighing}
            openAttProduct={openAttProduct}
            openAttService={openAttService}
            openAprovationsGrid={openAprovationsGrid}
            openDeleteProduct={openDeleteProduct}
            openDeleteService={openDeleteService}
            openAddProduct={openAddProduct}
            openAddService={openAddService}
            setRehabiliateAcquisition={setRehabiliateAcquisition}
            openAttAcquisitionOrder={openAttAcquisitionOrder}
          />
        ),
        title: 'Ordem de Compra'
      })
      open()
    }
  }, [open, close, pushPage])

  return (
    <>
      <Filter
        setFilterRequest={setFilterRequest}
        filterRequest={filterRequest}
        onClickInsertProvider={handleInsertProvider}
        setData={setData}
        data={data ?? []}
        setFilter={setFilter}
        setLoading={setLoading}
        onClickInsertAcquisitionOrder={handleInsertAcquisitionOrder}
      />
      <Container>
        <Loader
          loading={loading || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!data?.length && !filtered.length}
            overflow="auto"
          >
            <THead>
              <Tr>
                <Th width="30px" className="hide-md" color="#000" >OC</Th>
                <Th width="30px" className="hide-md" color="#000" >Empresa</Th>
                <Th width="30px" className="hide-md" color="#000" >Filial</Th>
                <Th width="50px" className="hide-md" color="#000" >Emissão</Th>
                <Th width="50px" className="hide-md" color="#000" >Trans. Prod</Th>
                <Th width="50px" className="hide-md" color="#000" >Trans. Serv</Th>
                <Th width="30px" className="hide-md" color="#000">Forn</Th>
                <Th width="100px" className="hide-md" color="#000" >Nome</Th>
                <Th width="60px" className="hide-sm" color="#000" >Nome Ger</Th>
                <Th width="40px" className="hide-sm" color="#000" >Sit Apr</Th>
                <Th width="120px" className="hide-sm" color="#000" >Obs</Th>
                <Th width="30px" className="hide-md" color="#000" >Sit. OC</Th>
                <Th width="40px" className="hide-md" color="#000" >Descrição</Th>
                <Th width="40px" className="hide-md" color="#000" >Liq. OC</Th>
                <Th width="20px" className="hide-md" color="#000" ></Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map((item, index) => (
                <Tr key={index}>
                  <Td title={item.numocp}>
                    <button
                      className="first-item"
                      onClick={openDetails(item)}
                    >
                      {item.numocp}
                    </button>
                  </Td>
                  <Td title={item.codemp}>{item.codemp}</Td>
                  <Td title={item.codfil}>{item.codfil}</Td>
                  <Td title={item.datemi}>{item.datemi}</Td>
                  <Td title={item.tnspro}>{item.tnspro}</Td>
                  <Td title={item.tnsser}>{item.tnsser}</Td>
                  <Td title={item.codfor}>{item.codfor}</Td>
                  <Td title={item.apefor}>{item.apefor}</Td>
                  <Td title={item.nomusu}>{item.nomusu}</Td>
                  <Td title={item.sitapr}>{item.sitapr}</Td>
                  <Td title={item.obsocp}>{item.obsocp}</Td>
                  <Td title={item.sitocp}>{item.sitocp}</Td>
                  <Td title={item.dessitocp}>{item.dessitocp}</Td>
                  <Td title={item.vlrliq}>{item.vlrliq}</Td>
                  <Td textAlign="center" className="hide-md">
                    <IconButton
                      icon={<MdDelete size={20} />}
                      padding={3}
                      size={20}
                      disabled={disable}
                      onClick={handleDeletePurchaseOrder(item)}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default AcquisitionOrder
