import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const CheckboxContainer = styled.div`
  display: inline-block;
  width: max-content;
  margin: 0 0 13px 0;

  &:hover input ~ .checkmark {
    background-color: ${colors.lightGray};
    border-radius: 50%;
  }

  input:checked ~ .checkmark {
    border: none;
    border-radius: 50%;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  label {
    display: block;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 13px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: white;
    border: 1px solid ${colors.lightBorders};

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      left: 4px;
      top: 1px;
      width: 5px;
      height: 8px;
      border: solid ${colors.primary};
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`
