import styled from 'styled-components'
import { colors } from '../../../styles/default-styles'

export const Container = styled.div`
  padding: 5px 15px;
  margin-top: 20px;
  margin-left: 0px;
  background-color: white;
  border-bottom: 1px solid ${colors.borders};
  width: 100%;
  Button{
    margin-right:10px;
  }
  .dentro{
    width: 45%;
    margin-right:40px;
    justify-content: space-between;
  }
  Form{
    display: flex;
  }
  
`
