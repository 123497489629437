import React from 'react'
import { FieldContainer } from './styles'

interface FieldProps {
  children: any
  label?: string
  id?: string
  error?: string
}

const Field: React.FC<FieldProps> = ({
  children,
  label,
  id,
  error
}) => {
  const hasError = Boolean(error)

  return (
    <FieldContainer id={id} error={hasError}>
      {label && <label>{label}</label>}
      {children}
      <div className="bar" />
      <p>{error}</p>
    </FieldContainer>
  )
}

export default Field
