import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { MdKeyboardArrowDown, MdCheck } from 'react-icons/md'
import Field from '../Field'
import { useClickAwayListener } from '../../hooks'
import { useFormContext } from 'react-hook-form'
import { SelectOption } from '../../models/select-option'
import { SelectContainer, SelectOptions } from './styles'

export interface SelectMultiProps {
  name: string
  label: string
  options: SelectOption[]
  values: string[]
  setValues: Dispatch<SetStateAction<string[]>>
}

const SelectMulti: React.FC<SelectMultiProps> = ({
  label,
  name,
  options,
  values,
  setValues
}) => {
  const [opened, setOpened] = useState(false)
  const { errors, setValue } = useFormContext()
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [, setFilter] = useState('')

  useClickAwayListener({
    elementId: name,
    onClickAway: () => {
      if (opened) {
        setOpened(false)
      }
    }
  })

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  const isSelected = (item: SelectOption): boolean => {
    return !!values.find(value => value === item.value)
  }

  return (
    <SelectContainer
      id={name}
      onClick={() => {
        setOpened(!opened)
      }}
    >
      <Field id={name} error={errors[name]?.message} label={label}>
        <input
          type='hidden'
          name={name}
        />
        <input
          autoComplete="off"
          onChange={(e) => {
            setOpened(true)
            setFilter(e.target.value)
          }}
          value={ values }
        />
        {/* <span className="value">{getValue()}</span> */}
        <MdKeyboardArrowDown className="arrow-down" />
      </Field>
      <SelectOptions opened={opened}>
        <ul>
          <li onClick={() => {
            setValue(name, undefined, {
              shouldValidate: true,
              shouldDirty: true
            })
          }}>
            Selecione
          </li>
          {filteredOptions.map((item, index) => (
            <li
              key={item.value}
              className={isSelected(item) ? 'selected' : ''}
              onClick={() => {
                if (isSelected(item)) {
                  setValues(values.filter((item, i) => index !== i))
                }
                setValue(name, item.value, {
                  shouldValidate: true,
                  shouldDirty: true
                })
                setFilter(item.display)
                const validation = !isSelected(item)
                if (validation) {
                  setValues([...values, item.value])
                }
              }}
            >
              {isSelected(item) ? <MdCheck/> : <> </> }
              {item.display}
            </li>
          ))}
        </ul>
      </SelectOptions>
    </SelectContainer>
  )
}

export default SelectMulti
