import React from 'react'
import DynamicIcon from '../../../../components/DynamicIcon'
import Button from '../../../../components/Button'
import Label from '../../../../components/Label'
import DetailsButtons from '../../../../components/DetailsButtons'
import Prompt from '../../../../components/Prompt'
import Loader from '../../../../components/Loader'
import {
  useModal,
  useDetailsContainer,
  useApi,
  useLoading
} from '../../../../hooks'
import { handle } from '../../../../utils/error-handlers'
import { toast } from 'react-toastify'
import { PurchaseRequest } from '../../../../models/purchase-request'
import { Container } from './styles'

interface PurchaseRequestDetailsProps {
  data: PurchaseRequest
  refetch: () => void
}

const PurchaseRequestDetails: React.FC<PurchaseRequestDetailsProps> = ({
  data,
  refetch
}) => {
  const { httpPut } = useApi()
  const { close } = useDetailsContainer()
  const { load, loading } = useLoading()
  const { confirm, open } = useModal()

  const handleClick = (action: 'approve' | 'reprove') => () => {
    const successMessage = {
      approve: 'aprovada',
      reprove: 'reprovada'
    }

    const handleSendData = (aditionalParams: any = {}): void => {
      handle(async () => {
        load(async () => {
          const body = {
            codEmp: data.codEmp,
            seqSol: data.seqsol,
            ...aditionalParams
          }

          await httpPut(`/purchase-requests/${action}/${data.numsol}`, body)
          toast.success(`Solicitação ${successMessage[action]}.`)
          close()
          refetch()
        })
      })
    }

    if (action === 'approve') {
      confirm('Continuar com a aprovação da solicitação de compra?', handleSendData)

      return
    }

    open(
      <Prompt
        inputLabel="Motivo"
        message="Informe um motivo para a reprovação:"
        onConfirm={(motRep) => handleSendData({ motRep })}
      />
    )
  }

  return (
    <Container>
      <Loader
        loading={loading}
        overlay
        message="Processando..."
      />
      <div className="info">
        <section>
          <Label
            title="Nº da solicitação"
            value={data.numsol}
          />
          <Label
            title="Data da solicitação"
            value={data.datsol}
          />
          <Label
            title="Sequencial da solicitação"
            value={data.seqsol}
          />
          <Label
            title="Observação"
            value={data.obssol ?? '-'}
          />
          <Label
            title="Código da família"
            value={data.codfam}
          />
          <Label
            title="Descrição da família"
            value={data.desfam}
          />
          <Label
            title="Centro de Custo"
            value={data.ccudes}
          />
        </section>
        <section>
          <Label
            title="Código do produto"
            value={data.codpro}
          />
          <Label
            title="Descriçao do produto"
            value={data.cplpro}
          />
          <Label
            title="Quantidade"
            value={data.qtdsol}
          />
          <Label
            title="Unidade de medida"
            value={data.unimed}
          />
          <Label
            title="Nome da empresa"
            value={data.nomemp}
          />
          <Label
            title="Nº da aprovação"
            value={data.numapr}
          />
          <Label
            title="Situação da aprovação"
            value={data.sitapr}
          />
        </section>
      </div>
      <DetailsButtons>
        <Button
          buttonType="primary"
          type="button"
          onClick={handleClick('approve')}
        >
          <DynamicIcon
            family="md"
            name="MdCheck"
            size={15}
          />
          &nbsp;
          Aprovar
        </Button>
        <Button
          buttonType="error"
          type="button"
          onClick={handleClick('reprove')}
        >
          <DynamicIcon
            family="md"
            name="MdClear"
            size={15}
          />

          &nbsp;
          Reprovar
        </Button>
      </DetailsButtons>
    </Container>
  )
}

export default PurchaseRequestDetails
