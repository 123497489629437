import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { LogisticModel } from '../../../models/logistic'
import Select from '../../../components/Select'

interface SituationModalProps {
  data: LogisticModel
  refetch: () => void
  refetchDetails: () => void
}

interface SituationForm {
  sitord: string
}

const SituationModal: React.FC<SituationModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<SituationForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const [loading, setLoading] = useState(false)
  const { httpPatch } = useApi()

  const handleSendData = async (formData: SituationForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const response = await httpPatch<string>(`/logistic/update-situation/${data.usu_numped}/${data.usu_codemp}/${data.usu_seqipd}`, {
        codemp: data.usu_codemp,
        codfil: data.usu_codfil,
        sitord: formData.sitord,
        numped: data.usu_numped,
        seqipd: data.usu_seqipd,
        tipo: data.usu_tipo
      })
      toast.info(response.body)
      setLoading(false)
      close()
      refetch()
      refetchDetails()
    })
  }

  return (
    <div>
      <h3>Atualizar Situação</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Select
          readonly
          label="Situação"
          name="sitord"
          options={[{
            display: 'A - Aberta ',
            value: 'A'
          }, {
            display: 'E - Andamento',
            value: 'E'
          }, {
            display: 'F - Fechado',
            value: 'F'
          }, {
            display: 'C - Cancelar',
            value: 'C'
          }
          ]}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default SituationModal
