import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { colors } from '../../styles/default-styles'

export type ButtonType = 'primary' | 'error' | 'secondary'

interface ButtonStyleProps {
  buttonType: ButtonType
  fullWidth: boolean
}

const getTypeColor = (props: ButtonStyleProps): FlattenSimpleInterpolation => {
  switch (props.buttonType) {
    case 'primary':
      return css`
        background-color: ${colors.primary};

        svg {
          color: white;
        }

        &:hover, &:disabled {
          cursor: pointer;
          background-color: #555;
        }
      `
    case 'error':
      return css`
        background-color: ${colors.error};

        svg {
          color: white;
        }

        &:hover, &:disabled {
          cursor: pointer;
          background-color: #eb6363;
        }
      `
    case 'secondary':
      return css`
        background-color: ${colors.secondary};
        color: ${colors.mediumGray};

        svg {
          color: ${colors.mediumGray};
        }

        &:hover, &:disabled {
          cursor: pointer;
          background-color: #dedddd;
        }
      `
    default:
      return css`
        background-color: ${colors.primary};

        svg {
          color: white;
        }

        &:hover, &:disabled {
          cursor: pointer;
          background-color: #555;
        }
      `
  }
}

export const ButtonStyle = styled.button<ButtonStyleProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  padding: 8px 20px;
  color: white;
  width: ${props => props.fullWidth ? '100%' : 'max-content'};
  transition: 0.15s all;
  ${props => props.disabled && css`
    pointer-events: none;
  `}
  ${getTypeColor}
`
