import styled from 'styled-components'

export const Container = styled.div`
  padding: 15px;

  .empty-message {
    margin: 20px 0;
    text-align: center;
  }
`

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .empty-message {
    text-align: center;
    margin-bottom: 20px;
  }
`
