import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useApi,
  useDetailsContainer,
  useModal
} from '../../../hooks'
import SelectApi from '../../../components/Select/api'
import { handle } from '../../../utils/error-handlers'
import { AcquisitionOrderModel } from '../../../models/acquisition-order'
import { toast } from 'react-toastify'
import Input from '../../../components/Input'

interface AttAcquisitionOrderProps {
  data: AcquisitionOrderModel
  setAttAcquisitionOrder: any
}

interface AttAcquisitionOrderForm {
  ciffob: string
  codcpg: string
  codemp: string
  codfil: string
  codfor: string
  codfpg: string
  codtra: string
  datemi: string
  numocp: string
  codtnsser: string
  codtnspro: string
  obsmot: string
  obsocp: string
  somfre: string
  tempar: string
  tnspro: string
  tnsser: string
  periss: string
  vlrfre: string
}

const AttAcquisitionOrderModal: React.FC<AttAcquisitionOrderProps> = ({
  data,
  setAttAcquisitionOrder
}) => {
  const methods = useForm<AttAcquisitionOrderForm>({
  })
  const { register } = methods
  const { clearData } = useDetailsContainer()
  const { close } = useModal()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AttAcquisitionOrderForm): Promise<void> => {
    await handle(async () => {
      const json = {
        ciffob: formData.ciffob,
        codcpg: formData.codcpg === '' ? data.codcpg : formData.codcpg,
        codemp: data.codemp,
        codfil: data.codfil,
        codfor: formData.codfor === '' ? data.codfor : formData.codfor,
        codfpg: formData.codfpg === '' ? data.codfpg : formData.codfpg,
        codtra: formData.codtra === '' ? data.codtra : formData.codtra,
        datemi: data.datemi,
        numocp: data.numocp,
        obsmot: formData.obsmot,
        obsocp: formData.obsocp,
        somfre: formData.somfre,
        tempar: formData.tempar,
        tnspro: formData.codtnspro === '' ? data.tnspro : formData.codtnspro,
        tnsser: formData.codtnsser === '' ? data.tnsser : formData.codtnsser,
        vlrfre: formData.vlrfre
      }

      const response = await httpPatch<string>('/acquisition-order/update-acquisition-order', json)

      if (response.statusCode === 200) {
        toast.info(response.body ? response.body : 'Produto Adicionado')
        clearData()
      }
      close()
      setAttAcquisitionOrder(true)
    })
  }

  return (
    <div>
      <h3>Atualizar</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="destns"
          readonly={false}
          label="Transação Produto"
          name="codtnspro"
          register={register({})}
          url={`/acquisition-order/list-transactions/${data.codemp}`}
          valueProperty="codtns"
        />

        <SelectApi
          displayProperty="destns"
          readonly={false}
          label="Transação Serviço"
          name="codtnsser"
          register={register({})}
          url={`/acquisition-order/list-transactions/${data.codemp}`}
          valueProperty="codtns"
        />
        <SelectApi
          displayProperty="nomfor"
          readonly={false}
          label="Fornecedor"
          name="codfor"
          register={register({
          })}
          url={`/acquisition-order/list-providers/${data.codemp}/${data.codfil}`}
          valueProperty="codfor"
        />
        <Input
          label="Frete Cif/Fob"
          name="ciffob"
          defaultValue={data.ciffob}
          register={register({
          })}
        />
        <SelectApi
          displayProperty="descpg"
          readonly={false}
          label="Condição de Pagamento"
          name="codcpg"
          register={register({})}
          url={`/acquisition-order/list-payment-condition/${data.codemp}`}
          valueProperty="codcpg"
        />
        <SelectApi
          displayProperty="desfpg"
          readonly={false}
          label="Forma de Pagamento"
          name="codfpg"
          register={register({
          })}
          url={`/acquisition-order/list-payment-form/${data.codemp}`}
          valueProperty="codfpg"
        />
        <SelectApi
          displayProperty="nomtra"
          readonly={false}
          label="Transportadora"
          name="codtra"
          register={register({
          })}
          url={'/acquisition-order/list-carriers'}
          valueProperty="codtra"
        />
        <Input
          label="Observação Orçamento"
          name="obsmot"
          register={register({
          })}
        />
        <Input
          label="Observação Ordem de Compra"
          name="obsocp"
          defaultValue={data.obsocp}
          register={register({
          })}
        />
        <Input
          label="Soma Frete"
          name="somfre"
          defaultValue={data.somfre}
          register={register({
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={clearData}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttAcquisitionOrderModal
