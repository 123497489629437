import styled from 'styled-components'
import { colors } from '../../../styles/default-styles'

export const Container = styled.div`
  width: 100%;

  button {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  .company {
    font-size: 16px;
    margin-bottom: 10px;
  }

  ul {
    list-style: none;
    max-height: 300px;
    min-height: 300px;
    overflow: auto;
    margin-bottom: 15px;

    li {
      padding: 7px;
      
      &.selected {
        background-color: ${colors.lightGray};
      }

      &:hover {
        background-color: ${colors.lightGray};
        cursor: pointer;
      }
    }
  }
`
