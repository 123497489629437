import React, {
  useCallback,
  useEffect
} from 'react'
import Form from '../../../../components/Form'
import Input from '../../../../components/Input'
import Checkbox from '../../../../components/Checkbox'
import Button from '../../../../components/Button'
import Loader from '../../../../components/Loader'
import DetailsButtons from '../../../../components/DetailsButtons'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
  useDetailsContainer,
  useApi,
  useLoading,
  useModal
} from '../../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../../utils/error-handlers'
import { Module } from '../../../../models/modules'
import { Container } from './styles'

interface ModuleDetailsProps {
  id?: number
  refetch: () => void
}

interface ModuleForm {
  name: string
  active: boolean
}

const ModuleDetails: React.FC<ModuleDetailsProps> = ({ id = -1, refetch }) => {
  const methods = useForm<ModuleForm>({ mode: 'onTouched' })
  const { register, setValue } = methods
  const {
    httpPost,
    httpGet,
    httpDelete,
    httpPatch
  } = useApi()
  const { close } = useDetailsContainer()
  const { replace } = useHistory()
  const { load, loading } = useLoading()
  const { confirm } = useModal()

  const handleSubmit = async (data: ModuleForm): Promise<void> => {
    handle(async () => {
      if (id === -1) {
        await httpPost('/modules', data)
      } else {
        await httpPatch(`/modules/${id}`, { active: data.active })
      }
      refetch()
      toast.success('Módulo salvo com sucesso.')
    })
  }

  const closeWrapper = useCallback(() => {
    close()
    replace('/configuracoes/modulos')
  }, [close, replace])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await load(async () => {
        if (id > -1) {
          const module = await httpGet<Module>(`/modules/${id}`)
          setValue('name', module.body?.name)
          setValue('active', module.body?.active)
        }
      })
    }
    fetchData()
  }, [id, httpGet, setValue, load])

  return (
    <Container>
      <Loader
        loading={loading}
        overlay
      />
      <Form onSubmit={handleSubmit} methods={methods}>
        <Input
          label="Nome"
          name="name"
          readOnly={id > -1}
          register={register({
            required: {
              message: 'Preencha este campo.',
              value: true
            }
          })}
        />
        {id > -1 && (
          <Checkbox
            label="Ativo"
            name="active"
            register={register()}
          />
        )}
        <DetailsButtons>
          <Button
            buttonType="primary"
            type="submit"
          >
            Salvar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={closeWrapper}
          >
            Cancelar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            disabled={id === -1}
            onClick={() => {
              handle(async () => {
                confirm('Tem certeza que deseja excluir esse módulo?', async () => {
                  await httpDelete(`/modules/${id}`)
                  closeWrapper()
                  toast.success('Módulo excluído com sucesso')
                  refetch()
                })
              })
            }}
          >
            Excluir
          </Button>
        </DetailsButtons>
      </Form>
    </Container>
  )
}

export default ModuleDetails
