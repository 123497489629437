import React, {
  useState,
  useCallback
} from 'react'
import Loader from '../../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import DynamicIcon from '../../../components/DynamicIcon'
import Checkbox from '../../../components/Checkbox/table'
import ApprovalsHeader from '../ApprovalsHeader'
import Filter from './Filter'
import BondDetails from './BondDetails'
import {
  useApi,
  useFetch,
  useDetailsContainer,
  useModal,
  useLoading,
  useSelectItems,
  useFilter
} from '../../../hooks'
import { toast } from 'react-toastify'
import { addDays, format } from 'date-fns'
import { handle } from '../../../utils/error-handlers'
import { Bond } from '../../../models/bond'
import IconButton from '../../../components/IconButton'
import { Container, DateFilter } from './styles'

const PaymentApproval: React.FC = () => {
  const { httpGet, httpPut } = useApi()
  const [stringFilter, setStringFilter] = useState(() => {
    const dateFormat = 'dd-MM-yyyy'
    const today = format(new Date(), dateFormat)
    const tomorrow = format(addDays(new Date(), 1), dateFormat)
    return `${today}/${tomorrow}`
  })
  const {
    data,
    loading,
    refetching,
    refetch
  } = useFetch<Bond[]>(httpGet, `/payment-approval/pending/${stringFilter}`)
  const {
    close,
    pushPage,
    open
  } = useDetailsContainer()
  const { open: openModal, confirm } = useModal()
  const { loading: loadingHook, load } = useLoading()
  const {
    handleSelect,
    handleSelectAll,
    isSelected,
    selectedItems
  } = useSelectItems<Bond>(item => {
    let key = ''
    for (const [, value] of Object.entries(item)) {
      key += String(value)
    }
    return key
  })
  const [filter, setFilter] = useState(() => () => true)
  const { filtered } = useFilter<Bond>(data, filter)

  const openDetails = useCallback((bond: Bond): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <BondDetails
            data={bond}
            refetch={refetch}
          />
        ),
        title: 'Título'
      })
      open()
    }
  }, [close, pushPage, open, refetch])

  const handleFilterClick = useCallback(() => {
    if (!loading && !loadingHook && data) {
      openModal(
        <Filter
          setStringFilter={setStringFilter}
          setFilter={setFilter}
          data={data ?? []}
        />
      )
      return
    }
    toast.info('Aguarde os dados carregarem.')
  }, [openModal, data, loadingHook, loading])

  const getFilterText = (): string => {
    const [start, end] = stringFilter.split('/')
    const startDate = start.replace(/-/g, '/')
    const endDate = end.replace(/-/g, '/')
    if (startDate === endDate) {
      return `Resultados de ${startDate}`
    }
    return `Resultados entre ${startDate} e ${endDate}`
  }

  const handleApprove = (items: Bond[]) => {
    return () => {
      confirm('Continuar com a aprovação?', () => {
        handle(async () => {
          load(async () => {
            for (const item of items) {
              await httpPut(`/payment-approval/approve/${item.numTit}`, {
                codEmp: item.codEmp,
                codFil: item.codFil,
                codFor: item.codFor,
                codTpt: item.codTpt,
                pgtApr: item.vlrAbe,
                vlrApr: item.vlrAbe
              })
              toast.success('Aprovado com sucesso.')
            }
            refetch()
          })
        })
      })
    }
  }

  return (
    <>
      <ApprovalsHeader
        refetch={refetch}
        onClickFilter={handleFilterClick}
      >
        <DateFilter>{getFilterText()}</DateFilter>
      </ApprovalsHeader>
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!filtered.length}
            selected={selectedItems.length}
            overflow="auto"
            actionButton={<IconButton
              padding={1}
              size={20}
              onClick={handleApprove(filtered)}
              icon={(
                <DynamicIcon
                  family="md"
                  name="MdCheckCircle"
                  size={15}
                  color="#33b850"
                />
              )}
            />}
          >
            <THead>
              <Tr>
                <Th width="40px">
                  <Checkbox
                    value={selectedItems.length === filtered.length}
                    onSelect={handleSelectAll(filtered, selectedItems.length !== filtered.length)}
                  />
                </Th>
                <Th width="200px" color="#000" >Nº título</Th>
                <Th width="90px" className="hide-sm" color="#000" >Data entrada</Th>
                <Th width="90px" color="#000" >Vencimento</Th>
                <Th width="90px" color="#000" >Valor aberto</Th>
                <Th width="90px" className="hide-sm" color="#000" >Valor original</Th>
                <Th width="90px" className="hide-sm" color="#000" >Empresa</Th>
                <Th className="hide-md" color="#000" >Observação</Th>
                <Th width="40px" color="#000" />
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item, index) => (
                <Tr key={index}>
                  <Td className="table-checkbox">
                    <Checkbox
                      value={isSelected(item)}
                      onSelect={handleSelect(item, !isSelected(item))}
                    />
                  </Td>
                  <Td title={item.numTit}>
                    <button
                      onClick={openDetails(item)}
                      className="first-item"
                    >
                      {item.numTit}
                    </button>
                  </Td>
                  <Td title={item.datEnt} className="hide-sm">{item.datEnt}</Td>
                  <Td title={item.vctOri}>{item.vctOri}</Td>
                  <Td title={item.vlrAbe}>{item.vlrAbe}</Td>
                  <Td title={item.vlrOri} className="hide-sm">{item.vlrOri}</Td>
                  <Td title={item.nomEmp} className="hide-md">{item.nomEmp}</Td>
                  <Td title={item.obsTcp} className="hide-md">{item.obsTcp}</Td>
                  <Td>
                    <IconButton
                      padding={1}
                      size={20}
                      onClick={handleApprove([item])}
                      icon={(
                        <DynamicIcon
                          family="md"
                          name="MdCheckCircle"
                          size={15}
                          color="#33b850"
                        />
                      )}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default PaymentApproval
