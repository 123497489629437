import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { CallRegistrationModel } from '../../../models/call-registration'
// import Input from '../../../components/Input'
import SelectApi from '../../../components/Select/api'

interface UserOpeningModalProps {
  data: CallRegistrationModel
  refetch: () => void
  refetchDetails: () => void
}

interface UserOpeningForm {
  usuarioAbertura: string
  usu_catcham: string
}

const UserOpeningModal: React.FC<UserOpeningModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<UserOpeningForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: UserOpeningForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>('/call-registration/update-user-opening', {
        usu_codchamabe: data.usu_codusuabe,
        usu_codcham: data.usu_codcham,
        usuarioAbertura: formData.usu_catcham
      })
      toast.success(response.body)
      close()
      refetch()
      refetchDetails()
    })
  }

  return (
    <div>
      <h3>Atualizar Usuário do Chamado #{data.usu_codcham}</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="usu_descham"
          readonly={false}
          label="Usuário"
          name="usu_catcham"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url={'call-registration/list-category'}
          valueProperty="usu_catcham"
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default UserOpeningModal
