import styled, { keyframes, css } from 'styled-components'
import { colors, sizes } from '../../styles/default-styles'

const slide = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`

const swipeUp = keyframes`
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
`

const expand = keyframes`
  0% {
    max-width: 0;
    min-width: 0;
    width: 0;
  }

  100% {
    max-width: ${sizes.detailsContainerWidth};
    min-width: ${sizes.detailsContainerWidth};
    width: ${sizes.detailsContainerWidth};
  }
`

interface ContainerProps {
  opened: boolean
  fullScreen: boolean
}

export const Container = styled.section<ContainerProps>`
  animation: 0.3s ${expand} ease-out;

  @media(max-width: 1000px) {
    animation: none;
    position: absolute;
    width: 100%;
    min-width: 100%;
    height: calc(100% - ${sizes.headerHeight});
  }
`

export const DetailsContainerStyle = styled.div<ContainerProps>`
  box-sizing: border-box;
  height: 100%;
  padding: 0 15px 50px;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  animation: 0.3s ${slide} ease-out;
  transition: width 0.3s, margin 0.1s;

  ${props => props.fullScreen ? css`
    position: absolute;
    height: calc(100% - ${sizes.headerHeight} - 20px);
    z-index: 1;
    width: calc(100% - ${sizes.sideMenuClosed} - 30px);
    border-radius: 2px;
    right: 0;
    margin: 10px 15px 10px;
    -webkit-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.2);
  ` : css`
    position: relative;
    border: 1px solid ${colors.borders};
    max-width: ${sizes.detailsContainerWidth};
    min-width: ${sizes.detailsContainerWidth};
    width: ${sizes.detailsContainerWidth};
  `}

  .content {
    margin-top: 15px;
  }

  @media(max-width: 1000px) {
    position: absolute;
    width: 100%;
    min-width: 100%;
    height: 100%;
    animation: 0.3s ${swipeUp} ease-out;
  }
`

export const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background: white;
  padding-top: 10px;
  z-index: 1;

  .left {
    display: flex;
    align-items: center;
  }
`
