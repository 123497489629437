import React, {
  useCallback,
  useEffect
} from 'react'
import {
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td
} from '../../../components/Table'
import Dropdown from '../../../components/Dropdown'
import Filter from '../../../components/Filter'
import { FaTrashAlt } from 'react-icons/fa'
import {
  MdPowerSettingsNew,
  MdCheck,
  MdClose
} from 'react-icons/md'
import ModuleDetails from './ModuleDetails'
import Loader from '../../../components/Loader'
import {
  Link,
  useLocation,
  useHistory
} from 'react-router-dom'
import {
  useDetailsContainer,
  useApi,
  useModal,
  useFetch
} from '../../../hooks'
import { Module } from '../../../models/modules'
import { handle } from '../../../utils/error-handlers'
import { toast } from 'react-toastify'
import { Container } from './styles'

const Modules: React.FC = () => {
  const {
    httpDelete,
    httpPatch,
    httpGet
  } = useApi()
  const { data, loading, refetch } = useFetch<Module[]>(httpGet, '/modules')
  const {
    pushPage,
    open,
    close
  } = useDetailsContainer()
  const { search } = useLocation()
  const { replace } = useHistory()
  const { confirm } = useModal()

  const openDetails = useCallback((id?: number): void => {
    close()
    pushPage({
      component: <ModuleDetails id={id} refetch={refetch} />,
      title: 'Módulo',
      data: { id },
      onClose: () => {
        replace('/configuracoes/modulos')
      }
    })
    open()
  }, [close, pushPage, open, replace, refetch])

  useEffect(() => {
    httpGet('/modules')
  }, [httpGet])

  useEffect(() => {
    if (search?.includes('?id=')) {
      const id = Number(search.substr(search.indexOf('=') + 1))
      openDetails(id)
    }
  }, [search, openDetails])

  const renderRow = (row: Module, index: number): JSX.Element => (
    <Tr key={index}>
      <Td title={row.name}>
        <Link to={`/configuracoes/modulos?id=${row.id}`}>
          {row.name}
        </Link>
      </Td>
      <Td>{row.active ? <MdCheck size={15} /> : <MdClose size={15} />}</Td>
      <Td>
        <Dropdown
          id="opcao"
          options={[
            {
              text: 'Excluir',
              icon: <FaTrashAlt size={15} />,
              onClick: () => {
                confirm('Tem certeza que deseja excluir este módulo?', () => {
                  handle(async () => {
                    await httpDelete(`/modules/${row.id}`)
                    toast.success('Módulo excluído com sucesso.')
                    refetch()
                  })
                })
              }
            },
            {
              text: row.active ? 'Desativar' : 'Ativar',
              icon: <MdPowerSettingsNew size={15} />,
              onClick: () => {
                handle(async () => {
                  await httpPatch(`/modules/${row.id}`, { active: !row.active })
                  const text = !row.active ? 'ativado' : 'desativado'
                  toast.success(`Módulo ${text} com sucesso.`)
                  refetch()
                })
              }
            }
          ]}
        />
      </Td>
    </Tr>
  )

  return (
    <>
      <Filter onClickAdd={() => openDetails()} />
      <Container>
        <Loader loading={loading}>
          <Table empty={!data}>
            <THead>
              <Tr>
                <Th width="200px">Nome</Th>
                <Th>Ativo</Th>
                <Th width="50px"></Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map((item: Module, index: number) => renderRow(item, index))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default Modules
