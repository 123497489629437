import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const Container = styled.div`
  margin-bottom: 20px;
  position: relative;
  z-index: 0;

  label {
    text-transform: uppercase;
    font-size: 9px;
    color: ${colors.mediumGray};
    display: inline-block;
    margin-bottom: 5px;
  }

  .jodit-wysiwyg {
    min-height: 200px !important;
  }

  ol {
    margin-left: 15px;
  }

  ul {
    margin-left: 15px
  }
`
