import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { SalesOrderModel } from '../../../models/salesorder'
import SelectApi from '../../../components/Select/api'
import Textarea from '../../../components/Textarea'
import { Div } from './styles'
import { H4 } from './styles'

interface AttBudgetProps {
  data: SalesOrderModel
  refetch: () => void
}

interface AttBudgetForm {
  codcpg: string
  codfpg: string
  entcli: string
  somfre: string
  tipfre: string
  tnspro: string
  codCcu: string
  codDep: string
  codPro: string
  preuni: string
  despro: string
  qtdPed: string
  obsPed: string
  seqIpd: string
}

const AttModal: React.FC<AttBudgetProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AttBudgetForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()
  const [radioButton, setRadioButton] = useState('')
  const [radioButtonT, setRadioButtonT] = useState('')

  const handleSendData = async (formData: AttBudgetForm): Promise<void> => {
    await handle(async () => {
      const json = {
        codCli: data.codcli,
        codCpg: formData.codcpg,
        codFpg: formData.codfpg,
        codVen: data.codven,
        codemp: data.codemp,
        codtpr: data.codtpr,
        codfil: data.codfil,
        numPed: data.numped,
        codrep: data.codrep,
        entcli: data.datemi,
        somfre: radioButton,
        tipfre: radioButtonT,
        tnsPro: data.tnspro,
        obsPed: formData.obsPed,
        orcIte: data?.itens.map(({
          codccu,
          coddep,
          qtdped,
          seqipd,
          preuni,
          codpro
        }) => ({
          codCcu: codccu,
          codDep: coddep,
          codPro: codpro,
          preuni: preuni ? String(preuni).replace('.', ',') : '',
          qtdPed: parseFloat(qtdped),
          seqIpd: seqipd
        })
        )
      }

      const response = await httpPatch<string>(`/salesorder/update-budget/${data.numped}`, json)
      toast.success(response.body)
      if (response.body === 'OK') {
        clearData()
      }
      close()
      refetch()
    })
  }

  return (
    <div>
      <h3>Atualizar orçamento nº {data.numped} </h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="descpg"
          readonly={false}
          defaultValue={data.codcpg}
          label="Atualizar Condição de Pagamento"
          name="codcpg"
          register={register({
          })}
          url={`/salesorder/list-payment-condition/${data.codcli}/${data.codemp}/${data.codfil}`}
          valueProperty="codcpg"
        />
        <SelectApi
          displayProperty="desfpg"
          readonly={false}
          defaultValue={data.codfpg}
          label="Atualizar Forma de Pagamento"
          name="codfpg"
          register={register({
          })}
          url={`/salesorder/list-payment-form/${data.codemp}`}
          valueProperty="codfpg"
        />
        <Div>
          <ul>
            <H4>Tipo Frete {data.ciffob}</H4>
            <div>
              <input id="tipfre" type="radio" name="tipfre" onClick={ () => setRadioButtonT('C')} />
              <label htmlFor="tipfre">CIF</label>
            </div>
            <div>
              <input id="tipfre"type="radio" name="tipfre" onClick={ () => setRadioButtonT('F')}/>
              <label htmlFor="tipfre">FOB</label>
            </div>
          </ul>
        </Div>
        <Div>
          <ul>
            <H4>Soma Frete {data.somfre}</H4>
            <div>
              <input id="somfre" type="radio" name="somfre" onClick={ () => setRadioButton('S')}/>
              <label htmlFor="somfre">SIM</label>
            </div>
            <div>
              <input id="somfre"type="radio" name="somfre" onClick={ () => setRadioButton('N')}/>
              <label htmlFor="somfre">NÃO</label>
            </div>
          </ul>
        </Div>
        <Textarea
          label="Observação Pedido"
          name="obsPed"
          defaultValue={data.obsped}
          register={register({
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={clearData}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttModal
