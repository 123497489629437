import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal
} from '../../../hooks'
import SelectApi from '../../../components/Select/api'
import { handle } from '../../../utils/error-handlers'

interface ListClientProps {
  openAttClient: (data: any) => void
  refetch: () => void
}

interface ListClientForm{
  cnpj: string
}

const ListClient: React.FC<ListClientProps> = ({
  openAttClient,
  refetch
}) => {
  const methods = useForm<ListClientForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const [dataClients, setDataClients] = useState<any>()

  const handleSendData = async (): Promise<void> => {
    await handle(async () => {
      openAttClient(dataClients)
    })
  }

  return (
    <div>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="cadCompleto"
          readonly={false}
          label="Atualizar Cliente"
          name="codCli"
          register={register({
          })}
          url={'/register/list-clients'}
          valueProperty="codcli"
          onSelect={(item) => {
            if (item) {
              setDataClients(item.data)
            }
          }}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Buscar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default ListClient
