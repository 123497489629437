import React, { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  InitialState,
  ModalActions,
  modalAction
} from '../redux/modal'
import Confirmation from '../components/Confirmation'

interface UseModal {
  opened: boolean
  component: JSX.Element | null
  open: (component: JSX.Element) => void
  close: () => void
  confirm: (text: string, onConfirm: () => void | Promise<void>) => void
}

export const useModal = (): UseModal => {
  const dispatch = useDispatch()
  const state = useSelector((state: any): InitialState => state.modal)
  const { opened, component } = state

  const open = useCallback((component: JSX.Element): void => {
    dispatch(modalAction(ModalActions.OPEN, {
      opened: true,
      component
    }))
  }, [dispatch])

  const close = useCallback((): void => {
    dispatch(modalAction(ModalActions.CLOSE))
  }, [dispatch])

  const confirm = useCallback((
    text: string,
    onConfirm: () => void
  ): void => {
    open(<Confirmation
      text={text}
      onConfirm={onConfirm}
    />)
  }, [open])

  return {
    opened,
    component,
    open,
    close,
    confirm
  }
}
