import React, { useState } from 'react'
import IconButton from '../IconButton'
import { MdMoreVert } from 'react-icons/md'
import { useClickAwayListener } from '../../hooks'
import {
  Container,
  OptionsContainer
} from './styles'

interface DropdownOption {
  icon?: JSX.Element
  text: string
  onClick?: () => void
  title?: string
  closeOnClick?: boolean
}

interface DropdownProps {
  id: string
  component?: JSX.Element
  divid?: string
  options: DropdownOption[]
  optionsTopDistance?: string
  left?: string
  right?: string
  disabled?: boolean
  title?: string
}

const Dropdown: React.FC<DropdownProps> = ({
  id,
  options,
  component,
  divid,
  optionsTopDistance = '30px',
  left,
  right = '0',
  disabled = false,
  title = 'Opções'
}) => {
  const [opened, setOpened] = useState(false)
  const [topDistance, setTopDistance] = useState(optionsTopDistance)

  const getComponent = (): JSX.Element => {
    if (component !== undefined) {
      return component
    }
    return (
      <IconButton
        icon={<MdMoreVert/>}
        padding={5}
        size={20}
      />
    )
  }

  const handleClick = (): void => {
    if (opened) {
      setOpened(false)
    }
  }

  useClickAwayListener({
    elementId: id,
    onClickAway: handleClick
  })

  return (
    <Container>
      <div
        title={title}
        onClick={() => {
          if (!disabled) {
            if (divid) {
              const distance = document.getElementById(divid)?.getBoundingClientRect().top
              setTopDistance(`${distance ?? '0'}px`)
            }
            setOpened(opened => !opened)
          }
        }}
      >
        {getComponent()}
      </div>
      <OptionsContainer
        id={id}
        opened={opened}
        top={topDistance}
        left={left}
        right={right}
        className="dropdown-options"
      >
        <ul>
          {options.map(({
            text,
            icon,
            onClick,
            closeOnClick,
            title
          }, index) => (
            <li
              key={index}
              title={title}
              onClick={() => {
                if (onClick) {
                  onClick()
                  if (closeOnClick) {
                    setOpened(false)
                  }
                }
              }}
            >
              {icon !== undefined && <>{icon}<span className="separator" /></>}{text}
            </li>
          ))}
        </ul>
      </OptionsContainer>
    </Container>
  )
}

export default Dropdown
