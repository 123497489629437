import styled from 'styled-components'

export const Container = styled.div`
  .pagination {
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  .total-records {
    color: var(--medium-grey);
    margin-right: 15px;
  }

  .navigation {
    display: flex;
    align-items: center;
  }

  .navigation a {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--text-color);
  }

  .navigation a:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.03);
  }

  .prev-page {
    padding: 0 10px 0 0;
  }

  .next-page {
    padding: 0 0 0 10px;
  }

  .current-page {
    color: var(--medium-grey);
    margin: 0 5px;
  }
`
