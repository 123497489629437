import {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react'
import { handle } from '../utils/error-handlers'
import { ApiResponse } from '../models/api'

interface UseFetch<T> {
  data: T | undefined
  setData: (data: T) => void
  loading: boolean
  setLoading: (value: boolean) => void
  refetch: () => void
  refetchDetails: () => void
  refetching: boolean
}

export const useFetch = <T> (
  cb: (params: any) => Promise<ApiResponse<T>>,
  cbParams: any = null,
  executionCondition = true
): UseFetch<T> => {
  const [data, setData] = useState<T | undefined>()
  const [loading, setLoading] = useState(true)
  const [refetching, setRefetching] = useState(false)
  const loaded = useRef(false)

  useEffect(() => {
    let mounted = true
    const fetchData = async (): Promise<void> => {
      if (loading && executionCondition) {
        await handle(async () => {
          const { body } = await cb(cbParams)
          if (mounted) {
            setData(body)
            setLoading(false)
          }
        }, () => {
          setLoading(false)
        })
      }
    }
    fetchData()
    return () => {
      mounted = false
      setLoading(false)
    }
  }, [loading, cb, cbParams, executionCondition])

  const refetch = useCallback(async () => {
    handle(async () => {
      setRefetching(true)
      const { body } = await cb(cbParams)
      setData(body)
      setRefetching(false)
    }, () => {
      setRefetching(false)
    })
  }, [cb, cbParams])

  const refetchDetails = useCallback(async () => {
    handle(async () => {
      setRefetching(true)
      const { body } = await cb(cbParams)
      setData(body)
      setRefetching(false)
    }, () => {
      setRefetching(false)
    })
  }, [cb, cbParams])

  useEffect(() => {
    if (loaded.current) {
      refetch()
      refetchDetails()
    } else {
      loaded.current = true
    }
  }, [cbParams, refetch, refetchDetails])

  return {
    data,
    setData,
    loading,
    setLoading,
    refetch,
    refetching,
    refetchDetails
  }
}
