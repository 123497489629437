import styled from 'styled-components'

export const Container = styled.div`
  padding: 15px;
`
export const Header = styled.header`
 padding-top: 20px;
 padding-bottom: 20px;
`
export const DateFilter = styled.strong`
  width: max-content;
  font-size: 13px;
  margin-right: 15px;
`
