import { combineReducers } from 'redux'
import { sideMenuReducer } from './side-menu'
import { detailsContainerReducer } from './details-container'
import { authReducer } from './auth'
import { modalReducer } from './modal'

export const rootReducer = combineReducers({
  sideMenu: sideMenuReducer,
  detailsContainer: detailsContainerReducer,
  auth: authReducer,
  modal: modalReducer
})
