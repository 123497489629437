import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { CallRegistrationModel } from '../../../models/call-registration'
import Input from '../../../components/Input'
import Textarea from '../../../components/Textarea'

interface CloseCallModalProps {
  data: CallRegistrationModel
  refetch: () => void
  refetchDetails: () => void
}

interface CloseCallForm {
  usu_databeti: string
  usu_datafecti: string
  usu_horfecti: string
  usu_horabeti: string
  usu_respti: string
}

const CloseCallModal: React.FC<CloseCallModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<CloseCallForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()
  const { clearData } = useDetailsContainer()
  const [loading, setLoading] = useState(false)

  const handleSendData = async (formData: CloseCallForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        usu_codcham: data.usu_codcham,
        usu_databeti: formData.usu_databeti.split('-').reverse().join('/'),
        usu_datfecti: formData.usu_datafecti.split('-').reverse().join('/'),
        usu_horabeti: formData.usu_horabeti,
        usu_horfecti: formData.usu_horfecti,
        usu_respti: formData.usu_respti
      }
      console.log(json)
      const response = await httpPatch<string>('/call-registration/update-close-call', json)

      if (response.body === 'Chamado encerrado com sucesso!') {
        clearData()
      }
      toast.info(response.body)
      setLoading(false)
      refetch()
      close()
    })
  }

  return (
    <div>
      <h3>Encerrar Chamado #{data.usu_codcham}</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Textarea
          label="Descrição Fechamento"
          name="usu_respti"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          type='date'
          label="Data Início"
          name="usu_databeti"
          register={register({
          })}
        />
        <Input
          type='date'
          label="Data Término"
          name="usu_datafecti"
          register={register({
          })}
        />
        <Input
          type='time'
          label="Hora Início"
          name="usu_horabeti"
          register={register({
          })}
        />
        <Input
          type='time'
          label="Hora Fechamento"
          name="usu_horfecti"
          register={register({
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Encerrar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default CloseCallModal
