import React from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import Select from '../../../components/Select'
import SelectApi from '../../../components/Select/api'

interface AttClientProps {
  data: any
  refetch: () => void
}

interface AttClientForm{
  apeCli: string
  baiCli: string
  cepCli: string
  cgcCpf: string
  cidCli: string
  cliCon: string
  codfpg: string
  codRam: string
  codVen: string
  codcpg: string
  confin: string
  cplEnd: string
  endCli: string
  fonCli: string
  insEst: string
  intNet: string
  nenCli: string
  nomCli: string
  sigUfs: string
  sitCli: string
  tipCli: string
  tipEmp: string
  tipMer: string
}

const AttClient: React.FC<AttClientProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AttClientForm>({})
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()
  const cgcCpf = data.cgccpf

  const handleSendData = async (formData: AttClientForm): Promise<void> => {
    await handle(async () => {
      const json = {
        apeCli: formData.apeCli,
        baiCli: formData.baiCli,
        cepCli: formData.cepCli,
        cgcCpf: formData.cgcCpf,
        cidCli: formData.cidCli,
        cliCon: formData.cliCon,
        codFpg: formData.codfpg,
        codRam: formData.codRam,
        codVen: formData.codVen,
        codcpg: formData.codcpg,
        confin: formData.confin,
        cplEnd: formData.cplEnd,
        endCli: formData.endCli,
        fonCli: formData.fonCli,
        insEst: formData.insEst,
        intNet: formData.intNet,
        nenCli: formData.nenCli,
        nomCli: formData.nomCli,
        sigUfs: formData.sigUfs,
        sitCli: formData.sitCli,
        tipCli: formData.tipCli,
        tipEmp: formData.tipEmp,
        tipMer: formData.tipMer
      }
      const response = await httpPatch<string>(`/register/update-client/${cgcCpf as string}`, json)
      toast.success(response.body)

      close()
      clearData()
      refetch()
    })
  }
  return (
    <div>
      <h3>Atualizar Cliente</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Nome Cliente"
          name="nomCli"
          defaultValue={data.nomcli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Nome Fantasia"
          name="apeCli"
          defaultValue={data.apecli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Tipo Cliente"
          name="tipCli"
          defaultValue={data.tipcli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Select
          readonly
          label="Tipo Empresa"
          name="tipEmp"
          options={[{
            display: '1 - INDUSTRIA',
            value: '1'
          }, {
            display: '2 - DISTRIBUIDOR',
            value: '2'
          }, {
            display: '3 - COMÉRCIO',
            value: '3'
          }
          ]}
          register={register()}
        />
        <Input
          label="Tipo Mercado"
          name="tipMer"
          defaultValue={data.tipmer}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="desRam"
          readonly={false}
          label="Ramo de Atividade"
          name="codRam"
          register={register({
          })}
          url="register/list-ramo"
          valueProperty="codRam"
        />
        <Input
          label="CPF/CNPJ"
          name="cgcCpf"
          defaultValue={data.cgccpf}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Inscrição Estadual"
          name="insEst"
          defaultValue={data.insest}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Endereço Cliente"
          name="endCli"
          defaultValue={data.endcli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Numéro do Endereço"
          name="nenCli"
          register={register({
          })}
        />
        <Input
          label="Bairro Cliente"
          name="baiCli"
          defaultValue={data.baicli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Complemento Endereço"
          name="cplEnd"
          defaultValue={data.cplend}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Cidade Cliente"
          name="cidCli"
          defaultValue={data.cidcli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Estado Cliente"
          name="sigUfs"
          defaultValue={data.sigufs}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="CEP Cliente"
          name="cepCli"
          defaultValue={data.cepcli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="E-mail Cliente"
          name="intNet"
          defaultValue={data.intnet}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Telefone Cliente"
          name="fonCli"
          defaultValue={data.foncli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Consumidor Final (S/N)"
          name="confin"
          defaultValue={data.confin}
          register={register({
          })}
        />
        <Input
          label="Cliente Contribuinte (S/N)"
          name="cliCon"
          defaultValue={data.clicon}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="desfpg"
          readonly={false}
          label="Forma de Pagamento"
          name="codfpg"
          register={register({
          })}
          url={'/salesorder/list-payment-form/2'}
          valueProperty="codfpg"
        />
        <SelectApi
          displayProperty="descpg"
          readonly={false}
          label="Condição de Pagamento"
          name="codcpg"
          register={register({
          })}
          url={'/salesorder/list-payment-condition/2/1/1'}
          valueProperty="codcpg"
        />
        <SelectApi
          displayProperty="nomVen"
          readonly={false}
          label="Vendedor"
          name="codVen"
          register={register({
          })}
          url="register/list-seller"
          valueProperty="codVen"
        />
        <Input
          label="Situação Cliente"
          name="sitCli"
          defaultValue={data.sitcli}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttClient
