import React, {
  useCallback,
  useEffect
} from 'react'
import Form from '../../../../components/Form'
import Input from '../../../../components/Input'
import Checkbox from '../../../../components/Checkbox'
import Button from '../../../../components/Button'
import Loader from '../../../../components/Loader'
import DetailsButtons from '../../../../components/DetailsButtons'
import { useHistory } from 'react-router-dom'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  useDetailsContainer,
  useApi,
  useLoading,
  useModal
} from '../../../../hooks'
import { toast } from 'react-toastify'
import { Module } from '../../../../models/modules'
import { handle } from '../../../../utils/error-handlers'
import { Profile, ProfilePermissions } from '../../../../models/profile'
import { Container, PermissionsContainer } from './styles'

interface ProfileDetailsProps {
  id?: number
  refetch: () => void
}

interface ProfileForm {
  name: string
  permissions: [{
    id: number
    name: string
    create: boolean
    read: boolean
    update: boolean
    delete: boolean
  }]
}

const ProfileDetails: React.FC<ProfileDetailsProps> = ({
  id = -1,
  refetch
}) => {
  const methods = useForm<ProfileForm>({ mode: 'onTouched' })
  const { register, setValue, control, reset, getValues } = methods
  const { fields, insert } = useFieldArray<ProfilePermissions, 'customId'>({
    control,
    name: 'permissions',
    keyName: 'customId'
  })
  const { close } = useDetailsContainer()
  const { httpGet, httpPost, httpDelete } = useApi()
  const { replace } = useHistory()
  const { load, loading } = useLoading()
  const { confirm } = useModal()

  const handleSubmit = async (data: ProfileForm): Promise<void> => {
    handle(async () => {
      await load(async () => {
        const { name, permissions } = data
        const modules = permissions.map(({ name, id, ...rest }) => ({
          ...rest,
          id: Number(id)
        }))
        if (id === -1) {
          const response = await httpPost<Profile>('/profiles', { name })
          const profile = response.body
          if (profile) {
            await httpPost(`/profiles/attach-modules/${profile.id}`, { modules })
          }
        } else {
          await httpPost(`/profiles/sync-modules/${id}`, { modules })
        }
        toast.success('Perfil salvo com sucesso.')
        refetch()
      })
    })
  }

  const closeWrapper = useCallback(() => {
    close()
    replace('/configuracoes/perfis')
  }, [close, replace])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      reset()
      await load(async () => {
        const response = await httpGet<Module[]>('/modules')
        const modules = response.body ?? []
        modules.forEach((module, index) => {
          insert(index, {
            id: module.id,
            name: module.name,
            create: false,
            delete: false,
            read: false,
            update: false
          })
        })
        if (id > -1) {
          const response = await httpGet<Profile>(`/profiles/${id}`)
          setValue('name', response.body?.name)
          const modules = response.body?.permissions ?? []
          modules.forEach(module => {
            const { permissions } = getValues()
            const ids: number[] = permissions.map(field => Number(field.id))
            const index = ids.indexOf(module.id)
            setValue(`permissions[${index}].create`, module.create)
            setValue(`permissions[${index}].read`, module.read)
            setValue(`permissions[${index}].update`, module.update)
            setValue(`permissions[${index}].delete`, module.delete)
          })
        }
      })
    }
    fetchData()
  }, [id, httpGet, setValue, insert, reset, getValues, load])

  return (
    <Container>
      <Loader
        loading={loading}
        overlay
      />
      <Form
        onSubmit={handleSubmit}
        methods={methods}
      >
        <Input
          label="Nome"
          name="name"
          register={register({
            required: {
              message: 'Preencha este campo.',
              value: true
            }
          })}
          readOnly={id > -1}
        />
        <h3>Módulos</h3>
        <PermissionsContainer>
          <table>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Visualizar</th>
                <th>Incluir</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((field, index) => (
                <tr key={field.customId}>
                  <td>{field.name}</td>
                  <td>
                    <input
                      ref={register()}
                      name={`permissions[${index}].id`}
                      type="hidden"
                      defaultValue={field.id}
                    />
                    <Checkbox
                      name={`permissions[${index}].read`}
                      register={register()}
                      defaultValue={field.read}
                      className="checkbox"
                    />
                  </td>
                  <td>
                    <Checkbox
                      name={`permissions[${index}].create`}
                      register={register()}
                      defaultValue={field.create}
                      className="checkbox"
                    />
                  </td>
                  <td>
                    <Checkbox
                      name={`permissions[${index}].update`}
                      register={register()}
                      defaultValue={field.update}
                      className="checkbox"
                    />
                  </td>
                  <td>
                    <Checkbox
                      name={`permissions[${index}].delete`}
                      register={register()}
                      defaultValue={field.delete}
                      className="checkbox"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PermissionsContainer>
        <DetailsButtons>
          <Button
            buttonType="primary"
            type="submit"
          >
            Salvar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={closeWrapper}
          >
            Cancelar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={async () => {
              if (typeof id === 'number') {
                handle(async () => {
                  confirm('Tem certeza que deseja excluir esse perfil?', async () => {
                    await httpDelete(`/profiles/${id}`)
                    closeWrapper()
                    refetch()
                  })
                })
              }
            }}
          >
            Excluir
          </Button>
        </DetailsButtons>
      </Form>
    </Container>
  )
}

export default ProfileDetails
