import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { BiddingModel } from '../../../models/bidding'
import SelectApi from '../../../components/Select/api'

interface AttCodfpgProps {
  data: BiddingModel
  refetch: () => void
}

interface AttCodfpgForm {
  codCli: string
  nomcli: string
  codFpg: string
  usu_codemp: string
  usu_CodFpg: string
  usu_DatEnt: string
  usu_datlic: string
  usu_horlic: string
  usu_plalic: string
  usu_stalic: string
  usu_obslic: string
}

const AttCodfpgModal: React.FC<AttCodfpgProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AttCodfpgForm>({
    defaultValues: {}
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AttCodfpgForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>(`/bidding/codcli/${data.usu_numlic}`, {
        usu_codemp: data.usu_codemp,
        usu_codfpg: formData.codFpg,
        usu_datent: data.usu_DatEnt,
        usu_datlic: data.usu_datlic,
        usu_horlic: data.usu_horlic,
        usu_numlic: data.usu_numlic,
        usu_plalic: data.usu_plalic,
        usu_obslic: data.usu_obslic,
        usu_stalic: data.usu_stalic,
        usu_codcli: data.usu_codcli,
        usu_nomcli: data.nomcli
      })
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Atualizar Licitações </h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="desFpg"
          readonly={false}
          label="Atualizar Forma de Pagamento"
          name="codFpg"
          register={register({})}
          url="bidding/list-payment"
          valueProperty="codFpg"
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttCodfpgModal
