import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useApi,
  useDetailsContainer,
  useModal
} from '../../../hooks'
import SelectApi from '../../../components/Select/api'
import { handle } from '../../../utils/error-handlers'
import { AcquisitionOrderModel } from '../../../models/acquisition-order'
import { toast } from 'react-toastify'
import Input from '../../../components/Input'

interface AddProductProps {
  data: AcquisitionOrderModel
  setAddProduct: any
}

interface AddProductForm {
  codccu: string
  coddep: string
  codemp: string
  codfil: string
  codpro: string
  numocp: string
  preuni: string
  qtdped: string
  seqipo: string
  codbem: string
  percrt: string
  perpit: string
  pericm: string
  peripi: string
  perirf: string
  percsl: string

}

const AddProductModal: React.FC<AddProductProps> = ({
  data,
  setAddProduct
}) => {
  const methods = useForm<AddProductForm>({
  })
  const { register } = methods
  const { clearData } = useDetailsContainer()
  const codpro = methods.watch('codpro')
  const [loading, setLoading] = useState(false)
  const { close } = useModal()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AddProductForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        ciffob: data.ciffob,
        codcpg: data.codcpg,
        codemp: data.codemp,
        codfil: data.codfil,
        codfor: data.codfor,
        codfpg: data.codfpg,
        codtra: data.codtra,
        datemi: data.datemi,
        numocp: data.numocp,
        obsmot: data.obsmot,
        obsocp: data.obsocp,
        somfre: data.somfre,
        tempar: data.tempar,
        tnspro: data.tnspro,
        tnsser: data.tnsser,
        vlrfre: data.vlrfre,
        servicos: data.servicos,
        produtos: [{
          codccu: formData.codccu,
          coddep: formData.coddep,
          codemp: data.codemp,
          codfil: data.codfil,
          codpro: formData.codpro,
          numocp: data.numocp,
          percrt: formData.percrt,
          perpit: formData.perpit,
          pericm: formData.pericm,
          peripi: formData.peripi,
          perirf: formData.perirf,
          percsl: formData.percsl,
          preuni: parseFloat(formData.preuni),
          qtdped: formData.qtdped,
          seqipo: '',
          usu_codbem: formData.codbem
        }]
      }

      const response = await httpPatch<string>('/acquisition-order/update-acquisition-order', json)

      if (response.statusCode === 200) {
        toast.info(response.body ? response.body : 'Produto Adicionado')
        clearData()
      }
      setLoading(false)
      close()
      setAddProduct(true)
    })
  }

  return (
    <div>
      <h3>Adicionar Produto</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="despro"
          readonly={false}
          label="Produto"
          name="codpro"
          register={register({
          })}
          url={`/acquisition-order/list-products/${data.codemp}`}
          valueProperty="codpro"
        />
        {codpro
          ? <>
            <SelectApi
              displayProperty="desccu"
              readonly={false}
              label="Centro de Custo"
              name="codccu"
              register={register({
                required: {
                  message: 'Preencha este campo',
                  value: true
                }
              })}
              url={`/acquisition-order/list-cost-center/${data.codemp}`}
              valueProperty="codccu"
            />
            <SelectApi
              displayProperty="desdep"
              readonly={false}
              label="Depósito"
              name="coddep"
              register={register({
                required: {
                  message: 'Preencha este campo',
                  value: true
                }
              })}
              url={`/acquisition-order/list-deposits/${data.codemp}/${codpro}`}
              valueProperty="coddep"
            />
            <Input
              label="Quantidade Pedida"
              name="qtdped"
              register={register({
              })}
            />
            <Input
              label="Preço Unitário"
              name="preuni"
              register={register({
              })}
            />
            <Input
              label="ICMS %"
              name="pericm"
              register={register({
              })}
            />
            <Input
              label="PIS %"
              name="perpit"
              register={register({
              })}
            />
            <Input
              label="ICMS %"
              name="pericm"
              register={register({
              })}
            />
            <Input
              label="IPI %"
              name="peripi"
              register={register({
              })}
            />
            <Input
              label="COFINS %"
              name="percrt"
              register={register({
              })}
            />
            <Input
              label="IRRF %"
              name="perirf"
              register={register({
              })}
            />
            <Input
              label="CSLL %"
              name="percsl"
              register={register({
              })}
            />
            <SelectApi
              displayProperty="desbem"
              readonly={false}
              label="Patrimonio"
              name="codbem"
              register={register({})}
              url={`/acquisition-order/list-patrimony/${data.codemp}`}
              valueProperty="codbem"
            />
          </>
          : <></>
        }
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={clearData}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AddProductModal
