import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from './root-reducer'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { api } from '../services/api'

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['auth']
}
const pReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
  pReducer,
  applyMiddleware(thunk.withExtraArgument(api))
)
const persistor = persistStore(store)

export { store, persistor }
