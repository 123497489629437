import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { LogisticModel } from '../../../models/logistic'
import SelectApi from '../../../components/Select/api'

interface ShippingCompanyModalProps {
  data: LogisticModel
  refetch: () => void
  refetchDetails: () => void
}

interface ShippingCompanyForm {
  codtra: string
}

const ShippingCompanyModal: React.FC<ShippingCompanyModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<ShippingCompanyForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()
  const numped = data.usu_numped

  const handleSendData = async (formData: ShippingCompanyForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>(`/logistic/update-shipping/${numped}/${data.usu_codemp}/${data.usu_seqipd}`, {
        codemp: data.usu_codemp,
        codfil: data.usu_codfil,
        codtra: formData.codtra,
        numped: data.usu_numped,
        seqipd: data.usu_seqipd,
        tipo: data.usu_tipo
      })
      toast.success(response.body)
      close()
      refetch()
      refetchDetails()
    })
  }

  return (
    <div>
      <h3>Atualizar Transportadora</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="nomtra"
          readonly={false}
          label="Transportadora"
          name="codtra"
          register={register({
          })}
          url="logistic/list-shipping"
          valueProperty="codtra"
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default ShippingCompanyModal
