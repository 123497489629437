import React, {
  useState,
  useCallback
} from 'react'
import Loader from '../../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import DynamicIcon from '../../../components/DynamicIcon'
import Checkbox from '../../../components/Checkbox/table'
import ApprovalsHeader from '../ApprovalsHeader'
import {
  useApi,
  useFetch,
  useModal,
  useLoading,
  useFilter,
  useSelectItems,
  useDetailsContainer
} from '../../../hooks'
import { Container } from './styles'
import { handle } from '../../../utils/error-handlers'
import { toast } from 'react-toastify'
import { RdApprovalsModel } from '../../../models/rdapprovals'
import RdApprovalsDetails from './RdApprovalsDetails'
import IconButton from '../../../components/IconButton'
import Filter from './Filter'

const RdApprovals: React.FC = () => {
  const { httpGet, httpPut } = useApi()
  const {
    data,
    loading,
    refetching,
    refetch
  } = useFetch<RdApprovalsModel[]>(httpGet, '/rdapprove/pending')
  const { confirm, open: openModal } = useModal()
  const {
    close,
    pushPage,
    open
  } = useDetailsContainer()
  const { load, loading: loadingHook } = useLoading()
  const [filter, setFilter] = useState(() => (item: any, index: number, array: any[]) => true)
  const { filtered } = useFilter<RdApprovalsModel>(data, filter)
  const {
    handleSelect,
    handleSelectAll,
    isSelected,
    selectedItems
  } = useSelectItems<RdApprovalsModel>(item => item.RD)

  const handleApprove = (items: RdApprovalsModel[]) => {
    return () => {
      confirm('Continuar com a aprovação da RD ?', () => {
        handle(async () => {
          load(async () => {
            for (const item of items) {
              await httpPut(`/rdapprove/approve/${item.RD}`, {
                codEmp: item.empresa,
                numRdv: item.RD,
                usuApr: item.usuarioAPR,
                codFil: item.filial
              })
              toast.success('RD Aprovado com sucesso')
              refetch()
            }
          })
        })
      })
    }
  }

  const handleDisapprove = (items: RdApprovalsModel[]) => {
    return () => {
      confirm('Continuar com a reprovação da RD ?', () => {
        handle(async () => {
          load(async () => {
            for (const item of items) {
              await httpPut(`/rdapprove/disapprove/${item.RD}`, {
                codEmp: item.empresa,
                numRdv: item.RD,
                usuApr: item.usuarioAPR,
                codFil: item.filial
              })
              toast.success('RD Reprovada com sucesso')
              refetch()
            }
          })
        })
      })
    }
  }

  const handleFilterClick = useCallback(() => {
    if (!loading && !refetching && data) {
      openModal(
        <Filter
          setFilter={setFilter}
          data={data}
        />
      )
      return
    }
    toast.info('Aguarde os dados carregarem.')
  }, [openModal, data, loading, refetching])

  const openDetails = useCallback((rdApprovals: RdApprovalsModel): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <RdApprovalsDetails
            data={rdApprovals}
            refetch={refetch}
          />
        ),
        title: 'Aprovação de RD'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close, pushPage, open, refetch, filtered])

  return (
    <>
      <ApprovalsHeader
        refetch={refetch}
        onClickFilter={handleFilterClick}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!filtered?.length}
            selected={selectedItems.length}
            overflow="auto"
            actionButton={<IconButton
              padding={3}
              size={20}
              onClick={handleApprove(selectedItems)}
              icon={(
                <DynamicIcon
                  family="md"
                  name="MdCheckCircle"
                  size={15}
                  color="#33b850"
                />
              )}
            />}
          >
            <THead>
              <Tr>
                <Th width="40px" color="#000">
                  <Checkbox
                    onSelect={handleSelectAll(filtered, !(filtered.length === selectedItems.length))}
                    value={(filtered.length === selectedItems.length)}
                  />
                </Th>
                <Th color="#000" width="30px ">RD</Th>
                <Th color="#000" width="10px ">Empresa</Th>
                <Th color="#000" width="20px ">Filial</Th>
                <Th color="#000" width="60px ">Nome Digitador</Th>
                <Th color="#000" width="60px ">Nome Usúario RD</Th>
                <Th color="#000" width="40px ">Código CCU</Th>
                <Th color="#000" width="120px">Descrição CCU</Th>
                <Th color="#000" width="120px">Observação</Th>
                <Th color="#000" width="60px ">Data Geração</Th>
                <Th color="#000" width="60px ">Valor Total</Th>
                <Th width="40px" color="#000" >Aprovar</Th>
                <Th width="40px" color="#000" >Reprovar</Th>
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item, index) => (
                <Tr key={index}>
                  <Td className="table-checkbox">
                    <Checkbox
                      value={isSelected(item)}
                      onSelect={handleSelect(item, !isSelected(item))}
                    />
                  </Td>
                  <Td title={item.RD}>
                    <button
                      className="first-item"
                      onClick={openDetails(item)}
                    >
                      {item.RD}
                    </button>
                  </Td>
                  <Td title={item.empresa} className="hide-md" >{item.empresa}</Td>
                  <Td title={item.filial}>{item.filial}</Td>
                  <Td title={item.nomeDigitador} className="hide-sm">{item.nomeDigitador}</Td>
                  <Td title={item.nomeUsuarioRD}> {item.nomeUsuarioRD}</Td>
                  <Td title={item.codigoCCU}>{item.codigoCCU}</Td>
                  <Td title={item.descricaoCCU}> {item.descricaoCCU}</Td>
                  <Td title={item.usu_obsrdv}> {item.usu_obsrdv}</Td>
                  <Td title={item.dataGeracao}> {item.dataGeracao}</Td>
                  <Td title={item.valorTotal}> R${item.valorTotal}</Td>
                  <Td>
                    <IconButton
                      padding={1}
                      size={20}
                      onClick={handleApprove([item])}
                      icon={(
                        <DynamicIcon
                          family="md"
                          name="MdCheckCircle"
                          size={15}
                          color="#33b850"
                        />
                      )}
                    />
                  </Td>
                  <Td>
                    <IconButton
                      padding={1}
                      size={20}
                      onClick={handleDisapprove([item])}
                      icon={(
                        <DynamicIcon
                          family="md"
                          name="MdCheckCircle"
                          size={15}
                          color="#b83333"
                        />
                      )}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default RdApprovals
