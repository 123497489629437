import React from 'react'
import {
  Route,
  Redirect,
  RouteProps
} from 'react-router-dom'
import { useAuth } from '../../hooks/auth'

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location }
        }}
      />
    )
  }

  return <Route {...props} />
}

export default PrivateRoute
