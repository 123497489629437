import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { Container } from './styles'

interface PaginationProps {
  currentPage: number
  linkUrl: string
  totalPages?: number
  totalRecords?: number
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  linkUrl,
  totalPages,
  totalRecords
}) => {
  const replaceLink = useCallback((target: 'next' | 'prev'): string => {
    const replace = (value: number): string => linkUrl.replace('#page', `${value}`)
    const prevPage = Number(currentPage) - 1
    const nextPage = Number(currentPage) + 1

    switch (target) {
      case 'prev':
        if (prevPage === 0) return replace(1)
        return replace(prevPage)
      case 'next':
        if (!totalPages) {
          return replace(1)
        }
        return replace(nextPage > totalPages ? totalPages : nextPage)
    }
  }, [linkUrl, currentPage, totalPages])

  return (
    <Container>
      <div className="pagination">
        {totalRecords ? <span className="total-records">{totalRecords} Registro(s)</span> : null}
        <div className="navigation">
          <Link
            to={replaceLink('prev')}
            className="prev-page"
          >
            <MdChevronLeft size={30} />
            <span>Anterior</span>
          </Link>
          <span className="current-page">
            Página {currentPage} {totalPages ? `de ${totalPages}` : null}
          </span>
          <Link
            to={replaceLink('next')}
            className="next-page"
          >
            <span>Próxima</span>
            <MdChevronRight size={30} />
          </Link>
        </div>
      </div>
    </Container>
  )
}

export default Pagination
