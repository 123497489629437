import React from 'react'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { useForm } from 'react-hook-form'
import { useModal } from '../../../../hooks'
import { format, isWithinInterval } from 'date-fns'
import { parseHyphenDate, parseSlashDate } from '../../../../utils/date-parser'
import { PurchaseOrder } from '../../../../models/purchase-order'
import { getUniqueOptions } from '../../../../utils/select-options'
import { Container } from './styles'

interface FilterForm {
  filterType: string
  startDate: string
  endDate: string
  fornecedor: string
  numeroOrdem: string
  nomeEmpresa: string
}

interface FilterProps {
  setFilter: (...args: any) => void
  data: PurchaseOrder[]
}

const Filter: React.FC<FilterProps> = ({ setFilter, data }) => {
  const methods = useForm<FilterForm>()
  const { watch, register } = methods
  const { close } = useModal()
  const watchFilterType = watch('filterType')

  const handleSubmit = (data: FilterForm): void => {
    const {
      filterType,
      endDate,
      startDate,
      fornecedor,
      numeroOrdem,
      nomeEmpresa
    } = data
    switch (filterType) {
      case 'data':
        setFilter(() => (item: PurchaseOrder) => {
          const current = parseSlashDate(item.datEmi)
          const start = parseHyphenDate(startDate)
          const end = parseHyphenDate(endDate)
          return isWithinInterval(current, { start, end })
        })
        break
      case 'fornecedor':
        setFilter(() => (item: PurchaseOrder) => {
          const value = fornecedor.toLowerCase()
          const search = item.nomFor.toLowerCase()
          return search.includes(value)
        })
        break
      case 'numeroOrdem':
        setFilter(() => (item: PurchaseOrder) => {
          return numeroOrdem === item.numOcp
        })
        break
      case 'nomeEmpresa':
        setFilter(() => (item: PurchaseOrder) => {
          const value = nomeEmpresa.toLowerCase()
          const search = item.nomEmp.toLowerCase()
          return search.includes(value)
        })
        break
      default:
        setFilter(() => () => true)
        break
    }
    close()
  }

  const renderField = (): JSX.Element => {
    const now = format(new Date(), 'yyyy-MM-dd')
    switch (watchFilterType) {
      case 'data':
        return (
          <>
            <Input
              label="Data inicial"
              name="startDate"
              register={register()}
              defaultValue={now}
              type="date"
            />
            <Input
              label="Data final"
              name="endDate"
              register={register()}
              defaultValue={now}
              type="date"
            />
          </>
        )
      case 'fornecedor':
        return <Select
          readonly
          label="Fornecedor"
          name="fornecedor"
          register={register()}
          options={getUniqueOptions(data, 'nomFor')}
        />
      case 'numeroOrdem':
        return <Input
          label="Nº da ordem"
          name="numeroOrdem"
          register={register()}
        />
      case 'nomeEmpresa':
        return <Select
          readonly
          label="Nome da empresa"
          name="nomeEmpresa"
          register={register()}
          options={getUniqueOptions(data, 'nomEmp')}
        />
      default:
        return <></>
    }
  }

  const handleClear = (): void => {
    setFilter(() => () => true)
    close()
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        methods={methods}
      >
        <Select
          readonly
          label="Filtrar por:"
          name="filterType"
          options={[{
            display: 'Data de Emissão',
            value: 'data'
          }, {
            display: 'Fornecedor',
            value: 'fornecedor'
          }, {
            display: 'Nº da ordem',
            value: 'numeroOrdem'
          }, {
            display: 'Nome da empresa',
            value: 'nomeEmpresa'
          }]}
          register={register()}
        />
        {renderField()}
        <Button
          buttonType="primary"
          type="submit"
        >
          Filtrar
        </Button>
        <Button
          buttonType="secondary"
          type="button"
          onClick={handleClear}
        >
          Limpar filtro
        </Button>
      </Form>
    </Container>
  )
}

export default Filter
