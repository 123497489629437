import { useEffect, useCallback } from 'react'

interface UseClickAwayListener {
  elementId: string
  onClickAway: () => void
}

export const useClickAwayListener = ({
  elementId,
  onClickAway
}: UseClickAwayListener): void => {
  const handleClick = useCallback((e: any): void => {
    const element = document.getElementById(elementId)
    const test = element?.contains(e.target) ?? true
    if (!test) {
      onClickAway()
    }
  }, [onClickAway, elementId])

  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  })
}
