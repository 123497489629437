import React from 'react'
import {
  Container,
  TBody as TBodyStyle,
  THead as THeadStyle,
  Table as TableStyle,
  Th as ThStyle,
  Td as TdStyle
} from './styles'

interface TableProps {
  children: any
  selected?: number
  actionButton?: JSX.Element
  empty?: boolean | false
  layout?: string
  overflow?: string
  className?: string
}

export const Table: React.FC<TableProps> = ({
  children,
  selected,
  className,
  actionButton = <></>,
  empty = false,
  layout = 'auto',
  overflow = 'unset'
}) => {
  return (
    <Container overflow={overflow}>
      {selected && selected > 0 ? (
        <div className="selected">
          {actionButton} &nbsp;
          <p>{selected} itens selecionado(s)</p>
        </div>
      ) : null}
      {empty ? <p>Nenhum registro disponível.</p> : <TableStyle className={className} layout={layout}>{children}</TableStyle>}
    </Container>
  )
}

export const THead: React.FC = ({ children }) => {
  return <THeadStyle>{children}</THeadStyle>
}

export const TBody: React.FC = ({ children }) => {
  return <TBodyStyle>{children}</TBodyStyle>
}

interface ThProps {
  width?: string
  children?: any
  className?: string
  textAlign?: string
  color?: string
}

export const Th: React.FC<ThProps> = ({
  children,
  color,
  width = 'auto',
  className = '',
  textAlign = 'left'
}) => {
  return (
    <ThStyle
      textAlign={textAlign}
      width={width}
      className={className}
      color={color}
    >
      {children}
    </ThStyle>
  )
}

export const Tr: React.FC = ({ children }) => {
  return <tr>{children}</tr>
}

interface TdProps {
  children?: any
  textAlign?: 'left' | 'center' | 'right'
  width?: string
  title?: string
  className?: string
  removeNoWrap?: boolean
}

export const Td: React.FC<TdProps> = ({
  children,
  textAlign = 'left',
  width = 'auto',
  title = '',
  className = '',
  removeNoWrap = false
}) => {
  return (
    <TdStyle
      textAlign={textAlign}
      width={width}
      title={title}
      className={className}
      removeNoWrap={removeNoWrap}
    >
      {children}
    </TdStyle>
  )
}
