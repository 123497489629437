import React, { useState } from 'react'
import {
  useApi,
  useDetailsContainer,
  useModal
} from '../../../hooks'
import Input from '../../../components/Input'
import { useForm } from 'react-hook-form'
import Form from '../../../components/Form'
import { handle } from '../../../utils/error-handlers'
import { toast } from 'react-toastify'
import Button from '../../../components/Button'
import { AcquisitionOrderModel } from '../../../models/acquisition-order'
import SelectApi from '../../../components/Select/api'

interface AttServiceProps {
  data: AcquisitionOrderModel
  index: number
  setAttService: any
}

interface AttServiceForm {
  codccu: string
  codemp: string
  codfil: string
  codser: string
  numocp: string
  pericm: string
  perins: string
  peripi: string
  perirf: string
  periss: string
  perpit: string
  percrt: string
  percsl: string
  preuni: string
  qtdped: string
}

const AttService: React.FC<AttServiceProps> = ({
  data,
  index,
  setAttService
}) => {
  const { httpPatch } = useApi()
  const methods = useForm<AttServiceForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const [loading, setLoading] = useState(false)
  const item = data.servicos[index]

  const handleSendData = async (formData: AttServiceForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        codemp: data.codemp,
        codfil: data.codfil,
        codfor: data.codfor,
        datemi: data.datemi,
        numocp: data.numocp,
        obsocp: data.obsocp,
        servicos: {
          codccu: formData.codccu === '' ? item.codccu : formData.codccu,
          codemp: data.codemp,
          codfil: data.codfil,
          codser: formData.codser,
          numocp: data.numocp,
          pericm: formData.pericm,
          perins: formData.perins,
          peripi: formData.peripi,
          perirf: formData.perirf,
          periss: formData.periss,
          preuni: formData.preuni,
          percrt: formData.percrt,
          percsl: formData.percsl,
          perpit: formData.perpit,
          seqiso: index + 1,
          qtdped: formData.qtdped
        },
        tnsser: data.tnsser
      }
      const response = await httpPatch<string>('/acquisition-order/update-acquisition-order/', json)

      toast.success(response.body)
      if (response.body === 'OK') {
        clearData()
      }
      setLoading(false)
      close()
      setAttService(true)
    })
  }

  return (
    <div>
      <Form methods={methods} onSubmit={handleSendData}>
        <div className="info-container">
          <>
            <section>
              <SelectApi
                displayProperty="desccu"
                readonly={false}
                label="Centro de Custo"
                name="codccu"
                register={register({})}
                url={`/acquisition-order/list-cost-center/${data.codemp}`}
                valueProperty="codccu"
              />
              <Input
                label="Empresa"
                name="codemp"
                defaultValue={item.codemp}
                register={register({})}
              />
              <Input
                label="Filial"
                name="codfil"
                defaultValue={item.codfil}
                register={register({})}
              />
              <Input
                label="Serviço"
                name="codser"
                defaultValue={item.codser}
                register={register({})}
              />

              <Input
                label="INSS %"
                name="perins"
                defaultValue={item.perins}
                register={register({})}
              />
              <Input
                label="PIS %"
                name="perpit"
                register={register({
                })}
              />
              <Input
                label="ICMS %"
                name="pericm"
                register={register({
                })}
              />
              <Input
                label="IPI %"
                name="peripi"
                register={register({
                })}
              />
              <Input
                label="IRRF %"
                name="perirf"
                register={register({
                })}
              />
              <Input
                label="COFINS %"
                name="percrt"
                register={register({
                })}
              />
              <Input
                label="CSLL %"
                name="percsl"
                register={register({
                })}
              />
              <Input
                label="ISS %"
                name="periss"
                defaultValue={item.peripi}
                register={register({})}
              />
              <Input
                label="Preço Unitário"
                name="preuni"
                defaultValue={item.preuni}
                register={register({})}
              />
              <Input
                label="Quantidade Pedida"
                name="qtdped"
                defaultValue={item.qtdped}
                register={register({})}
              />
            </section>
          </>
          <Button
            buttonType="primary"
            type="submit"
            disabled={loading}
          >
            Atualizar
          </Button>
          <span style={{ display: 'inline-block', width: '5px' }} />
          <Button
            buttonType="secondary"
            type="button"
            onClick={close}
          >
            Cancelar
          </Button>
        </div>
      </Form>
    </div >
  )
}
export default AttService
