import React from 'react'
import DetailsButtons from '../../../../components/DetailsButtons'
import Button from '../../../../components/Button'
import DynamicIcon from '../../../../components/DynamicIcon'
import Label from '../../../../components/Label'
import Loader from '../../../../components/Loader'
import Prompt from '../../../../components/Prompt'
import {
  useModal,
  useApi,
  useLoading,
  useDetailsContainer
} from '../../../../hooks'
import { PurchaseOrder, Cotacoesitem } from '../../../../models/purchase-order'
import { handle } from '../../../../utils/error-handlers'
import { toast } from 'react-toastify'
import {
  Container,
  InfoContainer,
  Buttao
} from './styles'

import { Tr, Th, Table, THead, TBody, Td } from '../../../../components/Table'

interface PurchaseOrderDetailsProps {
  data: PurchaseOrder
  refetch: () => void
}

const PurchaseOrderDetails: React.FC<PurchaseOrderDetailsProps> = ({
  data,
  refetch
}) => {
  const { open, confirm } = useModal()
  const { httpPut } = useApi()
  const { load, loading } = useLoading()
  const { close } = useDetailsContainer()
  const { tblitens } = data

  const handleClick = (action: 'approve' | 'reprove') => () => {
    const successMessage = {
      approve: 'aprovada',
      reprove: 'reprovada'
    }

    const params = action === 'approve' ? {
      codEmp: data.codEmp,
      codFil: data.codFil
    } : {
      codemp: data.codEmp,
      codfil: data.codFil
    }

    const handleSendData = (aditionalParams: any = {}): void => {
      handle(async () => {
        load(async () => {
          const body = { ...params, ...aditionalParams }
          await httpPut(`/purchase-orders/${action}/${data.numOcp}`, body)
          toast.success(`Ordem ${successMessage[action]}.`)
          close()
          refetch()
        })
      })
    }

    if (action === 'approve') {
      confirm('Continuar com a aprovação da ordem compra?', handleSendData)

      return
    }

    open(
      <Prompt
        inputLabel="Motivo"
        message="Informe um motivo para a reprovação:"
        onConfirm={(motRep) => handleSendData({ motRep })}
      />
    )
  }

  const openDetails = (index: number): () => void => {
    const item = tblitens[index]

    return () => {
      open(
        <Container>
          <div className="info-container">
            <section>
              <Label
                title="Código Produto"
                value={item.codpro}
              />
              <Label
                title="Descrição Produto"
                value={item.cplIpo}
              />
              <Label
                title="Unidade De Medida"
                value={item.unimed}
              />
              <Label
                title="Valor Líquido"
                value={item.vlrLiq}
              />
            </section>
            <section>
              <Label
                title="Código da Conta"
                value={item.ctared}
              />
              <Label
                title="Descrição da Conta"
                value={item.descta}
              />
              <Label
                title="Descrição do Centro de Custo"
                value={item.desccu}
              />
              <Label
                title="Centro de custo"
                value={item.codccu}
              />
            </section>
          </div>
        </Container>
      )
    }
  }

  const openModal = (cotacoes: Cotacoesitem[]): () => void => {
    return () => {
      open(
        <Container>
          <Table>
            <THead>
              <Tr>
                <Th width="100px" color="#000" >Número Da Cotação</Th>
                <Th width="100px" color="#000" >Código Fornecedor</Th>
                <Th width="100px" color="#000" >Nome Fornecedor</Th>
                <Th width="100px" color="#000" >Código do Produto</Th>
                <Th width="100px" color="#000" >Descrição do Produto</Th>
                <Th width="100px" color="#000" >Quantidade Cotada</Th>
                <Th width="100px" color="#000" >Preço da Cotação</Th>
              </Tr>
            </THead>
            <TBody>
              {cotacoes?.map(cotacao => (
                <Tr>
                  <Td title={cotacao.numcot}> {cotacao.numcot} </Td>
                  <Td title={cotacao.codfor}> {cotacao.codfor} </Td>
                  <Td title={cotacao.nomfor}> {cotacao.nomfor} </Td>
                  <Td title={cotacao.codpro}> {cotacao.codpro} </Td>
                  <Td title={cotacao.despro}> {cotacao.despro} </Td>
                  <Td title={cotacao.qtdcot}> {cotacao.qtdcot} </Td>
                  <Td title={cotacao.precot}> {cotacao.precot} </Td>
                </Tr>
              ))
              }
            </TBody>
          </Table>
        </Container>
      )
    }
  }

  return (
    <>
      <Loader
        overlay
        loading={loading}
      />
      <InfoContainer>
        <div className="info">
          <section>
            <Label
              title="Nº da ordem de compra"
              value={data.numOcp}
            />
            <Label
              title="Observação ordem de compra"
              value={data.obsOcp}
            />
            <Label
              title="Nome da Empresa"
              value={data.nomEmp}
            />
            <Label
              title="Código da empresa"
              value={data.codEmp}
            />
            <Label
              title="Código da filial"
              value={data.codFil}
            />
            <Label
              title="Descrição Procedência"
              value={data.desProc}
            />
          </section>
          <section>
            <Label
              title="Data de emissão"
              value={data.datEmi}
            />
            <Label
              title="Valor"
              value={`R$ ${data.vlrApr}`}
            />
            <Label
              title="Nome solicitante"
              value={data.nomSol}
            />
            <Label
              title="Código usuário aprovador"
              value={data.usuSol}
            />
            <Label
              title="Nome do fornecedor"
              value={data.nomFor}
            />

          </section>
        </div>
        {data.obsmot
          ? <section className="orc">
            <Label
              title=""
              value={data.obsmot}
            />
          </section>
          : <> </>}
      </InfoContainer>
      <InfoContainer>
        <div className="info">
          <section>
            <Label
              title="Orçado Competência"
              value={data.orcCom}
            />
            <Label
              title="Realizado Competência"
              value={data.reaCom}
            />
          </section>
          <section>
            <Label
              title="Orçado Anual"
              value={data.orcAnu}
            />
            <Label
              title="Realizado Anual"
              value={data.reaAnu}
            />
          </section>
        </div>
      </InfoContainer>
      <DetailsButtons>
        <Button
          buttonType="primary"
          type="button"
          onClick={handleClick('approve')}
        >
          <DynamicIcon
            family="md"
            name="MdCheck"
            size={15}
          />
          &nbsp;
          Aprovar
        </Button>
        <Button
          buttonType="error"
          type="button"
          onClick={handleClick('reprove')}
        >
          <DynamicIcon
            family="md"
            name="MdClear"
            size={15}
          />

          &nbsp;
          Reprovar
        </Button>

      </DetailsButtons>
      <br />
      <Table>
        <THead>
          <Tr>
            <Th color="#000" width="80px" >Cód.Pro </Th>
            <Th color="#000" width="120px">Des.Pro </Th>
            <Th color="#000" width="50px" >U.M</Th>
            <Th color="#000" width="50px">Qtd.Ped</Th>
            <Th color="#000" width="70px">Pre.Uni</Th>
            <Th color="#000" width="60px">Vlr.Liq</Th>
            <Th width="60px"></Th>
          </Tr>
        </THead>
        <TBody>
          {tblitens.map((item, index) => (
            <Tr key={`item-${index}`}>
              <Td>
                <Buttao onClick={openModal(item.cotacoes)}>
                  {item.codpro}
                </Buttao>
              </Td>
              <Td>
                <Buttao onClick={openDetails(index)}>
                  {item.cplIpo}
                </Buttao>
              </Td>
              <Td>{item.unimed}</Td>
              <Td>{item.qtdPed}</Td>
              <Td>{item.preUni}</Td>
              <Td>{item.vlrLiq}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </>
  )
}

export default PurchaseOrderDetails
