import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { BiddingModel } from '../../../models/bidding'
import Input from '../../../components/Input'

interface AttQntlicModalProps {
  data: BiddingModel
  usu_codpro: string
  refetch: () => void
}

interface AttQntlicForm {
  usu_codproant: string
  usu_qtdlic: number
}

const AttQntlicModal: React.FC<AttQntlicModalProps> = ({
  data,
  usu_codpro,
  refetch
}) => {
  const methods = useForm<AttQntlicForm>({})
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AttQntlicForm): Promise<void> => {
    await handle(async () => {
      const usu_qtdlic = formData.usu_qtdlic
      const response = await httpPatch<string>(`/bidding/itens/${data.usu_numlic}`, {
        usu_codemp: data.usu_codemp,
        usu_codcli: data.usu_codcli,
        usu_codfpg: data.usu_CodFpg,
        usu_datent: data.usu_DatEnt,
        usu_datlic: data.usu_datlic,
        usu_horlic: data.usu_horlic,
        usu_numlic: data.usu_numlic,
        usu_plalic: data.usu_plalic,
        usu_stalic: data.usu_stalic,
        usu_codproant: usu_codpro,
        usu_qtdlic
      })
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Atualizar quantidade do Item</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Quantidade"
          type="text"
          name="usu_qtdlic"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttQntlicModal
