import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import JoditEditor from 'jodit-react'
import { Container } from './styles'

interface TextEditorProps {
  name: string
  label: string
}

const config = {
  language: 'pt_br',
  showTooltipDelay: 200
}

const TextEditor: React.FC<TextEditorProps> = ({
  name,
  label
}) => {
  const { control } = useFormContext()

  return (
    <Container>
      <label>{label}</label>
      <Controller
        defaultValue=""
        name={name}
        control={control}
        render={({ onChange, value }) => (
          <JoditEditor
            value={value}
            onChange={onChange}
            config={config as any}
          />
        )}
      />
    </Container>
  )
}

export default TextEditor
