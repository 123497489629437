import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { Container } from './styles'
import { LogisticModel } from '../../../models/logistic'

interface FilterForm {
  empresa: string
  tipo: string
  transportadora: string
  situacao: string
}

interface FilterProps {
  refetch: () => void
  data: LogisticModel[]
}

const Filter: React.FC<FilterProps> = ({
  refetch,
  data
}) => {
  const methods = useForm<FilterForm>({})

  return (
    <Container>
      <Form
        onSubmit={refetch}
        methods={methods}
      >
        <Button
          buttonType="primary"
          type="button"
          onClick={refetch}
        >
          Atualizar
        </Button>
      </Form>
    </Container>
  )
}

export default Filter
