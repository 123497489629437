import React from 'react'
import { MdSearch, MdPrint, MdUndo } from 'react-icons/md'
import IconButton from '../../../components/IconButton'
import Button from '../../../components/Button'
import {
  Table,
  THead,
  TBody,
  Th,
  Td,
  Tr
} from '../../../components/Table'
import DetailsButtons from '../../../components/DetailsButtons'
import Loader from '../../../components/Loader'
import Label from '../../../components/Label'
import {
  useApi,
  useFetch,
  useModal
} from '../../../hooks'
import { TransportOrder, TransportOrderItem } from '../../../models/transport-order'
import { Container } from './styles'

interface OrderDetailsProps {
  data: TransportOrder
  renderStatus: (item: TransportOrder, step: number, currentStep: number) => JSX.Element
  openShippingCompanyModal: (data: TransportOrder) => () => void
  rehabilitateOrder: (data: TransportOrder) => () => void
  openCifModal: (data: TransportOrder) => () => void
}

const OrderDetails: React.FC<OrderDetailsProps> = ({
  data,
  renderStatus,
  openShippingCompanyModal,
  openCifModal,
  rehabilitateOrder
}) => {
  const {
    numPed,
    codEmp,
    codFil
  } = data
  const { open } = useModal()
  const { httpGet } = useApi()
  const {
    data: items,
    loading,
    refetching
  } = useFetch<TransportOrderItem[]>(httpGet, `/expedition/items/${numPed}/${codEmp}/${codFil}`)

  const openItemDetailsModal = (item: TransportOrderItem): () => void => {
    return () => {
      open(
        <Container>
          <div className="info-container">
            <section>
              <Label
                title="CÓDIGO"
                value={item.codPro}
              />
              <Label
                title="DESCRIÇÃO"
                value={item.desPro}
              />
              <Label
                title="QUANTIDADE"
                value={item.qtdPed}
              />
              <Label
                title="UNI.MEDIDA"
                value={item.unimed}
              />
            </section>
            <section>
              <Label
                title="RACK"
                value={item.rack}
              />
              <Label
                title="NÍVEL"
                value={item.nive}
              />
              <Label
                title="COLUNA"
                value={item.colu}
              />
              <Label
                title="DEPÓSITO"
                value={item.coddep}
              />
            </section>
          </div>
        </Container>
      )
    }
  }

  return (
    <Container>
      <div className="info">
        <section>
          <Label
            title="Nº do pedido"
            value={data.numPed}
          />
          <Label
            title="Data de entrega"
            value={data.dataEntrega}
          />
          <Label
            title="Data de emissão"
            value={data.datEmi}
          />
          <Label
            title="Valor do frete"
            value={data.vlrFrd}
          />
          <Label
            title="Valor Pedido"
            value={data.vlrliq}
          />
          <Label
            title="Vendedor"
            value={data.nomVen}
          />
          <Label
            title="Quantidade de Volume m²"
            value={data.usu_qtdemb}
          />
          <Label
            title="Atualizar valor CIF"
            value={
              <IconButton
                icon={<MdSearch size={20} />}
                padding={3}
                size={20}
                onClick={openCifModal(data)}
              />
            }
          />
        </section>
        <section>
          <Label
            title="Cliente"
            value={data.nomCli}
          />
          <Label
            title="CNPJ/CPF"
            value={data.cgccpf}
          />
          <Label
            title="Transportadora"
            value={data.nomTra}
          />
          <Label
            title="Observação"
            value={data.obsPed}
          />
          <Label
            title="Estado Cliente"
            value={data.sigufs}
          />
          <Label
            title="Cidade Cliente"
            value={data.cidcli}
          />
          <Label
            title="Atualizar transportadora"
            value={
              <IconButton
                icon={<MdSearch size={20} />}
                padding={3}
                size={20}
                onClick={openShippingCompanyModal(data)}
              />
            }
          />
        </section>
      </div>
      <DetailsButtons>
        <Button
          buttonType="primary"
          onClick={() => window.open('http://bi.grupofortaleza.com.br:8082/ReportServer/Pages/ReportViewer.aspx?%2fRelatorios%2fEsfera%2fRomaneio&rs:Command=Render')}
        >
          <MdPrint size={15} />&nbsp;Romaneio
        </Button>
        <Button
          buttonType="secondary"
          onClick={rehabilitateOrder(data)}
        >
          <MdUndo size={15} />&nbsp;Reabilitar
        </Button>
      </DetailsButtons>
      <br />
      <Table>
        <THead>
          <Tr>
            <Th color="#000" textAlign="center">Pedido liberado para separação</Th>
            <Th color="#000" textAlign="center">Pedido separado, aguardando faturamento</Th>
            <Th color="#000" textAlign="center">Pedido faturado, aguardando carregamento</Th>
            <Th color="#000" textAlign="center">Pedido carregado, a caminho do cliente</Th>
          </Tr>
        </THead>
        <TBody>
          <Tr>
            <Td textAlign="center">{renderStatus(data, 1, Number(data.usu_staped))}</Td>
            <Td textAlign="center">{renderStatus(data, 2, Number(data.usu_staped))}</Td>
            <Td textAlign="center">{renderStatus(data, 3, Number(data.usu_staped))}</Td>
            <Td textAlign="center">{renderStatus(data, 4, Number(data.usu_staped))}</Td>
          </Tr>
        </TBody>
      </Table>
      <br />
      <Loader loading={loading || refetching}>
        <Table>
          <THead>
            <Tr>
              <Th color="#000" width="40px">Código</Th>
              <Th color="#000">Descrição</Th>
              <Th color="#000" width="80px">Quantidade</Th>
              <Th color="#000" width="30px">Uni. medida</Th>
              <Th color="#000" >Rack</Th>
              <Th color="#000" >Nível</Th>
              <Th color="#000" >Coluna</Th>
              <Th color="#000" >Cód. Depósito</Th>
            </Tr>
          </THead>
          <TBody>
            {items?.map((item, index) => (
              <Tr key={index}>
                <Td title={item.codPro}>
                  <button type="button"
                    onClick={openItemDetailsModal(item)}>
                    {item.codPro}
                  </button>
                </Td>
                <Td title={item.desPro}>{item.desPro}</Td>
                <Td title={item.qtdPed}>{item.qtdPed}</Td>
                <Td title={item.unimed}>{item.unimed}</Td>
                <Td title={item.rack}>{item.rack}</Td>
                <Td title={item.nive}>{item.nive} </Td>
                <Td title={item.colu}>{item.colu} </Td>
                <Td title={item.coddep}>{item.coddep}</Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      </Loader>
    </Container>
  )
}

export default OrderDetails
