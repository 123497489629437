import React from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import {
  useModal,
  useApi
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { LogisticModel } from '../../../models/logistic'
import { addDays } from 'date-fns'

interface DeliveryDateModalProps {
  data: LogisticModel
  refetch: () => void
  refetchDetails: () => void
}

interface DeliveryDateForm {
  datEnt: string
}

const DeliveryDateModal: React.FC<DeliveryDateModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<DeliveryDateForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: DeliveryDateForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>(`/logistic/update-date-delivery/${data.usu_numped}/${data.usu_codemp}/${data.usu_seqipd}`, {
        codemp: data.usu_codemp,
        codfil: data.usu_codfil,
        datEnt: format(addDays(new Date(formData.datEnt), 1), 'dd/MM/yyyy'),
        numped: data.usu_numped,
        seqipd: data.usu_seqipd,
        tipo: data.usu_tipo
      })
      toast.success(response.body)
      close()
      refetch()
      refetchDetails()
    })
  }

  return (
    <div>
      <h3>Atualizar Data de Entrega</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Data de Entrega"
          type="date"
          name="datEnt"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default DeliveryDateModal
