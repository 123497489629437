import { useRef, RefObject } from 'react'

interface UseDebounce {
  inputRef: RefObject<HTMLInputElement>
  handleChange: () => void
}

export const useDebounce = (
  timeout: number,
  cb: () => void
): UseDebounce => {
  let time: any = useRef(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleChange = (): void => {
    clearTimeout(time)
    time = setTimeout(cb, timeout)
  }

  return {
    inputRef,
    handleChange
  }
}
