import styled from 'styled-components'

export const Container = styled.div`
  .info {
    display: flex;
    section {
      width: 50%;
      display: block;

    section {
      display: block;
      width: 50%;
     }
  }
}
`
