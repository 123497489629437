import styled from 'styled-components'

export const Container = styled.div`
  padding: 15px;

  @media(max-width: 800px) {
    .hide-md {
      display: none;
    }
  }

  @media(max-width: 600px) {
    .hide-sm {
      display: none;
    }
  }
`

export const DateFilter = styled.strong`
  width: max-content;
  font-size: 13px;
  margin-right: 15px;
`
