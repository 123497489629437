import React, { useState } from 'react'
import {
  MdSearch,
  MdAddShoppingCart,
  MdModeEdit,
  MdAdd,
  MdDelete
} from 'react-icons/md'
import Label from '../../../components/Label'
import IconButton from '../../../components/IconButton'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import { Container } from './styles'
import { BiddingModel } from '../../../models/bidding'

interface BiddingDetailsProps {
  data: BiddingModel
  renderStatus: (item: BiddingModel, step: number, currentStep: number) => JSX.Element
  refetch: () => void
  openAddModal: (data: BiddingModel) => () => void
  openAttModal: (data: BiddingModel) => () => void
  openDeleteItemModal: (data: BiddingModel, index: number) => () => void
  openDeleteProviderModal: (data: BiddingModel, index: number) => () => void
  openAttCodfpgModal: (data: BiddingModel) => () => void
  openAttCodcliModal: (data: BiddingModel) => () => void
  openAttItemModal: (data: BiddingModel, item: string, index: number) => () => void
  openAttCnpjProviderModal: (data: BiddingModel, item: string, usu_codfor: string, index: number) => () => void
  openAttPreforModal: (data: BiddingModel, item: string, usu_codfor: string, index: number) => () => void
  openAttQntlicModal: (data: BiddingModel, item: string, index: number) => () => void
  openAddProviderModal: (data: BiddingModel, item: string, index: number) => () => void
}

const BiddingDetails: React.FC<BiddingDetailsProps> = ({
  data,
  renderStatus,
  openAttModal,
  openAttCodcliModal,
  openDeleteItemModal,
  openDeleteProviderModal,
  openAttCodfpgModal,
  openAttItemModal,
  openAttQntlicModal,
  openAttCnpjProviderModal,
  openAddProviderModal,
  openAttPreforModal,
  refetch,
  openAddModal
}) => {
  const [provider, setProvider] = useState<number>(0)

  return (
    <Container>
      <div className="info">
        <section>
          <Label
            title="Cliente"
            value={data.nomcli}
          />
          <Label
            title="Cód. Cliente"
            value={data.usu_codcli}
          />
          <Label
            title="Plataforma"
            value={data.usu_plalic}
          />
          <Label
            title="Adicionar novo item"
            value={
              <IconButton
                icon={<MdAddShoppingCart size={20} />}
                padding={3}
                size={20}
                onClick={openAddModal(data)}
              />
            }
          />
        </section>
        <section>
          <Label
            title="Data"
            value={data.usu_DatEnt}
          />
          <Label
            title="Hora"
            value={data.usu_horger}
          />
          <Label
            title="Atualizar licitação"
            value={
              <IconButton
                icon={<MdModeEdit size={20} />}
                padding={3}
                size={18}
                onClick={openAttModal(data)}
              />
            }
          />
        </section>
      </div>
      <Table layout="fixed">
        <THead>
          <Tr>
            <Th color="black" width="60">Item</Th>
            <Th color="black" width="70px">Descrição</Th>
            <Th color="black" width="40px">Qtde</Th>
            <Th color="black" width="30px">For</Th>
            <Th color="black" width="30px">Excluir</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.itens.map((item, index) =>
            <Tr key={index}>
              <Td>
                <button onClick={() => setProvider(index)}>{item.usu_codpro}</button>
                <IconButton
                  icon={<MdSearch size={20} />}
                  padding={3}
                  size={18}
                  onClick={openAttItemModal(data, item.usu_codpro, index)}
                />
              </Td>
              <Td>{item.despro}</Td>
              <Td>
                {item.usu_qtdlic}
                <IconButton
                  icon={<MdSearch size={20} />}
                  padding={3}
                  size={18}
                  onClick={openAttQntlicModal(data, item.usu_codpro, index)}
                />
              </Td>
              <Td>
                <IconButton
                  onClick={openAddProviderModal(data, item.usu_codpro, index)}
                  icon={<MdAdd size={20} />}
                  padding={10}
                  size={18}
                />
              </Td>
              <Td>
                <IconButton
                  onClick={openDeleteItemModal(data, index)}
                  icon={<MdDelete size={20} />}
                  padding={10}
                  size={18}
                />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <br />
      <Table>
        <THead>
          <Tr>
            <Th color="#000" textAlign="center">Aguardando</Th>
            <Th color="#000" textAlign="center">Iniciada</Th>
            <Th color="#000" textAlign="center">Encerrada</Th>
            <Th color="#000" textAlign="center">Cancelada</Th>
          </Tr>
        </THead>
        <TBody>
          <Tr>
            <Td textAlign="center">{renderStatus(data, 1, Number(data.usu_stalic))}</Td>
            <Td textAlign="center">{renderStatus(data, 2, Number(data.usu_stalic))}</Td>
            <Td textAlign="center">{renderStatus(data, 3, Number(data.usu_stalic))}</Td>
            <Td textAlign="center">{renderStatus(data, 4, Number(data.usu_stalic))}</Td>
          </Tr>
        </TBody>
      </Table>
      <br />
      <Table layout="fixed">
        <THead>
          <Tr>
            <Th color="#000" width="100px">Fornecedor</Th>
            <Th color="#000" width="90px">CNPJ</Th>
            <Th color="#000" width="40px">Unidade</Th>
            <Th color="#000" width="50px">Unitário</Th>
            <Th color="#000" width="40px">Excluir</Th>
          </Tr>
        </THead>
        <TBody>
          {
          data?.itens !== undefined && data?.itens.length > 0
            ? data?.itens[provider]?.fornecedores?.map((fornecedor, index) =>
              <Tr key={index}>
                {}
                <Td>{fornecedor.nomfor}</Td>
                <Td>{fornecedor.cgccpf}
                  <IconButton
                    icon={<MdSearch size={20} />}
                    padding={3}
                    size={18}
                    onClick={openAttCnpjProviderModal(data, fornecedor.usu_codpro, fornecedor.usu_codfor, index)}
                  />
                </Td>
                <Td>{data?.itens[provider].unimed}</Td>
                <Td>{fornecedor.usu_prefor}
                  <IconButton
                    icon={<MdSearch size={20} />}
                    padding={3}
                    size={18}
                    onClick={openAttPreforModal(data, fornecedor.usu_codpro, fornecedor.usu_codfor, index)}
                  />
                </Td>
                <Td>
                  <IconButton
                    icon={<MdDelete size={20} />}
                    padding={3}
                    size={18}
                    onClick={openDeleteProviderModal(data, index)}
                  />
                </Td>
              </Tr>
            ) : null}
        </TBody>
      </Table>
    </Container>
  )
}

export default BiddingDetails
