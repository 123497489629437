import React, { useCallback } from 'react'
import Label from '../../../components/Label'
import { Container, H1 } from './styles'
import IconButton from '../../../components/IconButton'
import { handle } from '../../../utils/error-handlers'
import { AcquisitionOrderModel } from '../../../models/acquisition-order'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import { MdAddShoppingCart, MdCached, MdDelete, MdGavel, MdModeEdit, MdYoutubeSearchedFor } from 'react-icons/md'
import { toast } from 'react-toastify'

interface AcquisitionOrderDetailsProps {
  data: AcquisitionOrderModel
  disable: any
  setRehabiliateAcquisition: any
  openWeighing: (numocp: string, codemp: string, codfil: string, codpro: string) => () => void
  openAttProduct: (data: AcquisitionOrderModel, index: number) => () => void
  openAttService: (data: AcquisitionOrderModel, index: number) => () => void
  openAprovationsGrid: (numocp: string, codemp: string, codfil: string) => () => void
  openAttAcquisitionOrder: (data: AcquisitionOrderModel) => () => void
  openDeleteProduct: (data: AcquisitionOrderModel, index: number) => () => void
  openDeleteService: (data: AcquisitionOrderModel, index: number) => () => void
  openAddProduct: (data: AcquisitionOrderModel) => () => void
  openAddService: (data: AcquisitionOrderModel) => () => void
}

const AcquisitionOrderDetails: React.FC<AcquisitionOrderDetailsProps> = ({
  data,
  disable,
  setRehabiliateAcquisition,
  openAprovationsGrid,
  openAttProduct,
  openAttService,
  openWeighing,
  openAttAcquisitionOrder,
  openDeleteProduct,
  openDeleteService,
  openAddService,
  openAddProduct
}) => {
  const { open, confirm } = useModal()
  const { httpPatch } = useApi()
  const {
    clearData,
    close
  } = useDetailsContainer()

  const handleRehabilitate = useCallback((item: AcquisitionOrderModel): () => void => {
    return () => {
      confirm('Reabilitar Ordem de Compra?', () => {
        handle(async () => {
          const response = await httpPatch<string>(`/acquisition-order/rehabilitate/${data.numocp}/${data.codemp}/${data.codfil}/${data.codfor}`)
          if (response.statusCode === 200) {
            toast.info(response.body)
          }
          close()
          clearData()
          setRehabiliateAcquisition(true)
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, httpPatch])

  const openProductDetailsModal = (codpro: string): void => {
    const product = data?.produtos.find((item) => (item.codpro === codpro))
    return (
      open(
        <Container>
          <div className="info-container">
            <>
              <section>
                <Label
                  title="COFINS %"
                  value={product?.percrt}
                />
                <Label
                  title="CSLL %"
                  value={product?.percsl}
                />
                <Label
                  title="ICMS %"
                  value={product?.pericm}
                />
                <Label
                  title="IPI %"
                  value={product?.peripi}
                />
                <Label
                  title="Sequência Produto"
                  value={product?.seqipo}
                />
                <Label
                  title="Centro de Custo"
                  value={product?.codccu}
                />
              </section>
              <section>
                <Label
                  title="Preço Unitário"
                  value={product?.preuni}
                />
                <Label
                  title="Valor Bruto"
                  value={product?.vlrbru}
                />
                <Label
                  title="Depósito"
                  value={product?.coddep}
                />
              </section>
            </>
          </div>
        </Container>
      ))
  }

  const openServicesDetailsModal = (codser: string): void => {
    const service = data?.servicos.find((item) => (item.codser === codser))
    return (
      open(
        <Container>
          <div className="info-container">
            <section>
              <Label
                title="COFINS %"
                value={service?.percrt}
              />
              <Label
                title="CSLL %"
                value={service?.percsl}
              />
              <Label
                title="ICMS %"
                value={service?.pericm}
              />
              <Label
                title="IPI %"
                value={service?.peripi}
              />
              <Label
                title="Sequênca Produto"
                value={service?.seqipo}
              />
              <Label
                title="Centro de Custo"
                value={service?.codccu}
              />
            </section>
            <section>
              <Label
                title="IRRF %"
                value={service?.perirf}
              />
              <Label
                title="PIS %"
                value={service?.perpit}
              />
              <Label
                title="INSS %"
                value={service?.perins}
              />
              <Label
                title="Preço Unitário"
                value={service?.preuni}
              />
              <Label
                title="Quantidade"
                value={service?.qtdped}
              />
              <Label
                title="Valor Bruto"
                value={service?.vlrbru}
              />
            </section>
          </div>
        </Container>
      )
    )
  }

  return (
    <Container>
      <div className="info">
        <section>
          <Label
            title="Empresa"
            value={`${data.codemp} - ${data.nomemp}`}
          />
          <Label
            title="Filial"
            value={`${data.codfil} - ${data.nomfil}`}
          />
          <Label
            title="Nº Ordem de Compra"
            value={data.numocp}
          />
          <Label
            title="Fornecedor"
            value={`${data.codfor} - ${data.apefor}`}
          />
          <Label
            title="Data Geração"
            value={data.datemi}
          />
          <Label
            title="Transação Produto"
            value={data.tnspro}
          />
          <Label
            title="Transação Serviço"
            value={data.tnsser}
          />
          <Label
            title="Transportadora"
            value={`${data.codtra} - ${data.nomtra}`}
          />
          <Label
            title="Atualizar Ordem de compra"
            value={
              <IconButton
                icon={<MdModeEdit size={20} />}
                padding={3}
                size={18}
                onClick={openAttAcquisitionOrder(data)}
              />
            }
          />
          <Label
            title="Adicionar Serviço"
            value={
              <IconButton
                icon={<MdAddShoppingCart size={20} />}
                padding={3}
                size={18}
                onClick={openAddService(data)}
              />
            }
          />
        </section>
        <section>
          <Label
            title="Parcelas Especiais"
            value={data.tempar}
          />
          <Label
            title="Soma Frete"
            value={data.somfre}
          />
          <Label
            title="Valor Frete"
            value={data.vlrfre}
          />
          <Label
            title="Cif/Fob"
            value={data.ciffob}
          />
          <Label
            title="Forma Pagamento"
            value={`${data.codfpg} - ${data.desfpg}`}
          />
          <Label
            title="Condição Pagamento"
            value={`${data.codcpg} - ${data.descpg}`}
          />
          <Label
            title="Adicionar Produto"
            value={
              <IconButton
                icon={<MdAddShoppingCart size={20} />}
                padding={3}
                size={18}
                onClick={openAddProduct(data)}
              />
            }
          />
          <Label
            title="Aprovadores"
            value={
              <IconButton
                icon={<MdGavel size={20} />}
                padding={3}
                size={18}
                onClick={openAprovationsGrid(data.numocp, data.codemp, data.codfil)}
              />
            }
          />
          <Label
            title="Reabilitar Ordem de compra"
            value={
              <IconButton
                icon={<MdCached size={20} />}
                padding={3}
                size={18}
                onClick={handleRehabilitate(data)}
              />
            }
          />
        </section>
      </div>
      <H1>Produtos</H1>
      <Table layout="fixed">
        <THead>
          <Tr>
            <Th color="black" width="40px">Produto</Th>
            <Th color="black" width="50px">Descrição</Th>
            <Th color="black" width="40px">Qtd. Pedida</Th>
            <Th color="black" width="40px">Pre. Unitário</Th>
            <Th color="black" width="30px">Total</Th>
            <Th color="black" width="30px"></Th>
            <Th color="black" width="30px"></Th>
            <Th color="black" width="30px"></Th>
          </Tr>
        </THead>
        <TBody>
          {data?.produtos.map((item, index) =>
            <Tr key={index}>
              <Td>
                <button type="button"
                  onClick={() => { openProductDetailsModal(item.codpro) }}>
                  {item.codpro}
                </button>
              </Td>
              <Td>{item.cplipo}</Td>
              <Td>{item.qtdped}</Td>
              <Td>{item.preuni}</Td>
              <Td>{item.vlrbru}</Td>
              <Td>
                <IconButton
                  onClick={openDeleteProduct(data, index)}
                  icon={<MdDelete size={20} />}
                  disabled={disable}
                  padding={10}
                  size={18}
                />
              </Td>
              <Td>
                <IconButton
                  onClick={openWeighing(data.numocp, data.codemp, data.codfil, item.codpro)}
                  icon={<MdYoutubeSearchedFor size={20} />}
                  padding={10}
                  size={18}
                />
              </Td>
              <Td>
                <IconButton
                  onClick={openAttProduct(data, index)}
                  icon={<MdModeEdit size={20} />}
                  padding={3}
                  size={18}
                />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <br />
      <H1>Serviços</H1>
      <Table layout="fixed">
        <THead>
          <Tr>
            <Th color="black" width="40px">Serviço</Th>
            <Th color="black" width="50px">Descrição</Th>
            <Th color="black" width="40px">Qtd. Pedida</Th>
            <Th color="black" width="40px">Pre. Unitário</Th>
            <Th color="black" width="30px">Total</Th>
            <Th color="black" width="30px"></Th>
            <Th color="black" width="30px"></Th>
            <Th color="black" width="30px"></Th>
          </Tr>
        </THead>
        <TBody>
          {data?.servicos.map((item, index) =>
            <Tr key={index}>
              <Td>
                <button type="button"
                  onClick={() => { openServicesDetailsModal(item.codser) }}>
                  {item.codser}
                </button>
              </Td>
              <Td>{item.cpliso}</Td>
              <Td>{item.qtdped}</Td>
              <Td>{item.preuni}</Td>
              <Td>{item.vlrbru}</Td>
              <Td>
                <IconButton
                  onClick={openDeleteService(data, index)}
                  icon={<MdDelete size={20} />}
                  disabled={disable}
                  padding={10}
                  size={18}
                />
              </Td>
              <Td>
                <IconButton
                  onClick={openAttService(data, index)}
                  icon={<MdModeEdit size={20} />}
                  padding={3}
                  size={18}
                />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </Container>
  )
}

export default AcquisitionOrderDetails
