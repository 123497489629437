import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { LoginParams } from '../models/login'
import {
  InitialState,
  authAction,
  AuthActions,
  login as loginAction
} from '../redux/auth'
import { useDetailsContainer } from './details-container'

interface UseAuth {
  login: (params: LoginParams) => void
  logout: () => void
  isAuthenticated: boolean
  user: string
  token: string
}

export const useAuth = (): UseAuth => {
  const dispatch = useDispatch()
  const state = useSelector((state: any): InitialState => state.auth)
  const { pathname, search } = useLocation()
  const { replace } = useHistory()
  const { clearData } = useDetailsContainer()

  const login = useCallback((params: LoginParams): void => {
    dispatch(loginAction(params))
  }, [dispatch])

  const logout = useCallback((): void => {
    dispatch(authAction(AuthActions.CLEAR))
    localStorage.clear()
    clearData()
    replace('/')
  }, [dispatch, clearData, replace])

  useEffect(() => {
    if (state.isAuthenticated) {
      replace(pathname === '/' ? '/informativos/1' : pathname + search)
    }
  }, [state.isAuthenticated, replace, pathname, search])

  return {
    login,
    logout,
    isAuthenticated: state.isAuthenticated,
    user: state.user ?? '',
    token: state.token ?? ''
  }
}
