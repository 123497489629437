import styled from 'styled-components'
import { colors } from '../../../../styles/default-styles'

export const InfoContainer = styled.div`
  margin: 0;
  padding: 0;

  .info {
    display: flex;
    section {
      width: 50%;
      display: block;

    section {
      display: block;
      width: 50%;
  }
}
  }
  .orc {
    background-color: red;
    text-align: center;

    small {
        color: white;
        font-size: 0.9rem;
      }

    p {
      color: white;
      font-size: 1.5rem;
      padding-bottom: 20px;
    }
}
`
export const Container = styled.div`
  .info-container {    
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    section:first-child {
      width: 48%;
    }

    section:last-child {
      width: 50%;
    }
  }
`
export const Buttao = styled.div`
    border: none;
    cursor: pointer;
    color: black;
    font-weight: bold;
      
`

export const Itens = styled.div`
  ul {
    list-style: none;

    li {
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.07);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.07);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.07);
      padding: 10px;
      border-left: 5px solid #000;
      border-radius: 2px;
      margin-bottom: 10px;

      strong, span {
        display: inline-block;
      }

      strong:hover {
        cursor: pointer;
      }

      span, p {
        color: ${colors.mediumGray};
      }

      span {
        background-color: ${colors.lightGray};
        white-space: nowrap;
        height: max-content;
        padding: 5px 10px;
        border-radius: 30px;
      }

      p {
        font-size: 12px;
      }

      .main {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
`
