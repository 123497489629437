import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  InitialState,
  Content,
  detailsContainerAction,
  DetailsContainerActions
} from '../redux/details-container'

interface UseDetailsContainer {
  opened: boolean
  content: Content[]
  fullScreen: boolean
  mobile: boolean
  open: () => void
  close: () => void
  pushPage: (newContent: Content) => void
  setFullScreen: (value: boolean) => void
  setMobile: (value: boolean) => void
  clearData: () => void
}

export const useDetailsContainer = (): UseDetailsContainer => {
  const dispatch = useDispatch()
  const state = useSelector((state: any): InitialState => state.detailsContainer)
  const {
    content,
    opened,
    fullScreen,
    mobile
  } = state

  const open = useCallback((): void => {
    dispatch(
      detailsContainerAction(
        DetailsContainerActions.SET_OPENED, { opened: true }
      )
    )
  }, [dispatch])

  const close = useCallback((): void => {
    dispatch(
      detailsContainerAction(DetailsContainerActions.CLOSE)
    )
  }, [dispatch])

  const pushPage = useCallback((newContent: Content): void => {
    dispatch(
      detailsContainerAction(
        DetailsContainerActions.PUSH_PAGE, {
          data: {
            content: newContent
          }
        }
      )
    )
  }, [dispatch])

  const setFullScreen = useCallback((value: boolean): void => {
    dispatch(
      detailsContainerAction(
        DetailsContainerActions.SET_FULL_SCREEN, {
          fullScreen: value
        }
      )
    )
  }, [dispatch])

  const setMobile = useCallback((value: boolean): void => {
    dispatch(
      detailsContainerAction(
        DetailsContainerActions.SET_MOBILE, {
          mobile: value
        }
      )
    )
  }, [dispatch])

  const clearData = useCallback((): void => {
    dispatch(detailsContainerAction(DetailsContainerActions.CLEAR))
  }, [dispatch])

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setMobile(true)
      setFullScreen(false)
    }
  }, [setMobile, setFullScreen])

  return {
    opened,
    content,
    fullScreen,
    mobile,
    open,
    close,
    pushPage,
    setFullScreen,
    setMobile,
    clearData
  }
}
