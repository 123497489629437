import React from 'react'
import { Container } from './styles'

interface LabelProps {
  title: string
  value: any
  IconButton?: JSX.Element
}

const Label: React.FC<LabelProps> = ({ title, value, IconButton }) => {
  return (
    <Container>
      <small>{title} {IconButton}</small>
      <p>{value || '-' }</p>
    </Container>
  )
}

export default Label
