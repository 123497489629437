import React from 'react'
import * as MdIcons from 'react-icons/md'
import * as AiIcons from 'react-icons/ai'
import * as FiIcons from 'react-icons/fi'
import * as FaIcons from 'react-icons/fa'
import { IconBaseProps } from 'react-icons/lib'

interface DynamicIconProps {
  family: string
  name: string
  size: number
  color?: string
}

const getIcons = (family: string): any => {
  switch (family) {
    case 'md':
      return MdIcons
    case 'fi':
      return FiIcons
    case 'fa':
      return FaIcons
    case 'ai':
      return AiIcons
  }
}

const DynamicIcon: React.FC<DynamicIconProps> = ({
  family,
  name,
  size,
  color
}) => {
  const Icon: React.FC<IconBaseProps> = getIcons(family)[name] ?? null
  return <Icon size={size} color={color} />
}

export default DynamicIcon
