import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { CallRegistrationModel } from '../../../models/call-registration'
import Select from '../../../components/Select'

interface UserAttendantModalProps {
  data: CallRegistrationModel
  refetch: () => void
  refetchDetails: () => void
}

interface UserAttendantForm {
  usuarioatendente: string
}

const UserAttendantModal: React.FC<UserAttendantModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<UserAttendantForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: UserAttendantForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>('/call-registration/update-user-attendant', {
        usu_codchamabe: data.usu_codusuabe,
        usu_codcham: data.usu_codcham,
        usuarioatendente: formData.usuarioatendente
      })
      toast.success(response.body)
      close()
      refetch()
      refetchDetails()
    })
  }

  return (
    <div>
      <h3>Atualizar Usuário Atendente do Chamado #{data.usu_codcham}</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Select
          readonly
          label="Usuário Atendente"
          name="usuarioatendente"
          options={[{
            display: '25 - Lucas Mattge ',
            value: '25'
          }, {
            display: '282 - Guilherme Surdi',
            value: '282'
          }, {
            display: '370 - Leonardo Machado',
            value: '370'
          }
          ]}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default UserAttendantModal
