import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { BiddingModel } from '../../../models/bidding'
import SelectApi from '../../../components/Select/api'

interface AttItemModalProps {
  data: BiddingModel
  usu_codpro: string
  refetch: () => void
}

interface AttItemForm {
  codpro: string
  usu_codproant: string
  usu_qtdlic: string
}

const AttItemModal: React.FC<AttItemModalProps> = ({
  data,
  usu_codpro,
  refetch
}) => {
  const methods = useForm<AttItemForm>({
    defaultValues: {
      usu_qtdlic: '1'
    }
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AttItemForm): Promise<void> => {
    await handle(async () => {
      const usu_codpron = formData.codpro
      const response = await httpPatch<string>(`/bidding/itens/${data.usu_numlic}`, {
        usu_codemp: data.usu_codemp,
        usu_codcli: data.usu_codcli,
        usu_codfpg: data.usu_CodFpg,
        usu_datent: data.usu_DatEnt,
        usu_datlic: data.usu_datlic,
        usu_horlic: data.usu_horlic,
        usu_numlic: data.usu_numlic,
        usu_plalic: data.usu_plalic,
        usu_stalic: data.usu_stalic,
        usu_codproant: usu_codpro,
        usu_codpro: usu_codpron,
        usu_qtdlic: 1
      })
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Atualizar item da Licitação</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="despro"
          readonly={false}
          label="Novo Item"
          name="codpro"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url="bidding/list-products"
          valueProperty="codpro"
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttItemModal
