import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const Container = styled.div`
  border: 1px solid ${colors.borders};
  border-radius: 2px;
  margin: 15px;

  h2 {
    padding: 10px 15px;
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    border-bottom: 1px solid ${colors.borders};
    padding: 10px 15px;

    li {
      a {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 90px;
        width: 90px;
        border-radius: 50%;
        transition: 0.3s all;
        margin-right: 15px;
        text-decoration: none;
        color: ${colors.textColor};

        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.02);
        }
      }
    }
  }

  ul:last-child {
    border-bottom: none;
  }
`
