import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import Field from '../Field'
import { useClickAwayListener } from '../../hooks'
import { useFormContext } from 'react-hook-form'
import { SelectOption } from '../../models/select-option'
import { SelectContainer, SelectOptions } from './styles'

export interface SelectProps {
  name: string
  defaultValue?: any
  register: any
  readonly: boolean
  label: string
  options: SelectOption[]
  onSelect?: (option: SelectOption | undefined) => void
}

const Select: React.FC<SelectProps> = ({
  label,
  defaultValue,
  name,
  readonly = true,
  register,
  options,
  onSelect = (item) => {}
}) => {
  const [opened, setOpened] = useState(false)
  const { errors, setValue, watch } = useFormContext()
  const value = watch(name)
  const [filteredOptions, setFilteredOptions] = useState(options)
  const [filter, setFilter] = useState('')

  useClickAwayListener({
    elementId: name,
    onClickAway: () => {
      if (opened) {
        setOpened(false)
      }
    }
  })

  useEffect(() => {
    if (filter !== '' && filter !== undefined && filter !== null && !readonly) {
      const regexp = new RegExp(filter, 'i')
      const opt = options.filter(x => regexp.test(x.display))
      setFilteredOptions(opt)
    } else {
      setFilteredOptions(options)
    }
  }, [filter, options, readonly])

  useEffect(() => {
    setFilteredOptions(options)
  }, [options])

  useEffect(() => {
    if (defaultValue) {
      setFilter(options.find(x => x.value === defaultValue)?.display ?? '')
      setValue(name, defaultValue)
    }
  }, [defaultValue, setValue, name, options])

  useEffect(() => {
    if (readonly) {
      setFilter(options.find(x => x.value === value)?.display ?? '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <SelectContainer
      id={name}
      onClick={() => {
        setOpened(!opened)
      }}
    >
      <Field id={name} error={errors[name]?.message} label={label}>
        <input
          type='hidden'
          name={name}
          ref={register}
        />
        <input
          readOnly={readonly}
          autoComplete="off"
          onChange={(e) => {
            setOpened(true)
            setFilter(e.target.value)
          }}
          value={ filter }
        />
        {/* <span className="value">{getValue()}</span> */}
        <MdKeyboardArrowDown className="arrow-down" />
      </Field>
      <SelectOptions opened={opened}>
        <ul>
          <li onClick={() => {
            setValue(name, undefined, {
              shouldValidate: true,
              shouldDirty: true
            })
            onSelect(undefined)
          }}>
            Selecione
          </li>
          {filteredOptions.map((item) => (
            <li
              key={item.value}
              className={item.value === value ? 'selected' : ''}
              onClick={() => {
                setValue(name, item.value, {
                  shouldValidate: true,
                  shouldDirty: true
                })
                setFilter(item.display)
                onSelect(item)
              }}
            >
              {item.display}
            </li>
          ))}
        </ul>
      </SelectOptions>
    </SelectContainer>
  )
}

export default Select
