import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const TextareaStyle = styled.textarea`
  width: 100%;
  clear: both;
  border: none;
  resize: none;
  padding: 5px 0;
`

export const Border = styled.div`
  height: 2px;
  background-color: ${colors.borders};
`
