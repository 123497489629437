import React from 'react'
import Field from '../Field'
import { useFormContext } from 'react-hook-form'
import { TextareaStyle, Border } from './styles'

interface TextareaProps {
  name: string
  label: string
  defaultValue?: any
  register: any
}

const Textarea: React.FC<TextareaProps> = ({
  name,
  label,
  defaultValue,
  register
}) => {
  const { errors } = useFormContext()

  return (
    <Field
      id={name}
      label={label}
      error={errors[name]?.message}
    >
      <TextareaStyle
        name={name}
        ref={register}
        id={name}
        defaultValue={defaultValue}
        cols={30}
        rows={7}
      />
      <Border />
    </Field>
  )
}

export default Textarea
