import React from 'react'
import Header from '../../components/Header'
import SideMenu from '../../components/SideMenu'
import DetailsContainer from '../../components/DetailsContainer'
import Modal from '../../components/Modal'
import {
  useAuth,
  useSideMenu,
  useDetailsContainer
} from '../../hooks'
import { Container, Children } from './styles'

const Layout: React.FC = ({ children }) => {
  const { user, logout } = useAuth()
  const {
    toggle,
    opened,
    close
  } = useSideMenu()
  const {
    close: closeDetails,
    opened: detailsOpened,
    content,
    fullScreen,
    mobile,
    setFullScreen
  } = useDetailsContainer()

  return (
    <>
      <Modal />
      <Header
        user={user}
        logout={logout}
        toggle={toggle}
      />
      <Container>
        <SideMenu
          close={close}
          closeDetails={closeDetails}
          opened={opened}
        />
        <Children>
          {children}
        </Children>
        <DetailsContainer
          close={closeDetails}
          content={content}
          fullScreen={fullScreen}
          mobile={mobile}
          opened={detailsOpened}
          setFullScreen={setFullScreen}
        />
      </Container>
    </>
  )
}

export default Layout
