import { Action } from '../types'

export enum DetailsContainerActions {
  SET_OPENED = 'DETAILS_CONTAINER_SET_OPENED',
  CLOSE = 'DETAILS_CONTAINER_CLOSE',
  PUSH_PAGE = 'DETAILS_CONTAINER_PUSH_PAGE',
  SET_CONTENT = 'DETAILS_CONTAINER_SET_CONTENT',
  SET_FULL_SCREEN = 'DETAILS_CONTAINER_SET_FULL_SCREEN',
  SET_MOBILE = 'DETAILS_CONTAINER_SET_MOBILE',
  CLEAR = 'DETAILS_CONTAINER_CLEAR'
}

export const detailsContainerAction = (
  action: DetailsContainerActions,
  data: any = {}
): Action => {
  return {
    type: action,
    ...data
  }
}
