import styled from 'styled-components'

export const Container = styled.div`
  .info {
    display: flex;
    section {
      width: 50%;
      display: block;

    section {
      display: block;
      width: 50%;
     }
  }
  .teste{
    h1{
    padding-bottom: 10px;
  }
  }
}
`
export const H1 = styled.div`
    font-weight: 300;
    font-family: Lato, sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 5px;
`
