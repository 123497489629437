import styled from 'styled-components'

export const Container = styled.div`
  padding: 5px 10px;
`

export const Message = styled.p`
  margin-bottom: 10px;
  font-size: 15px !important;
`

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`
