import styled, { keyframes, css } from 'styled-components'

const rotation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

export const Container = styled.div<{ overlay: boolean }>`
  text-align: center;
  ${props => props.overlay && css`
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 25%;
    background: rgba(255, 255, 255, 0.8);
  `}

  .loader {
    content: ' ';
    display: inline-block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 1px solid #a6a6a6;
    border-color: #a6a6a6 transparent #a6a6a6 transparent;
    animation: 1.5s ${rotation} infinite linear;
  }

  p {
    margin-top: 5px;
    font-weight: 400;
    font-size: 13px;
  }
`
