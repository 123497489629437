import { toast } from 'react-toastify'

export const handle = async (
  callback: () => Promise<void>,
  fallback = () => {}
): Promise<void> => {
  try {
    await callback()
  } catch (error) {
    toast.error(error.response?.data.error ?? 'Ocorreu um erro.')
    fallback()
  }
}
