import styled from 'styled-components'

export const Container = styled.div`
  padding: 15px;

  @media(max-width: 700px) {
    .hide-md {
      display: none;
    }
  }

  @media(max-width: 550px) {
    .hide-sm {
      display: none;
    }
  }
`
