export const colors = {
  textColor: '#272626',
  body: '#FDFDFD',
  header: '#222',
  svgColor: '#666',
  borders: '#ECECEC',
  lightBorders: '#EFEFEF',
  lightGray: '#F4F4F4',
  gray: '#666',
  mediumGray: '#8E8E8E',
  primary: '#222',
  secondary: '#F4F4F4',
  error: '#EB4B4B'
}

export const sizes = {
  fontSize: '13px',
  headerHeight: '80px',
  maxWidth: '1700px',
  sideMenuOpened: '250px',
  sideMenuClosed: '60px',
  detailsContainerWidth: '500px'
}
