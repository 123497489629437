import styled from 'styled-components'
import { colors } from '../../../styles/default-styles'

export const CardStyle = styled.div`
  background-color: white;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;
  position: relative;
  max-width: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid ${colors.lightGray};

    .title {
      display: flex;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: red;
        border-radius: 50%;
        min-width: 20px;
        min-height: 20px;
        color: white;
        text-align: center;
        font-size: 13px;
        font-weight: 900;
        margin-right: 5px;
      }
    }
  }

  .date {
    margin: 10px 0;
    font-weight: bold;
    font-size: 11px;
  }

  .read-more {
    background-color: ${colors.primary};
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 3px;
    padding: 8px 20px;
    color: white;
    transition: 0.15s all;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`

export const Content = styled.div`
  overflow: hidden;
  max-height: 50px;
  margin-bottom: 10px;
  position: relative;

  .gradient {
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1), white);
    height: 50px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word; 
  }
`
