import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { BiddingModel } from '../../../models/bidding'
import Input from '../../../components/Input'

interface AttPreforProps {
  data: BiddingModel
  usu_codpro: string
  usu_codfor: string
  refetch: () => void
}

interface AttPreforForm {
  codfor: string
  usu_codforant: string
  usu_prefor: string
}

const AttPreforModal: React.FC<AttPreforProps> = ({
  data,
  usu_codpro,
  usu_codfor,
  refetch
}) => {
  const methods = useForm<AttPreforForm>({
    defaultValues: {
    }
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AttPreforForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>(`/bidding/provider/${data.usu_numlic}/${usu_codpro}`, {
        usu_codcli: data.usu_codcli,
        usu_codemp: data.usu_codemp,
        usu_codforant: usu_codfor,
        usu_codpro: usu_codpro,
        usu_numlic: data.usu_numlic,
        usu_prefor: formData.usu_prefor
      })
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Atualizar fornecedor do item</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Preço Do Fornecedor"
          type="text"
          name="usu_prefor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttPreforModal
