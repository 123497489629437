import React, { useEffect, useState } from 'react'
import Select, { SelectProps } from '.'
import { useAuth, useApi } from '../../hooks'
import { SelectOption } from '../../models/select-option'

interface SelectApiProps extends Omit<SelectProps, 'options'> {
  url: string
  displayProperty: string
  defaultValue?: any
  valueProperty: string
  readonly: boolean
}

const SelectApi: React.FC<SelectApiProps> = ({
  url,
  label,
  defaultValue,
  name,
  valueProperty,
  readonly = true,
  displayProperty,
  register,
  onSelect
}) => {
  const { token } = useAuth()
  const { httpGet } = useApi()
  const [options, setOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    const fetchOptions = async (): Promise<void> => {
      const { body } = await httpGet(url)
      setOptions(body.map((item: any) => ({
        display: item[displayProperty],
        value: `${item[valueProperty] as string}`,
        data: item
      })))
    }
    fetchOptions()
  }, [token, url, httpGet, displayProperty, valueProperty])

  return <Select
    onSelect={onSelect}
    defaultValue={defaultValue}
    readonly={readonly}
    label={label}
    name={name}
    options={options}
    register={register}
  />
}

export default SelectApi
