import styled from 'styled-components'
import { colors } from '../../../styles/default-styles'

export const Container = styled.div`
  position: relative;

  ul {
    position: absolute;
    right: 10px;
    left: 10px;
    z-index: 1;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    background-color: white;
    border-radius: 2px;
    max-height: 400px;
    overflow-y: auto;

    li a {
      display: flex;
      padding: 15px 20px;
      width: 100%;
      color: ${colors.textColor};
      font-size: 15px;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      &:hover {
        background-color: ${colors.secondary};
      }
    }
  }
`
