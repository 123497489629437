import { Action } from '../types'
import { SideMenuActions } from './side-menu-actions'

const initialState = {
  opened: false
}

export const sideMenuReducer = (
  state = initialState,
  action: Action
): typeof initialState => {
  switch (action.type) {
    case SideMenuActions.SET_OPENED:
      return { ...state, opened: true }
    case SideMenuActions.SET_CLOSED:
      return { ...state, opened: false }
    case SideMenuActions.TOGGLE:
      return { ...state, opened: !state.opened }
    default:
      return state
  }
}
