import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import Form from '../../../../components/Form'
import Input from '../../../../components/Input'
import Checkbox from '../../../../components/Checkbox'
import Button from '../../../../components/Button'
import SelectApi from '../../../../components/Select/api'
import Loader from '../../../../components/Loader'
import DetailsButtons from '../../../../components/DetailsButtons'
import { useHistory } from 'react-router-dom'
import {
  useForm,
  useFieldArray
} from 'react-hook-form'
import {
  useDetailsContainer,
  useApi,
  useLoading,
  useModal
} from '../../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../../utils/error-handlers'
import { User } from '../../../../models/user'
import { Profile } from '../../../../models/profile'
import {
  Container,
  ChangeProfileButton,
  SelectContainer,
  SubTitle
} from './styles'

interface UserForm {
  name: string
  username: string
  internal: boolean
  loginSenior: string
  passwordSenior: string
  userNectar: string
  profile: string
  profiles: Profile[]
}

interface UserDetailsProps {
  id?: number | -1
  refetch: () => void
}

const UserDetails: React.FC<UserDetailsProps> = ({ id = -1, refetch }) => {
  const methods = useForm<UserForm>({
    mode: 'onTouched',
    defaultValues: {
      internal: false
    }
  })
  const {
    register,
    watch,
    setValue,
    reset,
    control
  } = methods
  const {
    fields,
    append,
    remove
  } = useFieldArray<Profile, 'customId'>({
    control,
    name: 'profiles',
    keyName: 'customId'
  })
  const watchInternal = watch('internal')
  const {
    httpPost,
    httpGet,
    httpDelete,
    httpPut
  } = useApi()
  const { close } = useDetailsContainer()
  const { replace } = useHistory()
  const [selectedProfile, setSelectedProfile] = useState<Profile | undefined>()
  const { load, loading } = useLoading()
  const { confirm } = useModal()

  const handleSubmit = async (data: UserForm): Promise<void> => {
    console.log(data)
    handle(async () => {
      const profiles: number[] = fields.map(field => Number(field.id))
      const {
        name,
        username,
        loginSenior,
        passwordSenior,
        userNectar
      } = data
      if (id === -1) {
        const { body: user } = await httpPost<User>('/users', {
          name,
          username,
          loginSenior,
          passwordSenior,
          userNectar
        })
        if (user) {
          await httpPost(`/users/attach-profiles/${user.id}`, { profiles })
        }
      } else {
        await httpPut(`/users/${id}`, {
          name,
          username,
          loginSenior,
          passwordSenior,
          userNectar
        })
        await httpPost(`/users/attach-profiles/${id}`, { profiles })
      }
      toast.success('Usuário salvo com sucesso.')
      refetch()
    })
  }

  const closeWrapper = useCallback(() => {
    close()
    replace('/configuracoes/usuarios')
  }, [close, replace])

  const setUserData = useCallback((user: User) => {
    const {
      name,
      username,
      loginSenior,
      passwordSenior,
      userNectar,
      profiles
    } = user
    reset({
      internal: !!profiles?.length,
      loginSenior,
      name,
      passwordSenior,
      profile: '',
      profiles,
      userNectar,
      username
    })
  }, [reset])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      reset()
      await load(async () => {
        if (id > -1) {
          const { body } = await httpGet<User>(`/users/${id}`)
          setUserData(body as User)
        }
      })
    }
    fetchData()
  }, [id, httpGet, reset, load, setUserData])

  return (
    <Container>
      <Loader
        loading={loading}
        overlay
      />
      <Form onSubmit={handleSubmit} methods={methods}>
        <Input
          label="Nome"
          name="name"
          register={register({
            required: {
              message: 'Preencha este campo.',
              value: true
            }
          })}
        />
        <Input
          label="Login"
          name="username"
          register={register({
            required: {
              message: 'Preencha este campo.',
              value: true
            }
          })}
        />
        <Input
          label="Login Senior"
          name="loginSenior"
          register={register()}
        />
        <Input
          label="Senha Senior"
          name="passwordSenior"
          type="password"
          register={register()}
        />
        <SubTitle>Acessos</SubTitle>
        <Checkbox
          name="internal"
          register={register()}
          label="Interno"
        />
        {watchInternal && (
          <>
            {fields.map((field, index) => (
              <SelectContainer
                className="profile"
                key={field.customId }
              >
                <input
                  type="hidden"
                  ref={register()}
                  name={`profiles[${index}].id`}
                />
                <p>{field.name}</p>
                <ChangeProfileButton
                  type="button"
                  onClick={async () => {
                    if (id > -1) {
                      await httpPost(`/users/detach-profiles/${id}`, {
                        profiles: [field.id]
                      })
                      toast.success('Perfil desvinculado com sucesso.')
                    }
                    remove(index)
                  }}
                >
                  <MdDelete />
                </ChangeProfileButton>
              </SelectContainer>
            ))}
            <SelectContainer>
              <SelectApi
                readonly
                displayProperty="name"
                label="Perfil"
                name="profile"
                register={register}
                url="/profiles"
                valueProperty="id"
                onSelect={(item) => {
                  if (item) {
                    setSelectedProfile({
                      id: Number(item.value),
                      name: item.display
                    })
                    return
                  }
                  setSelectedProfile(undefined)
                }}
              />
              <ChangeProfileButton
                className="add-button"
                type="button"
                onClick={() => {
                  const ids: number[] = fields.map(field => Number(field.id))
                  if (ids.includes(selectedProfile?.id ?? -1)) {
                    toast.error('Este perfil já está na lista.')
                    return
                  }
                  if (!selectedProfile) {
                    toast.error('Selecione um perfil.')
                    return
                  }
                  append(selectedProfile)
                  setSelectedProfile(undefined)
                  setValue('profile', undefined)
                }}
              >
                <MdAdd />
              </ChangeProfileButton>
            </SelectContainer>
          </>
        )}
        <DetailsButtons>
          <Button
            buttonType="primary"
            type="submit"
          >
            Salvar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={closeWrapper}
          >
            Cancelar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={async () => {
              if (typeof id === 'number') {
                handle(async () => {
                  confirm('Tem certeza que deseja excluir esse usuário?', async () => {
                    await httpDelete(`/users/${id}`)
                    closeWrapper()
                    toast.success('Usuário excluído com sucesso.')
                    refetch()
                  })
                })
              }
            }}
          >
            Excluir
          </Button>
        </DetailsButtons>
      </Form>
    </Container>
  )
}

export default UserDetails
