import React from 'react'
import Form from '../../../components/Form'
import Select from '../../../components/Select'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { useModal } from '../../../hooks'
import { format, isWithinInterval } from 'date-fns'
import { BiddingModel } from '../../../models/bidding'
import { getUniqueOptions } from '../../../utils/select-options'
import { Container } from './styles'
import { parseHyphenDate, parseSlashDate } from '../../../utils/date-parser'

interface FilterForm {
  filterType: string
  startDate: string
  endDate: string
  status: string
  cliente: string
  processo: string
  empresa: string
}

interface FilterProps {
  setFilter: (...args: any) => void
  data: BiddingModel[]
}

const Filter: React.FC<FilterProps> = ({
  setFilter,
  data
}) => {
  const methods = useForm<FilterForm>({})
  const { watch, register } = methods
  const { close } = useModal()
  const watchFilterType = watch('filterType')

  const renderField = (): JSX.Element => {
    const now = format(new Date(), 'yyyy-MM-dd')
    switch (watchFilterType) {
      case 'date':
        return (
          <>
            <Input
              label="Data inicial"
              name="startDate"
              register={register()}
              defaultValue={now}
              type="date"
            />
            <Input
              label="Data final"
              name="endDate"
              register={register()}
              defaultValue={now}
              type="date"
            />
          </>
        )
      case 'status':
        return <Select
          readonly
          label="Status"
          name="status"
          register={register()}
          options={getUniqueOptions(data, 'usu_stalic')}
        />
      case 'cliente':
        return <Select
          readonly
          label="Cliente"
          name="cliente"
          register={register()}
          options={getUniqueOptions(data, 'nomcli')}
        />
      case 'processo':
        return <Input
          label="Processo"
          name="processo"
          register={register()}
        />
      case 'empresa':
        return <Input
          label="Empresa"
          name="empresa"
          register={register()}
        />
      default:
        return <></>
    }
  }

  const handleSubmit = (data: FilterForm): void => {
    const {
      filterType,
      endDate,
      startDate,
      empresa,
      processo,
      status,
      cliente
    } = data
    switch (filterType) {
      case 'date':
        setFilter(() => (item: BiddingModel) => {
          const current = parseSlashDate(item.usu_DatEnt)
          const start = parseHyphenDate(startDate)
          const end = parseHyphenDate(endDate)
          return isWithinInterval(current, { start, end })
        })
        setFilter(() => () => true)
        break
      case 'cliente':
        setFilter(() => (item: BiddingModel) => {
          const value = cliente.toLowerCase()
          const search = item.nomcli.toLowerCase()
          return search.includes(value)
        })
        break
      case 'status':
        setFilter(() => (item: BiddingModel) => {
          const value = status.toLowerCase()
          const search = item.usu_stalic.toLowerCase()
          return search.includes(value)
        })
        break
      case 'processo':
        setFilter(() => (item: BiddingModel) => {
          return processo === item.usu_numlic
        })
        break
      case 'empresa':
        setFilter(() => (item: BiddingModel) => {
          return empresa === item.usu_codemp
        })
        break
      default:
        setFilter(() => () => true)
        break
    }
    close()
  }

  const handleClear = (): void => {
    setFilter(() => () => true)
    close()
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        methods={methods}
      >
        <Select
          readonly
          label="Filtrar por:"
          name="filterType"
          options={[{
            display: 'Data',
            value: 'date'
          }, {
            display: 'Cliente',
            value: 'cliente'
          }, {
            display: 'Nº do processo',
            value: 'processo'
          }, {
            display: 'Nº da empresa',
            value: 'empresa'
          }, {
            display: 'Status',
            value: 'status'
          }]}
          register={register()}
        />
        {renderField()}
        <Button
          buttonType="primary"
          type="submit"
        >
          Filtrar
        </Button>
        <Button
          buttonType="secondary"
          type="button"
          onClick={handleClear}
        >
          Limpar filtro
        </Button>
      </Form>
    </Container>
  )
}

export default Filter
