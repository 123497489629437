import React from 'react'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { useForm } from 'react-hook-form'
import { useModal } from '../../../../hooks'
import { format, addDays } from 'date-fns'
import { Bond } from '../../../../models/bond'
import { getUniqueOptions } from '../../../../utils/select-options'
import { Container } from './styles'

interface FilterForm {
  filterType: string
  startDate: string
  endDate: string
  empresa: string
  numeroTitulo: string
  fornecedor: string
}

interface FilterProps {
  setStringFilter: (...args: any) => void
  setFilter: (...args: any) => void
  data: Bond[]
}

const Filter: React.FC<FilterProps> = ({
  setFilter,
  setStringFilter,
  data
}) => {
  const methods = useForm<FilterForm>()
  const { watch, register } = methods
  const { close } = useModal()
  const watchFilterType = watch('filterType')

  const renderField = (): JSX.Element => {
    const now = format(new Date(), 'yyyy-MM-dd')
    switch (watchFilterType) {
      case 'date':
        return (
          <>
            <Input
              label="Data inicial"
              name="startDate"
              register={register()}
              defaultValue={now}
              type="date"
            />
            <Input
              label="Data final"
              name="endDate"
              register={register()}
              defaultValue={now}
              type="date"
            />
          </>
        )
      case 'empresa':
        return <Select
          readonly
          label="Empresa"
          name="empresa"
          register={register()}
          options={getUniqueOptions(data, 'nomEmp')}
        />
      case 'fornecedor':
        return <Select
          readonly
          label="Fornecedor"
          name="fornecedor"
          register={register()}
          options={getUniqueOptions(data, 'nomFor')}
        />
      case 'numeroTitulo':
        return <Input
          label="Nº do título"
          name="numeroTitulo"
          register={register()}
        />
      default:
        return <></>
    }
  }

  const handleSubmit = (data: FilterForm): void => {
    const {
      filterType,
      endDate,
      startDate,
      empresa,
      fornecedor,
      numeroTitulo
    } = data
    const dateFormat = 'dd-MM-yyyy'
    switch (filterType) {
      case 'date':
        setStringFilter(() => {
          const start = startDate.split('-').reverse().join('-')
          const end = endDate.split('-').reverse().join('-')
          return `${start}/${end}`
        })
        setFilter(() => () => true)
        break
      case 'empresa':
        setFilter(() => (item: Bond) => {
          const value = empresa.toLowerCase()
          const search = item.nomEmp.toLowerCase()
          return search.includes(value)
        })
        break
      case 'fornecedor':
        setFilter(() => (item: Bond) => {
          const value = fornecedor.toLowerCase()
          const search = item.nomFor.toLowerCase()
          return search.includes(value)
        })
        break
      case 'numeroTitulo':
        setFilter(() => (item: Bond) => {
          return numeroTitulo === item.numTit
        })
        break
      default:
        setStringFilter(() => {
          const today = format(new Date(), dateFormat)
          const tomorrow = format(addDays(new Date(), 1), dateFormat)
          return `${today}/${tomorrow}`
        })
        setFilter(() => () => true)
        break
    }
    close()
  }

  const handleClear = (): void => {
    setFilter(() => () => true)
    close()
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        methods={methods}
      >
        <Select
          readonly
          label="Filtrar por:"
          name="filterType"
          options={[{
            display: 'Data',
            value: 'date'
          }, {
            display: 'Empresa',
            value: 'empresa'
          }, {
            display: 'Nº do título',
            value: 'numeroTitulo'
          }, {
            display: 'Fornecedor',
            value: 'fornecedor'
          }]}
          register={register()}
        />
        {renderField()}
        <Button
          buttonType="primary"
          type="submit"
        >
          Filtrar
        </Button>
        <Button
          buttonType="secondary"
          type="button"
          onClick={handleClear}
        >
          Limpar filtro
        </Button>
      </Form>
    </Container>
  )
}

export default Filter
