import React from 'react'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { useForm } from 'react-hook-form'
import { useModal } from '../../../../hooks'
import { getUniqueOptions } from '../../../../utils/select-options'
import { Container } from './styles'
import { RdApprovalsModel } from '../../../../models/rdapprovals'

interface FilterForm {
  filterType: string
  nomeEmpresa: string
  codigoCCU: string
  numeroRD: string
}

interface FilterProps {
  setFilter: (...args: any) => void
  data: RdApprovalsModel[]
}

const Filter: React.FC<FilterProps> = ({
  setFilter,
  data
}) => {
  const methods = useForm<FilterForm>({})
  const { watch, register } = methods
  const { close } = useModal()
  const watchFilterType = watch('filterType')

  const renderField = (): JSX.Element => {
    switch (watchFilterType) {
      case 'nomeEmpresa':
        return <Select
          readonly
          label="Empresa"
          name="nomeEmpresa"
          register={register()}
          options={getUniqueOptions(data, 'nomeEmpresa')}
        />
      case 'codigoCCU':
        return <Input
          label="Código CCU"
          name="codigoCCU"
          register={register()}
        />
      case 'numeroRD':
        return <Input
          label="Número RD"
          name="numeroRD"
          register={register()}
        />
      default:
        return <></>
    }
  }

  const handleSubmit = (data: FilterForm): void => {
    const {
      filterType,
      nomeEmpresa,
      numeroRD,
      codigoCCU
    } = data
    switch (filterType) {
      case 'nomeEmpresa':
        setFilter(() => (item: RdApprovalsModel) => {
          const value = nomeEmpresa.toLowerCase()
          const search = item.nomeEmpresa.toLowerCase()
          return search.includes(value)
        })
        break
      case 'codigoCCU':
        setFilter(() => (item: RdApprovalsModel) => {
          return codigoCCU === item.codigoCCU
        })
        break
      case 'numeroRD':
        setFilter(() => (item: RdApprovalsModel) => {
          return numeroRD === item.RD
        })
        break
      default:
        setFilter(() => () => true)
        break
    }
    close()
  }

  const handleClear = (): void => {
    setFilter(() => () => true)
    close()
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        methods={methods}
      >
        <Select
          readonly
          label="Filtrar por:"
          name="filterType"
          options={[{
            display: 'Empresa',
            value: 'nomeEmpresa'
          }, {
            display: 'Código CCU',
            value: 'codigoCCU'
          }, {
            display: 'Nº da RD',
            value: 'numeroRD'
          }
          ]}
          register={register()}
        />
        {renderField()}
        <Button
          buttonType="primary"
          type="submit"
        >
          Filtrar
        </Button>
        <Button
          buttonType="secondary"
          type="button"
          onClick={handleClear}
        >
          Limpar filtro
        </Button>
      </Form>
    </Container>
  )
}

export default Filter
