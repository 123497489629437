/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import Label from '../../../components/Label'
import { CallButton, Container, DivButton } from './styles'
import { CallRegistrationModel } from '../../../models/call-registration'
import { useApi, useFetch, useLoading, useModal } from '../../../hooks'
import IconButton from '../../../components/IconButton'
import { MdSearch } from 'react-icons/md'
import Loader from '../../../components/Loader'
import CategoryModal from '../CategoryModal'
import UserOpeningModal from '../UserOpeningModal'
import UserAttendantModal from '../UserAttendantModal'
import CompanyModal from '../CompanyModal'
import SectorModal from '../SectorModal'
import CloseCallModal from '../CloseCallModal'
import ParentCallModal from '../ParentCallModal'

interface CallRegistrationDetailsProps {
  refetch: () => void
  item: CallRegistrationModel
}

const CallRegistrationDetails: React.FC<CallRegistrationDetailsProps> = ({
  refetch,
  item
}) => {
  const { open: openModal } = useModal()
  const { httpGet } = useApi()

  const {
    loading,
    data,
    refetchDetails,
    refetching
  } = useFetch<CallRegistrationModel[]>(httpGet, '/call-registration/list-calls')
  const { loading: loadingHook } = useLoading()

  const openUserOpeningModal = useCallback((data: CallRegistrationModel): () => void => {
    return () => {
      openModal(
        <UserOpeningModal
          data={data}
          refetchDetails={refetchDetails}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openUserAttendantModal = useCallback((data: CallRegistrationModel): () => void => {
    return () => {
      openModal(
        <UserAttendantModal
          data={data}
          refetchDetails={refetchDetails}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openCompanyModal = useCallback((data: CallRegistrationModel): () => void => {
    return () => {
      openModal(
        <CompanyModal
          data={data}
          refetchDetails={refetchDetails}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openCategoryModal = useCallback((data: CallRegistrationModel): () => void => {
    return () => {
      openModal(
        <CategoryModal
          data={data}
          refetch={refetch}
          refetchDetails={refetchDetails}
        />
      )
    }
  }, [openModal, refetch])

  const openSectorModal = useCallback((data: CallRegistrationModel): () => void => {
    return () => {
      openModal(
        <SectorModal
          data={data}
          refetch={refetch}
          refetchDetails={refetchDetails}
        />
      )
    }
  }, [openModal, refetch, refetchDetails])

  const openCloseCallModal = useCallback((data: CallRegistrationModel): () => void => {
    return () => {
      openModal(
        <CloseCallModal
          data={data}
          refetch={refetch}
          refetchDetails={refetchDetails}
        />
      )
    }
  }, [openModal, refetch, refetchDetails])

  const openParentModal = useCallback((data: CallRegistrationModel): () => void => {
    return () => {
      openModal(
        <ParentCallModal
          data={data}
          refetch={refetch}
          refetchDetails={refetchDetails}
        />
      )
    }
  }, [openModal, refetch, refetchDetails])

  return (
    <Container>
      <Loader
        loading={loading || refetching || loadingHook}
        message={loadingHook ? 'Processando...' : undefined}
      >
        {data?.filter((data) => (data.usu_codcham === item.usu_codcham)).map((data) => (
          <>
            <div className="info" >
              <section>
                <Label
                  title="Código Chamado"
                  value={data.usu_codcham}
                />
                <Label
                  title="Categoria"
                  value={`${data.usu_catcham} - ${data.usu_descat}`}
                  IconButton={
                    <IconButton
                      icon={<MdSearch />}
                      padding={5}
                      size={16}
                      onClick={openCategoryModal(data)}
                    />
                  }
                />
                <Label
                  title="Usuário Abertura"
                  value={`${data.usu_codusuabe} - ${data.usu_nomusuabe}`}
                  IconButton={
                    <IconButton
                      icon={<MdSearch />}
                      padding={5}
                      size={16}
                      onClick={openUserOpeningModal(data)}
                    />
                  }
                />
                <Label
                  title="Chamado"
                  value={data.usu_descatv}
                />
                <Label
                  title="Data Abertura"
                  value={data.usu_datcham}
                />
              </section>
              <section>
                <Label
                  title="Empresa"
                  value={`${data.usu_empcham} - ${data.usu_desemp}`}
                  IconButton={
                    <IconButton
                      icon={<MdSearch />}
                      padding={5}
                      size={16}
                      onClick={openCompanyModal(data)}
                    />
                  }
                />
                <Label
                  title="Atendente"
                  value={`${data.usu_codate} - ${data.usu_nomate}`}
                  IconButton={
                    <IconButton
                      icon={<MdSearch />}
                      padding={5}
                      size={16}
                      onClick={openUserAttendantModal(data)}
                    />
                  }
                />
                <Label
                  title="Chamado Pai"
                  value={data.usu_champai}
                  IconButton={
                    <IconButton
                      icon={<MdSearch />}
                      padding={5}
                      size={16}
                      onClick={openParentModal(data)}
                    />
                  }
                />
                <Label
                  title="Hora Abertura"
                  value={data.usu_horcham}
                />
                <Label
                  title="Setor"
                  value={`${data.usu_setcham} - ${data.usu_desset}`}
                  IconButton={
                    <IconButton
                      icon={<MdSearch />}
                      padding={5}
                      size={16}
                      onClick={openSectorModal(data)}
                    />
                  }
                />
              </section>
            </div>
            <DivButton>
              {/* <CallButton
                buttonType="primary"
                fullWidth={false}
                type="button"
                onClick={openUserAttendantModal(data)}
              >
                Assumir Chamado
              </CallButton> */}

              {data.usu_codate !== '0'
                ? <CallButton
                  buttonType="primary"
                  fullWidth={false}
                  type="button"
                  onClick={openCloseCallModal(data)}
                >
                  Encerrar Chamado
                </CallButton>
                : <></>}
            </DivButton>
          </>
        ))}
      </Loader>
    </Container>
  )
}

export default CallRegistrationDetails
