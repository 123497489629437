import React, { useCallback } from 'react'
import {
  MdCheckCircle,
  MdCancel,
  MdSearch,
  MdRefresh,
  MdUndo
} from 'react-icons/md'
import OrderDetails from './OrderDetails'
import ShippingCompanyModal from './ShippingCompanyModal'
import CifModal from './CifModal'
import IconButton from '../../components/IconButton'
import Loader from '../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../components/Table'
import { useHistory } from 'react-router-dom'
import {
  useApi,
  useFetch,
  useDetailsContainer,
  useModal
} from '../../hooks'
import { toast } from 'react-toastify'
import { TransportOrder } from '../../models/transport-order'
import { handle } from '../../utils/error-handlers'
import { Container, Header } from './styles'

const Expedition: React.FC = () => {
  const { httpGet, httpPatch } = useApi()
  const {
    data,
    loading,
    refetch,
    refetching
  } = useFetch<TransportOrder[]>(httpGet, '/expedition/list-pending')
  const {
    open,
    close,
    pushPage
  } = useDetailsContainer()
  const { replace } = useHistory()
  const { open: openModal, confirm } = useModal()

  const openCifModal = useCallback((data: TransportOrder): () => void => {
    return () => {
      openModal(
        <CifModal
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openShippingCompanyModal = useCallback((data: TransportOrder): () => void => {
    return () => {
      openModal(
        <ShippingCompanyModal
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const handleUpdateStatus = useCallback((data: TransportOrder, status: number): () => void => {
    return () => {
      const currentStep = Number(data.usu_staped)
      let usuStaPed = status
      if (status < currentStep) {
        toast.warn('Só é possível editar o status atual.')
        return
      }
      if (status > currentStep && status - 1 !== currentStep) {
        toast.warn('Não é possível pular etapas.')
        return
      }
      if (status === currentStep) {
        usuStaPed = status - 1
      }
      confirm('Continuar com a atualização do status?', () => {
        handle(async () => {
          const response = await httpPatch<string>(`/expedition/status/${data.numPed}`, {
            codemp: data.codEmp,
            codFil: data.codFil,
            usu_StaPed: usuStaPed
          })
          toast.success(response.body)
          refetch()
        })
      })
    }
  }, [httpPatch, confirm, refetch])

  const handleRehabilitate = useCallback((item: TransportOrder): () => void => {
    return () => {
      confirm('Reabilitar pedido?', () => {
        handle(async () => {
          const response = await httpPatch<string>(`/expedition/rehabilitate/${item.numPed}`, {
            codEmp: item.codEmp,
            codFil: item.codFil
          })
          toast.success(response.body)
          refetch()
        })
      })
    }
  }, [confirm, httpPatch, refetch])

  const renderStatus = useCallback((item: TransportOrder, step: number): JSX.Element => {
    const currentStep = Number(item.usu_staped)
    return <IconButton
      icon={step <= currentStep ? <MdCheckCircle color="#33b850" /> : <MdCancel color="#ff5555" />}
      padding={3}
      size={20}
      onClick={handleUpdateStatus(item, step)}
    />
  }, [handleUpdateStatus])

  const openDetails = useCallback((data: TransportOrder): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <OrderDetails
            data={data}
            rehabilitateOrder={handleRehabilitate}
            renderStatus={renderStatus}
            openShippingCompanyModal={openShippingCompanyModal}
            openCifModal={openCifModal}
          />
        ),
        title: 'Pedido',
        onClose: () => {
          replace('/expedicao')
        }
      })
      open()
    }
  }, [close, pushPage, open, replace, openShippingCompanyModal, openCifModal, renderStatus, handleRehabilitate])

  return (
    <>
      <Header>
        <IconButton
          icon={<MdRefresh />}
          padding={5}
          size={30}
          onClick={refetch}
        />
      </Header>
      <Container>
        <Loader loading={loading || refetching}>
          <Table className="expedition-table" overflow="auto">
            <THead>
              <Tr>
                <Th width="90px" color="#000">Nº pedido</Th>
                <Th width="90px" color="#000">Nº Empresa</Th>
                <Th width="120px" color="#000">Data entrega</Th>
                <Th width="100px" color="#000">Frete</Th>
                <Th color="#000">Transportadora</Th>
                <Th className="hide-sm" color="#000">Cliente</Th>
                <Th className="hide-sm" color="#000" width="120px">Vendedor</Th>
                <Th className="hide-sm" color="#000">Observação</Th>
                <Th
                  className="hide-sm"
                  color="#000"
                  width="70px"
                  textAlign="center"
                >
                  Reabilitar
                </Th>
                <Th
                  textAlign="center"
                  width="70px"
                  className="hide-md"
                  color="#000"
                >
                  Pedido liberado para separaçao
                </Th>
                <Th
                  textAlign="center"
                  width="95px"
                  className="hide-md"
                  color="#000"
                >
                  Pedido separado, aguardando faturamento
                </Th>
                <Th
                  textAlign="center"
                  width="95px"
                  className="hide-md"
                  color="#000"
                >
                  Pedido faturado, aguardando carregamento
                </Th>
                <Th
                  textAlign="center"
                  width="95px"
                  className="hide-md"
                  color="#000"
                >
                  Pedido carregado, a caminho do cliente
                </Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map(item => (
                <Tr key={item.numPed}>
                  <Td title={item.numPed}>
                    <button onClick={openDetails(item)}>{item.numPed}</button>
                  </Td>
                  <Td title={item.codEmp}>{item.codEmp}</Td>
                  <Td title={item.dataEntrega}>{item.dataEntrega}</Td>
                  <Td title={item.vlrFrd} removeNoWrap>
                    {item.vlrFrd}
                    &nbsp;
                    <IconButton
                      icon={<MdSearch />}
                      padding={5}
                      size={16}
                      onClick={openCifModal(item)}
                    />

                  </Td>
                  <Td title={item.nomTra} removeNoWrap>
                    {item.nomTra}
                    &nbsp;
                    <IconButton
                      icon={<MdSearch />}
                      padding={5}
                      size={16}
                      onClick={openShippingCompanyModal(item)}
                    />
                  </Td>
                  <Td
                    title={item.nomCli}
                    className="hide-sm"
                    removeNoWrap
                  >
                    {item.nomCli}
                  </Td>
                  <Td
                    title={item.nomVen}
                    className="hide-sm"
                    removeNoWrap
                  >
                    {item.nomVen}
                  </Td>
                  <Td title={item.obsPed} className="hide-sm">{item.obsPed}</Td>
                  <Td textAlign="center" className="hide-sm">
                    <IconButton
                      icon={<MdUndo />}
                      padding={5}
                      size={20}
                      onClick={handleRehabilitate(item)}
                    />
                  </Td>
                  <Td textAlign="center" className="hide-md">{renderStatus(item, 1)}</Td>
                  <Td textAlign="center" className="hide-md">{renderStatus(item, 2)}</Td>
                  <Td textAlign="center" className="hide-md">{renderStatus(item, 3)}</Td>
                  <Td textAlign="center" className="hide-md">{renderStatus(item, 4)}</Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default Expedition
