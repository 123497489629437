import React, {
  useRef,
  RefObject
} from 'react'
import { MdAdd, MdSearch } from 'react-icons/md'
import { Container } from './styles'

interface FilterProps {
  inputRef?: RefObject<HTMLInputElement>
  input?: JSX.Element
  onClickAdd?: () => void
}

const Filter: React.FC<FilterProps> = ({
  inputRef,
  input,
  onClickAdd
}) => {
  const ref = inputRef ?? useRef<HTMLInputElement>(null)
  const inputElement = input ?? <input ref={ref} />

  return (
    <Container>
      <div
        className="filter-input"
        onClick={() => {
          ref.current?.focus()
        }}
      >
        <MdSearch />
        {inputElement}
      </div>
      <div className="filter-add-button">
        <button onClick={onClickAdd}>
          <MdAdd />
        </button>
      </div>
    </Container>
  )
}

export default Filter
