import { Action } from '../types'
import { DetailsContainerActions } from './details-container-actions'

export interface Content {
  title: string
  component: JSX.Element | null
  data?: any
  onClose?: () => void
}

export interface InitialState {
  opened: boolean
  content: Content[]
  fullScreen: boolean
  mobile: boolean
}

const initialState: InitialState = {
  opened: false,
  content: [],
  fullScreen: false,
  mobile: false
}

export const detailsContainerReducer = (
  state = initialState,
  action: Action
): InitialState => {
  switch (action.type) {
    case DetailsContainerActions.SET_OPENED:
      return { ...state, opened: action.opened }
    case DetailsContainerActions.PUSH_PAGE:
      return {
        ...state,
        content: [
          ...state.content,
          action.data.content
        ]
      }
    case DetailsContainerActions.SET_CONTENT:
      return { ...state, ...action.data }
    case DetailsContainerActions.SET_FULL_SCREEN:
      return { ...state, fullScreen: action.fullScreen }
    case DetailsContainerActions.SET_MOBILE:
      return { ...state, mobile: action.mobile }
    case DetailsContainerActions.CLOSE:
      return {
        ...state,
        content: state.content.slice(0, state.content.length - 1)
      }
    case DetailsContainerActions.CLEAR:
      return { ...initialState }
    default:
      return state
  }
}
