import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { LogisticModel } from '../../../models/logistic'
import SelectApi from '../../../components/Select/api'

interface DriverModalProps {
  data: LogisticModel
  refetch: () => void
  refetchDetails: () => void
}

interface DriverForm {
  codmtr: string
}

const DriverModal: React.FC<DriverModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<DriverForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: DriverForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>(`/logistic/update-driver/${data.usu_numped}/${data.usu_codemp}/${data.usu_seqipd}`, {
        codemp: data.usu_codemp,
        codfil: data.usu_codfil,
        codmot: formData.codmtr,
        numped: data.usu_numped,
        seqipd: data.usu_seqipd,
        tipo: data.usu_tipo
      })
      toast.success(response.body)
      close()
      refetch()
      refetchDetails()
    })
  }

  return (
    <div>
      <h3>Atualizar Motorista</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="nommot"
          readonly={false}
          label="Motorista"
          name="codmtr"
          register={register({
          })}
          url={`logistic/list-driver/${data.codtra}`}
          valueProperty="codmtr"
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default DriverModal
