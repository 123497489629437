import React, { useState } from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'
import { Div } from './styles'
import { H4 } from './styles'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import IconButton from '../../../components/IconButton'
import { MdDelete } from 'react-icons/md'
import { addDays, format } from 'date-fns'
import Textarea from '../../../components/Textarea'
interface InsertOrderProps {
  data: any
  refetch: () => void
  openGridModal: (codcli: string, codemp: string, codfil: string, setSelectedDefault: any) => () => void
}

interface InsertOrderForm{
  codcli: string
  codcpg: string
  codfpg: string
  codven: string
  codemp: string
  codfil: string
  codrep: string
  codtpr: string
  entcli: string
  numPed: string
  preuni: number
  qtdped: number
  obsPed: string
  somfre: string
  tipfre: string
  tnsPro: string
}
const InsertOrder: React.FC<InsertOrderProps> = ({
  data,
  refetch,
  openGridModal
}) => {
  const methods = useForm<InsertOrderForm>({
  })

  const codemp = methods.watch('codemp')
  const codfil = methods.watch('codfil')
  const codcli = methods.watch('codcli')
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()
  const [clientDefault, setClientDefault] = useState<any>()
  const [, setItensDefault] = useState([])
  const [selectedDefault, setSelectedDefault] = useState([])
  const [loading, setLoading] = useState(false)
  const [radioButton, setRadioButton] = useState('')
  const [radioButtonT, setRadioButtonT] = useState('')

  const handleSendDataCodEmp = async (formData: InsertOrderForm): Promise<void> => {
    await handle(async () => {
      // const codemp = formData.codemp
    })
  }

  const handleSendDataCodFil = async (formData: InsertOrderForm): Promise<void> => {
    await handle(async () => {
      // const codfil = formData.codfil
    })
  }

  const handleDelete = (index: number): void => {
    setSelectedDefault(selectedDefault.filter((item, i) => index !== i))
  }

  const handleSendDataCodCli = async (formData: InsertOrderForm): Promise<void> => {
    await handle(async () => {
      // const codcli = formData.codcli
    })
  }

  const handleSendData = async (formData: InsertOrderForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        codCli: codcli,
        codCpg: formData.codcpg,
        codFpg: formData.codfpg,
        codVen: formData.codven,
        codemp: codemp,
        codfil: codfil,
        codrep: formData.codrep,
        codtpr: formData.codtpr,
        entcli: format(addDays(new Date(formData.entcli), 1), 'dd/MM/yyyy'),
        numPed: '',
        obsPed: formData.obsPed,
        somfre: radioButton,
        tipfre: radioButtonT,
        tnsPro: '90100',
        orcIte: selectedDefault?.map(({
          codccu,
          coddep,
          qtdped,
          preuni,
          despro,
          seqipd,
          numped,
          codpro
        }) => ({
          codCcu: codccu,
          codDep: coddep,
          codPro: codpro,
          preuni: preuni ? String(preuni).replace('.', ',') : '',
          qtdPed: parseFloat(qtdped),
          ORC_numped: numped,
          ORC_Seqipd: seqipd,
          seqIpd: ''
        })
        )
      }
      const response = await httpPost<string>('/salesorder/insert-sale-order', json)
      toast.info(response.body)

      if (response.body === 'OK') {
        clearData()
      }
      setLoading(false)
      close()
      refetch()
    })
  }
  return (
    <div>
      <Form methods={methods} onSubmit={handleSendDataCodEmp}>
        <SelectApi
          displayProperty="nomemp"
          readonly={false}
          label="Empresa"
          name="codemp"
          register={register({
          })}
          url="salesorder/list-companies"
          valueProperty="codemp"
        />
      </Form>
      <Form methods={methods} onSubmit={handleSendDataCodFil}>
        { codemp
          ? <SelectApi
            displayProperty="nomfil"
            readonly={false}
            label="Filial"
            name="codfil"
            register={register({
            })}
            url={`/salesorder/list-branch/${codemp}`}
            valueProperty="codfil"
          />
          : <></>
        }
      </Form>
      <Form methods={methods} onSubmit={handleSendDataCodCli}>
        { codfil
          ? <SelectApi
            displayProperty="nomcli"
            readonly={false}
            label="Cliente"
            name="codcli"
            register={register({
            })}
            url={`/salesorder/search-client/${codemp}/${codfil}`}
            valueProperty="codcli"
            onSelect={(item) => {
              if (item) {
                setClientDefault(item.data)
              }
            }}
          />
          : <></>
        }
      </Form>
      <Form methods={methods} onSubmit={handleSendData} >
        { codemp && codcli && codfil
          ? <Input
            type='date'
            label="Entrada Cliente"
            name="entcli"
            register={register({
            })}
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="nomrep"
            readonly={false}
            defaultValue={clientDefault?.codrep}
            label="Representante"
            name="codrep"
            register={register({
            })}
            url={`/salesorder/list-agent/${codemp}/`}
            valueProperty="codrep"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="descpg"
            readonly={false}
            defaultValue={clientDefault?.codcpg}
            label="Condição de Pagamento"
            name="codcpg"
            register={register({
            })}
            url={`/salesorder/list-payment-condition/${codcli}/${codemp}/${codfil}`}
            valueProperty="codcpg"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="desfpg"
            readonly={false}
            defaultValue={clientDefault?.codfpg}
            label="Forma de Pagamento"
            name="codfpg"
            register={register({
            })}
            url={`/salesorder/list-payment-form/${codemp}`}
            valueProperty="codfpg"
            onSelect={(item) => {
              if (item) {
                setItensDefault(item.data)
              }
            }}
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Div>
            <ul>
              <H4>Tipo Frete {radioButtonT}</H4>
              <div>
                <input id="tipfre" type="radio" name="tipfre" onClick={ () => setRadioButtonT('C')} />
                <label htmlFor="tipfre">CIF</label>
              </div>
              <div>
                <input id="tipfre"type="radio" name="tipfre" onClick={ () => setRadioButtonT('F')}/>
                <label htmlFor="tipfre">FOB</label>
              </div>
            </ul>
          </Div>
          : <></>
        }
        {}
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="nomven"
            defaultValue={clientDefault?.codven}
            readonly={false}
            label="Vendedor"
            name="codven"
            register={register({
            })}
            url={`/salesorder/list-seller/${codemp}/`}
            valueProperty="codven"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Div>
            <ul>
              <H4>Soma Frete {radioButton}</H4>
              <div>
                <input id="somfre" type="radio" name="somfre" onClick={ () => setRadioButton('S')}/>
                <label htmlFor="somfre">SIM</label>
              </div>
              <div>
                <input id="somfre"type="radio" name="somfre" onClick={ () => setRadioButton('N')}/>
                <label htmlFor="somfre">NÃO</label>
              </div>
            </ul>
          </Div>
          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="destpr"
            defaultValue={clientDefault?.codtpr}
            readonly={false}
            label="Tabela de Produtos"
            name="codtpr"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
            url={`/salesorder/list-tables/${codemp}/`}
            valueProperty="codtpr"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Textarea
            label="Observação Pedido"
            name="obsPed"
            register={register({
            })}
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Table layout="fixed">
            <THead>
              <Tr>
                <Th color="black" width="50px" >Produto</Th>
                <Th color="black" width="40px" >Desc</Th>
                <Th color="black" width="50px" >C.Custo</Th>
                <Th color="black" width="40px" >Qtde</Th>
                <Th color="black" width="40px" >Dep</Th>
                <Th color="black" width="40px" >Preço</Th>
                <Th color="black" width="40px" >Total</Th>
                <Th color="black" width="30px" ></Th>
              </Tr>
            </THead>
            <TBody>
              {selectedDefault?.map(({
                coddep,
                codemp,
                codfil,
                codpro,
                despro,
                codccu,
                numped,
                preuni,
                qtdped,
                total
              }, index) =>
                <Tr key={ index }>
                  <Td> {codpro} </Td>
                  <Td> {despro} </Td>
                  <Td> {codccu} </Td>
                  <Td> {qtdped} </Td>
                  <Td> {coddep} </Td>
                  <Td> {preuni} </Td>
                  <Td> {total} </Td>
                  <Td>
                    <IconButton
                      padding={2}
                      size={20}
                      icon={<MdDelete />}
                      onClick={() => {
                        handleDelete(index)
                      }}
                    />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          : <> </> }
        <br/>
        { codemp && codcli && codfil
          ? <Button
            buttonType="primary"
            type="submit"
            disabled={loading}
          >
          Adicionar
          </Button>
          : <></> }
        <span style={{ display: 'inline-block', width: '5px' }} />
        { codemp && codcli && codfil
          ? <Button
            buttonType="secondary"
            type="button"
            onClick={openGridModal(codcli, codemp, codfil, setSelectedDefault)}
          >
          Adicionar Item
          </Button>
          : <></> }
      </Form>
    </div>
  )
}

export default InsertOrder
