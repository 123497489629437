import React, { useState } from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'
import { Div } from './styles'
import { H4 } from './styles'
import { SalesOrderModel } from '../../../models/salesorder'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import IconButton from '../../../components/IconButton'
import { MdDelete } from 'react-icons/md'
import { addDays } from 'date-fns'
import Textarea from '../../../components/Textarea'
import Select from '../../../components/Select'
interface InsertBudgetProps {
  data: any
  refetch: () => void
  openItemModal: (data: SalesOrderModel, codtpr: string, codcli: string, codemp: string, ciffob: string, somfre: string, redpac: string, setItensDefault: any, itensDefault: any) => () => void
}

interface InsertBudgetForm{
  codcli: string
  codcpg: string
  codfpg: string
  codven: string
  nomven: string
  nomrep: string
  descpg: string
  desfpg: string
  codemp: string
  codfil: string
  codrep: string
  codtpr: string
  entcli: string
  numPed: string
  obsPed: string
  somfre: string
  tipfre: string
  tnsPro: string
  redpac: string
}
const InsertBudget: React.FC<InsertBudgetProps> = ({
  data,
  openItemModal,
  refetch
}) => {
  const methods = useForm<InsertBudgetForm>({
  })
  const codemp = methods.watch('codemp')
  const codtpr = methods.watch('codtpr')
  const codfil = methods.watch('codfil')
  const codcli = methods.watch('codcli')
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()
  const [clientDefault, setClientDefault] = useState<any>()
  const [itensDefault, setItensDefault] = useState([])
  const [loading, setLoading] = useState(false)
  const [radioButton, setRadioButton] = useState('')
  const [radioButtonT, setRadioButtonT] = useState('')
  const [radioButtonD, setRadioButtonD] = useState('')

  const handleSendDataCodEmp = async (formData: InsertBudgetForm): Promise<void> => {
    await handle(async () => {
      // const codemp = formData.codemp
    })
  }

  const handleSendDataCodFil = async (formData: InsertBudgetForm): Promise<void> => {
    await handle(async () => {
      // const codfil = formData.codfil
    })
  }

  const handleDelete = (index: number): void => {
    setItensDefault(itensDefault.filter((item, i) => index !== i))
  }

  const handleSendDataCodCli = async (formData: InsertBudgetForm): Promise<void> => {
    await handle(async () => {
      // const codcli = formData.codcli
    })
  }

  const splitNameCnpj = (client: string): [string, string] => {
    const namecnpj = client.substring(client.indexOf('-') + 2)
    const name = namecnpj.substring(0, namecnpj.indexOf('-') - 1)
    const cnpj = namecnpj.substring(namecnpj.indexOf('-') + 2).replace(/[^0-9]/g, '')
    return [name, cnpj]
  }

  const handleSendData = async (formData: InsertBudgetForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const [clientName, cnpj] = splitNameCnpj(clientDefault.nomcli)
      const entcli = addDays(new Date(formData.entcli), 1).toISOString()
      const json = {
        clientName,
        cnpj,
        entcli,
        codCli: codcli,
        codCpg: formData.codcpg,
        codFpg: formData.codfpg,
        codVen: formData.codven,
        codemp: codemp,
        codfil: codfil,
        codrep: formData.codrep,
        codtpr: formData.codtpr,
        numPed: formData.numPed,
        obsPed: formData.obsPed,
        somfre: radioButton,
        tipfre: radioButtonT,
        redpac: radioButtonD,
        tnsPro: formData.tnsPro,
        orcIte: itensDefault
      }
      const response = await httpPost<any>('/salesorder/insert-budget', json)
      toast.info(response.body?.msgRet)
      toast.info(response.body?.msgretpro)
      toast.info(response.body?.msgNectar)
      if (response.body === 'OK') {
        clearData()
      }
      setLoading(false)
      close()
      refetch()
    })
  }

  return (
    <div>
      <Form methods={methods} onSubmit={handleSendDataCodEmp}>
        <SelectApi
          displayProperty="nomemp"
          readonly={false}
          label="Empresa"
          name="codemp"
          register={register({})}
          url="salesorder/list-companies"
          valueProperty="codemp"
        />
      </Form>
      <Form methods={methods} onSubmit={handleSendDataCodFil}>
        { codemp
          ? <SelectApi
            displayProperty="nomfil"
            readonly={false}
            label="Filial"
            name="codfil"
            register={register({})}
            url={`/salesorder/list-branch/${codemp}`}
            valueProperty="codfil"
          />
          : <></>
        }
      </Form>
      <Form methods={methods} onSubmit={handleSendDataCodCli}>
        { codfil
          ? <SelectApi
            displayProperty="nomcli"
            readonly={false}
            label="Cliente"
            name="codcli"
            register={register({})}
            url={`/salesorder/search-client/${codemp}/${codfil}`}
            valueProperty="codcli"
            onSelect={(item) => {
              if (item) {
                setClientDefault(item.data)
              }
            }}
          />
          : <></>
        }
      </Form>
      <Form methods={methods} onSubmit={handleSendData} >
        { codemp && codcli && codfil
          ? <Input
            label="Número do Pedido"
            name="numPed"
            register={register({})}
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Input
            type='date'
            label="Entrada do Cliente"
            name="entcli"
            register={register({
              required: {
                message: 'Preencha esse campo',
                value: true
              }
            })}
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Select
            readonly
            label="Transação"
            name="tnsPro"
            options={[{
              display: '90101 - Orçamento ',
              value: '90101'
            }, {
              display: '90110 - Amostra',
              value: '90110'
            }
            ]}
            register={register({
              required: {
                message: 'Preencha esse campo',
                value: true
              }
            })}
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="nomrep"
            defaultValue={clientDefault?.codrep}
            readonly={false}
            label="Representante"
            name="codrep"
            register={register({
              required: {
                message: 'Preencha esse campo',
                value: true
              }
            })}
            url={`/salesorder/list-agent/${codemp}/`}
            valueProperty="codrep"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="descpg"
            defaultValue={clientDefault?.codcpg}
            readonly={false}
            label="Condição de Pagamento"
            name="codcpg"
            register={register({
              required: {
                message: 'Preencha esse campo',
                value: true
              }
            })}
            url={`/salesorder/list-payment-condition/${codcli}/${codemp}/${codfil}`}
            valueProperty="codcpg"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="desfpg"
            defaultValue={clientDefault?.codfpg}
            readonly={false}
            label="Forma de Pagamento"
            name="codfpg"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
            url={`/salesorder/list-payment-form/${codemp}`}
            valueProperty="codfpg"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Div>
            <ul>
              <H4>Tipo Frete {radioButtonT}</H4>
              <div>
                <input id="tipfre" type="radio" name="tipfre" onClick={ () => setRadioButtonT('C')}/>
                <label htmlFor="tipfre">CIF</label>
              </div>
              <div>
                <input id="tipfre"type="radio" name="tipfre" onClick={ () => setRadioButtonT('F')}/>
                <label htmlFor="tipfre">FOB</label>
              </div>
            </ul>
          </Div>
          : <></>
        }

        { codemp && codcli && codfil
          ? <Div>
            <ul>
              <H4>Soma Frete {radioButton}</H4>
              <div>
                <input id="somfre" type="radio" name="somfre" onClick={ () => setRadioButton('S')}/>
                <label htmlFor="somfre">SIM</label>
              </div>
              <div>
                <input id="somfre"type="radio" name="somfre" onClick={ () => setRadioButton('N')}/>
                <label htmlFor="somfre">NÃO</label>
              </div>
            </ul>
          </Div>

          : <></>
        }
        { codemp && codcli && codfil
          ? <Div>
            <ul>
              <H4>Redespacho {radioButtonD}</H4>
              <div>
                <input id="redpac" type="radio" name="redpac" onClick={ () => setRadioButtonD('S')}/>
                <label htmlFor="redpac">SIM</label>
              </div>
              <div>
                <input id="redpac"type="radio" name="redpac" onClick={ () => setRadioButtonD('N')}/>
                <label htmlFor="redpac">NÃO</label>
              </div>
            </ul>
          </Div>

          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="nomven"
            defaultValue={clientDefault?.codven}
            readonly={false}
            label="Vendedor"
            name="codven"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
            url={`/salesorder/list-seller/${codemp}/`}
            valueProperty="codven"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <SelectApi
            displayProperty="destpr"
            defaultValue={clientDefault?.codtpr}
            readonly={false}
            label="Tabela de Produtos"
            name="codtpr"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
            url={`/salesorder/list-tables/${codemp}/`}
            valueProperty="codtpr"
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Textarea
            label="Observação Pedido"
            name="obsPed"
            register={register({
            })}
          />
          : <></>
        }
        { codemp && codcli && codfil
          ? <Table layout="fixed">
            <THead>
              <Tr>
                <Th color="black" width="80px" >Produto</Th>
                <Th color="black" width="80px" >Desc</Th>
                <Th color="black" width="70px" >Centro.C</Th>
                <Th color="black" width="40px" >Qtde</Th>
                <Th color="black" width="60px" >Deposito</Th>
                <Th color="black" width="40px" >Preço</Th>
                <Th color="black" width="40px" >Total</Th>
                <Th color="black" width="30px" ></Th>
              </Tr>
            </THead>
            <TBody>
              {itensDefault?.map(({
                codPro,
                codCcu,
                codDep,
                qtdPed,
                preuni,
                despro,
                seqIpd
              }, index) =>
                <Tr key={ index }>
                  <Td> {codPro} </Td>
                  <Td> {despro} </Td>
                  <Td> {codCcu} </Td>
                  <Td> {qtdPed} </Td>
                  <Td> {codDep} </Td>
                  <Td> {preuni} </Td>
                  <Td>{preuni * qtdPed}</Td>
                  <Td>
                    <IconButton
                      padding={2}
                      size={20}
                      icon={<MdDelete />}
                      onClick={() => {
                        handleDelete(index)
                      }}
                    />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          : <> </> }
        <br/>
        { codemp && codcli && codfil
          ? <Button
            buttonType="primary"
            type="submit"
            disabled={loading}
          >
          Adicionar
          </Button>
          : <></> }
        <span style={{ display: 'inline-block', width: '5px' }} />
        { codemp && codcli && codfil
          ? <Button
            buttonType="secondary"
            type="button"
            onClick={openItemModal(data, codtpr, codcli, codemp, radioButtonT, radioButton, radioButtonD, setItensDefault, itensDefault)}
          >
          Adicionar Item
          </Button>
          : <></> }
      </Form>
    </div>
  )
}

export default InsertBudget
