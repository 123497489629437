import { useState, useCallback } from 'react'

interface UseSelectItems<T> {
  selectedItems: T[]
  handleSelect: (item: T, value: boolean) => () => void
  handleSelectAll: (array: T[], value: boolean) => () => void
  isSelected: (item: T) => boolean
}

export const useSelectItems = <T>(
  makeUniqueKey: (item: T) => string
): UseSelectItems<T> => {
  const [selectedItems, setSelectedItems] = useState<T[]>([])

  const handleSelect = useCallback((item: T, value: boolean): () => void => {
    return (): void => {
      setSelectedItems(oldState => {
        if (value) {
          return [...oldState, item]
        }
        return oldState.filter(selectedItem => {
          return makeUniqueKey(selectedItem) !== makeUniqueKey(item)
        })
      })
    }
  }, [makeUniqueKey])

  const handleSelectAll = (items: T[], value: boolean): () => void => {
    return (): void => {
      if (value) {
        setSelectedItems([...items])
        return
      }
      setSelectedItems([])
    }
  }
  const isSelected = (item: T): boolean => {
    const selected = selectedItems.find(selectedItem => {
      return makeUniqueKey(selectedItem) === makeUniqueKey(item)
    })
    return !!selected
  }

  return {
    handleSelect,
    handleSelectAll,
    isSelected,
    selectedItems
  }
}
