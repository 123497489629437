import React from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'

interface InsertProviderProps {
  openAddProvider: (data: any) => void
}

interface InsertProviderForm {
  cnpj: string
}

const InsertProvider: React.FC<InsertProviderProps> = ({
  openAddProvider
}) => {
  const methods = useForm<InsertProviderForm>({
  })
  const { register } = methods
  const { close } = useDetailsContainer()
  const { httpGet } = useApi()

  const handleSendData = async (formData: InsertProviderForm): Promise<void> => {
    await handle(async () => {
      const cnpj = formData.cnpj
      const response = await httpGet<string>(`/bidding/list-companies/${cnpj}`)
      toast.success(response.body)
      openAddProvider(response.body)
    })
  }

  return (
    <div>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="CNPJ / CPF"
          name="cnpj"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default InsertProvider
