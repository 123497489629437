import styled from 'styled-components'

export const Container = styled.div`
  display: block;

  .info {
    display: flex;

    section {
      width: 48%;
    }
    section:last-child {
      width: 50%;
    }
  }
  .info-container{
    display: flex;

    section {
     width: 48%;
     padding-left: 10px; 
    }
  }
`
