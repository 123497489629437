import { Action } from '../types'

export enum SideMenuActions {
  SET_OPENED = 'SIDE_MENU_SET_OPENED',
  SET_CLOSED = 'SIDE_MENU_SET_CLOSED',
  TOGGLE = 'SIDE_MENU_TOGGLE'
}

export const sideMenuAction = (
  action: SideMenuActions
): Action => {
  return {
    type: action
  }
}
