import styled from 'styled-components'

export const IframeStyle = styled.iframe`
  max-height: calc(100% - 3px);
  max-width: 100%;
  height: 100%;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
`
