import React, { memo } from 'react'
import {
  MdClear,
  MdKeyboardBackspace,
  MdFullscreen,
  MdFullscreenExit
} from 'react-icons/md'
import IconButton from '../IconButton'
import { Content } from '../../redux/details-container'
import {
  Container,
  DetailsContainerStyle,
  Header
} from './styles'

const empty = (): void => {}

interface DetailsContainerProps {
  opened: boolean
  content: Content[]
  close: () => void
  fullScreen: boolean
  setFullScreen: (value: boolean) => void
  mobile: boolean
}

const DetailsContainer: React.FC<DetailsContainerProps> = ({
  opened,
  content,
  close,
  fullScreen,
  setFullScreen,
  mobile
}) => {
  const page = content.length - 1

  const closeWrapper = (): void => {
    close()
    const onClose = content[page].onClose ?? empty
    onClose()
  }

  if (!opened || content.length === 0) {
    return null
  }

  const renderResizeButtons = (): JSX.Element => {
    if (mobile) return <></>

    return fullScreen ? (
      <IconButton
        padding={2}
        size={20}
        icon={<MdFullscreenExit />}
        onClick={() => {
          setFullScreen(false)
        }}
      />
    ) : (
      <IconButton
        padding={2}
        size={20}
        icon={<MdFullscreen />}
        onClick={() => {
          setFullScreen(true)
        }}
      />
    )
  }

  return (
    <Container
      opened={opened}
      fullScreen={fullScreen}
    >
      <DetailsContainerStyle
        opened={opened}
        fullScreen={fullScreen}
      >
        <Header>
          <div className="left">
            {page === 0 ? (
              <IconButton
                padding={2}
                size={20}
                icon={<MdClear />}
                onClick={closeWrapper}
              />
            ) : (
              <IconButton
                padding={2}
                size={20}
                icon={<MdKeyboardBackspace />}
                onClick={closeWrapper}
              />
            )}
            &nbsp;
            <h3>{content[page].title}</h3>
          </div>
          <div className="right">
            {renderResizeButtons()}
          </div>
        </Header>
        <div className="content">
          {content[page].component}
        </div>
      </DetailsContainerStyle>
    </Container>
  )
}

export default memo(DetailsContainer)
