import React from 'react'
import IconButton from '../../../components/IconButton'
import DynamicIcon from '../../../components/DynamicIcon'
import { Container } from './styles'

interface BiddingHeaderProps {
  refetch: () => void
  onClickProvider?: () => void
  onClickFilter?: () => void
  openAddBiddingModal: () => void
}

const BiddingHeader: React.FC<BiddingHeaderProps> = ({
  refetch,
  onClickFilter,
  onClickProvider,
  openAddBiddingModal,
  children
}) => {
  return (
    <Container>
      {children}
      <IconButton
        padding={5}
        onClick={openAddBiddingModal}
        size={30}
        icon={(
          <DynamicIcon
            family="md"
            name="MdAdd"
            size={30}
          />
        )}
      />
      <IconButton
        padding={5}
        onClick={onClickProvider}
        size={30}
        icon={(
          <DynamicIcon
            family="md"
            name="MdPersonAdd"
            size={30}
          />
        )}
      />
      <IconButton
        padding={5}
        size={30}
        onClick={onClickFilter}
        icon={(
          <DynamicIcon
            family="md"
            name="MdFilterList"
            size={30}
          />
        )}
      />
      <IconButton
        padding={5}
        size={30}
        onClick={refetch}
        icon={(
          <DynamicIcon
            family="md"
            name="MdRefresh"
            size={30}
          />
        )}
      />
    </Container>
  )
}

export default BiddingHeader
