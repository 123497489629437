import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import Input from '../../../components/Input'
import { CostTableModel } from '../../../models/cost-table'

interface AttToleranceCostProps {
  data: CostTableModel
  refetch: () => void
}

interface AttToleranceCostForm {
  codder: string
  codemp: string
  codpro: string
  codtpr: string
  tolmen: string
}

const AttToleranceCostModal: React.FC<AttToleranceCostProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AttToleranceCostForm>({
    defaultValues: {
    }
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AttToleranceCostForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>(`/cost-table/tolerance-cost/${data.codpro}`, {
        codder: data.codder,
        codemp: data.codemp,
        codpro: data.codpro,
        codtpr: data.codtpr,
        tolmen: formData.tolmen
      })
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Atualizar Tolêrancia de Custo</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Nova Tolerância de Custo"
          type="text"
          name="tolmen"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttToleranceCostModal
