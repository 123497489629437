import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'
import { CallRegistrationModel } from '../../../models/call-registration'

interface CompanyModalProps {
  data: CallRegistrationModel
  refetch: () => void
  refetchDetails: () => void
}

interface CompanyForm {
  usu_empcham: string
}

const CompanyModal: React.FC<CompanyModalProps> = ({
  data,
  refetch,
  refetchDetails
}) => {
  const methods = useForm<CompanyForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { httpPatch } = useApi()
  const { clearData } = useDetailsContainer()
  const [loading, setLoading] = useState(false)

  const handleSendData = async (formData: CompanyForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        usu_codchamabe: data.usu_codusuabe,
        usu_codcham: data.usu_codcham,
        usu_empcham: formData.usu_empcham
      }
      const response = await httpPatch<string>('/call-registration/update-call-company', json)
      console.log(json)
      console.log(response)

      if (response.body === 'Atualizado com sucesso !!!') {
        clearData()
      }
      toast.info(response.body)
      setLoading(false)
      refetch()
      close()
    })
  }

  return (
    <div>
      <h3>Atualizar Empresa do Chamado #{data.usu_codcham}</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="usu_desemp"
          readonly={false}
          label="Empresa"
          name="usu_empcham"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url={'call-registration/list-Company'}
          valueProperty="usu_empcham"
        />
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default CompanyModal
