import React from 'react'
import { Link } from 'react-router-dom'
import {
  MdDashboard,
  MdAccountCircle,
  MdSupervisorAccount
} from 'react-icons/md'
import { Container } from './styles'

const Settings: React.FC = () => {
  return (
    <>
      <Container>
        <h2>Configurações</h2>
        <ul>
          <li>
            <Link to="/configuracoes/modulos">
              <MdDashboard size={40} />
              <p>Módulos</p>
            </Link>
          </li>
          <li>
            <Link to="/configuracoes/perfis">
              <MdSupervisorAccount size={40} />
              <p>Perfis</p>
            </Link>
          </li>
          <li>
            <Link to="/configuracoes/usuarios">
              <MdAccountCircle size={40} />
              <p>Usuários</p>
            </Link>
          </li>
        </ul>
      </Container>
    </>
  )
}

export default Settings
