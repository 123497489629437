import { Action } from '../types'
import { AxiosInstance } from 'axios'
import { InitialState } from './auth-reducer'
import { toast } from 'react-toastify'
import { LoginParams } from '../../models/login'

export enum AuthActions {
  SET_DATA = 'AUTH_SET_DATA',
  CLEAR = 'AUTH_CLEAR'
}

export const authAction = (
  action: AuthActions,
  data: any = {}
): Action => {
  return {
    type: action,
    ...data
  }
}

export const login =
  (params: LoginParams) =>
    async (
      dispatch: any,
      getState: () => InitialState,
      api: AxiosInstance
    ) => {
      try {
        const response = await api.post('/login', params)
        const { accessToken } = response.data
        dispatch(authAction(AuthActions.SET_DATA, {
          data: {
            user: params.username,
            token: accessToken,
            isAuthenticated: true
          }
        }))
        toast.success('Login realizado com sucesso.')
      } catch (error) {
        dispatch(authAction(AuthActions.CLEAR))
        const { response } = error
        if (response.status === 401) {
          toast.error('Credenciais inválidas.')
          return
        }
        toast.error(response.data.error)
      }
    }
