import React, {
  memo,
  useCallback
} from 'react'
import Dropdown from '../Dropdown'
import DynamicIcon from '../DynamicIcon'
import { Link, useHistory } from 'react-router-dom'
import {
  useClickAwayListener,
  useApi,
  useFetch,
  useAuth
} from '../../hooks'
import { Menu } from '../../models/menu'
import { SideMenuContainer, CollapsibleMenu } from './styles'

interface SideMenuProps {
  opened: boolean
  close: () => void
  closeDetails: () => void
}

const SideMenu: React.FC<SideMenuProps> = ({
  opened,
  close,
  closeDetails
}) => {
  const { isAuthenticated } = useAuth()
  const { httpGet } = useApi()
  const { push } = useHistory()
  const { data } = useFetch<Menu[]>(httpGet, '/menu', isAuthenticated)

  useClickAwayListener({
    elementId: 'side-menu',
    onClickAway: (): void => {
      if (opened) {
        close()
      }
    }
  })

  const closeWrapper = useCallback(() => {
    closeDetails()
    close()
  }, [closeDetails, close])

  return (
    <SideMenuContainer id="side-menu" opened={opened}>
      <nav>
        <ul className="main-menu">
          {data?.map((item, index) => (
            <li key={index}>
              {item.submenus.length ? (
                <>
                  <Dropdown
                    id="link-informativos"
                    divid={`button-menu${index}`}
                    disabled={opened}
                    title={item.text}
                    component={(
                      <button
                        id={`button-menu${index}`}
                      >
                        <DynamicIcon
                          name={item.icon}
                          family={item.iconFamily}
                          size={30}
                        />
                        {item.text}
                      </button>
                    )}
                    left="50px"
                    options={item.submenus.map(subitem => ({
                      text: subitem.text,
                      closeOnClick: true,
                      onClick: () => {
                        if (!subitem.external) {
                          push(subitem.link)
                          closeWrapper()
                          return
                        }
                        window.open(subitem.link, '_blank')
                      },
                      icon: (
                        <DynamicIcon
                          family={subitem.iconFamily}
                          name={subitem.icon}
                          size={15}
                        />
                      )
                    }))}
                  />
                  <CollapsibleMenu expanded={opened}>
                    {item.submenus.map((subitem, index) => (
                      <li key={`sub${index}`}>
                        {subitem.external ? (
                          <a
                            href={subitem.link ?? ''}
                            target="_blank"
                            onClick={closeWrapper}
                            rel="noopener noreferrer"
                            title={subitem.text}
                          >
                            <DynamicIcon
                              family={subitem.iconFamily}
                              name={subitem.icon}
                              size={15}
                            />
                            {subitem.text}
                          </a>
                        ) : (
                          <Link
                            onClick={closeWrapper}
                            to={subitem.link ?? ''}
                            title={item.text}
                          >
                            <DynamicIcon
                              family={subitem.iconFamily}
                              name={subitem.icon}
                              size={15}
                            />
                            {subitem.text}
                          </Link>
                        )}
                      </li>
                    ))}
                  </CollapsibleMenu>
                </>
              ) : item.external ? (
                <a
                  href={item.link ?? ''}
                  target="_blank"
                  onClick={closeWrapper}
                  rel="noopener noreferrer"
                  title={item.text}
                >
                  <DynamicIcon
                    family={item.iconFamily}
                    name={item.icon}
                    size={30}
                  />
                  {item.text}
                </a>
              ) : (
                <Link
                  to={item.link ?? ''}
                  onClick={closeWrapper}
                  title={item.text}
                >
                  <DynamicIcon
                    family={item.iconFamily}
                    name={item.icon}
                    size={30}
                  />
                  {item.text}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </SideMenuContainer>
  )
}

export default memo(SideMenu)
