import React from 'react'
import Loader from '../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../components/Table'
import {
  useApi,
  useDetailsContainer,
  useFetch,
  useLoading,
  useModal
} from '../../hooks'
import { Container } from './styles'
import { LogisticDriverModel } from '../../models/logistic-driver'
import { useCallback } from 'react'
import { handle } from '../../utils/error-handlers'
import IconButton from '../../components/IconButton'
import DynamicIcon from '../../components/DynamicIcon'
import LogisticDetails from './LogisticDetails'
import { toast } from 'react-toastify'
import Filter from './Header'

const LogisticDriver: React.FC = () => {
  const { httpGet, httpPatch } = useApi()
  const { confirm } = useModal()
  const { load } = useLoading()
  const {
    loading,
    data,
    refetch,
    refetching
  } = useFetch<LogisticDriverModel[]>(httpGet, '/logistic/list-logistics-drivers')
  const {
    pushPage,
    open,
    close
  } = useDetailsContainer()
  const { loading: loadingHook } = useLoading()

  const openDetails = useCallback((logistic: LogisticDriverModel): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <LogisticDetails
            data={logistic}
            refetch={refetch}
          />
        ),
        title: 'Geral'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushPage])

  const handleAssume = (items: LogisticDriverModel[]) => {
    return () => {
      confirm('Deseja assumir esse serviço ?', () => {
        handle(async () => {
          load(async () => {
            for (const item of items) {
              await httpPatch(`/logistic/update-assume/${item.usu_numped}/${item.usu_codemp}/${item.usu_seqipd}`, {
                codemp: item.usu_codemp,
                codfil: item.usu_codfil,
                numped: item.usu_numped,
                seqipd: item.usu_seqipd,
                tipo: item.usu_tipo
              })
              toast.success('Serviço Assumido !')
              refetch()
            }
          })
        })
      })
    }
  }

  const handleTerminate = (items: LogisticDriverModel[]) => {
    return () => {
      confirm('Deseja encerrar esse serviço ? ', () => {
        handle(async () => {
          load(async () => {
            for (const item of items) {
              await httpPatch(`/logistic/update-terminate/${item.usu_numped}/${item.usu_codemp}/${item.usu_seqipd}`, {
                codemp: item.usu_codemp,
                codfil: item.usu_codfil,
                numped: item.usu_numped,
                seqipd: item.usu_seqipd,
                tipo: item.usu_tipo
              })
              toast.success('Serviço Encerrado !')
              refetch()
            }
          })
        })
      })
    }
  }

  return (
    <>
      <Filter
        refetch={refetch}
        data={data ?? []}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
          >
            <THead>
              <Tr>
                <Th width="60px" className="hide-md" color="#000" >Pedido</Th>
                <Th width="180px" className="hide-md" color="#000" >Cliente</Th>
                <Th width="200px" className="hide-md" color="#000000">Produto</Th>
                <Th width="50px" className="hide-md" color="#000">Quantidade</Th>
                <Th width="100px" className="hide-sm" color="#000" >Carga</Th>
                <Th width="180px" className="hide-md" color="#000">Descrição Caixa</Th>
                <Th width="20px" className="hide-md" color="#000" >Situação</Th>
                <Th width="40px" color="#000" >Assumir</Th>
                <Th width="40px" color="#000" >Encerrar</Th>
                <Th width="30px" color="#000"></Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map((item, index) => (
                <Tr key={index}>
                  <Td title={item.usu_numped}>
                    <button
                      className="first-item"
                      onClick={openDetails(item)}
                    >
                      {item.usu_numped}
                    </button>
                  </Td>
                  <Td title={item.usu_nomcli}>{item.usu_nomcli}</Td>
                  <Td title={item.usu_despro}>{item.usu_despro}</Td>
                  <Td title={item.usu_qtdped}>{item.usu_qtdped}</Td>
                  <Td title={item.usu_datcar}>{item.usu_datcar}</Td>
                  <Td title={item.desbem}>{item.desbem}</Td>
                  <Td title={item.usu_sitord}>{item.usu_sitord}</Td>
                  {item.usu_sitord === 'A'
                    ? <>
                      <Td>
                        <IconButton
                          padding={1}
                          size={20}
                          onClick={handleAssume([item])}
                          icon={(
                            <DynamicIcon
                              family="md"
                              name="MdCheckCircle"
                              size={15}
                              color="#00ff0d"
                            />
                          )}
                        />
                      </Td>
                    </>
                    : <>
                      <Td>
                      </Td>
                      <Td>
                        <IconButton
                          padding={1}
                          size={20}
                          onClick={handleTerminate([item])}
                          icon={(
                            <DynamicIcon
                              family="md"
                              name="MdCheckCircle"
                              size={15}
                              color="#b83333"
                            />
                          )}
                        />
                      </Td>
                    </>}
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default LogisticDriver
