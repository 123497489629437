import React, { useState } from 'react'
import { useModal } from '../../hooks'
import Button from '../Button'
import Input from '../Input/no-form'
import * as S from './styles'

interface PromptProps {
  message: string
  inputLabel: string
  onConfirm: (message: string) => void
}

const Prompt: React.FC<PromptProps> = ({ message, onConfirm, inputLabel }) => {
  const [value, setValue] = useState('')
  const { close } = useModal()

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    onConfirm(value)
    close()
  }

  return (
    <S.Container>
      <form onSubmit={handleSubmit}>
        <S.Message>{message}</S.Message>
        <Input
          required
          onChange={(e) => setValue(e.target.value)}
          value={value}
          label={inputLabel}
        />
        <S.Actions>
          <Button type="submit" buttonType="primary">Confirmar</Button>
          <Button type="button" buttonType="secondary" onClick={close}>Cancelar</Button>
        </S.Actions>
      </form>
    </S.Container>
  )
}

export default Prompt
