import { useCallback } from 'react'

interface UseFilter<T = any> {
  filtered: T[]
}

export const useFilter = <T>(
  data: T[] | undefined,
  filter: (item: T, index: number, array: T[]) => boolean
): UseFilter<T> => {
  const filterWrapper = useCallback(filter, [filter])

  return {
    filtered: data?.filter(filterWrapper) ?? []
  }
}
