import React, {
  useCallback,
  useEffect
} from 'react'
import Filter from './NewsFilter'
import NewsDetails from './NewsDetails'
import Loader from '../../components/Loader'
import Card from './Card'
import Pagination from '../../components/Pagination'
import {
  useHistory,
  useParams,
  useLocation
} from 'react-router-dom'
import {
  useDetailsContainer,
  useApi,
  useFetch
} from '../../hooks'
import { News as INews } from '../../models/news'
import { Paginated } from '../../models/paginated'
import { Container, PaginationContainer } from './styles'

const News: React.FC = () => {
  const { page } = useParams<{ page: string }>()
  const {
    open,
    pushPage,
    close
  } = useDetailsContainer()
  const { replace } = useHistory()
  const { search } = useLocation()
  const { httpGet } = useApi()
  const {
    data,
    loading,
    refetch,
    refetching
  } = useFetch<Paginated<INews>>(httpGet, `/news/${page}/informativos`)

  const openDetails = useCallback((id?: number): void => {
    close()
    pushPage({
      component: <NewsDetails id={id} refetch={refetch} />,
      title: 'Informativo',
      data: { id },
      onClose: () => {
        replace('/informativos/1')
      }
    })
    open()
  }, [close, pushPage, open, replace, refetch])

  useEffect(() => {
    if (search?.includes('?id=')) {
      const id = Number(search.substr(search.indexOf('=') + 1))
      openDetails(id)
    }
  }, [search, openDetails])

  return (
    <>
      <Filter
        category="informativos"
        onClickAdd={() => {
          openDetails()
        }}
      />
      <Container>
        <Loader loading={loading || refetching}>
          {data?.records?.map((item: INews, index: number) => {
            return (
              <Card
                key={index}
                news={item}
                refetch={refetch}
              />
            )
          })}
          {!data?.records.length && (
            <p className="empty-message">Nenhum registro disponível.</p>
          )}
          <PaginationContainer>
            <Pagination
              currentPage={Number(page)}
              linkUrl="/informativos/#page"
              totalPages={data?.totalPages}
              totalRecords={data?.totalRecords}
            />
          </PaginationContainer>
        </Loader>
      </Container>
    </>
  )
}

export default News
