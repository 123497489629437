import React, {
  useState,
  useEffect,
  memo
} from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import {
  MdExitToApp,
  MdNotifications,
  MdReply
} from 'react-icons/md'
import Logo from '../../assets/grupofortalezalogo_colorfull.png'
import Dropdown from '../Dropdown'
import { Link, useHistory } from 'react-router-dom'
import { useApi, useLoading } from '../../hooks'
import { News } from '../../models/news'
import { HeaderContainer } from './styles'

interface HeaderProps {
  toggle: () => void
  logout: () => void
  user: string
}

const Header: React.FC<HeaderProps> = ({
  toggle,
  logout,
  user
}) => {
  const [news, setNews] = useState<News[]>([])
  const { httpGet } = useApi()
  const { load } = useLoading()
  const { push } = useHistory()

  useEffect(() => {
    if (user) {
      load(async () => {
        const response = await httpGet<News[]>('/news')
        setNews(response.body ?? [])
      })
    }
  }, [httpGet, load, user])

  return (
    <HeaderContainer>
      <div className="left">
        <button onClick={toggle}>
          <AiOutlineMenu />
        </button>
        <Link to="/">
          <img src={Logo} alt="Grupo Fortaleza" />
        </Link>
      </div>
      <div className="right">
        <Dropdown
          id="user"
          options={news.map(item => {
            const title = item.title.length > 25
              ? `${item.title.substr(0, 25)}...` : item.title
            return {
              text: title,
              icon: <MdReply />,
              title: item.title,
              onClick: () => {
                push(`/publicacao/${item.id}`)
              }
            }
          })}
          component={
            <button className="notifications">
              <MdNotifications color="#fff" size={30} />
              <span>{news.length}</span>
            </button>
          }
          optionsTopDistance="55px"
        />
        <Dropdown
          id="user"
          options={[{
            text: 'Sair',
            onClick: logout,
            icon: <MdExitToApp size={15} />
          }]}
          component={
            <button className="user">
              {user.charAt(0).toUpperCase()}
            </button>
          }
          optionsTopDistance="55px"
        />
      </div>
    </HeaderContainer>
  )
}

export default memo(Header)
