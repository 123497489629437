import React, {
  useState,
  useEffect
} from 'react'
import Dropdown from '../../../components/Dropdown'
import { FaTrashAlt } from 'react-icons/fa'
import { MdModeEdit } from 'react-icons/md'
import {
  Link,
  useLocation,
  useHistory
} from 'react-router-dom'
import {
  useDetailsContainer,
  useModal,
  useApi
} from '../../../hooks'
import parse from 'html-react-parser'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { News } from '../../../models/news'
import { handle } from '../../../utils/error-handlers'
import { CardStyle, Content } from './styles'

interface CardProps {
  news: News
  refetch: () => void
}

export const Card: React.FC<CardProps> = ({ news, refetch }) => {
  const { opened } = useDetailsContainer()
  const [showReadMore, setShowReadMore] = useState(false)
  const {
    id,
    date,
    readed,
    text,
    title
  } = news
  const { confirm } = useModal()
  const { httpDelete } = useApi()
  const { pathname } = useLocation()
  const { push } = useHistory()

  useEffect(() => {
    const content = document.getElementById(`content${id}`)
    if (content && content.offsetHeight > 40) {
      setShowReadMore(true)
    }
  }, [id, opened])

  const handleEdit = (): void => {
    push(`${pathname}?id=${id}`)
  }

  const handleDelete = (): void => {
    confirm('Tem certeza que deseja excluir esse informativo?', () => {
      handle(async () => {
        await httpDelete(`/news/${id}`)
        toast.success('Informativo excluído com sucesso')
        refetch()
      })
    })
  }

  return (
    <CardStyle>
      <div className="header">
        <div className="title">
          {!readed && <span title="Novo">N</span>}
          <h3>{title}</h3>
        </div>
        <Dropdown
          id={`news${id}`}
          options={[{
            text: 'Editar',
            icon: <MdModeEdit size={15} />,
            onClick: handleEdit,
            closeOnClick: true
          }, {
            text: 'Excluir',
            icon: <FaTrashAlt size={15} />,
            onClick: handleDelete
          }]}
        />
      </div>
      <p className="date">{format(new Date(date), 'dd/MM/yyyy')}</p>
      <Content id={`content${id}`}>
        <pre>
          {parse(text)}
        </pre>
        {showReadMore && <div className="gradient" />}
      </Content>
      <Link className="read-more" to={`/publicacao/${id}`}>Ler mais</Link>
    </CardStyle>
  )
}

export default Card
