import React from 'react'
import Button from '../Button'
import { useModal } from '../../hooks'
import { Container } from './styles'

interface ConfirmationProps {
  text: string
  onConfirm: () => void
}

const Confirmation: React.FC<ConfirmationProps> = ({ text, onConfirm }) => {
  const { close } = useModal()

  return (
    <Container>
      <p>{text}</p>
      <div className="actions">
        <Button
          onClick={() => {
            onConfirm()
            close()
          }}
          buttonType="primary"
        >
          Sim
        </Button>
        <Button
          onClick={close}
          buttonType="secondary"
        >
          Cancelar
        </Button>
      </div>
    </Container>
  )
}

export default Confirmation
