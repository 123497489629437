import styled from 'styled-components'

export const Container = styled.div`
  padding: 15px;
  height: max-content;

  Button{
    margin-top: 20px;
    margin-right: 10px;
  }
  
  @media(max-width: 850px) {
    .hide-md {
      display: none;
    }
  }

  @media(max-width: 550px) {
    .hide-sm {
      display: none;
    }
  }
`
