import React, { useState } from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'
import { SalesOrderModel } from '../../../models/salesorder'

interface AddItemOrderModalProps {
  data: SalesOrderModel
  refetch: () => void
}

interface AddItemOrderForm {
  preuni: string
  qtdPed: string
  codpro: string
  codccu: string
  coddep: string
  seqIpd: string
  numped: string
}

const AddItemOrderModal: React.FC<AddItemOrderModalProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AddItemOrderForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()
  const [productDefault, setProductDefault] = useState<any>()
  const codpro = methods.watch('codpro')

  const handleSendData = async (formData: AddItemOrderForm): Promise<void> => {
    await handle(async () => {
      const json = {
        codCli: data.codcli,
        codemp: data.codemp,
        codCpg: data.codcpg,
        codVen: data.codven,
        codFpg: data.codfpg,
        codfil: data.codfil,
        codrep: data.codrep,
        codtpr: data.codtpr,
        entcli: data.datemi,
        numPed: data.numped,
        obsPed: data.obsped,
        somfre: data.somfre,
        tipfre: data.ciffob,
        tnsPro: data.tnspro,
        orcIte: [{
          codCcu: productDefault.codccu,
          codDep: formData.coddep,
          codPro: formData.codpro,
          ORC_Seqipd: productDefault.seqipd,
          ORC_numped: productDefault.numped,
          preuni: productDefault.preuni ? String(productDefault.preuni).replace('.', ',') : '',
          qtdPed: parseFloat(productDefault.qtdped),
          seqIpd: ''
        }]
      }
      const response = await httpPost<string>(`/salesorder/insert-sale-order-itens/${data.codcli}/${data.codemp}/${data.codfil}/${data.numped}`, json)
      toast.info(response.body)

      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Inserir Item em Pedido</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        {data.codtpr
          ? <SelectApi
            displayProperty="desCom"
            readonly={false}
            label="Selecione o produto"
            name="codpro"
            register={register({
            })}
            url={`/salesorder/list-budget-itens/${data.codcli}/${data.codemp}/${data.codfil}`}
            valueProperty="codpro"
            onSelect={(item) => {
              if (item) {
                setProductDefault(item.data)
              }
            }}
          />
          : <></>}
        {codpro
          ? <Input
            label="Centro de Custo"
            name="codccu"
            disabled
            defaultValue={productDefault?.codccu}
            register={register({
            })}
          />
          : <></>}
        {codpro
          ? <SelectApi
            displayProperty="desdep"
            readonly={false}
            defaultValue={productDefault.coddep}
            label="Depósito"
            name="coddep"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
            url={`/salesorder/list-deposits/${data.codemp}/${codpro}`}
            valueProperty="coddep"
          />
          : <></>}
        {codpro
          ? <Input
            label="Preço Unitário"
            name="preuni"
            disabled
            defaultValue={productDefault.preuni}
            register={register({
            })}
          />
          : <></>}
        {codpro
          ? <Input
            label="Quantidade Pedida"
            name="qtdPed"
            disabled
            defaultValue={productDefault.qtdped}
            register={register({
            })}
          />
          : <></>}
        {/* { codpro
          ? <Input
            label="Sequencial"
            name="seqIpd"
            disabled
            defaultValue={productDefault.seqipd}
            register={register({
            })}
          />
          : <></> } */}
        {codpro
          ? <Input
            label="Número do Pedido"
            name="numped"
            disabled
            defaultValue={productDefault.numped}
            register={register({
            })}
          />
          : <></>}
        {codpro
          ? <Button
            buttonType="primary"
            type="submit"
          >
            Adicionar
          </Button>
          : <></>}
        <span style={{ display: 'inline-block', width: '5px' }} />
        {codpro
          ? <Button
            buttonType="secondary"
            type="button"
            onClick={close}
          >
            Fechar
          </Button>
          : <></>}
      </Form>
    </div>
  )
}

export default AddItemOrderModal
