import React from 'react'
import {
  useForm,
  DeepMap,
  FieldError
} from 'react-hook-form'
import { useAuth } from '../../hooks/auth'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Form from '../../components/Form'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Logo from '../../assets/grupofortalezalogo.png'
import { LoginParams } from '../../models/login'
import { LoginContainer } from './styles'

const Login: React.FC = () => {
  const methods = useForm<LoginParams>({ mode: 'onTouched' })
  const { register } = methods
  const { login } = useAuth()
  const { push } = useHistory()

  const onSubmit = async (data: LoginParams): Promise<void> => {
    try {
      login(data)
      push('/dashboard')
    } catch (error) {
      toast.error(error.message)
    }
  }

  const onError = (errors: DeepMap<LoginParams, FieldError>): void => {
    for (const [, value] of Object.entries(errors)) {
      const { message } = value ?? { message: '' }
      toast.error(message)
    }
  }

  return (
    <LoginContainer>
      <div className="content">
        <h1>
          <img src={Logo} alt="Grupo Fortaleza" />
        </h1>
        <Form
          methods={methods}
          onError={onError}
          onSubmit={onSubmit}
        >
          <Input
            label="Usuário"
            name="username"
            register={register({
              required: {
                value: true,
                message: 'O campo usuário é obrigatório.'
              }
            })}
          />
          <Input
            label="Senha"
            name="password"
            type="password"
            register={register({
              required: {
                value: true,
                message: 'O campo senha é obrigatório.'
              }
            })}
          />
          <Button
            buttonType="primary"
            fullWidth
            type="submit"
          >
            ENTRAR
          </Button>
        </Form>
      </div>
    </LoginContainer>
  )
}

export default Login
