/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import Label from '../../../components/Label'
import { Container } from './styles'
import { LogisticModel } from '../../../models/logistic'
import { useApi, useFetch, useLoading, useModal } from '../../../hooks'
import DeliveryDateModal from '../DeliveryDateModal'
import DriverModal from '../DriverModal'
import ServiceModal from '../ServiceModal'
import ShippingCompanyModal from '../ShippingCompanyModal'
import SituationModal from '../SituationModal'
import VehiclesModal from '../VehiclesModal'
import IconButton from '../../../components/IconButton'
import { MdSearch } from 'react-icons/md'
import Loader from '../../../components/Loader'

interface LogisticDetailsProps {
  refetch: () => void
  item: LogisticModel
}

const LogisticDetails: React.FC<LogisticDetailsProps> = ({
  refetch,
  item
}) => {
  const { open: openModal } = useModal()
  const { httpGet } = useApi()

  const {
    loading,
    data,
    refetchDetails,
    refetching
  } = useFetch<LogisticModel[]>(httpGet, '/logistic/list-logistics')
  const { loading: loadingHook } = useLoading()

  const openDeliveryDateModal = useCallback((data: LogisticModel): () => void => {
    return () => {
      openModal(
        <DeliveryDateModal
          data={data}
          refetchDetails={refetchDetails}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openDriverModal = useCallback((data: LogisticModel): () => void => {
    return () => {
      openModal(
        <DriverModal
          data={data}
          refetchDetails={refetchDetails}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openServiceModal = useCallback((data: LogisticModel): () => void => {
    return () => {
      openModal(
        <ServiceModal
          data={data}
          refetchDetails={refetchDetails}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openSituationModal = useCallback((data: LogisticModel): () => void => {
    return () => {
      openModal(
        <SituationModal
          data={data}
          refetchDetails={refetchDetails}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openShippingCompanyModal = useCallback((data: LogisticModel): () => void => {
    return () => {
      openModal(
        <ShippingCompanyModal
          data={data}
          refetch={refetch}
          refetchDetails={refetchDetails}
        />
      )
    }
  }, [openModal, refetch])

  const openVehiclesModal = useCallback((data: LogisticModel): () => void => {
    return () => {
      openModal(
        <VehiclesModal
          data={data}
          refetch={refetch}
          refetchDetails={refetchDetails}
        />
      )
    }
  }, [openModal, refetch, refetchDetails])

  return (
    <Container>
      <Loader
        loading={loading || refetching || loadingHook}
        message={loadingHook ? 'Processando...' : undefined}
      >
        {data?.filter((data) => (data.usu_numped === item.usu_numped && data.usu_seqipd === item.usu_seqipd)).map((data) => (
          <div className="info" >
            <section>
              <Label
                title="Empresa"
                value={data.usu_codemp}
              />
              <Label
                title="Pedido"
                value={data.usu_numped}
              />
              <Label
                title="Cliente"
                value={data.usu_nomcli}
              />
              <Label
                title="Código Produto"
                value={data.usu_codpro}
              />
              <Label
                title="Quantidade"
                value={data.usu_qtdped}
              />
              <Label
                title="Carga/Descarga"
                value={data.usu_datcar}
                IconButton={
                  <IconButton
                    icon={<MdSearch />}
                    padding={5}
                    size={16}
                    onClick={openDeliveryDateModal(data)}
                  />
                }
              />
              <Label
                title="Veículo"
                value={data.plavei}
                IconButton={
                  <IconButton
                    icon={<MdSearch />}
                    padding={5}
                    size={16}
                    onClick={openVehiclesModal(data)}
                  />
                }
              />
              <Label
                title="Serviço Frete"
                value={data.desser}
                IconButton={
                  <IconButton
                    icon={<MdSearch />}
                    padding={5}
                    size={16}
                    onClick={openServiceModal(data)}
                  />
                }
              />
              <Label
                title="Situação"
                value={data.usu_sitord}
                IconButton={
                  <IconButton
                    icon={<MdSearch />}
                    padding={5}
                    size={16}
                    onClick={openSituationModal(data)}
                  />
                }
              />
            </section>
            <section>
              <Label
                title="Filial"
                value={data.usu_codfil}
              />
              <Label
                title="Tipo"
                value={data.usu_tipo}
              />
              <Label
                title="Código Cliente"
                value={data.usu_codcli}
              />
              <Label
                title="Sequência"
                value={data.usu_seqipd}
              />
              <Label
                title="Produto"
                value={data.usu_despro}
              />
              <Label
                title="Geração"
                value={data.usu_datean}
              />
              <Label
                title="Transportadora"
                value={`${data.nomtra} - ${data.codtra}`}
                IconButton={
                  <IconButton
                    icon={<MdSearch />}
                    padding={5}
                    size={16}
                    onClick={openShippingCompanyModal(data)}
                  />
                }
              />
              <Label
                title="Motorista"
                value={data.nommot}
                IconButton={
                  <IconButton
                    icon={<MdSearch />}
                    padding={5}
                    size={16}
                    onClick={openDriverModal(data)}
                  />
                }
              />
              <Label
                title="Descrição Caixa"
                value={data.desbem}
              />
            </section>
          </div>
        ))}
      </Loader>
    </Container>
  )
}

export default LogisticDetails
