import React, {
  useEffect,
  useCallback
} from 'react'
import { MdDelete } from 'react-icons/md'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import SelectApi from '../../../components/Select/api'
import Loader from '../../../components/Loader'
import TextEditor from '../../../components/TextEditor'
import DetailsButtons from '../../../components/DetailsButtons'
import { useLocation } from 'react-router-dom'
import { useForm, useFieldArray } from 'react-hook-form'
import { useApi, useLoading } from '../../../hooks'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { handle } from '../../../utils/error-handlers'
import { News } from '../../../models/news'
import { Profile } from '../../../models/profile'
import { ProfileContainer, DeleteButton } from './styles'

interface NewsDetailsProps {
  id?: number
  refetch: () => void
}

interface NewsForm {
  title: string
  text: string
  date: string
  profile: string
  profiles: Profile[]
}

const NewsDetails: React.FC<NewsDetailsProps> = ({ id, refetch }) => {
  const methods = useForm<NewsForm>({ mode: 'onTouched' })
  const {
    register,
    control,
    setValue,
    reset
  } = methods
  const {
    fields,
    append,
    remove
  } = useFieldArray<Profile, 'customId'>({
    control,
    name: 'profiles',
    keyName: 'customId'
  })
  const { pathname } = useLocation()
  const {
    httpPost,
    httpGet,
    httpPut
  } = useApi()
  const { load, loading } = useLoading()

  const handleSubmit = (data: NewsForm): void => {
    if (!fields.length) {
      toast.error('Selecione pelo menos um setor.')
      return
    }
    const category = `${pathname.charAt(1).toUpperCase()}${pathname.substring(2, pathname.lastIndexOf('/'))}`
    const { date, text, title } = data
    const formattedDate = new Date(date)
    const body = {
      title,
      text,
      date: formattedDate,
      category,
      profiles: fields.map(field => field.id)
    }
    handle(async () => {
      if (id && id > 0) {
        await httpPut(`/news/${id}`, body)
      } else {
        await httpPost('/news/', body)
      }
      toast.success('Informativo salvo com sucesso.')
      refetch()
    })
  }

  const setNewsData = useCallback((news: News) => {
    const {
      title,
      date,
      text,
      profiles
    } = news
    reset({
      date: format(new Date(date), 'yyyy-MM-dd'),
      profile: '',
      profiles,
      text,
      title
    })
  }, [reset])

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await load(async () => {
        if (id && id > -1) {
          const { body } = await httpGet<News>(`/news/${id}`)
          setNewsData(body as News)
        }
      })
    }
    fetchData()
  }, [id, httpGet, load, setNewsData])

  return (
    <>
      <Loader overlay loading={loading} />
      <Form
        methods={methods}
        onSubmit={handleSubmit}
      >
        <Input
          label="Título"
          name="title"
          register={register({
            required: {
              message: 'Preencha este campo.',
              value: true
            }
          })}
        />
        <Input
          label="Data"
          name="date"
          type="date"
          register={register({
            required: {
              message: 'Preencha este campo.',
              value: true
            }
          })}
        />
        <TextEditor
          name="text"
          label="Texto"
        />
        <SelectApi
          readonly
          displayProperty="name"
          label="Setores"
          name="profile"
          register={register}
          url="/profiles"
          valueProperty="id"
          onSelect={(item) => {
            if (item) {
              const profile = fields.find(field => field.id === Number(item.value))
              if (!profile) {
                const value: Profile = {
                  id: Number(item.value),
                  name: item.display
                }
                append(value)
              }
            }
            setValue('profile', undefined)
          }}
        />
        {fields.map((field, index) => (
          <ProfileContainer key={field.customId}>
            <input
              type="hidden"
              ref={register()}
              name={`profiles[${index}].id`}
            />
            <p>{field.name}</p>
            <DeleteButton onClick={() => {
              remove(index)
            }}>
              <MdDelete />
            </DeleteButton>
          </ProfileContainer>
        ))}
        <DetailsButtons>
          <Button
            buttonType="primary"
            type="submit"
          >
            Salvar
          </Button>
        </DetailsButtons>
      </Form>
    </>
  )
}

export default NewsDetails
