import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const InputStyle = styled.input`
  width: 100%;
  border: none;
  padding: 5px 0;
  border-bottom: 2px solid ${colors.borders};
  outline: none;
`
