import React, { useState } from 'react'
import {
  useApi,
  useDetailsContainer,
  useModal
} from '../../../hooks'
import Input from '../../../components/Input'
import { useForm } from 'react-hook-form'
import Form from '../../../components/Form'
import { handle } from '../../../utils/error-handlers'
import { toast } from 'react-toastify'
import Button from '../../../components/Button'
import { AcquisitionOrderModel } from '../../../models/acquisition-order'
import SelectApi from '../../../components/Select/api'

interface AttProductProps {
  data: AcquisitionOrderModel
  index: number
  setAttProduct: any
}

interface AttProductForm {
  codccu: string
  coddep: string
  codemp: string
  codfil: string
  codpro: string
  numocp: string
  preuni: string
  qtdped: string
  percrt: string
  perpit: string
  pericm: string
  peripi: string
  perirf: string
  percsl: string
  usu_codbem: string
}

const AttProduct: React.FC<AttProductProps> = ({
  index,
  data,
  setAttProduct
}) => {
  const { httpPatch } = useApi()
  const methods = useForm<AttProductForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const [loading, setLoading] = useState(false)
  const item = data.produtos[index]

  const handleSendData = async (formData: AttProductForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        codemp: data.codemp,
        codfil: data.codfil,
        codfor: data.codfor,
        datemi: data.datemi,
        numocp: data.numocp,
        obsocp: data.obsocp,
        produtos: {
          codccu: formData.codccu === '' ? item.codccu : formData.codccu,
          coddep: formData.coddep,
          codemp: data.codemp,
          codfil: data.codfil,
          codpro: formData.codpro,
          numocp: data.numocp,
          percrt: formData.percrt,
          perpit: formData.perpit,
          pericm: formData.pericm,
          peripi: formData.peripi,
          perirf: formData.perirf,
          percsl: formData.percsl,
          preuni: formData.preuni,
          qtdped: formData.qtdped,
          seqipo: index + 1,
          usu_codbem: formData.usu_codbem === '' ? item.usu_codbem : formData.usu_codbem
        },
        tnspro: data.tnspro
      }
      const response = await httpPatch<string>('/acquisition-order/update-acquisition-order', json)

      toast.success(response.body)
      if (response.body === 'OK') {
        clearData()
      }
      setLoading(false)
      close()
      setAttProduct(true)
    })
  }

  return (
    <div>
      <Form methods={methods} onSubmit={handleSendData}>
        <div className="info-container">
          <>
            <section>
              <SelectApi
                displayProperty="desccu"
                readonly={false}
                label="Centro de Custo"
                name="codccu"
                register={register({})}
                url={`/acquisition-order/list-cost-center/${data.codemp}`}
                valueProperty="codccu"
              />
              <Input
                label="Depósito"
                name="coddep"
                defaultValue={item.coddep}
                register={register({})}
              />
              <Input
                label="Empresa"
                name="codemp"
                defaultValue={item.codemp}
                register={register({})}
              />
              <Input
                label="Filial"
                name="codfil"
                defaultValue={item.codfil}
                register={register({})}
              />
              <Input
                label="Produto"
                name="codpro"
                defaultValue={item.codpro}
                register={register({})}
              />
              <Input
                label="PIS %"
                name="perpit"
                register={register({
                })}
              />
              <Input
                label="ICMS %"
                name="pericm"
                register={register({
                })}
              />
              <Input
                label="COFINS %"
                name="percrt"
                register={register({
                })}
              />
              <Input
                label="IPI %"
                name="peripi"
                register={register({
                })}
              />
              <Input
                label="IRRF %"
                name="perirf"
                register={register({
                })}
              />
              <Input
                label="PIS %"
                name="perpit"
                register={register({
                })}
              />
              <Input
                label="CSLL %"
                name="percsl"
                register={register({
                })}
              />
              <Input
                label="Preço Unitário"
                name="preuni"
                defaultValue={item.preuni}
                register={register({})}
              />
              <Input
                label="Quantidade Pedida"
                name="qtdped"
                defaultValue={item.qtdped}
                register={register({})}
              />
              <SelectApi
                displayProperty="desbem"
                readonly={false}
                label="Patrimonio"
                name="codbem"
                register={register({})}
                url={`/acquisition-order/list-patrimony/${data.codemp}`}
                valueProperty="codbem"
              />
            </section>
          </>
          <Button
            buttonType="primary"
            type="submit"
            disabled={loading}
          >
            Atualizar
          </Button>
          <span style={{ display: 'inline-block', width: '5px' }} />
          <Button
            buttonType="secondary"
            type="button"
            onClick={close}
          >
            Cancelar
          </Button>
        </div>
      </Form>
    </div >
  )
}
export default AttProduct
