import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  sideMenuAction,
  SideMenuActions
} from '../redux/side-menu/side-menu-actions'

interface UseSideMenu {
  opened: boolean
  open: () => void
  close: () => void
  toggle: () => void
}

export const useSideMenu = (): UseSideMenu => {
  const opened = useSelector((state: any): boolean => state.sideMenu.opened)
  const dispatch = useDispatch()

  const open = useCallback((): void => {
    dispatch(sideMenuAction(SideMenuActions.SET_OPENED))
  }, [dispatch])

  const close = useCallback((): void => {
    dispatch(sideMenuAction(SideMenuActions.SET_CLOSED))
  }, [dispatch])

  const toggle = useCallback((): void => {
    dispatch(sideMenuAction(SideMenuActions.TOGGLE))
  }, [dispatch])

  return {
    open,
    close,
    toggle,
    opened
  }
}
