import React from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import { BiddingModel } from '../../../models/bidding'
import Input from '../../../components/Input'
import Label from '../../../components/Label'
import IconButton from '../../../components/IconButton'
import { MdModeEdit } from 'react-icons/md'

interface AttModalProps {
  data: BiddingModel
  openAttCodcliModal: (data: BiddingModel) => () => void
  openAttCodfpgModal: (data: BiddingModel) => () => void
  refetch: () => void
}

interface AttForm {
  codCli: string
  nomcli: string
  codFpg: string
  usu_codemp: string
  usu_CodFpg: string
  usu_DatEnt: string
  usu_datlic: string
  usu_horlic: string
  usu_plalic: string
  usu_stalic: string
  usu_obslic: string
}

const AttModal: React.FC<AttModalProps> = ({
  data,
  openAttCodcliModal,
  openAttCodfpgModal,
  refetch
}) => {
  const methods = useForm<AttForm>({
    defaultValues: {
      usu_codemp: data.usu_codemp,
      usu_obslic: data.usu_obslic,
      usu_plalic: data.usu_plalic,
      usu_datlic: data.usu_datlic,
      usu_horlic: data.usu_horlic,
      nomcli: data.nomcli
    }
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPatch } = useApi()

  const handleSendData = async (formData: AttForm): Promise<void> => {
    await handle(async () => {
      const response = await httpPatch<string>(`/bidding/codcli/${data.usu_numlic}`, {
        usu_codemp: data.usu_codemp,
        usu_codfpg: data.usu_CodFpg,
        usu_datent: data.usu_DatEnt,
        usu_datlic: formData.usu_datlic,
        usu_horlic: formData.usu_horlic,
        usu_numlic: data.usu_numlic,
        usu_plalic: formData.usu_plalic,
        usu_obslic: formData.usu_obslic,
        usu_stalic: data.usu_stalic,
        usu_codcli: data.usu_codcli,
        usu_nomcli: data.nomcli
      })
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Atualizar Licitações </h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Atualizar Data da Licitação"
          type="text"
          name="usu_datlic"
          register={register({})}
        />
        <Input
          label="Atualizar Hora da Licitação"
          type="time"
          name="usu_horlic"
          register={register({})}
        />
        <Input
          label="Atualizar Plataforma da Licitação"
          type="text"
          name="usu_plalic"
          register={register({})}
        />
        <Input
          label="Atualizar Observação da Licitação"
          type="text"
          name="usu_obslic"
          register={register({})}
        />
        <Label
          title="Atualizar Cliente"
          value={
            <IconButton
              icon={<MdModeEdit size={20} />}
              padding={3}
              size={18}
              onClick={openAttCodcliModal(data)}
            />
          }
        />
        <Label
          title='Atualizar Forma de Pagamento${}'
          value={
            <IconButton
              icon={<MdModeEdit size={20} />}
              padding={3}
              size={18}
              onClick={openAttCodfpgModal(data)}
            />
          }
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Atualizar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AttModal
