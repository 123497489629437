import React, {
  useState,
  useCallback,
  useEffect
} from 'react'
import Loader from '../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../components/Table'
import Filter from './Filter'
import BiddingDetails from './BiddingDetails'
import {
  useApi,
  useFetch,
  useDetailsContainer,
  useModal,
  useLoading,
  useSelectItems,
  useFilter
} from '../../hooks'
import { toast } from 'react-toastify'
import IconButton from '../../components/IconButton'
import { Container } from './styles'
import AddModal from './AddModal'
import AttItemModal from './AttItemModal'
import { BiddingModel } from '../../models/bidding'
import BiddingHeader from '../Biddings/BiddingHeader'
import { handle } from '../../utils/error-handlers'
import { MdCancel, MdCheckCircle, MdDelete } from 'react-icons/md'
import AddBiddingModal from './AddBiddingModal'
import AttModal from './AttModal'
import AttQntlicModal from './AttQntlicModal'
import AddProviderModal from './AddProvider'
import AddProvider from './FindProvider'
import AddNewProvider from './AddNewProvider'
import AttCnpjProviderModal from './AttCnpjProvider'
import AttPreforModal from './AttPrefor'
import AttCodfpgModal from './AttCodfpg'
import AttCodcliModal from './AttCodcli'

const Bidding: React.FC = () => {
  const { httpGet, httpPatch, httpDelete, httpPut } = useApi()
  const {
    data,
    loading,
    refetching,
    refetch
  } = useFetch<BiddingModel[]>(httpGet, '/bidding/list-bidding')
  const {
    close,
    clearData,
    pushPage,
    open
  } = useDetailsContainer()
  const { open: openModal, confirm } = useModal()
  const { loading: loadingHook } = useLoading()

  const openAddModal = useCallback((data: BiddingModel): () => void => {
    return () => {
      openModal(
        <AddModal
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const {
    selectedItems
  } = useSelectItems<BiddingModel>(item => {
    let key = ''
    for (const [, value] of Object.entries(item)) {
      key += String(value)
    }
    return key
  })
  const [filter, setFilter] = useState(() => () => true)
  const [lastIndex, setLastIndex] = useState(1)
  const { filtered } = useFilter<BiddingModel>(data, filter)
  useEffect(() => {
    let maxItem = 0
      filtered?.forEach((item, index) => {
        if (index <= 0 || maxItem < parseInt(item.usu_numlic)) {
          maxItem = parseInt(item.usu_numlic)
        }
      })
      setLastIndex(maxItem + 1)
  }, [filtered])

  const openDetails = useCallback((bidding: BiddingModel): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <BiddingDetails
            openDeleteItemModal={openDeleteItemModal}
            openDeleteProviderModal={openDeleteProviderModal}
            openAttCodcliModal={openAttCodcliModal}
            openAttCodfpgModal={openAttCodfpgModal}
            openAttPreforModal={openAttPreforModal}
            data={bidding}
            renderStatus={renderStatus}
            refetch={refetch}
            openAttCnpjProviderModal={openAttCnpjProviderModal}
            openAttModal={openAttModal}
            openAddModal={openAddModal}
            openAttQntlicModal={openAttQntlicModal}
            openAttItemModal={openAttItemModal}
            openAddProviderModal={openAddProviderModal}
          />
        ),
        title: 'Licitações'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close, pushPage, open, refetch])

  const openDeleteItemModal = useCallback((data: BiddingModel, index: number): () => void => {
    return () => {
      confirm('Continuar com a exclusão do item?', () => {
        handle(async () => {
          const response = await httpPut<string>(`/bidding/delete-bidding/${data.usu_numlic}`, {
            usu_codcli: data.usu_codcli,
            usu_codemp: data.usu_codemp,
            usu_codpro: data.itens[index].usu_codpro,
            usu_codfor: ''
          })
          toast.success(response.body)

          close()
          clearData()
          refetch()
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpDelete, confirm, refetch])

  const openDeleteProviderModal = useCallback((data: BiddingModel, index: number): () => void => {
    return () => {
      confirm('Continuar com a exclusão do item?', () => {
        handle(async () => {
          const response = await httpPut<string>(`/bidding/delete-bidding/${data.usu_numlic}`, {
            usu_codcli: data.usu_codcli,
            usu_codemp: data.usu_codemp,
            usu_codpro: data.itens[index].usu_codpro,
            usu_codfor: data.itens[index].fornecedores[index].usu_codfor
          })
          toast.success(response.body)

          close()
          clearData()
          refetch()
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpDelete, confirm, refetch])

  const openAddProviderModal = useCallback((data: BiddingModel, usu_codpro: string, index: number): () => void => {
    return () => {
      pushPage({
        component: (
          <AddProviderModal
            usu_codpro={data.itens[index].usu_codpro}
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Buscar Fornecedor'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const openAttItemModal = useCallback((data: BiddingModel, usu_codpro: string, index: number): () => void => {
    return () => {
      openModal(
        <AttItemModal
          usu_codpro={data.itens[index].usu_codpro}
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openAttCnpjProviderModal = useCallback((data: BiddingModel, usu_codpro: string, usu_codfor: string, index: number): () => void => {
    return () => {
      openModal(
        <AttCnpjProviderModal
          usu_codfor={usu_codfor}
          usu_codpro={usu_codpro}
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openAttPreforModal = useCallback((data: BiddingModel, usu_codpro: string, usu_codfor: string, index: number): () => void => {
    return () => {
      openModal(
        <AttPreforModal
          usu_codfor={usu_codfor}
          usu_codpro={usu_codpro}
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openAttQntlicModal = useCallback((data: BiddingModel, usu_codpro: string, index: number): () => void => {
    return () => {
      openModal(
        <AttQntlicModal
          usu_codpro={data.itens[index].usu_codpro}
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const handleAddClick = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <AddBiddingModal
            biddingNumber={lastIndex}
            refetch={refetch}
          />
        ),
        title: 'Licitações'
      })
      open()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading, filtered])

  const handleProviderClick = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <AddProvider
            openAddNewProvider={openAddNewProvider}
            refetch={refetch}
          />
        ),
        title: 'Buscar Fornecedor'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const openAddNewProvider = useCallback((data: any) => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <AddNewProvider
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Cadastrar Fornecedor'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const handleFilterClick = useCallback(() => {
    if (!loading && !loadingHook && data) {
      openModal(
        <Filter
          setFilter={setFilter}
          data={data ?? []}
        />
      )
      return
    }
    toast.info('Aguarde os dados carregarem.')
  }, [openModal, data, loadingHook, loading])

  const handleUpdateStatus = useCallback((data: BiddingModel, status: number): () => void => {
    return () => {
      const currentStep = Number(data.usu_stalic)
      let usuStaLic = status
      if (status < currentStep) {
        toast.warn('Só é possível editar o status atual.')
        return
      }
      if (status > currentStep && status - 1 !== currentStep) {
        toast.warn('Não é possível pular etapas.')
        return
      }
      if (status === currentStep) {
        usuStaLic = status - 1
      }
      confirm('Continuar com a atualização do status?', () => {
        handle(async () => {
          const response = await httpPatch<string>(`/bidding/status/${data.usu_numlic}`, {
            usu_codcli: data.usu_codcli,
            usu_codemp: data.usu_codemp,
            usu_codfpg: data.usu_CodFpg,
            usu_datent: data.usu_DatEnt,
            usu_datlic: data.usu_datlic,
            usu_horlic: data.usu_horlic,
            usu_plalic: data.usu_plalic,
            usu_numlic: data.usu_numlic,
            usu_stalic: usuStaLic
          })
          toast.success(response.body)
          close()
          refetch()
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpPatch, confirm, refetch])

  const handleDeleteStatus = useCallback((data: BiddingModel): () => void => {
    return () => {
      confirm('Continuar com a exclusão da licitação?', () => {
        handle(async () => {
          const response = await httpPut<string>(`/bidding/delete-bidding/${data.usu_numlic}`, {
            usu_codcli: data.usu_codcli,
            usu_codemp: data.usu_codemp,
            usu_codpro: '',
            usu_codfor: ''
          })
          toast.success(response.body)

          refetch()
        })
      })
    }
  }, [httpPut, confirm, refetch])

  const openAttModal = useCallback((data: BiddingModel): () => void => {
    return () => {
      pushPage({
        component: (
          <AttModal
            openAttCodfpgModal={openAttCodfpgModal}
            openAttCodcliModal={openAttCodcliModal}
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Atualizar Licitação'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const openAttCodfpgModal = useCallback((data: BiddingModel): () => void => {
    return () => {
      pushPage({
        component: (
          <AttCodfpgModal
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Atualizar Forma de Pagamento'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const openAttCodcliModal = useCallback((data: BiddingModel): () => void => {
    return () => {
      pushPage({
        component: (
          <AttCodcliModal
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Atualizar Forma de Pagamento'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const renderStatus = useCallback((item: BiddingModel, step: number): JSX.Element => {
    const currentStep = Number(item.usu_stalic)
    return <IconButton
      icon={step <= currentStep ? <MdCheckCircle color="#33b850" /> : <MdCancel color="#ff5555" />}
      padding={3}
      size={20}
      onClick={handleUpdateStatus(item, step)}
    />
  }, [handleUpdateStatus])

  return (
    <>
      <BiddingHeader
        refetch={refetch}
        onClickProvider={handleProviderClick}
        onClickFilter={handleFilterClick}
        openAddBiddingModal={handleAddClick}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!filtered.length}
            selected={selectedItems.length}
            overflow="auto"
          >
            <THead>
              <Tr>
                <Th width="40px" color="#000" >Empresa</Th>
                <Th width="40px" color="#000" >Processo Lic.</Th>
                <Th width="40px" className="hide-md" color="#000" >Cód.Cliente</Th>
                <Th width="120px" className="hide-md" color="#000" >Cliente</Th>
                <Th width="120px" color="#000" >Observação</Th>
                <Th width="60px" className="hide-md" color="#000" >Pagamento</Th>
                <Th width="60px" className="hide-sm" color="#000" >Data Ger.</Th>
                <Th width="60px" className="hide-sm" color="#000" >Hora Ger.</Th>
                <Th width="60px" className="hide-sm" color="#000" >Data Lic.</Th>
                <Th width="40px" className="hide-sm" color="#000" >Hora Lic.</Th>
                <Th
                  textAlign="center"
                  width="45px"
                  className="hide-md"
                  color="#000"
                >
                  Aguardando
                </Th>
                <Th
                  textAlign="center"
                  width="45px"
                  className="hide-md"
                  color="#000"
                >
                  Iniciada
                </Th>
                <Th
                  textAlign="center"
                  width="45px"
                  className="hide-md"
                  color="#000"
                >
                  Encerrada
                </Th>
                <Th
                  textAlign="center"
                  width="45px"
                  className="hide-md"
                  color="#000"
                >
                  Cancelada
                </Th>
                <Th
                  textAlign="center"
                  width="45px"
                  className="hide-md"
                  color="#000"
                >
                  Excluir
                </Th>
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item, index) => (
                <Tr key={index}>
                  <Td title={item.usu_codemp}>{item.usu_codemp}</Td>
                  <Td title={item.usu_numlic}> <button
                    className="first-item"
                    onClick={openDetails(item)}
                  >
                    {item.usu_numlic}
                  </button>
                  </Td>
                  <Td title={item.usu_codcli}>{item.usu_codcli}</Td>
                  <Td title={item.nomcli}>{item.nomcli}</Td>
                  <Td title={item.usu_obslic}>{item.usu_obslic}</Td>
                  <Td title={item.usu_CodFpg}>{item.usu_CodFpg}</Td>
                  <Td title={item.usu_datger}>{item.usu_datger}</Td>
                  <Td title={item.usu_horger}>{item.usu_horger}</Td>
                  <Td title={item.usu_datlic}>{item.usu_datlic}</Td>
                  <Td title={item.usu_horlic}>{item.usu_horlic}</Td>
                  <Td textAlign="center" className="hide-md">{renderStatus(item, 1)}</Td>
                  <Td textAlign="center" className="hide-md">{renderStatus(item, 2)}</Td>
                  <Td textAlign="center" className="hide-md">{renderStatus(item, 3)}</Td>
                  <Td textAlign="center" className="hide-md">{renderStatus(item, 4)}</Td>
                  <Td textAlign="center" className="hide-md">
                    <IconButton
                      icon= {<MdDelete size={20}/>}
                      padding={3}
                      size={20}
                      onClick={handleDeleteStatus(item)}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default Bidding
