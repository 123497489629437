import React from 'react'
import Label from '../../../components/Label'
import {
  useLoading
} from '../../../hooks'
import Loader from '../../../components/Loader'
import { Container } from './styles'
import { Tr, Th, Table, THead, TBody, Td } from '../../../components/Table'
import { CostTableModel } from '../../../models/cost-table'
import IconButton from '../../../components/IconButton'
import { MdSearch } from 'react-icons/md'

interface CostTableDetailsProps {
  data: CostTableModel
  openAttMarginCost: (data: CostTableModel) => () => void
  openAttBasePrice: (data: CostTableModel) => () => void
  openAttTolerancecCost: (data: CostTableModel) => () => void
  refetch: () => void
}

const CostTableDetails: React.FC<CostTableDetailsProps> = ({
  data,
  openAttMarginCost,
  openAttBasePrice,
  openAttTolerancecCost,
  refetch
}) => {
  const { loading } = useLoading()

  return (
    <Container>
      <Loader
        loading={loading}
        overlay
        message="Processando..."
      />
      <div className="info">
        <section>
          <Label
            title="Empresa"
            value={data.codemp}
          />
          <Label
            title="Tabela"
            value={data.codtpr}
          />
          <Label
            title="Descrição Tabela"
            value={data.destpr}
          />
          <Label
            title="Código Produto"
            value={data.codpro}
          />
          <Label
            title="Preço Médio"
            value={data.premed}
          />
          <Label
            title="% Curva ABC"
            value={data.usu_curabc}
          />
          <Label
            title="% IRPJ"
            value={data.usu_vlripj}
          />
          <Label
            title="% Comissão"
            value={data.percom}
          />
        </section>
        <section>
          <Label
            title="Descrição Produto"
            value={data.descricaoProdutoDerivacao}
          />
          <Label
            title="% Custo Direto"
            value={data.usu_cusdir}
          />
          <Label
            title="% Custo Indireto"
            value={data.usu_cusind}
          />
          <Label
            title="% Despesas"
            value={data.usu_vlrdes}
          />
          <Label
            title="% Pis"
            value={data.usu_vlrpis}
          />
          <Label
            title="% Cofins"
            value={data.usu_vlrcof}
          />
          <Label
            title="% Csll"
            value={data.usu_vlrcsl}
          />
        </section>
      </div>
      <Table layout="fixed">
        <THead>
          <Tr>
            <Th color="black" width="30px">% Menor</Th>
            <Th color="black" width="30px">% Mar. Lucro</Th>
            <Th color="black" width="30px">Preço Base</Th>
          </Tr>
        </THead>
        <TBody>
          <Tr>
            <Td>
              {data.tolmen}
              <IconButton
                icon={<MdSearch size={20} />}
                padding={3}
                size={18}
                onClick={openAttTolerancecCost(data)}
              />
            </Td>
            <Td>
              {data.usu_marluc}
              <IconButton
                icon={<MdSearch size={20} />}
                padding={3}
                size={18}
                onClick={openAttMarginCost(data)}
              />
            </Td>
            <Td>
              {data.prebas}
              <IconButton
                icon={<MdSearch size={20} />}
                padding={3}
                size={18}
                onClick={openAttBasePrice(data)}
              />
            </Td>
          </Tr>
        </TBody>
      </Table>
    </Container>
  )
}

export default CostTableDetails
