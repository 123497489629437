import React, {
  useState,
  useCallback
} from 'react'
import Loader from '../../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import Dropdown from '../../../components/Dropdown'
import DynamicIcon from '../../../components/DynamicIcon'
import Checkbox from '../../../components/Checkbox/table'
import PurchaseOrderDetails from './PurchaseOrderDetails'
import ApprovalsHeader from '../ApprovalsHeader'
import {
  useApi,
  useFetch,
  useDetailsContainer,
  useModal,
  useLoading,
  useFilter,
  useSelectItems
} from '../../../hooks'
import { PurchaseOrder } from '../../../models/purchase-order'
import { Container } from './styles'
import { handle } from '../../../utils/error-handlers'
import { toast } from 'react-toastify'
import Filter from './Filter'
import IconButton from '../../../components/IconButton'

const PurchaseOrders: React.FC = () => {
  const { httpGet, httpPut } = useApi()
  const {
    data,
    loading,
    refetching,
    refetch
  } = useFetch<PurchaseOrder[]>(httpGet, '/purchase-orders/pending')
  const {
    close,
    pushPage,
    open
  } = useDetailsContainer()
  const { confirm, open: openModal } = useModal()
  const { load, loading: loadingHook } = useLoading()
  const [filter, setFilter] = useState(() => (item: any, index: number, array: any[]) => true)
  const { filtered } = useFilter<PurchaseOrder>(data, filter)
  const {
    handleSelect,
    handleSelectAll,
    isSelected,
    selectedItems
  } = useSelectItems<PurchaseOrder>(item => item.numOcp)

  const openDetails = useCallback((purchaseOrder: PurchaseOrder): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <PurchaseOrderDetails
            data={purchaseOrder}
            refetch={refetch}
          />
        ),
        title: 'Ordem de compra'
      })
      open()
    }
  }, [close, pushPage, open, refetch])

  const handleApprove = useCallback((
    items: PurchaseOrder[],
    action: 'aprove' | 'reprove'
  ): () => void => {
    return () => {
      switch (action) {
        case 'aprove':
          confirm('Continuar com a aprovação da ordem de compra?', () => {
            handle(async () => {
              load(async () => {
                for (const item of items) {
                  await httpPut(`/purchase-orders/approve/${item.numOcp}`, {
                    codEmp: item.codEmp,
                    codFil: item.codFil
                  })
                  toast.success('Ordem aprovada.')
                  refetch()
                }
              })
            })
          })
          return
        case 'reprove':
          confirm('Continuar com a reprovação da ordem de compra?', () => {
            handle(async () => {
              load(async () => {
                for (const item of items) {
                  await httpPut(`/purchase-orders/reprove/${item.numOcp}`, {
                    codemp: item.codEmp,
                    codfil: item.codFil
                  })
                  toast.success('Ordem reprovada.')
                  refetch()
                }
              })
            })
          })
      }
    }
  }, [confirm, httpPut, load, refetch])

  const handleFilterClick = useCallback(() => {
    if (!loading && !refetching && data) {
      openModal(
        <Filter
          setFilter={setFilter}
          data={data}
        />
      )
      return
    }
    toast.info('Aguarde os dados carregarem.')
  }, [openModal, data, loading, refetching])

  return (
    <>
      <ApprovalsHeader
        refetch={refetch}
        onClickFilter={handleFilterClick}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!filtered?.length}
            selected={selectedItems.length}
            actionButton={
              <Dropdown
                id="table-dropdown"
                left="0"
                options={[{
                  text: 'Aprovar',
                  onClick: handleApprove(selectedItems, 'aprove'),
                  icon: (
                    <DynamicIcon
                      family="md"
                      name="MdCheck"
                      size={15}
                    />
                  )
                }]}
              />}
          >
            <THead>
              <Tr>
                <Th width="40px">
                  <Checkbox
                    onSelect={handleSelectAll(filtered, !(filtered.length === selectedItems.length))}
                    value={(filtered.length === selectedItems.length)}
                  />
                </Th>
                <Th width="75px" color="#000" > Ordem </Th>
                <Th width="100px" className="hide-md" color="#000" > Emissão </Th>
                <Th width="300px" color="#000" > Empresa </Th>
                <Th width="250px" color="#000" > Fornecedor </Th>
                <Th width="180px" color="#000" > Responsável </Th>
                <Th width="400px" className="hide-sm" color="#000" > Observação </Th>
                <Th width="130px" color="#000" > Valor </Th>
                <Th width="00px" color="#000" >Vezes</Th>
                <Th width="120px" color="#000" >Aprovadas</Th>
                <Th width="160px" color="#000" > Estouro  de  Orçamento </Th>
                <Th width="-20px" color="#000" />
                <Th width="10px" color="#000" />
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item, index) => (
                <Tr key={index}>
                  <Td className="table-checkbox">
                    <Checkbox
                      value={isSelected(item)}
                      onSelect={handleSelect(item, !isSelected(item))}
                    />
                  </Td>
                  <Td title={item.numOcp}>
                    <button
                      className="first-item"
                      onClick={openDetails(item)}
                    >
                      {item.numOcp}
                    </button>
                  </Td>
                  <Td title={item.datEmi} className="hide-md">{item.datEmi}</Td>
                  <Td title={item.nomFor}>{item.nomEmp}</Td>
                  <Td title={item.nomEmp}>{item.nomFor}</Td>
                  <Td title={item.nomSol}>{item.nomSol}</Td>
                  <Td title={item.obsOcp} className="hide-sm">{item.obsOcp}</Td>
                  <Td title={item.vlrApr}> R$ {item.vlrApr}</Td>
                  <Td title={item.vezesApr}>{item.vezesApr}</Td>
                  <Td title={item.vezesApr}>{item.vezesApr === '1' || item.vezesApr === '0'
                    ? <>
                      <Td>
                        <IconButton
                          padding={1}
                          size={20}
                          icon={(
                            <DynamicIcon
                              family="md"
                              name="MdCheck"
                              size={20}
                              color="#00ff0d"
                            />
                          )}
                        />
                      </Td>
                    </>
                    : item.vezesApr === '2'
                      ? <> <Td>
                        <IconButton
                          padding={1}
                          size={20}
                          icon={(
                            <DynamicIcon
                              family="md"
                              name="MdCheck"
                              size={20}
                              color="#e7eb26"
                            />
                          )}
                        />
                      </Td>
                      </>
                      : <Td>
                        <IconButton
                          padding={1}
                          size={20}
                          icon={(
                            <DynamicIcon
                              family="md"
                              name="MdCheck"
                              size={20}
                              color="#f01616"
                            />
                          )}
                        />
                      </Td>}
                  </Td>
                  <Td title={item.obsmot}>{item.obsmot === 'Estouro de Orçamento' || item.obsmot === ''
                    ? <>
                      <Td>
                        <IconButton
                          padding={0}
                          size={20}
                          icon={(
                            <DynamicIcon
                              family="md"
                              name="MdReport"
                              size={20}
                              color="#E35259"
                            />
                          )}
                        />
                      </Td>
                    </>
                    : item.obsmot === ''
                      ? <> <Td>
                        <IconButton
                          padding={0}
                          size={20}
                          icon={(
                            <DynamicIcon
                              family="md"
                              name="MdAttachMoney"
                              size={20}
                              color="00ff0d"
                            />
                          )}
                        />
                      </Td>
                      </>
                      : item.obsmot === '' || item.obsmot === 'Estouro de Projeto'
                        ? <Td>
                          <IconButton
                            padding={0}
                            size={20}
                            icon={(
                              <DynamicIcon
                                family="md"
                                name="MdBuild"
                                size={20}
                                color="#E35259"
                              />
                            )}
                          />
                        </Td>
                        : <Td>
                          <IconButton
                            padding={0}
                            size={20}
                            icon={(
                              <DynamicIcon
                                family="md"
                                name="MdAttachMoney"
                                size={20}
                                color="00ff0d"
                              />
                            )}
                          />
                        </Td>
                  }

                  </Td>

                  <Td textAlign="center">
                    <Dropdown
                      id={`dropdown${index}`}
                      options={[{
                        text: 'Aprovar',
                        onClick: handleApprove([item], 'aprove'),
                        icon: (
                          <DynamicIcon
                            family="md"
                            name="MdCheck"
                            size={30}
                          />
                        )
                      }, {
                        text: 'Reprovar',
                        onClick: handleApprove([item], 'reprove'),
                        icon: (
                          <DynamicIcon
                            family="md"
                            name="MdClose"
                            size={30}
                          />
                        )
                      }]}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default PurchaseOrders
