import styled, { css } from 'styled-components'
import { colors } from '../../styles/default-styles'

export const Container = styled.div<{ overflow: string }>`
  border: 1px solid ${colors.borders};
  padding: 10px;
  border-radius: 2px;
  background-color: white;
  overflow: ${props => props.overflow};

  > p, > div {
    color: ${colors.mediumGray};
    padding: 5px;

    &.selected {
      width: 100%;
      border-bottom: 1px solid ${colors.lightBorders};
      padding: 0 0 5px;
      display: flex;
      align-items: center;
    }
  }
`

export const Table = styled.table<{ layout: string }>`
  width: 100%;
  border: none;
  border-collapse: collapse;
  text-align: left;
  table-layout: ${props => props.layout};
`

export const THead = styled.thead`
  tr {
    border-bottom: 1px solid ${colors.lightBorders};
    padding: 5px 0;
  }
`

interface ThProps {
  width: string
  textAlign: string
  color?: string
}

export const Th = styled.th<ThProps>`
  text-align: ${props => props.textAlign};
  color: ${props => props.color ?? colors.borders};
  text-transform: uppercase;
  font-size: 10px;
  padding: 5px 0 5px 5px;
  width: ${props => props.width};
  min-width: ${props => props.width};

  @media(max-width: 850px) {
    &.mobile-hidden {
      display: none;
    }
  }
`

export const TBody = styled.tbody`
  tr {
    border-bottom: 1px solid ${colors.lightBorders};
    vertical-align: middle;
  }

  tr:hover {
    background-color: ${colors.lightGray};
  }

  .first-item,
  tr td:first-child,
  tr td:first-child > a,
  tr td:first-child > button {
    color: #444;
    font-weight: 600;
    border: none;
    background-color: transparent;
  }

  .first-item:hover,
  tr td:first-child > a:hover,
  tr td:first-child > button:hover {
    cursor: pointer;
    color: ${colors.mediumGray};
  }

  .table-checkbox {
    text-overflow: unset !important;
  }
`

interface TdProps {
  textAlign: 'left' | 'center' | 'right'
  width?: string
  removeNoWrap: boolean
}

export const Td = styled.td<TdProps>`
  display: table-cell;
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  padding: 8px 10px 8px 5px;
  vertical-align: middle;
  text-anchor: middle;
  font-size: 13px;
  max-width: 0;
  color: ${colors.mediumGray};
  ${props => !props.removeNoWrap && css`
    text-overflow: ellipsis;
    white-space: nowrap;
  `}

  button {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  &:not(:last-child) {
    overflow: hidden;
  }

  button {
    text-align: ${props => props.textAlign};
  }

  @media(max-width: 850px) {
    &.mobile-hidden {
      display: none;
    }
  }
`
