import React from 'react'
import GlobalStyle from './styles/global-style'
import Routes from './pages/Routes'
import ReduxProvider from './redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App: React.FC = () => {
  return (
    <ReduxProvider>
      <GlobalStyle />
      <Routes />
      <ToastContainer />
    </ReduxProvider>
  )
}

export default App
