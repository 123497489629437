import React from 'react'
import Form from '../../../components/Form'
import Select from '../../../components/Select'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import { Container } from './styles'
import { CostTableModel } from '../../../models/cost-table'
import { getUniqueOptions } from '../../../utils/select-options'

interface FilterForm {
  produto: string
  tabela: string
}

interface FilterProps {
  setFilter: any
  refetch: () => void
  data: CostTableModel[]
}

const Filter: React.FC<FilterProps> = ({
  setFilter,
  refetch,
  data
}) => {
  const methods = useForm<FilterForm>({})
  const { register } = methods

  const handleSubmit = (data: FilterForm): void => {
    setFilter(data)
  }

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        methods={methods}
      >
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'destpr')}
            readonly
            label="Tabela"
            name="tabela"
            register={register()}
          />
        </div>
        <div className="dentro">
          <Select
            options={getUniqueOptions(data, 'descricaoProdutoDerivacao')}
            readonly
            label="Produto"
            name="produto"
            register={register()}
          />
        </div>
        <div className="dentro">
          <Button
            buttonType="primary"
            type="submit"
          >
          Filtrar
          </Button>
          <Button
            buttonType="secondary"
            type="button"
            onClick={refetch}
          >
          Atualizar
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default Filter
