import { Action } from '../types'

export enum ModalActions {
  OPEN = 'MODAL_OPEN',
  CLOSE = 'MODAL_CLOSE'
}

export const modalAction = (
  action: ModalActions,
  data: any = {}
): Action => {
  return {
    type: action,
    ...data
  }
}
