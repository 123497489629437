import React, { useEffect, useState } from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useApi,
  useDetailsContainer
} from '../../../hooks'
import Select from '../../../components/Select'
import SelectApi from '../../../components/Select/api'
import { handle } from '../../../utils/error-handlers'
import { toast } from 'react-toastify'

interface AddProviderProps {
  data: any
}

interface AddProviderForm {
  apefor: string
  baifor: string
  ccbfor: string
  cepfor: string
  cgccpf: string
  cidfor: string
  codage: string
  codban: string
  codcpg: string
  codfor: string
  codFpg: string
  cplend: string
  endfor: string
  fonfor: string
  insest: string
  intnet: string
  nenfor: string
  nomfor: string
  sigufs: string
  sitfor: string
  tipemp: string
  tipfor: string
  tipmer: string
  usu_enqemp: string
}

const AddProvider: React.FC<AddProviderProps> = ({ data }) => {
  const methods = useForm<AddProviderForm>({
    mode: 'onTouched',
    defaultValues: {
      apefor: data.razao_social,
      baifor: data.bairro,
      cepfor: data.cep,
      cgccpf: data.cnpj,
      cidfor: data.municipio,
      codage: '',
      codban: 'N',
      cplend: 'Empresa',
      endfor: data.logradouro,
      fonfor: data.telefone_1,
      insest: 'ISENTO',
      intnet: data.email,
      nenfor: data.numero,
      nomfor: data.razao_social,
      sigufs: data.uf,
      sitfor: 'A',
      tipfor: 'J',
      tipemp: 'J',
      tipmer: 'I'
    }
  })

  const { register, watch, setValue } = methods
  const { close, clearData } = useDetailsContainer()
  const { httpPost } = useApi()
  const [loading, setLoading] = useState(false)
  const codban = watch('codban')

  const handleSendData = async (formData: AddProviderForm): Promise<void> => {
    setLoading(true)

    await handle(async () => {
      const json = {
        apefor: formData.apefor,
        baifor: formData.baifor,
        ccbfor: formData.ccbfor,
        cepfor: formData.cepfor,
        cgccpf: formData.cgccpf,
        cidfor: formData.cidfor,
        codage: formData.codage,
        codban: formData.codban,
        codcpg: formData.codcpg,
        codfor: formData.codfor,
        codfpg: formData.codFpg,
        cplend: formData.cplend,
        endfor: formData.endfor,
        fonfor: formData.fonfor,
        insest: formData.insest,
        intnet: formData.intnet,
        nenfor: formData.nenfor,
        nomfor: formData.nomfor,
        sigufs: formData.sigufs,
        sitfor: formData.sitfor,
        tipemp: formData.tipemp,
        tipfor: formData.tipfor,
        tipmer: formData.tipmer,
        usu_enqemp: formData.usu_enqemp
      }

      const response = await httpPost<string>('/register/insert-provider', json)

      if (response.body !== 'OK') {
        toast.error(response.body ?? 'Ocorreu um erro ao inserir o fornecedor.')

        return
      }

      toast.success(response.body)
      clearData()
    })

    setLoading(false)
  }

  useEffect(() => {
    setValue('usu_enqemp', data.enquadramento_tipo.toString())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <h3>Cadastrar Novo Fornecedor</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Nome Fornecedor"
          name="nomfor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Nome Fantasia"
          name="apefor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Tipo Fornecedor"
          name="tipfor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Select
          readonly
          label="Tipo Empresa"
          name="tipemp"
          options={[
            {
              display: '1 - INDUSTRIA',
              value: '1'
            },
            {
              display: '2 - DISTRIBUIDOR',
              value: '2'
            },
            {
              display: '3 - COMÉRCIO',
              value: '3'
            },
            {
              display: '67 - PRESTADOR DE SERVIÇOS',
              value: '67'
            }
          ]}
          register={register()}
        />
        <Input
          label="Tipo Mercado"
          name="tipmer"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="CPF/CNPJ"
          name="cgccpf"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Select
          readonly
          label="Enquadramento"
          name="usu_enqemp"
          register={register()}
          options={[
            {
              display: 'Não informado',
              value: '1'
            },
            {
              display: 'Imunes',
              value: '2'
            },
            {
              display: 'Isentas',
              value: '3'
            },
            {
              display: 'Lucro Arbitrado',
              value: '4'
            },
            {
              display: 'Lucro Presumido',
              value: '5'
            },
            {
              display: 'Lucro Real',
              value: '6'
            }
          ]}
        />
        <Input
          label="Inscrição Estadual"
          name="insest"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Endereço Fornecedor"
          name="endfor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Número do Endereço"
          name="nenfor"
          register={register({
          })}
        />
        <Input
          label="Bairro Fornecedor"
          name="baifor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Complemento Endereço"
          name="cplend"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Cidade Fornecedor"
          name="cidfor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Estado Fornecedor"
          name="sigufs"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="CEP Fornecedor"
          name="cepfor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="E-mail Fornecedor"
          name="intnet"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Telefone Fornecedor"
          name="fonfor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="descpg"
          readonly={false}
          label="Condição de Pagamento"
          name="codcpg"
          register={register({
            required: {
              message: 'Selecione um valor',
              value: true
            }
          })}
          url={'/salesorder/list-payment-condition/2/1/1'}
          valueProperty="codcpg"
        />
        <Input
          label="Situação Fornecedor"
          name="sitfor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="nomban"
          readonly={false}
          label="Código Banco"
          name="codban"
          register={register({
          })}
          url={'/register/list-bank'}
          valueProperty="codban"
        />
        <SelectApi
          displayProperty="nomage"
          readonly={false}
          label="Código Agência"
          name="codage"
          register={register({
          })}
          url={`/register/list-agencies/${codban}`}
          valueProperty="codage"
        />
        <Input
          label="Conta Fornecedor"
          name="ccbfor"
          register={register({})}
        />
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AddProvider
