import React, {
  useCallback,
  useEffect
} from 'react'
import {
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td
} from '../../../components/Table'
import Dropdown from '../../../components/Dropdown'
import Filter from '../../../components/Filter'
import { FaTrashAlt } from 'react-icons/fa'
import UserDetails from './UserDetails'
import Loader from '../../../components/Loader'
import {
  Link,
  useLocation,
  useHistory
} from 'react-router-dom'
import {
  useDetailsContainer,
  useApi,
  useFetch,
  useModal
} from '../../../hooks'
import { toast } from 'react-toastify'
import { User } from '../../../models/user'
import { handle } from '../../../utils/error-handlers'
import { Container } from './styles'

const Users: React.FC = () => {
  const { httpDelete, httpGet } = useApi()
  const {
    data,
    loading,
    refetch
  } = useFetch<User[]>(httpGet, '/users')
  const {
    pushPage,
    open,
    close
  } = useDetailsContainer()
  const { search } = useLocation()
  const { replace } = useHistory()
  const { confirm } = useModal()

  const openDetails = useCallback((id?: number): void => {
    close()
    pushPage({
      component: (
        <UserDetails id={id} refetch={refetch} />
      ),
      title: 'Usuário',
      onClose: () => {
        replace('/configuracoes/usuarios')
      }
    })
    open()
  }, [close, pushPage, open, replace, refetch])

  useEffect(() => {
    httpGet('/users')
  }, [httpGet])

  useEffect(() => {
    if (search?.includes('?id=')) {
      const id = Number(search.substr(search.indexOf('=') + 1))
      openDetails(id)
    }
  }, [search, openDetails])

  const renderRow = (row: User, index: number): JSX.Element => (
    <Tr key={index}>
      <Td title={row.name}>
        <Link to={`/configuracoes/usuarios?id=${row.id}`}>
          {row.name}
        </Link>
      </Td>
      <Td title={row.username}>{row.username}</Td>
      <Td>
        <Dropdown
          id="opcao"
          options={[
            {
              text: 'Excluir',
              icon: <FaTrashAlt size={15} />,
              onClick: () => {
                confirm('Tem certeza que deseja excluir esse usuário?', () => {
                  handle(async () => {
                    await httpDelete(`/users/${row.id}`)
                    toast.success('Usuário excluído com sucesso.')
                    refetch()
                  })
                })
              }
            }
          ]}
        />
      </Td>
    </Tr>
  )

  return (
    <>
      <Filter onClickAdd={() => {
        openDetails()
      }} />
      <Container>
        <Loader loading={loading}>
          <Table empty={!data}>
            <THead>
              <Tr>
                <Th width="250px">Nome</Th>
                <Th>Login</Th>
                <Th width="50px"></Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map((item: User, index: number) => renderRow(item, index))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default Users
