import React, { useState } from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'
import { SalesOrderModel } from '../../../models/salesorder'
import Label from '../../../components/Label'

interface AddItemModalProps {
  data: SalesOrderModel
  refetch: () => void
}

interface AddItemForm{
  preuni: string
  qtdPed: string
  codpro: string
  codccu: string
  coddep: string
  seqIpd: string
}

const AddItemModal: React.FC<AddItemModalProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AddItemForm>({})
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()
  const [productDefault, setProductDefault] = useState<any>()
  const codpro = methods.watch('codpro')

  const handleSendData = async (formData: AddItemForm): Promise<void> => {
    await handle(async () => {
      const json = {
        codCli: data.codcli,
        codemp: data.codemp,
        codCpg: data.codcpg,
        codVen: data.codven,
        codFpg: data.codfpg,
        codfil: data.codfil,
        codrep: data.codrep,
        codtpr: data.codtpr,
        entcli: data.datemi,
        numPed: data.numped,
        obsPed: data.obsped,
        somfre: data.somfre,
        tipfre: data.ciffob,
        tnsPro: data.tnspro,
        orcIte: [{
          codCcu: formData.codccu,
          codDep: formData.coddep,
          codPro: formData.codpro,
          preuni: formData.preuni ? String(formData.preuni).replace('.', ',') : '',
          qtdPed: parseFloat(formData.qtdPed),
          seqIpd: formData.seqIpd
        }]
      }
      const response = await httpPost<string>(`/salesorder/insert-budget-itens/${data.codcli}/${data.codemp}/${data.codfil}/${data.numped}`, json)
      toast.info(response.body)

      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Inserir Item em </h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        { data.codtpr
          ? <SelectApi
            displayProperty="despro"
            readonly={false}
            label="Produto"
            name="codpro"
            register={register({})}
            url={`/salesorder/list-products/${data.codemp}/${data.codcli}/${data.codtpr}/${data.ciffob}/${data.somfre}`}
            valueProperty="codpro"
            onSelect={item => {
              if (item) {
                setProductDefault(item.data)
              }
            }}
          />
          : <></> }
        { data.codtpr
          ? <Label
            title="Código Produto"
            value={codpro}
          />
          : <></> }
        { data.codtpr
          ? <Input
            label="Centro de Custo"
            name="codccu"
            defaultValue={productDefault?.codccu}
            register={register({
            })}
          />
          : <></> }
        { data.codtpr && codpro
          ? <SelectApi
            displayProperty="desdep"
            readonly={false}
            label="Depósito"
            name="coddep"
            register={register({
              required: {
                message: 'Preencha este campo',
                value: true
              }
            })}
            url={`/salesorder/list-deposits/${data.codemp}/${codpro}`}
            valueProperty="coddep"
          />
          : <></> }
        { data.codtpr && codpro
          ? <Label
            title= "Informação valores"
            value={` O valor é de ${parseFloat(productDefault?.minimo).toFixed(4)} e o valor ideal é de ${parseFloat(productDefault?.ideal).toFixed(4)}`}
          />
          : <></> }
        { data.codtpr
          ? <Input
            label="Preço Unitário"
            name="preuni"
            register={register({
            })}
          />
          : <></> }
        { data.codtpr
          ? <Input
            label="Quantidade"
            name="qtdPed"
            register={register({
            })}
          />
          : <></> }
        { data.codtpr
          ? <Input
            label="Sequencial"
            name="seqIpd"
            register={register({
            })}
          />
          : <></> }
        { data.codtpr
          ? <Button
            buttonType="primary"
            type="submit"
          >
          Adicionar
          </Button>
          : <></> }
        <span style={{ display: 'inline-block', width: '5px' }} />
        { data.codtpr
          ? <Button
            buttonType="secondary"
            type="button"
            onClick= {close}
          >
          Fechar
          </Button>
          : <></> }
      </Form>
    </div>
  )
}

export default AddItemModal
