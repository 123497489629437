import React from 'react'
import {
  useApi,
  useDetailsContainer,
  useFetch,
  useModal
} from '../../../hooks'
import { WeighingModel } from '../../../models/acquisition-order'
import Label from '../../../components/Label'
import Input from '../../../components/Input'
import { useForm } from 'react-hook-form'
import Form from '../../../components/Form'
import { handle } from '../../../utils/error-handlers'
import { toast } from 'react-toastify'
import Button from '../../../components/Button'

interface WeighingProps {
  codfil: string
  codemp: string
  numocp: string
  codpro: string
}

interface UpdateWeighingForm {
  pesobruto: string
  pesoliquido: string
  pesomanual: string
}

const Weighing: React.FC<WeighingProps> = ({
  codfil,
  codemp,
  numocp,
  codpro
}) => {
  const { httpGet, httpPatch } = useApi()
  const methods = useForm<UpdateWeighingForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()

  const {
    data
  } = useFetch<WeighingModel[]>(httpGet, `/acquisition-order/list-weighing/${codemp}/${codfil}/${codpro}/${numocp}`)

  const handleSendData = async (formData: UpdateWeighingForm): Promise<void> => {
    await handle(async () => {
      const json = {
        pesobruto: formData.pesobruto,
        pesoliquido: formData.pesoliquido,
        pesomanual: formData.pesomanual
      }
      const response = await httpPatch<string>('/acquisition-order/update-weighing', json)

      toast.success(response.body)
      if (response.body === 'OK') {
        clearData()
      }
      close()
    })
  }

  return (
    <div>
      {data?.map((item, index) => (
        <Form methods={methods} onSubmit={handleSendData}>
          <div className="info-container">
            <>
              <section>
                <Label
                  title="Código Produto"
                  value={item?.codpro}
                />
                <Label
                  title="Descrição Produto"
                  value={item?.cplipo}
                />
                <Label
                  title="Data Peso Bruto"
                  value={item?.datapesobruto}
                />
                <Label
                  title="Data Peso Liquido"
                  value={item?.datapesoliquido}
                />
                <Label
                  title="Hora Peso Bruto"
                  value={item?.horapesobruto}
                />
                <Label
                  title="Hora Peso Líquido"
                  value={item?.horapesoliquido}
                />
              </section>
              <section>
                <Label
                  title="Peso Diferença"
                  value={item?.pesodiferenca}
                />
                <Input
                  label="Peso Bruto"
                  name="pesobruto"
                  defaultValue={item?.pesobruto}
                  register={register({})}
                />
                <Input
                  label="Peso Líquido"
                  name="pesoliquido"
                  defaultValue={item?.pesoliquido}
                  register={register({})}
                />
                <Input
                  label="Peso Manual"
                  name="pesomanual"
                  defaultValue={item?.pesomanual}
                  register={register({})}
                />
              </section>
            </>
            <Button
              buttonType="primary"
              type="submit"
            >
              Atualizar
            </Button>
            <span style={{ display: 'inline-block', width: '5px' }} />
            <Button
              buttonType="secondary"
              type="button"
              onClick={close}
            >
              Cancelar
            </Button>
          </div>
        </Form>
      ))}
    </div >
  )
}
export default Weighing
