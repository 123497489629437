import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const Container = styled.div`
  padding: 15px;

  .expedition-table {
    table-layout: fixed;

    @media (max-width: 800px) {
      table-layout: auto;
    }
  }

  @media (max-width: 1000px) {
    .hide-md {
      display: none;
    }
  }

  @media (max-width: 650px) {
    .hide-sm {
      display: none;
    }
  }
`
export const Header = styled.header`
  padding: 5px 15px;
  background-color: white;
  border-bottom: 1px solid ${colors.borders};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
