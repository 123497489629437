import React from 'react'
import { Container } from './styles'

interface LoaderProps {
  children?: any
  loading: boolean
  overlay?: boolean | false
  message?: string
}

const Loader: React.FC<LoaderProps> = ({
  children,
  loading,
  overlay = false,
  message = 'Carregando...'
}) => {
  if (loading) {
    return (
      <Container overlay={overlay}>
        <span className="loader" />
        <p>{message}</p>
      </Container>
    )
  }

  return children ?? null
}

export default Loader
