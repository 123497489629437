import styled from 'styled-components'
import { css } from 'styled-components'

interface ButtonProps {
  size: number
  padding: number
}

export const Button = styled.button<ButtonProps>`
  background: none;
  border: none;
  border-radius: 50%;
  padding: ${props => props.padding}px;
  box-sizing: border-box;
  transition: 0.2s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.size + props.padding}px;
  height: ${props => props.size + props.padding}px;
  min-width: ${props => props.size + props.padding}px;
  min-height: ${props => props.size + props.padding}px;
  ${props => props.disabled && css`
    pointer-events: none;
  `}

  &:hover {
    background: rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }

  svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    min-width: ${props => props.size}px;
    min-height: ${props => props.size}px;
  }
`
