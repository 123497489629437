import styled from 'styled-components'
import { sizes } from '../../styles/default-styles'

export const Container = styled.section`
  max-width: 100%;
  height: calc(100% - ${sizes.headerHeight});
  overflow: hidden;
  display: flex;
`

export const Children = styled.div`
  overflow: auto;
  width: 100%;
  transition: width 0.3s;
  position: relative;
  max-height: 100%;
`
