import React, {
  useState,
  useEffect,
  useCallback
} from 'react'
import { MdCheck } from 'react-icons/md'
import Loader from '../../components/Loader'
import Button from '../../components/Button'
import { useParams } from 'react-router-dom'
import { useApi, useLoading } from '../../hooks'
import parse from 'html-react-parser'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import { handle } from '../../utils/error-handlers'
import { News } from '../../models/news'
import { Container } from './styles'

const ShowNews: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [news, setNews] = useState<News | undefined>(undefined)
  const { httpGet, httpPatch } = useApi()
  const { load, loading } = useLoading()

  const fetchData = useCallback(async (): Promise<void> => {
    await load(async () => {
      const response = await httpGet<News>(`/user-news/${id}`)
      const { body } = response
      setNews(body)
    })
  }, [httpGet, load, id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Container>
      <Loader loading={loading}>
        {news ? (
          <>
            <h2>{news.title}</h2>
            <p className="date">{format(new Date(news.date), 'dd/MM/yyyy')}</p>
            <pre>{parse(news.text)}</pre>
            <br />
            <Button
              buttonType="primary"
              disabled={news.readed}
              onClick={() => {
                handle(async () => {
                  await httpPatch(`/news/${news.id}`)
                  await fetchData()
                  toast.success('Lido com sucesso.')
                })
              }}
            >
              {news.readed && (
                <>
                  <MdCheck color="#fff" />&nbsp;
                </>
              )}Marcar como lido
            </Button>
          </>
        ) : (
          <p>Nada encontrado.</p>
        )}
      </Loader>
    </Container>
  )
}

export default ShowNews
