import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal
} from '../../../hooks'
import SelectApi from '../../../components/Select/api'
import Input from '../../../components/Input'

interface ProductsModalProps {
  productsDefault: any
  codemp: string
  codfil: string
  setProductsDefault: any
  data: any
}

interface ProductsModalForm {
  codpro: string
  despro: string
  qtdped: string
  preuni: string
  total: string
  codccu: string
  coddep: string
  pericm: string
  perpit: string
  perirf: string
  percsl: string
  peripi: string
  percrt: string
  seqipo: string
  codbem: string
}

const ProductsModal: React.FC<ProductsModalProps> = ({
  productsDefault,
  setProductsDefault,
  codfil,
  codemp,
  data
}) => {
  const methods = useForm<ProductsModalForm>({
  })
  const { close } = useModal()
  const { register } = methods
  const codpro = methods.watch('codpro')
  const [desproProducts, setDesproProducts] = useState<any>()

  const handleSubmit = (formData: ProductsModalForm): void => {
    setProductsDefault([
      ...productsDefault,
      {
        codccu: formData.codccu,
        coddep: formData.coddep,
        codemp: codemp,
        codfil: codfil,
        codpro: formData.codpro,
        despro: desproProducts?.despro,
        numocp: '',
        pericm: formData.pericm,
        perirf: formData.perirf,
        perpit: formData.perpit,
        percrt: formData.percrt,
        percsl: formData.percsl,
        peripi: formData.peripi,
        preuni: parseFloat(formData.preuni),
        qtdped: formData.qtdped,
        seqipo: '',
        usu_codbem: formData.codbem
      }])
    close()
  }

  return (
    <div>
      <h3>Inserir Produto</h3>
      <br />
      <Form methods={methods} onSubmit={handleSubmit}>
        <SelectApi
          displayProperty="despro"
          readonly={false}
          label="Produto"
          name="codpro"
          register={register({
          })}
          url={`/acquisition-order/list-products/${codemp}`}
          valueProperty="codpro"
          onSelect={(item) => {
            if (item) {
              setDesproProducts(item.data)
            }
          }}
        />
        <SelectApi
          displayProperty="desccu"
          readonly={false}
          label="Centro de Custo"
          name="codccu"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url={`/acquisition-order/list-cost-center/${codemp}`}
          valueProperty="codccu"
        />
        <SelectApi
          displayProperty="desdep"
          readonly={false}
          label="Depósito"
          name="coddep"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url={`/acquisition-order/list-deposits/${codemp}/${codpro}`}
          valueProperty="coddep"
        />
        <Input
          label="Quantidade Pedida"
          name="qtdped"
          register={register({
          })}
        />
        <Input
          label="Preço Unitário"
          name="preuni"
          register={register({
          })}
        />
        <Input
          label="ICMS %"
          name="pericm"
          register={register({
          })}
        />
        <Input
          label="PIS %"
          name="perpit"
          register={register({
          })}
        />
        <Input
          label="IPI %"
          name="peripi"
          register={register({
          })}
        />
        <Input
          label="IRRF %"
          name="perirf"
          register={register({
          })}
        />
        <Input
          label="COFINS %"
          name="percrt"
          register={register({
          })}
        />
        <Input
          label="CSLL %"
          name="percsl"
          register={register({
          })}
        />
        <SelectApi
          displayProperty="desbem"
          readonly={false}
          label="Patrimonio"
          name="codbem"
          register={register({})}
          url={`/acquisition-order/list-patrimony/${codemp}`}
          valueProperty="codbem"
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Adicionar
        </Button>
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Fechar
        </Button>
      </Form>
    </div>
  )
}

export default ProductsModal
