import React from 'react'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../../components/Table'
import Label from '../../../../components/Label'
import Loader from '../../../../components/Loader'
import Button from '../../../../components/Button'
import {
  useModal,
  useApi,
  useLoading
} from '../../../../hooks'
import { Bond } from '../../../../models/bond'
import { handle } from '../../../../utils/error-handlers'
import { Container } from './styles'
import { toast } from 'react-toastify'
import DetailsButtons from '../../../../components/DetailsButtons'

interface BondDetailsProps {
  data: Bond
  refetch: () => void
}

const BondDetails: React.FC<BondDetailsProps> = ({ data, refetch }) => {
  const { httpPut } = useApi()
  const { load, loading } = useLoading()
  const { open, confirm } = useModal()
  const total = data.tbItens.length > 0
    ? data.tbItens
      .map(item => Number(item.preUni))
      .reduce((prev, curr) => prev + curr)
      .toFixed(2)
    : 0

  const handleApprove = (): void => {
    confirm('Continuar com a aprovação?', () => {
      handle(async () => {
        load(async () => {
          await httpPut(`/payment-approval/approve/${data.numTit}`, {
            codEmp: data.codEmp,
            codFil: data.codFil,
            codFor: data.codFor,
            codTpt: data.codTpt,
            pgtApr: data.vlrAbe,
            vlrApr: data.vlrAbe
          })
          toast.success('Aprovado com sucesso.')
          refetch()
        })
      })
    })
  }

  const openModal = (index: number): () => void => {
    const item = data.tbItens[index]

    return () => {
      open(
        <Container>
          <div className="info-container">
            <section>
              <Label
                title="Nº nota"
                value={item.numNfc}
              />
              <Label
                title="Série"
                value={item.codSnf}
              />
              <Label
                title="Código"
                value={item.codIte}
              />
              <Label
                title="Descrição"
                value={item.desIte}
              />
            </section>
            <section>
              <Label
                title="Quantidade"
                value={item.qtdRec}
              />
              <Label
                title="Unidade medida"
                value={item.unimed}
              />
              <Label
                title="Preço unitário"
                value={`R$ ${item.preUni}`}
              />
            </section>
          </div>
        </Container>
      )
    }
  }

  return (
    <Container>
      <Loader loading={loading} />
      <div className="info-container">
        <section>
          <Label
            title="Nº do título"
            value={data.numTit}
          />
          <Label
            title="Data entrada"
            value={data.datEnt}
          />
          <Label
            title="Vencimento"
            value={data.vctOri}
          />
          <Label
            title="Nome da empresa"
            value={data.nomEmp}
          />
          <Label
            title="Código da empresa"
            value={data.codEmp}
          />
          <Label
            title="Nome da filial"
            value={data.nomFil}
          />
          <Label
            title="Código da filial"
            value={data.codFil}
          />
        </section>
        <section>
          <Label
            title="Valor aberto"
            value={`R$ ${data.vlrAbe}`}
          />
          <Label
            title="Valor original"
            value={`R$ ${data.vlrOri}`}
          />
          <Label
            title="Valor total"
            value={`R$ ${total}`}
          />
          <Label
            title="Nome fornecedor"
            value={data.nomFor}
          />
          <Label
            title="Código do fornecedor"
            value={data.codFor}
          />
          <Label
            title="Observação"
            value={data.obsTcp}
          />
        </section>
      </div>
      <DetailsButtons>
        <Button
          buttonType="primary"
          type="button"
          onClick={handleApprove}
        >
          Aprovar
        </Button>
      </DetailsButtons>
      <br />
      <Table empty={!data.tbItens.length}>
        <THead>
          <Tr>
            <Th color="#000">Nº nota</Th>
            <Th color="#000">Quantidade</Th>
            <Th color= "#000">Unid. medida</Th>
            <Th color= "#000">Preço uni.</Th>
          </Tr>
        </THead>
        <TBody>
          {data.tbItens.map((item, index) => (
            <Tr>
              <Td>
                <button onClick={openModal(index)}>
                  {item.numNfc}
                </button>
              </Td>
              <Td>{item.qtdRec}</Td>
              <Td>{item.unimed}</Td>
              <Td>R$ {item.preUni}</Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    </Container>
  )
}

export default BondDetails
