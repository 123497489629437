import React from 'react'
import Field from '../Field'
import { InputStyle } from './styles'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

const Input: React.FC<InputProps> = ({ label, ...props }) => {
  return (
    <Field
      label={label}
      id={props.id ?? ''}
    >
      <InputStyle {...props} />
    </Field>
  )
}

export default Input
