import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { MdReply } from 'react-icons/md'
import Filter from '../../../components/Filter'
import {
  useDebounce,
  useApi,
  useClickAwayListener
} from '../../../hooks'
import { News } from '../../../models/news'
import { Container } from './styles'

interface NewsFilterProps {
  category: string
  onClickAdd: () => void
}

const NewsFilter: React.FC<NewsFilterProps> = ({ category, onClickAdd }) => {
  const [news, setNews] = useState<News[]>([])
  const { httpGet } = useApi()
  const { inputRef, handleChange } = useDebounce(1000, () => {
    if (inputRef.current?.value) {
      httpGet<News[]>(`/search-news/${inputRef.current.value}/${category}`)
        .then(response => {
          setNews(response.body ?? [])
        })
      return
    }
    setNews([])
  })

  const reset = (): void => {
    setNews([])
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  useClickAwayListener({
    elementId: 'news-filter',
    onClickAway: () => {
      reset()
    }
  })

  return (
    <Container
      id="news-filter"
      onKeyDown={e => {
        if (e.key === 'Escape') {
          reset()
        }
      }}
    >
      <Filter
        onClickAdd={onClickAdd}
        inputRef={inputRef}
        input={<input ref={inputRef} onChange={handleChange} />}
      />
      {news.length > 0 && (
        <ul>
          {news.map((item, index) => (
            <li key={index}>
              <Link to={`/publicacao/${item.id}`}>
                <MdReply size={20} />
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </Container>
  )
}

export default NewsFilter
