import { createGlobalStyle } from 'styled-components'
import { colors, sizes } from './default-styles'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: Lato, sans-serif;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
  }

  body {
    font-size: ${sizes.fontSize};
    color: ${colors.textColor};
    background-color: ${colors.body}
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    margin: 1px 5px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: transparent;
    border-radius: 15px;
    margin: 1px 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 15px;
    margin: 1px 5px;
  }

  svg {
    color: ${colors.svgColor}
  }

  h1, h2, h3, h4 {
    font-weight: 900;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  h2 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  a {
    text-decoration: none;
  }

  .full-width {
    width: 100%;
    min-width: 100%;
  }
`
