import styled from 'styled-components'
import { colors } from '../../../styles/default-styles'

export const Container = styled.div`
  padding: 5px 15px;
  background-color: white;
  border-bottom: 1px solid ${colors.borders};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
