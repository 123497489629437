/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import Loader from '../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../components/Table'
import {
  useApi,
  useDetailsContainer,
  useFetch,
  useLoading,
  useModal
} from '../../hooks'
import { Container } from './styles'
import { SalesOrderModel } from '../../models/salesorder'
import SalesOrderDetails from './SalesDetails'
import Filter from './Filter'
import InsertBudget from './InsertBudget'
import ItemModal from './ItemModal'
import InsertOrder from './InsertOrder'
import GridModal from './GridModal'
import AttBudget from './AttBudget'
import { toast } from 'react-toastify'
import IconButton from '../../components/IconButton'
import { MdDelete } from 'react-icons/md'
import { handle } from '../../utils/error-handlers'
import AddItemModal from './AddItemModal'
import FindClient from './FindClient'
import AddClient from './AddClient'
import AddItemOrderModal from './AddItemOrderModal'
import ListClient from './ListClient'
import AttClient from './AttClient'

const SalesOrder: React.FC = () => {
  const { httpGet, httpPut } = useApi()
  const { open: openModal, confirm } = useModal()
  const {
    close,
    pushPage,
    clearData,
    open
  } = useDetailsContainer()
  const {
    data,
    loading,
    refetch,
    refetching
  } = useFetch<SalesOrderModel[]>(httpGet, '/salesorder/list-salesorder')
  const { loading: loadingHook } = useLoading()
  const [filter, setFilter] = useState(() => ({
    cliente: '',
    vendedora: '',
    empresa: '',
    dataInicio: '',
    dataFim: '',
    situation: ['Aberto', 'Em Faturamento'] as string[],
    type: [] as string[]
  }))
  const [filtered, setFiltered] = useState<SalesOrderModel[]>([])

  useEffect(() => {
    if (data) {
      setFiltered(data?.filter((item) => {
        return (filter.cliente === '' || item.nomcli === filter.cliente) &&
          (filter.vendedora === '' || item.nomrep === filter.vendedora) &&
          (filter.empresa === '' || item.des_emp === filter.empresa) &&
          (filter.situation?.length <= 0 || filter.situation.includes(item.dessit)) &&
          (filter.type?.length <= 0 || filter.type.includes(item.tipo)) &&
          (filter.dataInicio === '' || new Date((item.datemi).split('/').reverse().join('-') + 'T03:00:00.000Z') >= new Date((filter.dataInicio))) &&
          (filter.dataFim === '' || new Date((item.datemi).split('/').reverse().join('-') + 'T03:00:00.000Z') <= new Date((filter.dataFim)))
      }) ?? [{ data }])
    }
  },
  [filter, data, setFiltered])

  const openDeleteItemModal = useCallback((data: SalesOrderModel, index: number): () => void => {
    return () => {
      confirm('Continuar com a exclusão do item?', () => {
        handle(async () => {
          const response = await httpPut<string>(`/salesorder/delete-budget-itens/${data.numped}/${data.codcli}/${data.codemp}/${data.codfil}`, {
            codCli: data.codcli,
            codCpg: data.codcpg,
            codFpg: data.codfpg,
            codVen: data.codven,
            codemp: data.codemp,
            codtpr: data.codtpr,
            codfil: data.codfil,
            numPed: data.numped,
            codrep: data.codrep,
            entcli: data.datemi,
            somfre: data.somfre,
            tipfre: data.tipfre,
            tnsPro: data.tnspro,
            obsPed: data.obsped,
            orcIte: data?.itens.map(({
              codccu,
              coddep,
              qtdped,
              preuni,
              codpro
            }) => ({
              codCcu: codccu,
              codDep: coddep,
              codPro: codpro,
              preuni: preuni,
              qtdPed: parseFloat(qtdped),
              seqIpd: 1
            })
            )
          })

          toast.info(response.body)
          close()
          clearData()
          refetch()
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm, refetch])

  const openItemModal = useCallback((
    data: SalesOrderModel,
    codtpr: string,
    codcli: string,
    codemp: string,
    ciffob: string,
    somfre: string,
    redpac: string,
    setItensDefault: any,
    itensDefault: any
  ): () => void => {
    return () => {
      openModal(
        <ItemModal
          setItensDefault={setItensDefault}
          itensDefault={itensDefault}
          codcli={codcli}
          codemp={codemp}
          codtpr={codtpr}
          ciffob={ciffob}
          somfre={somfre}
          redpac={redpac}
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openAddModal = useCallback((data: SalesOrderModel): () => void => {
    return () => {
      openModal(
        <AddItemModal
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const openAddItemOrderModal = useCallback((data: SalesOrderModel): () => void => {
    return () => {
      openModal(
        <AddItemOrderModal
          data={data}
          refetch={refetch}
        />
      )
    }
  }, [openModal, refetch])

  const handleDeleteStatus = useCallback((data: SalesOrderModel): () => void => {
    return () => {
      confirm('Continuar com a exclusão do orçamento ?', () => {
        handle(async () => {
          const response = await httpPut<string>(`/salesorder/delete-sale-order/${data.numped}/${data.codcli}/${data.codemp}/${data.codfil}`, {})

          toast.info(response.body)
          refetch()
        })
      })
    }
  }, [httpPut, confirm, refetch])

  const handleInsertBudget = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <InsertBudget
            openItemModal={openItemModal}
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Inserir Orçamento'
      })
      open()
    }
  }, [refetch, loadingHook, loading, open, pushPage])

  const openGridModal = useCallback((codcli: string, codemp: string, codfil: string, setSelectedDefault: any): () => void => {
    return () => {
      openModal(
        <GridModal
          setSelectedDefault={setSelectedDefault}
          codcli={codcli}
          codemp={codemp}
          codfil={codfil}
        />
      )
    }
  }, [openModal])

  const handleInsertOrder = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <InsertOrder
            openGridModal={openGridModal}
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Inserir Pedido'
      })
      open()
    }
  }, [refetch, data, loadingHook, loading, open, pushPage])

  const openAttBudget = useCallback((data: SalesOrderModel): () => void => {
    return () => {
      pushPage({
        component: (
          <AttBudget
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Atualizar Orçamento'
      })
      open()
    }
  }, [openModal, refetch, loadingHook, loading])

  const handleFindClientClick = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <FindClient
            openAddClient={openAddClient}
            refetch={refetch}
          />
        ),
        title: 'Buscar Cliente'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const openAttClient = useCallback((data: any) => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <AttClient
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Atualizar Cliente'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const handleListClientClick = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <ListClient
            openAttClient={openAttClient}
            refetch={refetch}
          />
        ),
        title: 'Atualizar Cliente'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const openAddClient = useCallback((data: any) => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <AddClient
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Cadastrar Cliente'
      })
      open()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, refetch, loadingHook, loading])

  const openDetails = useCallback((salesOrder: SalesOrderModel): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <SalesOrderDetails
            openDeleteBudgetItem={openDeleteItemModal}
            openAddItemOrderModal={openAddItemOrderModal}
            openAddItemModal={openAddModal}
            openAttBudget={openAttBudget}
            data={salesOrder}
            refetch={refetch}
          />
        ),
        title: 'Geral'
      })
      open()
    }
  }, [close, pushPage, open, refetch, filtered])

  return (
    <>
      <Filter
        onClickInsertOrder={handleInsertOrder}
        onClickListClient={handleListClientClick}
        onClickInsertBudget={handleInsertBudget}
        onClickFindClient={handleFindClientClick}
        refetch={refetch}
        setFilter={setFilter}
        data={data ?? []}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!filtered.length}
            overflow="auto"
          >
            <THead>
              <Tr>
                <Th width="40px" className="hide-md" color="#000" >Pedido</Th>
                <Th width="40px" className="hide-md" color="#000" >Empresa</Th>
                <Th width="40px" className="hide-md" color="#000" >Filial</Th>
                <Th width="70px" className="hide-md" color="#000" >Tipo</Th>
                <Th width="70px" className="hide-md" color="#000" >Data Emissão</Th>
                <Th width="70px" className="hide-md" color="#000" >Data Entrega</Th>
                <Th width="50px" className="hide-md" color="#000" >Cliente</Th>
                <Th width="120px" className="hide-md" color="#000">Nome Cliente</Th>
                <Th width="90px" className="hide-md" color="#000" >Situação</Th>
                <Th width="50px" className="hide-sm" color="#000" >Vlr.Liq</Th>
                <Th width="50px" className="hide-sm" color="#000" >Qtd.Vol</Th>
                <Th width="60px" className="hide-sm" color="#000" >Embalagem</Th>
                <Th width="120px" className="hide-sm" color="#000" >Vendedor</Th>
                <Th width="180px" className="hide-md" color="#000" >Observação</Th>
                <Th width="30px" className="hide-md" color="#000" ></Th>
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item, index) => (
                <Tr key={index}>
                  <Td title={item.numped}>
                    <button
                      className="first-item"
                      onClick={openDetails(item)}
                    >
                      {item.numped}
                    </button>
                  </Td>
                  <Td title={item.codemp}>{item.codemp}</Td>
                  <Td title={item.codfil}>{item.codfil}</Td>
                  <Td title={item.tipo}>{item.tipo}</Td>
                  <Td title={item.datemi}>{item.datemi}</Td>
                  <Td title={item.dataentregacliente}>{item.dataentregacliente}</Td>
                  <Td title={item.codcli}>{item.codcli}</Td>
                  <Td title={item.nomcli}>{item.nomcli}</Td>
                  <Td title={item.dessit}>{item.dessit}</Td>
                  <Td title={item.vlrori}>{item.vlrori}</Td>
                  <Td title={item.qtdemb}>{item.qtdemb}</Td>
                  <Td title={item.desemb}>{item.desemb}</Td>
                  <Td title={item.nomrep}>{item.nomrep}</Td>
                  <Td title={item.obsped}>{item.obsped}</Td>
                  <Td textAlign="center" className="hide-md">
                    <IconButton
                      icon={<MdDelete size={20} />}
                      padding={3}
                      size={20}
                      onClick={handleDeleteStatus(item)}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default SalesOrder
