import React from 'react'
import DynamicIcon from '../../../../components/DynamicIcon'
import Button from '../../../../components/Button'
import Label from '../../../../components/Label'
import DetailsButtons from '../../../../components/DetailsButtons'
import {
  useModal,
  useDetailsContainer,
  useApi,
  useLoading
} from '../../../../hooks'
import { handle } from '../../../../utils/error-handlers'
import Loader from '../../../../components/Loader'
import { toast } from 'react-toastify'
import { Container } from './styles'
import { RdApprovalsModel } from '../../../../models/rdapprovals'
import { Tr, Th, Table, THead, TBody, Td } from '../../../../components/Table'

interface RdApprovalsDetailsProps {
  data: RdApprovalsModel
  refetch: () => void
}

const RdApprovalsDetails: React.FC<RdApprovalsDetailsProps> = ({
  data,
  refetch
}) => {
  const { httpPut } = useApi()
  const { close } = useDetailsContainer()
  const { load, loading } = useLoading()
  const { confirm } = useModal()

  const handleApprove = (): void => {
    confirm('Continuar com a aprovação da RD ?', async () => {
      handle(async () => {
        load(async () => {
          await httpPut(`/rdapprove/approve/${data.RD}`, {
            codEmp: data.empresa,
            numRdv: data.RD,
            usuApr: data.usuarioAPR,
            codFil: data.filial
          })
          close()
          toast.success('RD aprovada.')
          refetch()
        })
      })
    })
  }

  const handleDisapprove = (): void => {
    confirm('Continuar com a reprovação da RD ?', async () => {
      handle(async () => {
        load(async () => {
          await httpPut(`/rdapprove/disapprove/${data.RD}`, {
            codEmp: data.empresa,
            numRdv: data.RD,
            usuApr: data.usuarioAPR,
            codFil: data.filial
          })
          close()
          toast.success('RD reprovada.')
          refetch()
        })
      })
    })
  }

  return (
    <Container>
      <Loader
        loading={loading}
        overlay
        message="Processando..."
      />
      <div className="info">
        <section>
          <Label
            title="Nº Empresa"
            value={data.empresa}
          />
          <Label
            title="Empresa"
            value={data.nomeEmpresa}
          />
          <Label
            title="Filial"
            value={data.filial}
          />
          <Label
            title="RD"
            value={data.RD}
          />
          <Label
            title="Nome Digitador"
            value={data.nomeDigitador}
          />
          <Label
            title="Nome Usuário RD"
            value={data.nomeUsuarioRD}
          />
        </section>
        <section>
          <Label
            title="Situação Aprovação"
            value={data.situacaoAPR}
          />
          <Label
            title="Data Geração"
            value={data.dataGeracao}
          />
          <Label
            title="Valor Total"
            value={data.valorTotal}
          />
          <Label
            title="Código CCU"
            value={data.codigoCCU}
          />
          <Label
            title="Descrição CCU"
            value={data.descricaoCCU}
          />
          <Label
            title="Data Entrada"
            value={data.usu_datent}
          />
        </section>
      </div>
      <section>
        <Label
          title="Observação"
          value={data.usu_obsrdv}
        />
      </section>
      <Table layout="fixed">
        <THead>
          <Tr>
            <Th color="black" width="40px">Sequencial</Th>
            <Th color="black" width="30px">Nº Doc</Th>
            <Th color="black" width="40px">Tipo Doc</Th>
            <Th color="black" width="60px">Descrição Despesa</Th>
            <Th color="black" width="30px">Qtde</Th>
            <Th color="black" width="30px">Valor Total</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.itensRD.map((item, index) =>
            <Tr key={index}>
              <Td>
                {item.sequencial}
              </Td>
              <Td>
                {item.usu_numdoc}
              </Td>
              <Td>
                {item.destpt}
              </Td>
              <Td>
                {item.descricaoDespesa}
              </Td>
              <Td>
                {item.qtde}
              </Td>
              <Td>
                {item.valortotal}
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      <DetailsButtons>
        <Button
          buttonType="primary"
          type="button"
          onClick={handleApprove}
        >
          <DynamicIcon
            family="md"
            name="MdCheck"
            size={15}
          />
          &nbsp;
          Aprovar
        </Button>
        <Button
          buttonType="secondary"
          type="button"
          onClick={handleDisapprove}
        >
          <DynamicIcon
            family="md"
            name="MdClear"
            size={15}
          />
          &nbsp;
          Reprovar
        </Button>
      </DetailsButtons>
    </Container>
  )
}

export default RdApprovalsDetails
