import React from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'

interface AddNewProviderProps {
  data: any
  refetch: () => void
}

interface AddNewProviderForm{
  apeFor: string
  baiFor: string
  cepFor: string
  cgcCpf: string
  endFor: string
  fonFor: string
  insEst: string
  intNet: string
  nenFor: string
  nomFor: string
  tipEmp: string
  tipFor: string
  tipMer: string
}

const AddNewProvider: React.FC<AddNewProviderProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AddNewProviderForm>({
    mode: 'onTouched',
    defaultValues: {
      apeFor: data.nome_fantasia,
      baiFor: data.bairro,
      cepFor: data.cep,
      cgcCpf: data.cnpj,
      endFor: data.logradouro,
      fonFor: data.telefone_1,
      insEst: 'ISENTO',
      intNet: data.email,
      nenFor: '0',
      nomFor: data.razao_social,
      tipEmp: '2',
      tipFor: 'J',
      tipMer: 'I'
    }
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()

  const handleSendData = async (formData: AddNewProviderForm): Promise<void> => {
    await handle(async () => {
      const json = {
        apeFor: formData.apeFor,
        baiFor: formData.baiFor,
        cepFor: formData.cepFor,
        cgcCpf: formData.cgcCpf,
        endFor: formData.endFor,
        fonFor: formData.fonFor,
        insEst: formData.insEst,
        intNet: formData.intNet,
        nenFor: formData.nenFor,
        nomFor: formData.nomFor,
        tipEmp: formData.tipEmp,
        tipFor: formData.tipFor,
        tipMer: formData.tipMer
      }
      const response = await httpPost<string>('/bidding/insert-provider', json)
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }
  return (
    <div>
      <h3>Cadastrar Novo Fornecedor</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Nome do Fornecedor"
          name="nomFor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Nome Fantasia"
          name="apeFor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Inscrição Estadual"
          name="insEst"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="CNPJ ou CPF"
          name="cgcCpf"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Endereço do Fornecedor"
          name="endFor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Bairro Do Fornecedor"
          name="baiFor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="CEP do Fornecedor"
          name="cepFor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Telefone do Fornecedor"
          name="fonFor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Número de Telefone"
          name="nenFor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Email do Fornecedor"
          name="intNet"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Tipo Da Empresa"
          name="tipEmp"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Tipo Do Fornecedor"
          name="tipFor"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Tipo Do Mercado"
          name="tipMer"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AddNewProvider
