import React from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'
import { addDays, format } from 'date-fns'

interface AddBiddingModalProps {
  refetch: () => void
  biddingNumber: number
}

interface AddBiddingForm{
  codFpg: string
  desFpg: string
  usu_DatEnt: string
  codCli: string
  usu_codemp: string
  usu_datger: string
  usu_datlic: string
  usu_horger: string
  usu_horlic: string
  usu_numlic: string
  usu_obslic: string
  usu_plalic: string
  usu_usuger: string
}

const AddBiddingModal: React.FC<AddBiddingModalProps> = ({
  refetch,
  biddingNumber
}) => {
  const methods = useForm<AddBiddingForm>({
    mode: 'onTouched',
    defaultValues: {
      usu_numlic: biddingNumber.toString(),
      usu_usuger: 'undefined'
    }
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()

  const handleSendData = async (formData: AddBiddingForm): Promise<void> => {
    const date = format(new Date(), 'dd/MM/yyyy')
    const time = format(new Date(), 'HH:mm')
    const usu_datger = date
    const usu_DatEnt = date
    const usu_horger = time
    await handle(async () => {
      const json = {
        usu_CodFpg: formData.codFpg,
        usu_codcli: formData.codCli,
        usu_codemp: formData.usu_codemp,
        usu_datlic: format(addDays(new Date(formData.usu_datlic), 1), 'dd/MM/yyyy'),
        usu_horlic: formData.usu_horlic,
        usu_numlic: formData.usu_numlic,
        usu_obslic: formData.usu_obslic,
        usu_plalic: formData.usu_plalic,
        usu_stalic: 1,
        usu_usuger: 'undefined',
        usu_DatEnt,
        usu_horger,
        usu_datger
      }
      const response = await httpPost<string>('/bidding/insert', json)
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Adicionar nova licitação</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <Input
          label="Código da Empresa"
          name="usu_codemp"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Número da Licitação"
          readOnly
          name="usu_numlic"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="nomcli"
          readonly={false}
          label="Cliente"
          name="codCli"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url="bidding/list-clients"
          valueProperty="codCli"
        />
        <Input
          label="Data Licitação"
          name="usu_datlic"
          type="date"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Hora Licitação"
          name="usu_horlic"
          type="time"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="desFpg"
          readonly={false}
          label="Código Forma de Pagamento"
          name="codFpg"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url="bidding/list-payment"
          valueProperty="codFpg"
        />
        <Input
          label="Plataforma da Licitação"
          name="usu_plalic"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Input
          label="Observação da Licitação"
          name="usu_obslic"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AddBiddingModal
