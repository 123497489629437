import React from 'react'
import Form from '../../../components/Form'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { BiddingModel } from '../../../models/bidding'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'

interface AddModalProps {
  data: BiddingModel
  refetch: () => void
}

interface AddForm{
  usu_qtdlic: string
  codpro: string
}

const AddModal: React.FC<AddModalProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<AddForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()

  const handleSendData = async (formData: AddForm): Promise<void> => {
    await handle(async () => {
      const usu_qtdlic = formData.usu_qtdlic
      const usu_codpro = formData.codpro
      const response = await httpPost<string>(`/bidding/insert-itens/${data.usu_numlic}`, {
        usu_codemp: data.usu_codemp,
        usu_codcli: data.usu_codcli,
        usu_qtdlic,
        usu_codpro
      })
      toast.success(response.body)
      close()
      clearData()
      refetch()
    })
  }

  return (
    <div>
      <h3>Adicionar novo item a licitação</h3>
      <br />
      <Form methods={methods} onSubmit={handleSendData}>
        <SelectApi
          displayProperty="despro"
          readonly={false}
          label="Produto"
          name="codpro"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url="bidding/list-products"
          valueProperty="codpro"
        />
        <Input
          label="Quantidade"
          name="usu_qtdlic"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <Button
          buttonType="primary"
          type="submit"
        >
          Adicionar
        </Button>
        <span style={{ display: 'inline-block', width: '5px' }} />
        <Button
          buttonType="secondary"
          type="button"
          onClick={close}
        >
          Cancelar
        </Button>
      </Form>
    </div>
  )
}

export default AddModal
