import styled, { keyframes, css } from 'styled-components'
import { colors } from '../../styles/default-styles'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: max-content;
`

const fadeIn = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

interface OptionsContainerProps {
  opened: boolean
  top: string
  left?: string
  right?: string
}

export const OptionsContainer = styled.div<OptionsContainerProps>`
  display: ${props => props.opened ? 'block' : 'none'};
  position: absolute;
  top: ${props => props.top};
  width: max-content;
  background-color: white;
  border-radius: 3px;
  z-index: 300;
  animation: 0.2s ${fadeIn} ease;
  ${props => props.left && css`
    left: ${props.left};
  `}
  ${props => props.right && css`
    right: ${props.right};
  `}

  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);

  ul {
    list-style: none;

    li {
      padding: 10px 15px;
      color: ${colors.textColor};
      display: flex;
      align-items: center;
      transition: 0.2s all;

      .separator {
        display: inline-block;
        width: 10px;
      }
    }

    li:hover {
      background-color: rgba(0, 0, 0, 0.03);
      cursor: pointer;
    }
  }
`
