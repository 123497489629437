import styled, { keyframes } from 'styled-components'
import { colors } from '../../styles/default-styles'

export const SelectContainer = styled.div`
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .value {
    display: block;
    position: absolute;
    bottom: 18px;
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 20px);
    text-overflow: ellipsis;
  }

  .arrow-down {
    position: absolute;
    z-index: 0;
    right: 5px;
    top: 13px;
    min-height: 20px;
    min-width: 20px;
    color: ${colors.mediumGray};
  }

  input {
    //color: transparent;
    width: 100%;
    border: none;
    padding: 5px 0;
    border-bottom: 2px solid ${colors.borders};
    outline: none;

    &:focus {
      outline: none
    }

    &:hover {
      cursor: pointer;
    }
  }
`

const fadeIn = keyframes`
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

export const SelectOptions = styled.div<{ opened: boolean }>`
  display: ${props => props.opened ? 'block' : 'none'};
  width: calc(100% - 10px);
  z-index: 1;
  position: absolute;
  border-radius: 3px;
  background-color: white;
  overflow: auto;
  max-height: 250px;
  margin: -15px 5px 0;
  animation: 0.2s ${fadeIn} ease;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);

  ul {
    list-style: none;

    li {
      padding: 10px 0 10px 10px;
      text-align: left;
      transition: 0.2s all;

      &:hover, &.selected {
        cursor: pointer;
        background-color: ${colors.lightGray};
      }
    }

    li:first-child {
      color: ${colors.mediumGray};
    }
  }

  .filter-input {
    padding: 10px 0 10px 10px;
  }
`
