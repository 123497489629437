import { Action } from '../types'
import { AuthActions } from './auth-actions'

export interface InitialState {
  user: string | null
  token: string | null
  isAuthenticated: boolean
}

const initialState: InitialState = {
  user: null,
  token: null,
  isAuthenticated: false
}

export const authReducer = (
  state = initialState,
  action: Action
): InitialState => {
  switch (action.type) {
    case AuthActions.SET_DATA:
      return { ...state, ...action.data }
    case AuthActions.CLEAR:
      return { ...initialState }
    default:
      return state
  }
}
