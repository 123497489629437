import React from 'react'
import Settings from '../Settings'
import Modules from '../Settings/Modules'
import Profiles from '../Settings/Profiles'
import Users from '../Settings/Users'
import News from '../News'
import Rules from '../News/Rules'
import ShowNews from '../ShowNews'
import PurchaseOrders from '../Approvals/PurchaseOrders'
import PurchaseRequests from '../Approvals/PurchaseRequests'
import PaymentApproval from '../Approvals/PaymentApproval'
import Expedition from '../Expedition'
import Biddings from '../Biddings'
import CRMMoskit from '../CRMMoskit'
import LeadEasy from '../LeadEasy'
import RdApprovals from '../Approvals/RdApprovals'
import SalesOrder from '../SalesOrder'
import CostTable from '../CostTable'
import Logistic from '../Logistic'
import LogisticDriver from '../LogisticDriver'
import AcquisitionOrder from '../AcquisitionOrder'
import CallRegistration from '../CallRegistration'

interface Route {
  path: string
  exact: boolean
  component: JSX.Element
}

export const routes: Route[] = [
  {
    path: '/configuracoes',
    exact: true,
    component: <Settings />
  }, {
    path: '/configuracoes/modulos',
    exact: false,
    component: <Modules />
  }, {
    path: '/configuracoes/perfis',
    exact: false,
    component: <Profiles />
  }, {
    path: '/configuracoes/usuarios',
    exact: false,
    component: <Users />
  }, {
    path: '/informativos/:page',
    exact: true,
    component: <News />
  }, {
    path: '/normas/:page',
    exact: true,
    component: <Rules />
  }, {
    path: '/publicacao/:id',
    exact: true,
    component: <ShowNews />
  }, {
    path: '/ordens-compra',
    exact: true,
    component: <PurchaseOrders />
  },
  {
    path: '/pedidosvendas',
    exact: true,
    component: <SalesOrder />
  },
  {
    path: '/ordemcompra',
    exact: true,
    component: <AcquisitionOrder />
  },
  {
    path: '/logistica',
    exact: true,
    component: <Logistic />
  },
  {
    path: '/logistica-motorista',
    exact: true,
    component: <LogisticDriver />
  },
  {
    path: '/tabela-custo',
    exact: true,
    component: <CostTable />
  }, {
    path: '/solicitacoes-compra',
    exact: true,
    component: <PurchaseRequests />
  }, {
    path: '/aprovacoes-pagamentos',
    exact: true,
    component: <PaymentApproval />
  },
  {
    path: '/aprovacoes-rd',
    exact: true,
    component: <RdApprovals />
  },
  {
    path: '/expedicao',
    exact: true,
    component: <Expedition />
  }, {
    path: '/licitacoes',
    exact: true,
    component: <Biddings />
  }, {
    path: '/crm-moskit',
    exact: true,
    component: <CRMMoskit />
  }, {
    path: '/lead-easy',
    exact: true,
    component: <LeadEasy />
  }, {
    path: '/chamados',
    exact: true,
    component: <CallRegistration />
  }
]
