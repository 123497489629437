import styled from 'styled-components'
import { colors } from '../../styles/default-styles'

export const Container = styled.div`
  margin-bottom: 20px;

  small {
    display: inline-block;
    text-transform: uppercase;
    font-size: 9px;
    color: ${colors.mediumGray};
    margin-bottom: 5px;
  }

  p {
    font-size: 13px;
  }
`
