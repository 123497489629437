import React, {
  useState,
  useCallback
} from 'react'
import Loader from '../../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../../components/Table'
import IconButton from '../../../components/IconButton'
import Checkbox from '../../../components/Checkbox/table'
import PurchaseRequestDetails from './PurchaseRequestDetails'
import DynamicIcon from '../../../components/DynamicIcon'
import ApprovalsHeader from '../ApprovalsHeader'
import Filter from './Filter'
import { toast } from 'react-toastify'
import {
  useFetch,
  useApi,
  useModal,
  useDetailsContainer,
  useLoading,
  useFilter,
  useSelectItems
} from '../../../hooks'
import { PurchaseRequest } from '../../../models/purchase-request'
import { handle } from '../../../utils/error-handlers'
import { Container } from './styles'

const PurchaseRequests: React.FC = () => {
  const { httpGet, httpPut } = useApi()
  const {
    data,
    loading,
    refetch,
    refetching
  } = useFetch<PurchaseRequest[]>(httpGet, '/purchase-requests/pending')
  const { confirm, open: openModal } = useModal()
  const {
    close,
    pushPage,
    open
  } = useDetailsContainer()
  const { load, loading: loadingHook } = useLoading()
  const [filter, setFilter] = useState(() => () => true)
  const { filtered } = useFilter<PurchaseRequest>(data, filter)
  const {
    selectedItems,
    isSelected,
    handleSelect,
    handleSelectAll
  } = useSelectItems<PurchaseRequest>(item => `${item.numsol}${item.seqsol}`)

  const openDetails = useCallback((purchaseRequest: PurchaseRequest): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <PurchaseRequestDetails
            data={purchaseRequest}
            refetch={refetch}
          />
        ),
        title: 'Solicitação de compra'
      })
      open()
    }
  }, [close, pushPage, open, refetch])

  const handleFilterClick = useCallback(() => {
    if (!loading && !refetching && data) {
      openModal(
        <Filter
          setFilter={setFilter}
          data={data}
        />
      )
      return
    }
    toast.info('Aguarde os dados carregarem.')
  }, [openModal, data, loading, refetching])

  const handleApprove = (items: PurchaseRequest[]) => {
    return () => {
      confirm('Continuar com a aprovação da solicitação de compra?', () => {
        handle(async () => {
          load(async () => {
            for (const item of items) {
              await httpPut(`/purchase-requests/approve/${item.numsol}`, {
                codEmp: item.codEmp,
                seqSol: item.seqsol
              })
              toast.success(`Solicitação nº ${item.numsol} e sequencial ${item.seqsol} aprovada.`)
              refetch()
            }
          })
        })
      })
    }
  }

  return (
    <>
      <ApprovalsHeader
        refetch={refetch}
        onClickFilter={handleFilterClick}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!filtered?.length}
            selected={selectedItems.length}
            overflow="auto"
            actionButton={<IconButton
              padding={3}
              size={20}
              onClick={handleApprove(selectedItems)}
              icon={(
                <DynamicIcon
                  family="md"
                  name="MdCheckCircle"
                  size={15}
                  color="#33b850"
                />
              )}
            />}
          >
            <THead>
              <Tr>
                <Th width="45px">
                  <Checkbox
                    onSelect={handleSelectAll(filtered, !(filtered.length === selectedItems.length))}
                    value={(filtered.length === selectedItems.length)}
                  />
                </Th>
                <Th width="60px" color="#000" >Nº solic.</Th>
                <Th width="130px" color="#000" >Data solicitação</Th>
                <Th width="130px" className="hide-md" color="#000" >Código produto</Th>
                <Th width="90px" className="hide-md" color="#000" >Quantidade</Th>
                <Th color="#000" >Produto</Th>
                <Th className="hide-sm" color="#000" >Nome empresa</Th>
                <Th width="45px" />
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item, index) => (
                <Tr key={index}>
                  <Td className="table-checkbox">
                    <Checkbox value={isSelected(item)} onSelect={handleSelect(item, !isSelected(item))}/>
                  </Td>
                  <Td>
                    <button
                      className="first-item"
                      onClick={openDetails(item)}
                    >
                      {item.numsol}
                    </button>
                  </Td>
                  <Td>{item.datsol}</Td>
                  <Td className="hide-md">{item.codpro}</Td>
                  <Td className="hide-md">{item.qtdsol} {item.unimed}</Td>
                  <Td>{item.cplpro}</Td>
                  <Td className="hide-sm">{item.nomemp}</Td>
                  <Td>
                    <IconButton
                      padding={1}
                      size={20}
                      onClick={handleApprove([item])}
                      icon={(
                        <DynamicIcon
                          family="md"
                          name="MdCheckCircle"
                          size={15}
                          color="#33b850"
                        />
                      )}
                    />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default PurchaseRequests
