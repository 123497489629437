import styled from 'styled-components'
import login from '../../assets/login.png'

export const LoginContainer = styled.section`
  background: url(${login}) center no-repeat;
  background-color: #000423;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    box-sizing: border-box;
    max-width: 300px;
    width: 300px;
    max-height: 380px;
    height: 380px;
    position: absolute;
    margin-top: -150px;

    h1 {
      text-align: center;

      img {
        width: 200px;
        height: 150px;
        padding: 15px;
        border-radius: 5px;
      }
    }

    form {
      background-color: white;
      padding: 20px 15px;
      border-radius: 10px;
      position: relative;
    }

    @media(max-height: 600px) {
      margin-top: 0;
    }
  }
`
