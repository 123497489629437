import React from 'react'
import { useModal, useClickAwayListener } from '../../hooks'
import { Container, ModalStyle } from './styles'

const Modal: React.FC = () => {
  const { opened, component, close } = useModal()

  useClickAwayListener({
    elementId: 'modal',
    onClickAway: () => {
      if (opened) {
        close()
      }
    }
  })

  return (
    <Container opened={opened}>
      {opened && (
        <ModalStyle id="modal">
          {component}
        </ModalStyle>
      )}
    </Container>
  )
}

export default Modal
