import React, { useState } from 'react'
import Form from '../../../components/Form'
import Button from '../../../components/Button'
import { useForm } from 'react-hook-form'
import {
  useModal,
  useApi,
  useDetailsContainer
} from '../../../hooks'
import { toast } from 'react-toastify'
import { handle } from '../../../utils/error-handlers'
import SelectApi from '../../../components/Select/api'
import Textarea from '../../../components/Textarea'
import Select from '../../../components/Select'

interface InsertCallProps {
  data: any
  refetch: () => void
}

interface InsertCallForm {
  usu_catcham: string
  usu_descatv: string
  usu_empcham: string
  usu_setcham: string
}
const InsertCall: React.FC<InsertCallProps> = ({
  data,
  refetch
}) => {
  const methods = useForm<InsertCallForm>({
  })
  const { register } = methods
  const { close } = useModal()
  const { clearData } = useDetailsContainer()
  const { httpPost } = useApi()
  const [loading, setLoading] = useState(false)

  const handleSendData = async (formData: InsertCallForm): Promise<void> => {
    setLoading(true)
    await handle(async () => {
      const json = {
        usu_catcham: formData.usu_catcham,
        usu_descatv: formData.usu_descatv,
        usu_empcham: formData.usu_empcham,
        usu_setcham: formData.usu_setcham
      }
      const response = await httpPost<any>('/call-registration/insert-call', json)

      if (response.body === 'Chamado inserido com Sucesso !!!') {
        clearData()
        refetch()
      }
      toast.info(response.body)
      setLoading(false)
      close()
    })
  }
  return (
    <div>
      <Form methods={methods} onSubmit={handleSendData} >
        <Select
          readonly
          label="Categoria"
          name="usu_catcham"
          options={[{
            display: 'Suporte ao Usuário',
            value: '1'
          }, {
            display: 'Desenvolvimento/Projetos',
            value: '2'
          }
          ]}
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <SelectApi
          displayProperty="usu_desemp"
          readonly={false}
          label="Empresa"
          name="usu_empcham"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url={'call-registration/list-Company'}
          valueProperty="usu_empcham"
        />
        <SelectApi
          displayProperty="usu_desset"
          readonly={false}
          label="Setor"
          name="usu_setcham"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
          url={'call-registration/list-sector'}
          valueProperty="usu_setcham"
        />
        <Textarea
          label="Descrição Chamado"
          name="usu_descatv"
          register={register({
            required: {
              message: 'Preencha este campo',
              value: true
            }
          })}
        />
        <br />
        <Button
          buttonType="primary"
          type="submit"
          disabled={loading}
        >
          Adicionar
        </Button>
      </Form>
    </div>
  )
}

export default InsertCall
