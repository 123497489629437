import React, { useCallback } from 'react'
import Loader from '../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../components/Table'
import {
  useFetch,
  useApi,
  useLoading,
  useDetailsContainer
} from '../../hooks'
import { CallRegistrationModel } from '../../models/call-registration'
import CallRegistrationDetails from './CallRegistrationDetails'
import CallRegistrationHeader from './CallRegistrationHeader'
import InsertCall from './InsertCall'
import { Container } from './styles'

const CallRegistration: React.FC = () => {
  const { httpGet } = useApi()
  const {
    data,
    loading,
    refetching,
    refetch
  } = useFetch<CallRegistrationModel[]>(httpGet, '/call-registration/list-calls')
  const {
    pushPage,
    open,
    close
  } = useDetailsContainer()
  const { loading: loadingHook } = useLoading()

  const openCallRegistrationDetails = useCallback((logistic: CallRegistrationModel): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <CallRegistrationDetails
            refetch={refetch}
            item={logistic}
          />
        ),
        title: 'Detalhes do Chamado'
      })
      open()
    }
  }, [close, open, pushPage, refetch])

  const handleInsertCall = useCallback(() => {
    if (!loading && !loadingHook) {
      pushPage({
        component: (
          <InsertCall
            data={data}
            refetch={refetch}
          />
        ),
        title: 'Inserir Chamado'
      })
      open()
    }
  }, [loading, refetch, loadingHook, pushPage, data, open])

  return (
    <>
      <CallRegistrationHeader
        onClickInsertCall={handleInsertCall}
        refetch={refetch}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            overflow="auto"
          >
            <THead>
              <Tr>
                <Th color="#000" width="30px">Chamado</Th>
                <Th color="#000" width="20px">Cód Categoria</Th>
                <Th color="#000" width="120px">Categoria</Th>
                <Th color="#000" width="40px">Cód. Usu. Abertura</Th>
                <Th color="#000" width="80px">Usuário Abertura</Th>
                <Th color="#000" width="200px">Descrição Chamado</Th>
                <Th color="#000" width="80px">Data Abertura</Th>
                <Th color="#000" width="20px">Hora Abertura</Th>
                <Th color="#000" width="20px">Cód. Setor</Th>
                <Th width="60px" color="#000">Setor</Th>
                <Th width="20px" color="#000">Cód. Empresa</Th>
                <Th width="40px" color="#000">Empresa</Th>
                <Th width="20px" color="#000">Cód. Atendente</Th>
                <Th width="100px" color="#000">Atendente</Th>
                <Th width="40px" color="#000">Chamado Pai</Th>
              </Tr>
            </THead>
            <TBody>
              {data?.map((item, index) => (
                <Tr key={index}>
                  <Td title={item.usu_codcham} width="30px">
                    <button
                      className="first-item"
                      onClick={openCallRegistrationDetails(item)}
                    >
                      {item.usu_codcham}
                    </button>
                  </Td>
                  <Td title={item.usu_catcham} className="hide-md">{item.usu_catcham}</Td>
                  <Td title={item.usu_descat} className="hide-sm">{item.usu_descat}</Td>
                  <Td title={item.usu_codusuabe}>{item.usu_codusuabe}</Td>
                  <Td title={item.usu_nomusuabe}>{item.usu_nomusuabe}</Td>
                  <Td title={item.usu_descatv}>{item.usu_descatv}</Td>
                  <Td title={item.usu_datcham}>{item.usu_datcham}</Td>
                  <Td title={item.usu_horcham}>{item.usu_horcham}</Td>
                  <Td title={item.usu_setcham}>{item.usu_setcham}</Td>
                  <Td title={item.usu_desset}>{item.usu_desset}</Td>
                  <Td title={item.usu_empcham}>{item.usu_empcham}</Td>
                  <Td title={item.usu_desemp}>{item.usu_desemp}</Td>
                  <Td title={item.usu_codate}>{item.usu_codate}</Td>
                  <Td title={item.usu_nomate}>{item.usu_nomate}</Td>
                  <Td title={item.usu_champai}>{item.usu_champai}</Td>
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default CallRegistration
