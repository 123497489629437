import React, { useState } from 'react'
import Loader from '../../components/Loader'
import {
  TBody,
  THead,
  Table,
  Td,
  Th,
  Tr
} from '../../components/Table'
import {
  useApi,
  useDetailsContainer,
  useFetch,
  useLoading,
  useModal
} from '../../hooks'
import { Container } from './styles'
import { LogisticModel } from '../../models/logistic'
import { useCallback } from 'react'
import LogisticDetails from './LogisticDetails'
import Filter from './Filter'
import { handle } from '../../utils/error-handlers'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import IconButton from '../../components/IconButton'
import DynamicIcon from '../../components/DynamicIcon'

const Logistic: React.FC = () => {
  const { httpGet, httpPatch } = useApi()
  const {
    loading,
    data,
    refetch,
    refetching
  } = useFetch<LogisticModel[]>(httpGet, '/logistic/list-logistics')
  const {
    pushPage,
    open,
    close
  } = useDetailsContainer()
  const { confirm } = useModal()
  const [filter, setFilter] = useState(() => ({
    empresa: '',
    cliente: '',
    transportadora: '',
    motorista: '',
    situacao: '',
    tipo: ''
  }))
  const [filtered, setFiltered] = useState<LogisticModel[]>([])
  const { load, loading: loadingHook } = useLoading()

  useEffect(() => {
    if (data) {
      setFiltered(data?.filter((item) => {
        return (filter.empresa === '' || item.usu_codemp === filter.empresa) &&
          (filter.transportadora === '' || item.nomtra === filter.transportadora) &&
          (filter.cliente === '' || item.usu_nomcli === filter.cliente) &&
          (filter.motorista === '' || item.nommot === filter.motorista) &&
          (filter.situacao === '' || item.usu_sitord === filter.situacao) &&
          (filter.tipo === '' || item.usu_tipo === filter.tipo)
      }) ?? [{ data }])
    }
  },
  [filter, data, setFiltered])

  const openDetails = useCallback((logistic: LogisticModel): () => void => {
    return () => {
      close()
      pushPage({
        component: (
          <LogisticDetails
            refetch={refetch}
            item={logistic}
          />
        ),
        title: 'Geral'
      })
      open()
    }
  }, [close, open, pushPage, refetch])

  const handleCancel = (items: LogisticModel[]) => {
    return () => {
      confirm('Continuar com o cancelamento da Logistica ?', () => {
        handle(async () => {
          load(async () => {
            for (const data of items) {
              await httpPatch<string>(`/logistic/update-situation/${data.usu_numped}/${data.usu_codemp}/${data.usu_seqipd}`, {
                codemp: data.usu_codemp,
                codfil: data.usu_codfil,
                sitord: 'C',
                numped: data.usu_numped,
                seqipd: data.usu_seqipd,
                tipo: data.usu_tipo
              })
              toast.success('RD Reprovada com sucesso')
              refetch()
            }
          })
        })
      })
    }
  }

  return (
    <>
      <Filter
        refetch={refetch}
        setFilter={setFilter}
        data={data ?? []}
      />
      <Container>
        <Loader
          loading={loading || refetching || loadingHook}
          message={loadingHook ? 'Processando...' : undefined}
        >
          <Table
            empty={!filtered.length}
            overflow="auto"
          >
            <THead>
              <Tr>
                <Th width="60px" className="hide-md" color="#000" >Pedido</Th>
                <Th width="40px" className="hide-md" color="#000" >Emp</Th>
                <Th width="70px" className="hide-md" color="#000" >Tipo</Th>
                <Th width="50px" className="hide-md" color="#000" >CódCli</Th>
                <Th width="350px" className="hide-md" color="#000" >Cliente/Fornecedor</Th>
                <Th width="40px" className="hide-md" color="#000" >Seq</Th>
                <Th width="90px" className="hide-md" color="#000" >CódPro</Th>
                <Th width="200px" className="hide-md" color="#000">Produto</Th>
                <Th width="70px" className="hide-md" color="#000" >Qtd</Th>
                <Th width="90px" className="hide-sm" color="#000" >Carga</Th>
                <Th width="220px" className="hide-sm" color="#000" >Transportadora</Th>
                <Th width="90px" className="hide-sm" color="#000">Veículo</Th>
                <Th width="160px" className="hide-md" color="#000">Motorista</Th>
                <Th width="140px" className="hide-md" color="#000" >Serviço</Th>
                <Th width="40px" className="hide-md" color="#000" >Sit</Th>
                <Th width="50px" className="hide-md" color="#000"></Th>
                <Th width="30px" className="hide-md" />
              </Tr>
            </THead>
            <TBody>
              {filtered?.map((item) => (
                <Tr>
                  <Td title={item.usu_numped}>
                    <button
                      className="first-item"
                      onClick={openDetails(item)}
                    >
                      {item.usu_numped}
                    </button>
                  </Td>
                  <Td title={item.usu_codemp}>{item.usu_codemp}</Td>
                  <Td title={item.usu_tipo}>{item.usu_tipo}</Td>
                  <Td title={item.usu_codcli}>{item.usu_codcli}</Td>
                  <Td title={item.usu_nomcli}>{item.usu_nomcli}</Td>
                  <Td title={item.usu_seqipd}>{item.usu_seqipd}</Td>
                  <Td title={item.usu_codpro}>{item.usu_codpro}</Td>
                  <Td title={item.usu_despro}>{item.usu_despro}</Td>
                  <Td title={item.usu_qtdped}>{item.usu_qtdped}</Td>
                  <Td title={item.usu_datcar}>{item.usu_datcar}</Td>
                  <Td title={item.nomtra}>{item.nomtra}</Td>
                  <Td title={item.plavei}>{item.plavei}</Td>
                  <Td title={item.nommot}>{item.nommot}</Td>
                  <Td title={item.desser}>{item.desser}</Td>
                  <Td title={item.usu_sitord}>{item.usu_sitord}</Td>
                  <Td>
                    <IconButton
                      padding={1}
                      size={20}
                      onClick={handleCancel([item])}
                      icon={(
                        <DynamicIcon
                          family="md"
                          name="MdCheckCircle"
                          size={15}
                          color="#b83333"
                        />
                      )}
                    />
                  </Td>
                  {item.usu_sitord === 'E'
                    ? <Td>
                      <IconButton
                        padding={1}
                        size={20}
                        icon={(
                          <DynamicIcon
                            family="fa"
                            name="FaShippingFast"
                            size={15}
                            color="#000000"
                          />
                        )}
                      />
                    </Td>
                    : <> </>}
                </Tr>
              ))}
            </TBody>
          </Table>
        </Loader>
      </Container>
    </>
  )
}

export default Logistic
